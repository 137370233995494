var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "home",
      staticStyle: { "font-family": "Montserrat, sans-serif" }
    },
    [
      _c("top-nav-bar"),
      _vm._v(" "),
      _c("div", { staticClass: "home-header" }, [
        _c(
          "header",
          [
            _c("gift-box"),
            _vm._v(" "),
            _c("img", {
              staticClass: "tesla-logo-header",
              attrs: {
                id: "tesla-logo-header",
                src: require("assets/img/img-header-logo-small.png")
              }
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "container text-center home-head-btn-ctn" },
              [
                _c(
                  "a",
                  {
                    staticClass: "btn active shadow none home-head-btn",
                    attrs: { href: _vm.goToH("reservation") }
                  },
                  [_vm._v("IDŐPONTFOGLALÁS")]
                )
              ]
            ),
            _vm._v(" "),
            _vm._m(0)
          ],
          1
        )
      ]),
      _vm._v(" "),
      _vm._m(1),
      _vm._v(" "),
      _c("div", { attrs: { id: "kiloves-block" } }, [
        _c("div", { staticClass: "container" }, [
          _c("div", { staticClass: "row" }, [
            _vm._m(2),
            _vm._v(" "),
            _vm._m(3),
            _vm._v(" "),
            _vm._m(4),
            _vm._v(" "),
            _c("div", { staticClass: "col" }, [
              _c(
                "button",
                {
                  staticClass: "btn text-uppercase",
                  staticStyle: {
                    "background-color": "rgba(255,255,255,0)",
                    color: "rgb(0,0,0)",
                    "font-size": "1.5rem",
                    "font-family": "Montserrat, sans-serif",
                    "margin-right": "0px",
                    "padding-right": "15px",
                    "padding-top": "35px",
                    "padding-left": "0px"
                  },
                  on: {
                    click: function($event) {
                      return _vm.goToC("reservation")
                    }
                  }
                },
                [
                  _c("strong", [_vm._v("tovább")]),
                  _c("img", {
                    staticStyle: { "padding-left": "35px" },
                    attrs: { src: require("assets/img/btn-more.png") }
                  })
                ]
              )
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "row justify-content-sm-center justify-content-md-center col-12",
          staticStyle: {
            "padding-left": "0px",
            "padding-right": "15px",
            "margin-top": "80px",
            "margin-right": "0px",
            "margin-left": "0px",
            width: "auto"
          }
        },
        [
          _vm._m(5),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "col-xl-4 text-sm-center text-md-center text-lg-center text-xl-left align-self-center",
              attrs: { id: "ajandekozz" }
            },
            [
              _vm._m(6),
              _vm._v(" "),
              _c(
                "h3",
                {
                  staticClass:
                    "text-uppercase text-sm-center text-md-center text-lg-center text-xl-left"
                },
                [_vm._v("teslapilóta élményt!")]
              ),
              _vm._v(" "),
              _vm._m(7),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "Férjed, barátod, feleséged, barátnőd imádja a fejletépő gyorsulát, a suhanós száguldást? Ajándékozd meg egy felejthetetlen TeslaPilóta élménnyel! Garantáltan életreszóló élmény lesz ez az adrenalin-fröccs! Rendeld meg a 30, 60 vagy 90 perces\n                TeslaPilóta élményt még ma és postázzuk az ajándékkártyát, mely 12 hónapon belül felhasználható."
                )
              ]),
              _vm._v(" "),
              _vm._m(8),
              _c(
                "button",
                {
                  staticClass: "btn active shadow-none",
                  attrs: { id: "btn-giftcard" },
                  on: {
                    click: function($event) {
                      return _vm.goToC("reservation", { g: true })
                    }
                  }
                },
                [_vm._v("AJÁNDÉKKÁRTYA")]
              )
            ]
          )
        ]
      ),
      _vm._v(" "),
      _vm._m(9),
      _vm._v(" "),
      _c("div", [
        _c("div", { staticClass: "module", attrs: { id: "wrapper2" } }, [
          _c("header", [
            _c("h1", [_vm._v("\n                CSAPATÉPÍTŐ\n            ")]),
            _vm._v(" "),
            _c("h2", [
              _vm._v("\n                PROGRAMOT SZERVEZEL?\n            ")
            ]),
            _vm._v(" "),
            _vm._m(10),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn text-uppercase",
                staticStyle: {
                  "background-color": "rgba(255,255,255,0)",
                  color: "rgb(255,255,255)",
                  "font-size": "1.5rem",
                  "font-family": "Montserrat, sans-serif",
                  "margin-right": "0px",
                  "padding-right": "15px",
                  "padding-top": "35px",
                  "padding-left": "0px"
                },
                attrs: { id: "teambutton" },
                on: {
                  click: function($event) {
                    return _vm.goToC("company")
                  }
                }
              },
              [
                _c("strong", [_vm._v("tovább")]),
                _c("img", {
                  staticStyle: { "padding-left": "35px" },
                  attrs: { src: require("assets/img/btn-more-white.png") }
                })
              ]
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _vm._m(11),
      _vm._v(" "),
      _c(
        "section",
        {
          staticClass: "pricing-table",
          staticStyle: { "font-family": "Montserrat, sans-serif" }
        },
        [
          _c("div", { staticClass: "container" }, [
            _c("div", { staticClass: "pricing-area text-center" }, [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-sm-4 plan price red wow fadeInDown" },
                  [
                    _c("ul", { staticClass: "list-group" }, [
                      _vm._m(12),
                      _vm._v(" "),
                      _vm._m(13),
                      _vm._v(" "),
                      _c(
                        "li",
                        {
                          staticClass: "list-group-item",
                          staticStyle: {
                            color: "rgb(0,0,0)",
                            "background-color": "rgb(255,255,255)"
                          }
                        },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "btn active shadow-none",
                              on: {
                                click: function($event) {
                                  return _vm.goToC("reservation", { p: 0 })
                                }
                              }
                            },
                            [_vm._v("LEFOGLALOM")]
                          )
                        ]
                      )
                    ])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-sm-4 plan price yellow wow fadeInDown" },
                  [
                    _c("img", {
                      attrs: {
                        src: require("assets/img/bg-package-ribbon.png")
                      }
                    }),
                    _vm._v(" "),
                    _c("ul", { staticClass: "list-group" }, [
                      _vm._m(14),
                      _vm._v(" "),
                      _vm._m(15),
                      _vm._v(" "),
                      _c(
                        "li",
                        {
                          staticClass: "list-group-item",
                          staticStyle: {
                            color: "rgb(0,0,0)",
                            "background-color": "rgb(255,255,255)"
                          }
                        },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "btn active shadow-none",
                              on: {
                                click: function($event) {
                                  return _vm.goToC("reservation", { p: 1 })
                                }
                              }
                            },
                            [_vm._v("LEFOGLALOM")]
                          )
                        ]
                      )
                    ])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-sm-4 plan price green wow fadeInDown" },
                  [
                    _c("ul", { staticClass: "list-group" }, [
                      _vm._m(16),
                      _vm._v(" "),
                      _vm._m(17),
                      _vm._v(" "),
                      _c(
                        "li",
                        {
                          staticClass: "list-group-item",
                          staticStyle: {
                            color: "rgb(0,0,0)",
                            "background-color": "rgb(255,255,255)"
                          }
                        },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "btn active shadow-none",
                              on: {
                                click: function($event) {
                                  return _vm.goToC("reservation", { p: 2 })
                                }
                              }
                            },
                            [_vm._v("LEFOGLALOM")]
                          )
                        ]
                      )
                    ])
                  ]
                )
              ])
            ])
          ]),
          _vm._v(" "),
          _vm._m(18),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { "margin-top": "20px" } },
            [_c("tesla-pilot-calendar")],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "text-center",
              staticStyle: { "margin-top": "20px", "margin-bottom": "70px" }
            },
            [
              _c(
                "button",
                {
                  staticClass: "btn active shadow-none",
                  attrs: { id: "btn-reservation" },
                  on: {
                    click: function($event) {
                      return _vm.goToC("reservation")
                    }
                  }
                },
                [_vm._v("IDŐPONTFOGLALÁS")]
              )
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "col nolicence" }, [
            _vm._m(19),
            _vm._v(" "),
            _vm._m(20),
            _vm._v(" "),
            _vm._m(21),
            _c(
              "button",
              {
                staticClass: "btn text-uppercase",
                on: {
                  click: function($event) {
                    return _vm.goToC("reservation")
                  }
                }
              },
              [
                _c("strong", [_vm._v("tovább")]),
                _c("img", {
                  staticStyle: { "padding-left": "35px" },
                  attrs: { src: require("assets/img/btn-more.png") }
                })
              ]
            )
          ]),
          _vm._v(" "),
          _vm._m(22)
        ]
      ),
      _vm._v(" "),
      _vm._m(23),
      _vm._v(" "),
      _vm._m(24),
      _vm._v(" "),
      _vm._m(25),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "d-none d-xl-block",
          staticStyle: { "margin-bottom": "90px" }
        },
        [
          _c(
            "div",
            {
              staticClass:
                "row justify-content-sm-center justify-content-md-center col-12",
              staticStyle: {
                "padding-left": "0px",
                "padding-right": "15px",
                "margin-top": "80px",
                "margin-right": "0px",
                "margin-left": "0px",
                width: "auto"
              }
            },
            [
              _vm._m(26),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "col-xl-4 text-sm-center text-md-center text-lg-center text-xl-left align-self-center",
                  attrs: { id: "elmenyvideo" }
                },
                [
                  _vm._m(27),
                  _vm._v(" "),
                  _vm._m(28),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn active shadow-none",
                      attrs: { id: "btn-giftcard" },
                      on: {
                        click: function($event) {
                          return _vm.goVideos()
                        }
                      }
                    },
                    [_vm._v("TOVÁBB A VIDEÓKHOZ")]
                  )
                ]
              )
            ]
          )
        ]
      ),
      _vm._v(" "),
      _vm._m(29),
      _vm._v(" "),
      _vm._m(30)
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "container home-head-ctn" }, [
        _c("div", { staticClass: "row home-head-ctn-row" }, [
          _c(
            "div",
            { staticClass: "col home-head-row1 col-xs-6 col-sm-6 col-lg-4" },
            [
              _c("h2", [_vm._v("LEGYÉL TE IS"), _c("br")]),
              _vm._v(" "),
              _c("h1", [_c("strong", [_vm._v("TESLAPILÓTA!")]), _c("br")])
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col d-none d-lg-block home-head-row2 col-lg-4" },
            [
              _c("h1", [_c("strong", [_vm._v("2.6 S")]), _c("br")]),
              _vm._v(" "),
              _c("h2", [_vm._v("0 – 100 KM/H"), _c("br")]),
              _vm._v(" "),
              _c("p", [_vm._v("UTOLÉRHETETLEN GYORSULÁS"), _c("br")])
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col home-head-row3 col-xs-6 col-sm-6 col-lg-4" },
            [
              _c("h1", [_c("strong", [_vm._v("NE ÁLMODOZZ,")]), _c("br")]),
              _vm._v(" "),
              _c("h2", [
                _vm._v("HANEM ÉLD"),
                _c("br"),
                _vm._v("AZ ÁLMODAT!"),
                _c("br")
              ])
            ]
          )
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("main", { staticStyle: { "margin-top": "100px" } }, [
      _c(
        "div",
        {
          staticClass:
            "container-md text-left text-sm-center text-md-center text-lg-center text-xl-left",
          attrs: { id: "home-elmeny" }
        },
        [
          _c("h1", { staticClass: "text-uppercase" }, [
            _vm._v("Élménytesla vezetés")
          ]),
          _vm._v(" "),
          _c("h2", { staticClass: "text-uppercase" }, [
            _vm._v("Magyarországon")
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "Kilövésre felkészültél? Tesztelheted magad mint TeslaPilóta és mit tud a"
            ),
            _c("br"),
            _vm._v("772 lóerős P100D CsúcsTesla!")
          ])
        ]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "col-md-4", staticStyle: { "line-height": "1.1rem" } },
      [
        _c("h1", { staticClass: "text-uppercase" }, [
          _c("img", { attrs: { src: require("assets/img/line.png") } }),
          _c("br"),
          _vm._v("Velünk")
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "TeslaPilótaként forgalomban vezetheted a méregdrága sportkocsikat porig alázó P100D-t."
          )
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-4" }, [
      _c("h1", { staticClass: "text-uppercase" }, [
        _c("img", { attrs: { src: require("assets/img/line.png") } }),
        _c("br"),
        _vm._v("Kilövés 0-100")
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "Reméljük jól bírod a gyorsulást, mert egy TeslaPilótának bírnia kell a 0-100-at 2.6 mp alatt is."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "col-md-4", staticStyle: { "padding-right": "0px" } },
      [
        _c("h1", { staticClass: "text-uppercase" }, [
          _c("img", { attrs: { src: require("assets/img/line.png") } }),
          _c("br"),
          _vm._v("Autópilóta")
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "Próbáld ki milyen, amikor átadod a vezetést a Robotpilótának (autopilot üzemmód)."
          )
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "col-md-6 col-xl-7 text-sm-center text-md-center align-self-center",
        staticStyle: {
          "padding-right": "0px",
          "padding-left": "0px",
          "margin-right": "0px"
        }
      },
      [
        _c("div", { staticClass: "video-container" }, [
          _c("iframe", {
            attrs: {
              allowfullscreen: "",
              frameborder: "0",
              src:
                "https://www.youtube.com/embed/Kx8buCK-VBA?autoplay=1&mute=1&controls=0",
              width: "auto",
              height: "auto"
            }
          })
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "h1",
      {
        staticClass:
          "text-uppercase text-sm-center text-md-center text-lg-center text-xl-left justify-content-xl-center align-items-xl-end"
      },
      [_vm._v("ajándékozz"), _c("br")]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "h5",
      {
        staticClass: "text-sm-center text-md-center text-lg-center text-xl-left"
      },
      [
        _vm._v("Ne a tárgyakat gyűjtsd,"),
        _c("br"),
        _vm._v("hanem az élményeket!")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h4", { staticClass: "text-uppercase" }, [
      _vm._v("Remek ajándék"),
      _c("br"),
      _vm._v("minden alkalomra!")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "container-md",
        staticStyle: { "margin-top": "100px", "margin-bottom": "100px" }
      },
      [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            {
              staticClass:
                "col text-sm-center text-md-center text-lg-center text-xl-left align-self-center col-sm-12 col-md-12 col-lg-12 col-xl-4",
              staticStyle: {
                "border-left": "1px solid black",
                "border-right": "1px solid black",
                "padding-top": "16px",
                "padding-bottom": "16px"
              }
            },
            [
              _c(
                "h1",
                {
                  staticClass: "text-uppercase",
                  staticStyle: {
                    "font-weight": "700",
                    "font-size": "2.8rem",
                    "margin-bottom": "0px"
                  },
                  attrs: { id: "teambuild_header" }
                },
                [_vm._v("Csapatépítő")]
              ),
              _vm._v(" "),
              _c(
                "h2",
                {
                  staticClass: "text-uppercase",
                  staticStyle: { "font-weight": "700", "font-size": "1.8rem" }
                },
                [_vm._v("Teslateszt-kilövés")]
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col col-sm-12 col-md-12 col-lg-12 col-xl-4" },
            [
              _c(
                "h1",
                {
                  staticClass: "text-center counter",
                  staticStyle: { "font-size": "18rem", "font-weight": "800" }
                },
                [
                  _c("span", { attrs: { id: "counter3" } }, [_vm._v("3")]),
                  _vm._v(" "),
                  _c("span", { attrs: { id: "counter2" } }, [_vm._v("2")]),
                  _vm._v(" "),
                  _c("span", { attrs: { id: "counter1" } }, [_vm._v("1")])
                ]
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "col text-sm-center text-md-center text-lg-center text-xl-left align-self-center col-sm-12 col-md-12 col-lg-12 col-xl-4",
              staticStyle: {
                "border-left": "1px solid black",
                "border-right": "1px solid black"
              }
            },
            [
              _c(
                "h2",
                {
                  staticClass: "text-uppercase",
                  staticStyle: {
                    "font-size": "1.8rem",
                    "font-weight": "700",
                    "margin-bottom": "0px"
                  }
                },
                [_vm._v("Mert van amit csak")]
              ),
              _vm._v(" "),
              _c(
                "h1",
                {
                  staticClass: "text-uppercase",
                  staticStyle: {
                    "font-weight": "700",
                    "font-size": "2.8rem",
                    "margin-bottom": "0px"
                  }
                },
                [_vm._v("a teslával")]
              ),
              _vm._v(" "),
              _c(
                "h2",
                {
                  staticClass: "text-uppercase",
                  staticStyle: { "font-size": "1.8rem", "font-weight": "700" }
                },
                [_vm._v("élhetsz át")]
              )
            ]
          )
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v("A TeslaPilóta programja garantáltan"),
      _c("br"),
      _vm._v("\n                szórakoztató élmény lesz a csapat tagjainak.")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "container", staticStyle: { "margin-top": "100px" } },
      [
        _c(
          "h1",
          {
            staticClass: "text-uppercase",
            staticStyle: {
              "font-family": "Montserrat, sans-serif",
              "font-weight": "700",
              "margin-bottom": "0px",
              "font-size": "3rem"
            }
          },
          [_vm._v("Foglalj helyet")]
        ),
        _vm._v(" "),
        _c(
          "h2",
          {
            staticClass: "text-uppercase",
            staticStyle: {
              "font-family": "Montserrat, sans-serif",
              "font-weight": "500",
              "margin-bottom": "35px",
              "font-size": "2rem"
            }
          },
          [_vm._v("A teslapilóta-ülésben!")]
        ),
        _vm._v(" "),
        _c("p", { staticStyle: { "font-size": "1.1rem" } }, [
          _vm._v("Válassz a TeslaPilóta csomagok közül!"),
          _c("br"),
          _vm._v("Vigyázat! Erősen addiktív élmény. :)")
        ]),
        _c("img", {
          staticStyle: { "margin-top": "20px", "margin-bottom": "36px" },
          attrs: { src: require("assets/img/line.png") }
        })
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "li",
      {
        staticClass: "list-group-item heading",
        staticStyle: {
          "margin-left": "0px",
          "background-color": "rgb(255,255,255)"
        }
      },
      [
        _c(
          "h1",
          {
            staticClass: "text-uppercase",
            staticStyle: {
              color: "rgb(0,0,0)",
              "margin-bottom": "0px",
              "font-family": "Montserrat, sans-serif",
              "font-weight": "600",
              "font-size": "2rem"
            }
          },
          [_vm._v("Start")]
        ),
        _vm._v(" "),
        _c("p", { staticStyle: { color: "rgb(0,0,0)" } }, [
          _vm._v("Szólóban vezethetsz")
        ]),
        _c("span", { staticClass: "price" }, [_vm._v("49.900 Ft")])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "li",
      {
        staticClass: "list-group-item",
        staticStyle: {
          color: "rgb(0,0,0)",
          "background-color": "rgb(255,255,255)",
          height: "320px"
        }
      },
      [
        _c("p", [_vm._v("TeslaPilóta extrém gyorsulás")]),
        _vm._v(" "),
        _c("p", [_vm._v("Autopilot mód")]),
        _vm._v(" "),
        _c("p", { staticStyle: { "margin-bottom": "40%" } }, [
          _vm._v("Élményvezetés forgalomban")
        ]),
        _vm._v(" "),
        _c("p", { staticStyle: { "margin-top": "0px" } }, [
          _vm._v("Legfeljebb 1 fő vezethet")
        ]),
        _vm._v(" "),
        _c("p", [_vm._v("30 perc TeslaPilóta élményvezetés")])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "li",
      {
        staticClass: "list-group-item heading",
        staticStyle: {
          "margin-left": "0px",
          "background-color": "rgb(255,255,255)"
        }
      },
      [
        _c(
          "h1",
          {
            staticClass: "text-uppercase",
            staticStyle: {
              color: "rgb(0,0,0)",
              "margin-bottom": "0px",
              "font-family": "Montserrat, sans-serif",
              "font-weight": "600",
              "font-size": "2rem"
            }
          },
          [_vm._v("Dupla")]
        ),
        _vm._v(" "),
        _c("p", { staticStyle: { color: "rgb(0,0,0)" } }, [
          _vm._v("Párban vezethettek")
        ]),
        _c("span", { staticClass: "price" }, [_vm._v("59.900 Ft")])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "li",
      {
        staticClass: "list-group-item",
        staticStyle: { height: "320px", "background-color": "rgb(255,255,255)" }
      },
      [
        _c("p", [_vm._v("TeslaPilóta extrém gyorsulás")]),
        _vm._v(" "),
        _c("p", [_vm._v("Autopilot mód")]),
        _vm._v(" "),
        _c(
          "p",
          { staticStyle: { color: "rgb(0,0,0)", "margin-bottom": "20%" } },
          [_vm._v("Élményvezetés forgalomban")]
        ),
        _vm._v(" "),
        _c("p", { staticStyle: { color: "rgb(0,0,0)" } }, [
          _vm._v("Legfeljebb 2 fő vezethet")
        ]),
        _vm._v(" "),
        _c("p", [_vm._v("(29.950 Ft/fő)*")]),
        _vm._v(" "),
        _c("p", [_vm._v("*2 fő foglalása esetén")]),
        _vm._v(" "),
        _c("p", [_vm._v("60 perc TeslaPilóta élményvezetés")])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "li",
      {
        staticClass: "list-group-item heading",
        staticStyle: {
          "margin-left": "0px",
          "background-color": "rgb(255,255,255)"
        }
      },
      [
        _c(
          "h1",
          {
            staticClass: "text-uppercase",
            staticStyle: {
              color: "rgb(0,0,0)",
              "margin-bottom": "0px",
              "font-family": "Montserrat, sans-serif",
              "font-weight": "600",
              "font-size": "2rem"
            }
          },
          [_vm._v("Tripla")]
        ),
        _vm._v(" "),
        _c("p", { staticStyle: { color: "rgb(0,0,0)" } }, [
          _vm._v("Hárman vezethettek")
        ]),
        _c("span", { staticClass: "price" }, [_vm._v("83.900 Ft")])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "li",
      {
        staticClass: "list-group-item",
        staticStyle: { "background-color": "rgb(255,255,255)", height: "320px" }
      },
      [
        _c("p", [_vm._v("TeslaPilóta extrém gyorsulás")]),
        _vm._v(" "),
        _c("p", [_vm._v("Autopilot mód")]),
        _vm._v(" "),
        _c(
          "p",
          { staticStyle: { color: "rgb(0,0,0)", "margin-bottom": "20%" } },
          [_vm._v("Élményvezetés forgalomban")]
        ),
        _vm._v(" "),
        _c("p", [_vm._v("Legfeljebb 3 fő vezethet")]),
        _vm._v(" "),
        _c("p", [_vm._v("(27.966 Ft/fő)*")]),
        _vm._v(" "),
        _c("p", [_vm._v("*3 fő foglalása esetén")]),
        _vm._v(" "),
        _c("p", [_vm._v("90 perc TeslaPilóta élményvezetés")])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "container", attrs: { id: "legkozelebbi" } },
      [
        _c(
          "h1",
          {
            staticClass: "text-uppercase",
            staticStyle: {
              "font-family": "Montserrat, sans-serif",
              "font-weight": "700",
              "margin-bottom": "0px",
              "font-size": "2.5rem"
            }
          },
          [_vm._v("legközelebbi")]
        ),
        _vm._v(" "),
        _c(
          "h1",
          {
            staticClass: "text-uppercase",
            staticStyle: {
              "font-family": "Montserrat, sans-serif",
              "font-weight": "700",
              "margin-bottom": "0px",
              "font-size": "3rem"
            }
          },
          [_vm._v("szabad időpontok!")]
        ),
        _vm._v(" "),
        _c("p", { staticStyle: { "font-size": "1.1rem" } }, [
          _vm._v(
            "Nézd meg a közelgő TeslaPilóta élményvezetés időpontokat és válaszd"
          ),
          _c("br"),
          _vm._v("ki a hozzád legközelebb eső helyszínt!")
        ]),
        _c("img", { attrs: { src: require("assets/img/line.png") } })
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h1", { staticClass: "text-uppercase" }, [
      _vm._v("Nincs még"),
      _c("br"),
      _vm._v("JOGOSÍTVÁNYOD?"),
      _c("br")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h2", [
      _vm._v("akkor"),
      _c("br"),
      _vm._v("mi leszünk"),
      _c("br"),
      _vm._v("a teslapilótád!")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v("Ha nincs még jogosítványod vagy"),
      _c("br"),
      _vm._v("annak, akit meg szeretnél lepni az"),
      _c("br"),
      _vm._v("élménnyel, akkor sincs gond, mert"),
      _c("br"),
      _vm._v("akkor mi leszünk a TeslaPilótátok. Min-"),
      _c("br"),
      _vm._v("dent ugyanúgy meg tudunk mutatni,"),
      _c("br"),
      _vm._v("pl. hogy vezet az autopilot és a gyor-"),
      _c("br"),
      _vm._v("sulás élménye\n                    pedig ugyanolyan"),
      _c("br"),
      _vm._v("mélyreható lesz, ezt megígérhetjük :)")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "container home-joyridectn" }, [
      _c("h2", [_vm._v("a teslapilóta")]),
      _vm._v(" "),
      _c("h1", [_vm._v("élményvezetés menete:")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "container drivefaq",
        attrs: { id: "container-drivefaq" }
      },
      [
        _c(
          "div",
          { staticClass: "table-responsive table-borderless faqtable" },
          [
            _c("table", { staticClass: "table table-bordered table-sm" }, [
              _c("tbody", [
                _c("tr", [
                  _c("td", [_c("p", [_vm._v("1")])]),
                  _vm._v(" "),
                  _c("td", [_c("p", [_vm._v("2")])]),
                  _vm._v(" "),
                  _c("td", [_c("p", [_vm._v("3")])]),
                  _vm._v(" "),
                  _c("td", [_c("p", [_vm._v("4")])])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [
                    _c("span", [
                      _c("br"),
                      _vm._v(
                        "Lehetőség szerint az egyeztetett időpont előtt pár perccel érkezz a helyszínre."
                      ),
                      _c("br"),
                      _c("br")
                    ])
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _c("span", [
                      _c("br"),
                      _vm._v(
                        "Elintézzük a formaságokat, kitöltjük a szükséges iratokat, legyen nálad ÉRVÉNYES jogosítvány, megfújod a szondát. Kérünk, hogy a vezetést 12 órával megelőzően ne fogyassz alkoholt, drogokat meg egyáltalán ne!"
                      ),
                      _c("br"),
                      _c("br")
                    ])
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _c("span", [
                      _c("br"),
                      _vm._v(
                        "Instruktor TeslaPilótánk bemutatja neked a Tesla P100D-t és a legfontosabb tudnivalókat, nyugodtan tedd fel kérdéseidet, ez nem a te idődből megy!"
                      ),
                      _c("br"),
                      _c("br")
                    ])
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _c("span", [
                      _c("br"),
                      _vm._v(
                        "Ha hoztál magaddal GoPro kamerát, akkor azt használhatod. Csak szólj időben, hogy még vezetés előtt beélesíthessük."
                      ),
                      _c("br"),
                      _c("br")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [_c("p", [_vm._v("5")])]),
                  _vm._v(" "),
                  _c("td", [_c("p", [_vm._v("6")])]),
                  _vm._v(" "),
                  _c("td", [_c("p", [_vm._v("7")])]),
                  _vm._v(" "),
                  _c("td")
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [
                    _c("span", [
                      _c("br"),
                      _vm._v(
                        "Ha minden tudnivavalót megbeszéltünk, helyet foglalsz a TeslaPilóta ülésében, beállítod az ülést és a tükröket, hogy ezt már ne vezetés közben kelljen állítgatni."
                      ),
                      _c("br"),
                      _c("br")
                    ])
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _c("span", [
                      _c("br"),
                      _vm._v(
                        "Elindulást követően eleinte óvatosan vezess, hogy még megszokd, érezd az autót, és főleg a gázpedált ;) Aztán jöhet a tempósabb vezetés, kipróbálhatod a Tesla minden funkcióját és képességét."
                      ),
                      _c("br"),
                      _c("br")
                    ])
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _c("span", [
                      _c("br"),
                      _vm._v(
                        "Visszaérkezés a kiindulási pontra, igény esetén fotózás, szelfizés."
                      ),
                      _c("br"),
                      _c("br")
                    ])
                  ]),
                  _vm._v(" "),
                  _c("td")
                ])
              ])
            ])
          ]
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "container enjoyment-ctn d-none d-md-block" },
      [
        _c("h1", [_vm._v("A tesla")]),
        _vm._v(" "),
        _c("h2", [_vm._v("élménymérő")])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "container speedbar d-none d-md-block",
        staticStyle: { "margin-top": "90px", "margin-bottom": "90px" }
      },
      [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-xl-3" }, [
            _c("span", [
              _c("br"),
              _c("strong", [_vm._v("Gyorsulás élménye")]),
              _c("br")
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-xl-7 col-lg-10 col-md-10" }, [
            _c("div", { attrs: { id: "ui" } }, [
              _c("div", { staticClass: "night" }),
              _vm._v(" "),
              _c("div", { staticClass: "night" }),
              _vm._v(" "),
              _c("div", { staticClass: "night" }),
              _vm._v(" "),
              _c("div", { staticClass: "night" }),
              _vm._v(" "),
              _c("div", { staticClass: "night" }),
              _vm._v(" "),
              _c("div", { staticClass: "night" }),
              _vm._v(" "),
              _c("div", { staticClass: "night" }),
              _vm._v(" "),
              _c("div", { staticClass: "night" }),
              _vm._v(" "),
              _c("div", { staticClass: "night" }),
              _vm._v(" "),
              _c("div", { staticClass: "night" }),
              _vm._v(" "),
              _c("div", { staticClass: "night" })
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-xl-2 col-lg-2 col-md-2" }, [
            _c("span", [_c("br"), _c("strong", [_vm._v("11/10")]), _c("br")])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-xl-3" }, [
            _c("span", [
              _c("br"),
              _c("strong", [_vm._v("A Tesla Robotpilóta élménye")]),
              _c("br")
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-xl-7 col-lg-10 col-md-10" }, [
            _c("div", { attrs: { id: "ui2" } }, [
              _c("div", { staticClass: "night" }),
              _vm._v(" "),
              _c("div", { staticClass: "night" }),
              _vm._v(" "),
              _c("div", { staticClass: "night" }),
              _vm._v(" "),
              _c("div", { staticClass: "night" }),
              _vm._v(" "),
              _c("div", { staticClass: "night" }),
              _vm._v(" "),
              _c("div", { staticClass: "night" }),
              _vm._v(" "),
              _c("div", { staticClass: "night" }),
              _vm._v(" "),
              _c("div", { staticClass: "night" }),
              _vm._v(" "),
              _c("div", { staticClass: "night" }),
              _vm._v(" "),
              _c("div", { staticClass: "night" })
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-xl-2 col-lg-2 col-md-2" }, [
            _c("span", [_c("br"), _c("strong", [_vm._v("10/10")]), _c("br")])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-xl-3" }, [
            _c("span", [
              _c("br"),
              _c("strong", [_vm._v("Útfekvés, stabilitás")]),
              _c("br")
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-lg-10 col-xl-7 col-md-10" }, [
            _c("div", { attrs: { id: "ui3" } }, [
              _c("div", { staticClass: "night" }),
              _vm._v(" "),
              _c("div", { staticClass: "night" }),
              _vm._v(" "),
              _c("div", { staticClass: "night" }),
              _vm._v(" "),
              _c("div", { staticClass: "night" }),
              _vm._v(" "),
              _c("div", { staticClass: "night" }),
              _vm._v(" "),
              _c("div", { staticClass: "night" }),
              _vm._v(" "),
              _c("div", { staticClass: "night" }),
              _vm._v(" "),
              _c("div", { staticClass: "night" }),
              _vm._v(" "),
              _c("div", { staticClass: "night" }),
              _vm._v(" "),
              _c("div", { staticClass: "night" })
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-lg-2 col-xl-2 col-md-2" }, [
            _c("span", [_c("br"), _c("strong", [_vm._v("10/10")]), _c("br")])
          ])
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "col-md-6 col-xl-7 text-sm-center text-md-center align-self-center",
        staticStyle: {
          "padding-right": "0px",
          "padding-left": "0px",
          "margin-right": "0px"
        }
      },
      [
        _c("div", { staticClass: "video-container" }, [
          _c("iframe", {
            attrs: {
              allowfullscreen: "",
              frameborder: "0",
              src: "https://www.youtube.com/embed/plR4SfLRMjQ",
              width: "auto",
              height: "auto"
            }
          })
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "h1",
      {
        staticClass:
          "text-uppercase text-sm-center text-md-center text-lg-center text-xl-left justify-content-xl-center align-items-xl-end"
      },
      [_vm._v("még több"), _c("br")]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "h3",
      {
        staticClass:
          "text-uppercase text-sm-center text-md-center text-lg-center text-xl-left"
      },
      [_vm._v("teslapilóta "), _c("br"), _vm._v("élmény videó!")]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "d-none d-xl-block" }, [
      _c("div", { staticClass: "text-uppercase" }, [
        _c("div", { staticClass: "container" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-md-4 col-xl-10 offset-xl-1" }, [
              _c("h1", { staticClass: "text-uppercase text-left" }, [
                _c("strong", [_vm._v("KÖVESS")])
              ])
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", [
        _c("div", { staticClass: "container" }, [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              {
                staticClass:
                  "col-md-4 col-xl-2 offset-xl-5 text-right align-self-center"
              },
              [
                _c(
                  "a",
                  { attrs: { href: "http://www.facebook.com/teslapilota" } },
                  [
                    _c("img", {
                      staticStyle: { "padding-right": "1.875rem" },
                      attrs: { src: require("assets/img/btn-facebook.png") }
                    })
                  ]
                ),
                _c(
                  "a",
                  { attrs: { href: "http://www.instagram.com/teslapilota" } },
                  [
                    _c("img", {
                      attrs: { src: require("assets/img/btn-instagram.png") }
                    })
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "col-md-4 col-xl-5 offset-xl-0" }, [
              _c("h1", { staticClass: "text-uppercase text-right" }, [
                _c("strong", [_vm._v("MINKET")])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "clearfix" })
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mobile d-block d-xl-none" }, [
      _vm._v("\n        Kövess minket\n        "),
      _c("div", [
        _c(
          "a",
          {
            attrs: {
              href: "https://www.facebook.com/TeslaPilota/",
              target: "_blank"
            }
          },
          [
            _c("img", {
              attrs: { src: require("assets/img/btn-facebook.png") }
            })
          ]
        ),
        _vm._v(" "),
        _c(
          "a",
          {
            attrs: {
              href: "https://www.instagram.com/teslapilota/",
              target: "_blank"
            }
          },
          [
            _c("img", {
              attrs: { src: require("assets/img/btn-instagram.png") }
            })
          ]
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
import Vue from 'vue';

// app specific
import router from './plugins/router';
//import store from "./plugins/store";

import './plugins/bootstrap-vue';
import './plugins/axios';
import './plugins/validate';
import "./plugins/api";

import app from './app.vue';

// Config
Vue.config.productionTip = false;

// App
new Vue({
    router,
    //store,
    render: h => h(app),
}).$mount('#vue-app');

<template>
  <div class="not-found">
    <div class="not-found-header">
      <top-nav-bar />
      <div class="carousel-caption">
        <h1
          class="title"
          style="position: relative;bottom: 11.3rem;font-size: 8rem;"
        >
          404
        </h1>
        <h1
          class="sub-title"
          style="position: relative;bottom: 11.3rem;font-size: 4rem;"
        >
          A keresett oldal nem található!
        </h1>
        <h3
          style="position: relative;bottom: 8.8rem;font-size: 2.5rem;"
          class="font-weight-light"
        >
          Kérjük ellenőrizze a beírt címet!
        </h3>
      </div>
    </div>
  </div>
</template>

<script>
  import TopNavBar from '../general/top-nav-bar';

  export default {
    name: "not-found",
    components: {
      TopNavBar
    }
  };
</script>

<style scoped>
  body {
    margin-top: 150px;
    background-color: #C4CCD9;
  }
</style>

<template>
  <div class="contact">
    <div class="contact-header">
      <top-nav-bar />
      <header>
        <h1>KAPCSOLAT</h1>
      </header>
    </div>
    <div style="padding-top: 5.625rem;">
      <div class="container">
        <div class="row">
          <div class="col">
            <h1></h1>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <h1 class="text-uppercase"><strong>Üzenetküldés</strong></h1>
            <h3 class="text-uppercase">A Teslapilótának</h3>
            <p>
              Ha kérésed vagy kérdésed lenne, akkor küldj üzenetet a Teslapilóta
              irányítóközpontba! Add meg adataidat, hogy felvehessük veled a
              kapcsolatot.
            </p>
          </div>
          <div class="col-md-6">
            <contact-form />
          </div>
        </div>
      </div>
    </div>
    <div class="text-right">
      <img
        class="center"
        src="~assets/img/img-header-logo-small-gray.png"
      />
    </div>
    <div class="d-none d-xl-block">
      <div class="text-uppercase">
        <div class="container">
          <div class="row">
            <div class="col-md-4 col-xl-10 offset-xl-2">
              <h1 class="text-uppercase text-left"><strong>KÖVESS</strong></h1>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="container">
          <div class="row">
            <div
              class="col-md-4 col-xl-2 offset-xl-5 text-right align-self-center"
            >
              <a href="http://www.facebook.com/teslapilota"
                ><img
                  style="padding-right: 1.875rem"
                  src="~assets/img/btn-facebook.png"/></a
              ><a href="http://www.instagram.com/teslapilota"
                ><img src="~assets/img/btn-instagram.png"
              /></a>
            </div>
            <div class="col-md-4 col-xl-4 offset-xl-0">
              <h1 class="text-uppercase text-right"><strong>MINKET</strong></h1>
            </div>
            <div class="clearfix"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="mobile d-block d-xl-none">
      Kövess minket
      <div>
        <a href="https://www.facebook.com/TeslaPilota/" target="_blank">
          <img src="~assets/img/btn-facebook.png" />
        </a>
        <a href="https://www.instagram.com/teslapilota/" target="_blank">
          <img src="~assets/img/btn-instagram.png" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
  import TopNavBar from '../general/top-nav-bar';
  import ContactForm from '../general/contact-form';

  export default {
    name: "contact",
    components: {
      TopNavBar,
      ContactForm
    }
  };
</script>

<style scoped>
.b-row {
  text-transform: uppercase;
}

img.center {
  display: block;
  margin: 0 auto;
}
.form-control.form-control-lg {
  padding-top: 0.5rem;
  margin: 0rem;
  height: 3.125rem;
}

.form-control.form-control-lg-msg {
  padding-top: 0.5rem;
  margin: 0rem;
  height: 12.5rem;
}

.btn.text-uppercase > img {
  padding-left: 2.1875rem;
}

.btn.text-uppercase {
  background-color: rgba(255,255,255,0);
  color: rgb(0,0,0);
  font-size: 1.5rem;
  font-family: Montserrat, sans-serif;
  margin-right: 0px;
  padding-right: 0.9375rem;
  padding-top: 2.1875rem;
}

.col-md-6 > form {
  margin: 0.625rem;
}

.col-md-4.col-xl-2.offset-xl-5.text-right.align-self-center > img {
  padding-left: 1rem;
}

.text-uppercase {
  margin-bottom: 0rem;
}

.center {
  padding-top: 8rem;
  padding-bottom: 6rem;
  align-content: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: auto;
}

.text-uppercase.text-right {
  -webkit-tap-highlight-color: transparent;
  --breakpoint-xs: 0;
  --breakpoint-sm: 36rem;
  --breakpoint-md: 48rem;
  --breakpoint-lg: 62rem;
  --breakpoint-xl: 75rem;
  --font-family-sans-serif: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  --font-family-monospace: SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
  text-align: right;
  text-transform: uppercase;
  font-family: Raleway;
  font-weight: 800;
  color: #b6b6b6;
  box-sizing: border-box;
  margin-bottom: 1rem;
  font-size: 7.0625rem;
  line-height: 7.0625rem;
  margin-top: -1.875rem;
}

.text-uppercase.text-left {
  -webkit-tap-highlight-color: transparent;
  --breakpoint-xs: 0;
  --breakpoint-sm: 36rem;
  --breakpoint-md: 48rem;
  --breakpoint-lg: 62rem;
  --breakpoint-xl: 75rem;
  text-align: left;
  text-transform: uppercase;
  font-family: Raleway;
  font-weight: 800;
  color: #b6b6b6;
  box-sizing: border-box;
  margin-top: 0;
  font-size: 11.875rem;
  line-height: 11.875rem;
  margin-bottom: 0;
}

.d-xl-none {
  -webkit-tap-highlight-color: transparent;
  line-height: 1.5;
  text-align: center;
  color: #2b2b2b;
  text-transform: uppercase;
  font-family: Raleway;
  font-weight: 800;
  box-sizing: border-box;
  font-size: 41px;
  padding-bottom: 2rem;
}
</style>

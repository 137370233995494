<template>
  <div class="contact-form">
    <form method="post" style="margin: 10px;">
      <div class="form-group">
        <input
                class="form-control form-control-lg"
                type="text"
                name="name"
                placeholder="Neved"
                style="padding-top: 8px;margin: 0px;height: 50px;"
                v-model="$v.data.name.$model"
        />
        <div class="error" v-if="!$v.data.name.required">Töltsd ki a név mezőt!</div>
      </div>
      <div class="form-group">
        <input
                class="form-control form-control-lg"
                type="text"
                name="email"
                placeholder="E-mail címed"
                style="height: 50px;"
                v-model="$v.data.email.$model"
        />
        <div class="error" v-if="!$v.data.email.required">Töltsd ki az e–mail cím mezőt!</div>
        <div class="error" v-if="!$v.data.email.email">Hibás e–mail cím!</div>
      </div>
      <div class="form-group">
        <input
                class="form-control form-control-lg"
                type="text"
                name="phone"
                placeholder="Telefon számod"
                style="height: 50px;"
                v-model="data.phone"
        />
      </div>
      <div class="form-group">
        <textarea
                class="border rounded form-control form-control-lg"
                name="message"
                placeholder="Üzenet"
                style="height: 180px;"
                v-model="$v.data.message.$model"
        >
        </textarea>
        <div class="error" v-if="!$v.data.message.required">Töltsd ki az üzenet mezőt!</div>
      </div>
      <div class="form-group">
        <button
                class="btn text-uppercase"
                type="submit"
                style="background-color: rgba(255,255,255,0);color: rgb(0,0,0);font-size: 1.5rem;font-family: Montserrat, sans-serif;margin-right: 0px;padding-right: 15px;padding-top: 35px;"
                @click.prevent="send_message"
                :disabled="$v.$invalid||send"
        >
          <strong>Küldés</strong><img src="~assets/img/btn-send.png" style="padding-left: 35px;" />
        </button>
      </div>
    </form>
  </div>
</template>

<script>
  import { required, email } from 'vuelidate/lib/validators';

  export default
  {
    name: "contact-form",

    data()
    {
      return {
        data: {
          name: '',
          email: '',
          phone: '',
          message: ''
        },
        send: false
      };
    },

    methods:
    {
      send_message: function()
      {
        this.send = true;
        this.$api
                .contact(this.data)
                .then((res1) =>
                {
                  if (!res1.success)
                  {
                    this.$bvModal.msgBoxOk("Hiba történt, próbálkozzon újra később!", {okVariant: 'danger'});
                  }
                  else
                  {
                    this.$bvModal.msgBoxOk("Üzenet elküldve.");
                  }
                  this.send = false;
                });
      }
    },

    //TODO add validation, req, email
    validations()
    {
      return {
        data: {
          name: { required },
          email: { required, email },
          message: { required }
        }
      }
    }
  };
</script>

<style scoped>
  .error {
    color: red;
  }
</style>

import Vue from 'vue';

import Common from "../api/common";

const ApiPlugin = {
    install (vue, options = {}) {
        vue.prototype.$api = Common;
    }
};

Vue.use(ApiPlugin);

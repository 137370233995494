var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "top-nav-bar" },
    [
      _c(
        "b-navbar",
        {
          attrs: {
            toggleable: "lg",
            type: "dark",
            variant: "custom",
            fixed: "top",
            id: "top-nav-bar"
          }
        },
        [
          _c("b-navbar-brand", [
            _c("a", { attrs: { href: "/" } }, [
              _c("div", { staticClass: "logo" })
            ])
          ]),
          _vm._v(" "),
          _c("b-navbar-toggle", { attrs: { target: "nav-collapse" } }),
          _vm._v(" "),
          _c(
            "b-collapse",
            { attrs: { id: "nav-collapse", "is-nav": "" } },
            [
              _c(
                "b-navbar-nav",
                { staticClass: "ml-auto pt-auto pb-auto" },
                [
                  _c("b-nav-item", { attrs: { active: "", href: "/" } }, [
                    _c("div", { staticClass: "home" })
                  ]),
                  _vm._v(" "),
                  _c("b-nav-item", { attrs: { href: _vm.goTo("about") } }, [
                    _vm._v("Rólunk")
                  ]),
                  _vm._v(" "),
                  _c(
                    "b-nav-item",
                    { attrs: { href: _vm.goTo("reservation") } },
                    [_vm._v("Időpontfoglalás")]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-nav-item",
                    {
                      attrs: {
                        href:
                          "https://www.youtube.com/channel/UCG6NYETa6O-jtRkVCtbazFw"
                      }
                    },
                    [_vm._v("Videók")]
                  ),
                  _vm._v(" "),
                  _c("b-nav-item", { attrs: { href: _vm.goTo("company") } }, [
                    _vm._v("Cégeknek")
                  ]),
                  _vm._v(" "),
                  _c("b-nav-item", { attrs: { href: _vm.goTo("tours") } }, [
                    _vm._v("Transzferek")
                  ]),
                  _vm._v(" "),
                  _c("b-nav-item", { attrs: { href: "http://tesla.hu" } }, [
                    _vm._v("Teslát vennék")
                  ]),
                  _vm._v(" "),
                  _c("b-nav-text", [_vm._v("|")]),
                  _vm._v(" "),
                  _c("b-nav-item", { attrs: { href: _vm.goTo("contact") } }, [
                    _vm._v("Kapcsolat")
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "contact" }, [
    _c(
      "div",
      { staticClass: "contact-header" },
      [_c("top-nav-bar"), _vm._v(" "), _vm._m(0)],
      1
    ),
    _vm._v(" "),
    _c("div", { staticStyle: { "padding-top": "5.625rem" } }, [
      _c("div", { staticClass: "container" }, [
        _vm._m(1),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _vm._m(2),
          _vm._v(" "),
          _c("div", { staticClass: "col-md-6" }, [_c("contact-form")], 1)
        ])
      ])
    ]),
    _vm._v(" "),
    _vm._m(3),
    _vm._v(" "),
    _vm._m(4),
    _vm._v(" "),
    _vm._m(5)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("header", [_c("h1", [_vm._v("KAPCSOLAT")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col" }, [_c("h1")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-6" }, [
      _c("h1", { staticClass: "text-uppercase" }, [
        _c("strong", [_vm._v("Üzenetküldés")])
      ]),
      _vm._v(" "),
      _c("h3", { staticClass: "text-uppercase" }, [_vm._v("A Teslapilótának")]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "\n            Ha kérésed vagy kérdésed lenne, akkor küldj üzenetet a Teslapilóta\n            irányítóközpontba! Add meg adataidat, hogy felvehessük veled a\n            kapcsolatot.\n          "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text-right" }, [
      _c("img", {
        staticClass: "center",
        attrs: { src: require("assets/img/img-header-logo-small-gray.png") }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "d-none d-xl-block" }, [
      _c("div", { staticClass: "text-uppercase" }, [
        _c("div", { staticClass: "container" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-md-4 col-xl-10 offset-xl-2" }, [
              _c("h1", { staticClass: "text-uppercase text-left" }, [
                _c("strong", [_vm._v("KÖVESS")])
              ])
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", [
        _c("div", { staticClass: "container" }, [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              {
                staticClass:
                  "col-md-4 col-xl-2 offset-xl-5 text-right align-self-center"
              },
              [
                _c(
                  "a",
                  { attrs: { href: "http://www.facebook.com/teslapilota" } },
                  [
                    _c("img", {
                      staticStyle: { "padding-right": "1.875rem" },
                      attrs: { src: require("assets/img/btn-facebook.png") }
                    })
                  ]
                ),
                _c(
                  "a",
                  { attrs: { href: "http://www.instagram.com/teslapilota" } },
                  [
                    _c("img", {
                      attrs: { src: require("assets/img/btn-instagram.png") }
                    })
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "col-md-4 col-xl-4 offset-xl-0" }, [
              _c("h1", { staticClass: "text-uppercase text-right" }, [
                _c("strong", [_vm._v("MINKET")])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "clearfix" })
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mobile d-block d-xl-none" }, [
      _vm._v("\n    Kövess minket\n    "),
      _c("div", [
        _c(
          "a",
          {
            attrs: {
              href: "https://www.facebook.com/TeslaPilota/",
              target: "_blank"
            }
          },
          [
            _c("img", {
              attrs: { src: require("assets/img/btn-facebook.png") }
            })
          ]
        ),
        _vm._v(" "),
        _c(
          "a",
          {
            attrs: {
              href: "https://www.instagram.com/teslapilota/",
              target: "_blank"
            }
          },
          [
            _c("img", {
              attrs: { src: require("assets/img/btn-instagram.png") }
            })
          ]
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
import { render, staticRenderFns } from "./home.vue?vue&type=template&id=f964efea&scoped=true&"
import script from "./home.vue?vue&type=script&lang=js&"
export * from "./home.vue?vue&type=script&lang=js&"
import style0 from "./home.vue?vue&type=style&index=0&id=f964efea&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f964efea",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\Dev\\Web\\Projects\\teslapilota\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('f964efea')) {
      api.createRecord('f964efea', component.options)
    } else {
      api.reload('f964efea', component.options)
    }
    module.hot.accept("./home.vue?vue&type=template&id=f964efea&scoped=true&", function () {
      api.rerender('f964efea', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "assets/js/components/pages/home.vue"
export default component.exports
import { render, staticRenderFns } from "./contact.vue?vue&type=template&id=06b57624&scoped=true&"
import script from "./contact.vue?vue&type=script&lang=js&"
export * from "./contact.vue?vue&type=script&lang=js&"
import style0 from "./contact.vue?vue&type=style&index=0&id=06b57624&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06b57624",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\Dev\\Web\\Projects\\teslapilota\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('06b57624')) {
      api.createRecord('06b57624', component.options)
    } else {
      api.reload('06b57624', component.options)
    }
    module.hot.accept("./contact.vue?vue&type=template&id=06b57624&scoped=true&", function () {
      api.rerender('06b57624', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "assets/js/components/pages/contact.vue"
export default component.exports
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.gift
    ? _c("div", { attrs: { id: "giftbox" } }, [
        _c("a", { attrs: { href: _vm.goToH("reservation", { g: true }) } }, [
          _vm._m(0)
        ])
      ])
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "back" }, [
      _c("div", { staticClass: "giftcardlayer" }, [
        _c("img", {
          staticClass: "cardsize",
          attrs: { src: require("assets/img/card.png") }
        })
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "number1" }),
      _vm._v(" "),
      _c("div", { staticClass: "number2" }),
      _vm._v(" "),
      _c("div", { staticClass: "number3" }),
      _vm._v(" "),
      _c("div", { staticClass: "number4" }),
      _vm._v(" "),
      _c("div", { staticClass: "number5" }),
      _vm._v(" "),
      _c("div", { staticClass: "number6" }),
      _vm._v(" "),
      _c("div", { staticClass: "number7" }),
      _vm._v(" "),
      _c("div", { staticClass: "number8" }),
      _vm._v(" "),
      _c("div", { staticClass: "number9" }),
      _vm._v(" "),
      _c("div", { staticClass: "number10" }),
      _vm._v(" "),
      _c("div", { staticClass: "number11" }),
      _vm._v(" "),
      _c("div", { staticClass: "number12" }),
      _vm._v(" "),
      _c("div", { staticClass: "number13" }),
      _vm._v(" "),
      _c("div", { staticClass: "number14" }),
      _vm._v(" "),
      _c("div", { staticClass: "number15" }),
      _vm._v(" "),
      _c("div", { staticClass: "number16" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
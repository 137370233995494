var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "company" }, [
    _c(
      "div",
      { staticClass: "company-header" },
      [_c("top-nav-bar"), _vm._v(" "), _c("header")],
      1
    ),
    _vm._v(" "),
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticStyle: { "padding-top": "90px" } }, [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "row" }, [
          _vm._m(1),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-md-6 contactform-right" },
            [_c("contact-form")],
            1
          )
        ])
      ])
    ]),
    _vm._v(" "),
    _vm._m(2),
    _vm._v(" "),
    _vm._m(3)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", { staticClass: "company-section" }, [
      _c("div", { staticClass: "container", attrs: { id: "company-ctn" } }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-lg-5 comp-head" }, [
            _c("h1", [_c("strong", [_vm._v("CÉGEKNEK")]), _c("br")])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-lg-6" })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            {
              staticClass: "col-lg-5 comp-left",
              staticStyle: { "padding-left": "0px" }
            },
            [
              _c("h2", [
                _c("strong", [_vm._v("CSAPATÉPÍTŐ PROGRAMOT")]),
                _c("br"),
                _c("strong", [_vm._v("SZERVEZEL?")]),
                _c("br")
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v("A TeslaPilóta programja garantáltan szórakoztató"),
                _c("br"),
                _vm._v("élmény lesz a csapat tagjainak. Hívj minket egyedi"),
                _c("br"),
                _vm._v("ajánlatunkért! A helyszín közelében legalább"),
                _c("br"),
                _vm._v("22kw-os töltőnek kell lennie."),
                _c("br")
              ])
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "col-lg-6 offset-xl-0" })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-lg-5" }),
          _vm._v(" "),
          _c("div", { staticClass: "col-lg-6 comp-right" }, [
            _c("h2", [
              _c("strong", [
                _vm._v(
                  "VAGY MOTIVÁLNI SZERETNÉD A HÓNAP DOLGOZÓJÁT, ÉRTÉKESÍTŐJÉT EGY ÉLETRESZÓLÓ ÉLMÉNNYEL?"
                )
              ]),
              _c("br")
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v("A P100D ÉlményTesla vezetése lehet, hogy komoly"),
              _c("br"),
              _vm._v("motiváló tud lenni a csapatod számára. Kérdezd"),
              _c("br"),
              _vm._v("csak meg őket!"),
              _c("br")
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-lg-5 comp-left" }, [
            _c("h2", [
              _c("strong", [_vm._v("NETÁN DÍJAZNI SZERETNÉD")]),
              _c("br"),
              _c("strong", [_vm._v("ÜGYFELED HŰSÉGÉT?")]),
              _c("br")
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v("Lepd meg ügyfeledet egy ÉlményTesla vezetéssel,"),
              _c("br"),
              _vm._v("és lehet, hogy ezzel még a köztetek lévő kapcsolat"),
              _c("br"),
              _vm._v("is szorosabbá válik, a forgalmad pedig kilő, mint"),
              _c("br"),
              _vm._v("egy P100D!"),
              _c("br")
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-lg-6" })
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-6 contactform-left" }, [
      _c(
        "h1",
        {
          staticClass: "text-uppercase",
          staticStyle: { "margin-bottom": "0px" }
        },
        [_c("strong", [_vm._v("Üzenetküldés")])]
      ),
      _vm._v(" "),
      _c("h3", { staticClass: "text-uppercase" }, [_vm._v("A Teslapilótának")]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "Ha kérésed vagy kérdésed lenne, akkor küldj üzenetet a Teslapilóta irányítóközpontba! Add meg adataidat, hogy felvehessük veled a kapcsolatot."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("img", {
        staticClass: "center",
        staticStyle: { "padding-top": "8rem" },
        attrs: { src: require("assets/img/img-header-logo-small-gray.png") }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "container", attrs: { id: "followus" } }, [
      _c("div", { staticClass: "d-none d-xl-block" }, [
        _c("div", { staticClass: "text-uppercase" }, [
          _c("div", { staticClass: "container" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-md-4 col-xl-10 offset-xl-1" }, [
                _c("h1", { staticClass: "text-uppercase text-left" }, [
                  _c("strong", [_vm._v("KÖVESS")])
                ])
              ])
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", [
          _c("div", { staticClass: "container" }, [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                {
                  staticClass:
                    "col-md-4 col-xl-2 offset-xl-5 text-right align-self-center"
                },
                [
                  _c(
                    "a",
                    { attrs: { href: "http://www.facebook.com/teslapilota" } },
                    [
                      _c("img", {
                        staticStyle: { "padding-right": "1.875rem" },
                        attrs: { src: require("assets/img/btn-facebook.png") }
                      })
                    ]
                  ),
                  _c(
                    "a",
                    { attrs: { href: "http://www.instagram.com/teslapilota" } },
                    [
                      _c("img", {
                        attrs: { src: require("assets/img/btn-instagram.png") }
                      })
                    ]
                  )
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "col-md-4 col-xl-4 offset-xl-0" }, [
                _c("h1", { staticClass: "text-uppercase text-right" }, [
                  _c("strong", [_vm._v("MINKET")])
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "clearfix" })
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "mobile d-block d-xl-none" }, [
        _vm._v("\n      Kövess minket\n      "),
        _c("div", [
          _c(
            "a",
            {
              attrs: {
                href: "https://www.facebook.com/TeslaPilota/",
                target: "_blank"
              }
            },
            [
              _c("img", {
                attrs: { src: require("assets/img/btn-facebook.png") }
              })
            ]
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              attrs: {
                href: "https://www.instagram.com/teslapilota/",
                target: "_blank"
              }
            },
            [
              _c("img", {
                attrs: { src: require("assets/img/btn-instagram.png") }
              })
            ]
          )
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
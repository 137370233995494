var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "contact-form" }, [
    _c("form", { staticStyle: { margin: "10px" }, attrs: { method: "post" } }, [
      _c("div", { staticClass: "form-group" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.$v.data.name.$model,
              expression: "$v.data.name.$model"
            }
          ],
          staticClass: "form-control form-control-lg",
          staticStyle: { "padding-top": "8px", margin: "0px", height: "50px" },
          attrs: { type: "text", name: "name", placeholder: "Neved" },
          domProps: { value: _vm.$v.data.name.$model },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.$v.data.name, "$model", $event.target.value)
            }
          }
        }),
        _vm._v(" "),
        !_vm.$v.data.name.required
          ? _c("div", { staticClass: "error" }, [
              _vm._v("Töltsd ki a név mezőt!")
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-group" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.$v.data.email.$model,
              expression: "$v.data.email.$model"
            }
          ],
          staticClass: "form-control form-control-lg",
          staticStyle: { height: "50px" },
          attrs: { type: "text", name: "email", placeholder: "E-mail címed" },
          domProps: { value: _vm.$v.data.email.$model },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.$v.data.email, "$model", $event.target.value)
            }
          }
        }),
        _vm._v(" "),
        !_vm.$v.data.email.required
          ? _c("div", { staticClass: "error" }, [
              _vm._v("Töltsd ki az e–mail cím mezőt!")
            ])
          : _vm._e(),
        _vm._v(" "),
        !_vm.$v.data.email.email
          ? _c("div", { staticClass: "error" }, [_vm._v("Hibás e–mail cím!")])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-group" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.data.phone,
              expression: "data.phone"
            }
          ],
          staticClass: "form-control form-control-lg",
          staticStyle: { height: "50px" },
          attrs: { type: "text", name: "phone", placeholder: "Telefon számod" },
          domProps: { value: _vm.data.phone },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.data, "phone", $event.target.value)
            }
          }
        })
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-group" }, [
        _c("textarea", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.$v.data.message.$model,
              expression: "$v.data.message.$model"
            }
          ],
          staticClass: "border rounded form-control form-control-lg",
          staticStyle: { height: "180px" },
          attrs: { name: "message", placeholder: "Üzenet" },
          domProps: { value: _vm.$v.data.message.$model },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.$v.data.message, "$model", $event.target.value)
            }
          }
        }),
        _vm._v(" "),
        !_vm.$v.data.message.required
          ? _c("div", { staticClass: "error" }, [
              _vm._v("Töltsd ki az üzenet mezőt!")
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-group" }, [
        _c(
          "button",
          {
            staticClass: "btn text-uppercase",
            staticStyle: {
              "background-color": "rgba(255,255,255,0)",
              color: "rgb(0,0,0)",
              "font-size": "1.5rem",
              "font-family": "Montserrat, sans-serif",
              "margin-right": "0px",
              "padding-right": "15px",
              "padding-top": "35px"
            },
            attrs: { type: "submit", disabled: _vm.$v.$invalid || _vm.send },
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.send_message($event)
              }
            }
          },
          [
            _c("strong", [_vm._v("Küldés")]),
            _c("img", {
              staticStyle: { "padding-left": "35px" },
              attrs: { src: require("assets/img/btn-send.png") }
            })
          ]
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
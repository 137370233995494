<template>
  <div class="top-nav-bar">
    <b-navbar toggleable="lg" type="dark" variant="custom" fixed="top" id="top-nav-bar">
      <b-navbar-brand><a href="/"><div class="logo"></div></a></b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav class="ml-auto pt-auto pb-auto">
          <b-nav-item active href="/"><div class="home"></div></b-nav-item>
          <b-nav-item :href="goTo('about')">Rólunk</b-nav-item>
          <b-nav-item :href="goTo('reservation')">Időpontfoglalás</b-nav-item>
          <b-nav-item href="https://www.youtube.com/channel/UCG6NYETa6O-jtRkVCtbazFw">Videók</b-nav-item>
          <b-nav-item :href="goTo('company')">Cégeknek</b-nav-item>
          <b-nav-item :href="goTo('tours')">Transzferek</b-nav-item>
		  <b-nav-item href="http://tesla.hu">Teslát vennék</b-nav-item>
          <b-nav-text>|</b-nav-text>
          <b-nav-item :href="goTo('contact')">Kapcsolat</b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
  import router from "assets/js/plugins/router";

  export default {
    name: "top-nav-bar",
    methods: {
      goTo: function(name)
      {
        return router.resolve({ name: name }).href;
      }
    }
  };
</script>

<style scoped>
  #top-nav-bar {
    height: 59px;
    padding-left: 3rem;
    padding-right: 3rem;
    text-transform: uppercase;
    font-family: Raleway;
  }

  .bg-custom {
    background-image: url("~assets/img/bg-menu-container.png");
  }

  .navbar-dark .navbar-brand .logo {
    background-image: url("~assets/img/img-header-logo-big.png");
    background-repeat: no-repeat;
    width: 255px;
    background-position: center;
    background-size: auto;
    height: 59px;
    position: absolute;
    top: 0;
  }

  .navbar-dark .navbar-nav .nav-link .home {
    background-image: url("~assets/img/btn-home.png");
    background-repeat: no-repeat;
    width: 32px;
    background-position: center;
    background-size: auto;
    height: 59px;
  }

  .navbar-dark .navbar-nav .nav-link,
  .navbar-dark .navbar-nav .navbar-text {
    color: #ffffff;
    padding-left: 1rem;
    padding-right: 1rem;
    line-height: 59px;
  }

  #top-nav-bar > div.navbar-brand {
    position: absolute;
    top: 0px;
    left: 30px;
  }

  #top-nav-bar > button {
    position: absolute;
    top: 10px;
    right: 30px;
    padding: 0.25rem 0.75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 0.25rem;
  }
  @media (max-width: 990px) {
    #nav-collapse {
      background-color: hsla(0, 0%, 73%, 0.7);
      background-size: cover;
      width: 100%;
      position: absolute;
      top: 59px;
      left: 0px;
      font-size: 18px;
    }
    .navbar-dark .navbar-nav .nav-link,
    .navbar-dark .navbar-nav .navbar-text {
      margin-top: -1px;
      color: #ffffff;
      padding-left: 1rem;
      padding-right: 1rem;
      line-height: 22px;
    }
    .navbar-dark .navbar-nav .nav-link .home {
      background: none;
      width: 0px;
      margin-bottom: -15px;
      height: 0px;
    }
    .navbar-dark .navbar-nav .navbar-text {
      color: transparent;
      size: 0px;
      font-size: 0px;
      margin-bottom: -15px;
      line-height: 0px;
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }
</style>

<template>
  <div class="aszf">
    <div class="aszf-header">
      <top-nav-bar />
      <header>
        <h1>ÁSZF</h1>
      </header>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="container" style="margin-top: 100px;">
          <div class="row">
            <div class="col">
              <h1
                style="font-size: 2.5rem;margin-bottom: 50px;font-weight: 600;"
              >
                Általános Szerződési Feltételek
              </h1>
              <p
                style="font-size: 1.1rem;font-weight: 500;margin-bottom: 30px;"
              >
                Jelen dokumentum nem kerül iktatásra (utólag nem hozzáférhető),
                kizárólag elektronikus formában kerül megkötésre, nem minősül
                írásbeli szerződésnek, magyar nyelven íródik, magatartási
                kódexre nem utal. A weboldal működésével, megrendelési
                folyamatával kapcsolatosan felmerülő kérdések esetén a megadott
                elérhetőségeinken rendelkezésére állunk.<br />
              </p>

              <p
                style="font-size: 1.1rem;font-weight: 500;margin-bottom: 30px;"
              >
                Jelen Ászf hatálya a Szolgáltató weblapján (teslapilota.hu,
                teslapilóta.hu, elmenytesla.hu, élménytesla.hu, pilóta.hu) és
                aldomainjein történő jogviszonyokra terjed ki. Jelen Ászf
                folyamatosan elérhető a következő weboldalról:
                http://teslapilota.hu/aszf és letölthető, továbbá bármikor
                kinyomtatható az alábbi linkről:
                http://teslapilota.hu/aszf.pdf<br />
              </p>

              <h1
                style="font-size: 1.5rem;padding-top: 30px;padding-bottom: 30px;"
              >
                <strong>1. Szolgáltató adatai:</strong><br />
              </h1>
              <p
                style="font-size: 1.1rem;font-weight: 500;margin-bottom: 30px;"
              >
                A szolgáltató neve: Brazil Reklámügynökség Korlátolt Felelősségű
                Társaság<br />A szolgáltató székhelye (és egyben a
                panaszügyintézés helye): 1014 Budapest, Országház utca 22. 1.
                em. 2.<br />A szolgáltató elérhetősége, az igénybe vevőkkel való
                kapcsolattartásra szolgáló, rendszeresen használt elektronikus
                levelezési címe: hello@teslapilota.hu<br />Cégjegyzékszáma:
                01-09-703588<br />Adószáma: 12791022-2-41<br />Nyilvántartásban
                bejegyző hatóság neve: Fővárosi Törvényszék<br />Telefonszáma:<br />A
                szerződés nyelve: magyar<br />A tárhely-szolgáltató neve, címe,
                e-mail címe: Dotroll Kft., 1148 Budapest, Fogarasi út 3-5,
                support@dotroll.com<br />
              </p>

              <h1
                style="font-size: 1.5rem;padding-top: 30px;padding-bottom: 30px;"
              >
                <strong>2. Alapvető rendelkezések</strong><br />
              </h1>
              <p
                style="font-size: 1.1rem;font-weight: 500;margin-bottom: 30px;"
              >
                <strong>2.1,</strong>&nbsp;A jelen Szabályzatban nem
                szabályozott kérdésekre, valamint jelen Szabályzat értelmezésére
                a magyar jog az irányadó, különös tekintettel a Polgári
                Törvénykönyvről szóló 2013. évi V. törvény („Ptk.”) és az
                elektronikus kereskedelmi szolgáltatások, az információs
                társadalommal összefüggő szolgáltatások egyes kérdéseiről szóló
                2001. évi CVIII. (Elker. tv.) törvény, valamint a fogyasztó és a
                vállalkozás közötti szerződések részletes szabályairól szóló
                45/2014. (II. 26.) Korm. rendelet vonatkozó rendelkezéseire. A
                vonatkozó jogszabályok kötelező rendelkezései a felekre külön
                kikötés nélkül is irányadók.<br />
              </p>

              <p
                style="font-size: 1.1rem;font-weight: 500;margin-bottom: 30px;"
              >
                <strong>2.2,&nbsp;</strong>A jelen Szabályzat 2019. augusztus
                01. napjától hatályos és visszavonásig hatályban marad. A
                Szolgáltató jogosult egyoldalúan módosítani a Szabályzatot (a
                módosításra okot adó körülmények: jogszabály-változás, üzleti
                érdek, céggel kapcsolatos változások). A módosításokat a
                Szolgáltató azok hatályba lépése előtt 11 (tizenegy) nappal a
                weboldalon közzéteszi - mely idő alatt a Felhasználó jogosult a
                szerződéstől elállni, vagy azt felmondani. Felhasználók a
                weboldal használatával elfogadják, hogy rájuk nézve a weboldal
                használatával kapcsolatos valamennyi szabályozás automatikusan
                érvényes.<br />
              </p>

              <p
                style="font-size: 1.1rem;font-weight: 500;margin-bottom: 30px;"
              >
                <strong>2.3,&nbsp;</strong>Szolgáltató fenntart magának minden
                jogot a weboldal, annak bármely részlete és az azon megjelenő
                tartalmak, valamint a weboldal terjesztésének tekintetében.
                Tilos a weboldalon megjelenő tartalmak vagy azok bármely
                részletének letöltése, elektronikus tárolása, feldolgozása és
                értékesítése a Szolgáltató írásos hozzájárulása nélkül.<br />
              </p>

              <h1
                style="font-size: 1.5rem;padding-top: 30px;padding-bottom: 30px;"
              >
                <strong>3. Vásárlás, jelentkezés vezetésre</strong><br />
              </h1>
              <p
                style="font-size: 1.1rem;font-weight: 500;margin-bottom: 30px;"
              >
                <strong>3.1,</strong>&nbsp;Felhasználó a weboldalon történő
                vásárlásával/jelentkezésével kijelenti, hogy jelen ÁSZF, és a
                weboldalon közzétett Adatkezelési tájékoztató feltételeit
                megismerte és elfogadja, az adatkezelésekhez hozzájárul.<br />
              </p>
              <p
                style="font-size: 1.1rem;font-weight: 500;margin-bottom: 30px;"
              >
                <strong>3.2,&nbsp;</strong>Felhasználó a vásárlás/jelentkezés
                során köteles a saját, valós adatait megadni. A
                vásárlás/jelentkezés során megadott valótlan, vagy más
                személyhez köthető adatok esetén a létrejövő elektronikus
                szerződés semmis. Szolgáltató kizárja felelősségét, amennyiben
                Felhasználó más nevében, más személy adataival veszi igénybe
                szolgáltatásait.<br />
              </p>

              <p
                style="font-size: 1.1rem;font-weight: 500;margin-bottom: 30px;"
              >
                <strong>3.3,&nbsp;</strong>A Szolgáltatót a Felhasználó által
                tévesen és/vagy pontatlanul megadott adatokra visszavezethető
                késedelemért, illetve egyéb problémáért, hibáért semminemű
                felelősség nem terheli.<br />
              </p>
              <h1
                style="font-size: 1.5rem;padding-top: 30px;padding-bottom: 30px;"
              >
                <strong>4. Megvásárolható szolgáltatások köre</strong><br />
              </h1>
              <p
                style="font-size: 1.1rem;font-weight: 500;margin-bottom: 30px;"
              >
                <strong>4.1,</strong>&nbsp;A megjelenített szolgáltatások
                kizárólag online rendelhetők meg. A szolgáltatásokra vonatkozóan
                megjelenített árak forintban értendők, tartalmazzák a törvényben
                előírt áfát.<br />
              </p>
              <p
                style="font-size: 1.1rem;font-weight: 500;margin-bottom: 30px;"
              >
                <strong>4.2,&nbsp;</strong>A weboldalon Szolgáltató részletesen
                feltünteti a szolgáltatás nevét, leírását.<br />
              </p>
              <p
                style="font-size: 1.1rem;font-weight: 500;margin-bottom: 30px;"
              >
                <strong>4.3,&nbsp;</strong>Amennyiben akciós ár kerül
                bevezetésre, Szolgáltató teljes körűen tájékoztatja
                Felhasználókat az akcióról és annak pontos időtartamáról.<br />
              </p>
              <p
                style="font-size: 1.1rem;font-weight: 500;margin-bottom: 30px;"
              >
                <strong>4.4,&nbsp;</strong>Amennyiben a Szolgáltató minden
                gondossága ellenére hibás ár kerül a weboldal felületére,
                különös tekintettel a nyilvánvalóan téves, pl. a szolgáltatás
                közismert, általánosan elfogadott vagy becsült árától jelentősen
                eltérő, esetleg rendszerhiba miatt megjelenő “0” Ft-os vagy “1”
                Ft-os árra, akkor a Szolgáltató nem köteles a szolgáltatást
                hibás áron nyújtani, hanem felajánlhatja a helyes áron történő
                szolgáltatást, amelynek ismeretében az Ügyfél elállhat vásárlási
                szándékától. Jelentős eltérésnek minősül – a hazai bírói
                joggyakorlatnak megfelelően – az adott szolgáltatás piaci
                értékétől akár pozitív-, akár negatív irányban minimum 50 %-ban
                történő eltérés. Tájékoztatjuk azonban a fogyasztókat, hogy a
                feltűnő értékaránytalanság (Ptk. 6:98. § ) fogalmát a törvény
                nem határozza meg.<br />
              </p>

              <p
                style="font-size: 1.1rem;font-weight: 500;margin-bottom: 30px;"
              >
                <strong>4.5,&nbsp;</strong>A 4.4 pontban ismertetett mértékű
                hibás ár esetén feltűnő értékaránytalanság áll fenn a
                szolgáltatás valódi és feltüntetett ára között, amit egy
                átlagfogyasztónak is azonnal észlelnie szükséges. A Polgári
                Törvénykönyvről szóló 2013. évi V. törvény (Ptk.) alapján a
                szerződés a felek akaratának kölcsönös és egybehangzó
                kifejezésével jön létre. Amennyiben a felek nem tudnak
                megállapodni a szerződéses feltételekben, azaz nincs meg a felek
                akaratát kölcsönösen és egybehangzóan kifejező nyilatkozat,
                abban az esetben nem beszélhetünk érvényesen létrejött
                szerződésről, amelyekből jogok és kötelezettségek fakadnának.
                Ennek alapján a hibás/téves áron visszaigazolt megrendelés
                semmis szerződésnek tekintendő.<br />
              </p>

              <h1
                style="font-size: 1.5rem;padding-top: 30px;padding-bottom: 30px;"
              >
                <strong>5. Rendelés menete</strong><br />
              </h1>
              <p
                style="font-size: 1.1rem;font-weight: 500;margin-bottom: 30px;"
              >
                <strong>5.1,</strong>&nbsp;Felhasználó kiválasztja a megfelelő
                csomagot, majd megadja a személyes adatait.<br />
              </p>
              <p
                style="font-size: 1.1rem;font-weight: 500;margin-bottom: 30px;"
              >
                <strong>5.2,&nbsp;</strong>Felhasználó kiválasztja a fizetési
                módot, melynek típusai a következők:<br />
              </p>
              <p
                style="font-size: 1.1rem;font-weight: 500;margin-bottom: 30px;margin-left: 25px;"
              >
                <strong>5.2.1,&nbsp;Fizetési módok:</strong><br />
              </p>
              <p
                style="font-size: 1.1rem;font-weight: 500;margin-bottom: 30px;margin-left: 25px;"
              >
                Átutalással: Felhasználó a megrendelt szolgáltatás ellenértékét
                a visszaigazoló e-mailben található bankszámlára 3 napon belül
                köteles átutalni.<br />
              </p>
              <p
                style="font-size: 1.1rem;font-weight: 500;margin-bottom: 30px;margin-left: 25px;"
              >
                Online bankkártyával: Felhasználónak lehetősége van a
                szolgáltatás, tesztvezetés összértékét online, bankkártyával
                fizetni a Szolgáltató által igénybe vett pénzügyi szolgáltató
                (Simple) biztonságos fizetési rendszerén keresztül.<br />
              </p>
              <p
                style="font-size: 1.1rem;font-weight: 500;margin-bottom: 30px;"
              >
                <strong>5.3,&nbsp;</strong>Amennyiben a weboldalon hiba vagy
                hiányosság lép fel a szolgáltatásnál, vagy az áraknál,
                fenntartjuk a jogot a korrekcióra. Ilyen esetben a hiba
                felismerése illetve módosítása után azonnal tájékoztatjuk a
                Felhasználót az új adatokról. Felhasználó ezt követően még
                egyszer megerősítheti a megrendelést, vagy elállhat a
                szerződéstől.<br />
              </p>

              <p
                style="font-size: 1.1rem;font-weight: 500;margin-bottom: 30px;"
              >
                <strong>5.4,&nbsp;</strong>A fizetendő végösszeg a megrendelés
                összesítése és visszaigazoló levél alapján minden költséget
                tartalmaz.<br />
              </p>
              <p
                style="font-size: 1.1rem;font-weight: 500;margin-bottom: 30px;"
              >
                <strong>5.5,&nbsp;</strong>Az adatok megadását követően
                Felhasználó a ”megrendelem” gombra kattintva tudja elküldeni
                megrendelését, előtte azonban még egyszer ellenőrizheti a
                megadott adatokat.<br />
              </p>
              <p
                style="font-size: 1.1rem;font-weight: 500;margin-bottom: 30px;"
              >
                <strong>5.6,&nbsp;</strong>Felhasználó a megrendeléssel
                tudomásul veszi, hogy fizetési kötelezettsége keletkezik.<br />
              </p>
              <p
                style="font-size: 1.1rem;font-weight: 500;margin-bottom: 30px;"
              >
                <strong>5.7,&nbsp;</strong>Adatbeviteli hibák javítása:
                Felhasználó a megrendelési folyamat lezárása előtt minden
                esetben vissza tud lépni az előző fázisba, ahol javítani tudja a
                bevitt adatokat.<br />
              </p>
              <p
                style="font-size: 1.1rem;font-weight: 500;margin-bottom: 30px;"
              >
                <strong>5.8,&nbsp;</strong>Felhasználó e-mail-ben a megrendelés
                elküldését követően visszaigazolást kap. Amennyiben e
                visszaigazolás Felhasználó megrendelésének elküldésétől
                számított, a szolgáltatás jellegétől függő elvárható határidőn
                belül, de legkésőbb 48 órán belül Felhasználóhoz nem érkezik
                meg, Felhasználó az ajánlati kötöttség vagy szerződéses
                kötelezettség alól mentesül. A megrendelés és annak
                visszaigazolása akkor tekintendő a Szolgáltatóhoz, illetve az
                Felhasználóhoz megérkezettnek, amikor az számára hozzáférhetővé
                válik. Szolgáltató kizárja a visszaigazolási felelősségét, ha a
                visszaigazolás azért nem érkezik meg időben, mert Felhasználó
                rossz e-mail címet adott meg regisztrációja során, vagy a
                fiókjához tartozó tárhely telítettsége miatt nem tud üzenetet
                fogadni.<br />
              </p>

              <h1
                style="font-size: 1.5rem;padding-top: 30px;padding-bottom: 30px;"
              >
                <strong>6. A megrendelések feldolgozása és teljesítés</strong
                ><br />
              </h1>
              <p
                style="font-size: 1.1rem;font-weight: 500;margin-bottom: 30px;"
              >
                <strong>6.1,</strong>&nbsp;A megrendelések feldolgozása
                folyamatos.<br />
              </p>
              <p
                style="font-size: 1.1rem;font-weight: 500;margin-bottom: 30px;"
              >
                <strong>6.2,</strong>&nbsp;A teljesítés a foglalt időpontban
                történik.<br />
              </p>
              <h1
                style="font-size: 1.5rem;padding-top: 30px;padding-bottom: 30px;"
              >
                <strong>7. Elállás joga</strong><br />
              </h1>
              <p
                style="font-size: 1.1rem;font-weight: 500;margin-bottom: 30px;"
              >
                <strong>7.1,</strong>&nbsp;A jelen ÁSZF elfogadásával a
                fogyasztónak minősülő felhasználó kifejezetten tudomásul veszi,
                hogy a 45/2014. (II. 26.) Korm. rendeletben rögzített felmondási
                jog a rendelet 29. § (1) bekezdésének l) pontjára tekintettel
                nem illeti meg Önt, tekintettel arra, hogy egy tesztvezetésre
                irányuló szerződés esetében a szerződésben meghatározott
                teljesítési határnap vagy határidő van kikötve.<br />
              </p>

              <p
                style="font-size: 1.1rem;font-weight: 500;margin-bottom: 30px;"
              >
                <strong>7.2,&nbsp;</strong>A felmondási jogról részletesebben a
                fogyasztó és a vállalkozás közötti szerződések részletes
                szabályairól szóló 45/2014. (II. 26.) Korm. rendeletben lehet
                olvasni, mely hatályos állapotban itt érhető el.<br />
              </p>

              <h1
                style="font-size: 1.5rem;padding-top: 30px;padding-bottom: 30px;"
              >
                <strong>8. Szavatosság</strong><br />
              </h1>
              <h1
                style="font-size: 1.5rem;padding-top: 30px;padding-bottom: 30px;"
              >
                <strong>Hibás teljesítés</strong><br />
              </h1>
              <p
                style="font-size: 1.1rem;font-weight: 500;margin-bottom: 30px;"
              >
                A kötelezett hibásan teljesít, ha a szolgáltatás a teljesítés
                időpontjában nem felel meg a szerződésben vagy jogszabályban
                megállapított minőségi követelményeknek. Nem teljesít hibásan a
                kötelezett, ha a jogosult a hibát a szerződéskötés időpontjában
                ismerte, vagy a hibát a szerződéskötés időpontjában ismernie
                kellett.<br />
              </p>

              <p
                style="font-size: 1.1rem;font-weight: 500;margin-bottom: 30px;"
              >
                Fogyasztó és vállalkozás közötti szerződésben semmis az a
                kikötés, amely e fejezetnek a kellékszavatosságra és a
                jótállásra vonatkozó rendelkezéseitől a fogyasztó hátrányára tér
                el.<br />
              </p>
              <p
                style="font-size: 1.1rem;font-weight: 500;margin-bottom: 30px;"
              >
                Több szavatossági jog csak a Polgári Törvénykönyv szerinti
                fogyasztónak minősülő Felhasználókat illeti meg.<br />
              </p>
              <p
                style="font-size: 1.1rem;font-weight: 500;margin-bottom: 30px;"
              >
                Vállalkozásnak minősülő Felhasználó: olyan személy, aki a
                szakmája, önálló foglalkozása vagy üzleti tevékenysége körében
                jár el.<br />
              </p>
              <h1
                style="font-size: 1.5rem;padding-top: 30px;padding-bottom: 30px;"
              >
                <strong>Kellékszavatosság</strong><br />
              </h1>
              <p
                style="font-size: 1.1rem;font-weight: 500;margin-bottom: 30px;"
              >
                <strong>8.1,</strong>&nbsp;Milyen esetben élhet Felhasználó a
                kellékszavatossági jogával?<br />
              </p>
              <p
                style="font-size: 1.1rem;font-weight: 500;margin-bottom: 30px;"
              >
                Felhasználó a Szolgáltató hibás teljesítése esetén a
                Szolgáltatóval szemben kellékszavatossági igényt érvényesíthet a
                Polgári Törvénykönyv szabályai szerint.<br />
              </p>
              <p
                style="font-size: 1.1rem;font-weight: 500;margin-bottom: 30px;"
              >
                <strong>8.2,</strong>&nbsp;Milyen jogok illetik meg a
                Felhasználót a kellékszavatossági igénye alapján?<br />
              </p>
              <p
                style="font-size: 1.1rem;font-weight: 500;margin-bottom: 30px;"
              >
                Felhasználó – választása szerint – az alábbi kellékszavatossági
                igényekkel élhet: <br />kérhet kijavítást vagy kicserélést,
                kivéve, ha az ezek közül a Felhasználó által választott igény
                teljesítése lehetetlen vagy a vállalkozás számára más igénye
                teljesítéséhez képest aránytalan többletköltséggel járna. Ha a
                kijavítást vagy a kicserélést nem kérte, illetve nem kérhette,
                úgy igényelheti az ellenszolgáltatás arányos leszállítását vagy
                a hibát a vállalkozás költségére Felhasználó is kijavíthatja,
                illetve mással kijavíttathatja vagy – végső esetben – a
                szerződéstől is elállhat. Választott kellékszavatossági jogáról
                egy másikra is áttérhet, az áttérés költségét azonban
                Felhasználó viseli, kivéve, ha az indokolt volt, vagy arra a
                vállalkozás adott okot.<br />
              </p>

              <p
                style="font-size: 1.1rem;font-weight: 500;margin-bottom: 30px;"
              >
                <strong>8.3,</strong>&nbsp;Milyen határidőben érvényesítheti
                Felhasználó kellékszavatossági igényét?<br />
              </p>
              <p
                style="font-size: 1.1rem;font-weight: 500;margin-bottom: 30px;"
              >
                Felhasználó (ha fogyasztónak minősül) köteles a hibát annak
                felfedezése után haladéktalanul, de nem később, mint a hiba
                felfedezésétől számított kettő hónapon belül közölni. Ugyanakkor
                felhívjuk a figyelmét, hogy a szerződés teljesítésétől számított
                két éves (vállalkozás esetén 1 éves) elévülési határidőn túl
                kellékszavatossági jogait már nem érvényesítheti.<br />
              </p>

              <p
                style="font-size: 1.1rem;font-weight: 500;margin-bottom: 30px;"
              >
                <strong>8.4,</strong>&nbsp;Kivel szemben érvényesítheti
                kellékszavatossági igényét?<br />
              </p>
              <p
                style="font-size: 1.1rem;font-weight: 500;margin-bottom: 30px;"
              >
                Felhasználó a Szolgáltatóval szemben érvényesítheti
                kellékszavatossági igényét.<br />
              </p>
              <p
                style="font-size: 1.1rem;font-weight: 500;margin-bottom: 30px;"
              >
                <strong>8.5,</strong>&nbsp;Milyen egyéb feltétele van
                kellékszavatossági jogai érvényesítésének (amennyiben a
                Felhasználó fogyasztónak minősül)?<br />
              </p>
              <p
                style="font-size: 1.1rem;font-weight: 500;margin-bottom: 30px;"
              >
                A teljesítéstől számított hat hónapon belül a kellékszavatossági
                igénye érvényesítésének a hiba közlésén túl nincs egyéb
                feltétele, ha Felhasználó igazolja, hogy a szolgáltatást a
                weboldalt üzemeltető vállalkozás nyújtotta. A teljesítéstől
                számított hat hónap eltelte után azonban már Felhasználó köteles
                bizonyítani, hogy a Felhasználó által felismert hiba már a
                teljesítés időpontjában is megvolt.<br />
              </p>

              <h1
                style="font-size: 1.5rem;padding-top: 30px;padding-bottom: 30px;"
              >
                <strong
                  >9. A szavatossági igény esetén történő eljárás (fogyasztónak
                  minősülő felhasználók esetén)</strong
                ><br />
              </h1>
              <p
                style="font-size: 1.1rem;font-weight: 500;margin-bottom: 30px;"
              >
                <strong>9.1,</strong>&nbsp;Fogyasztó és vállalkozás közötti
                szerződésben a felek megállapodása a rendelet rendelkezéseitől a
                fogyasztó hátrányára nem térhet el.<br />
              </p>
              <p
                style="font-size: 1.1rem;font-weight: 500;margin-bottom: 30px;"
              >
                <strong>9.2,&nbsp;</strong>A fogyasztó kötelessége a szerződés
                megkötésének bizonyítása (számlával, vagy akár csak
                nyugtával).<br />
              </p>
              <p
                style="font-size: 1.1rem;font-weight: 500;margin-bottom: 30px;"
              >
                <strong>9.3,&nbsp;</strong>A szavatossági kötelezettség
                teljesítésével kapcsolatos költségek a Szolgáltatót terhelik
                (Ptk. 6:166. §).<br />
              </p>
              <p
                style="font-size: 1.1rem;font-weight: 500;margin-bottom: 30px;"
              >
                <strong>9.4,&nbsp;</strong>A Szolgáltató a fogyasztó nála
                bejelentett szavatossági igényéről jegyzőkönyvet köteles
                felvenni.<br />
              </p>
              <p
                style="font-size: 1.1rem;font-weight: 500;margin-bottom: 30px;"
              >
                <strong>9.5,&nbsp;</strong>A jegyzőkönyv másolatát
                haladéktalanul, igazolható módon a fogyasztó rendelkezésére kell
                bocsátani.<br />
              </p>
              <p
                style="font-size: 1.1rem;font-weight: 500;margin-bottom: 30px;"
              >
                <strong>9.6,&nbsp;</strong>Ha a Szolgáltató a fogyasztó
                szavatossági igényének teljesíthetőségéről annak bejelentésekor
                nem tud nyilatkozni, álláspontjáról – az igény elutasítása
                esetén az elutasítás indokáról és a békéltető testülethez
                fordulás lehetőségéről is – öt munkanapon belül, igazolható
                módon köteles értesíteni a fogyasztót.<br />
              </p>

              <p
                style="font-size: 1.1rem;font-weight: 500;margin-bottom: 30px;"
              >
                <strong>9.7,&nbsp;</strong>A Szolgáltató a jegyzőkönyvet az
                annak felvételétől számított három évig köteles megőrizni, és
                azt az ellenőrző hatóság kérésére bemutatni.<br />
              </p>
              <p
                style="font-size: 1.1rem;font-weight: 500;margin-bottom: 30px;"
              >
                <strong>9.8,&nbsp;</strong>A Szolgáltatónak törekednie kell
                arra, hogy a kijavítást vagy kicserélést legfeljebb tizenöt
                napon belül elvégezze.<br />
              </p>
              <h1
                style="font-size: 1.5rem;padding-top: 30px;padding-bottom: 30px;"
              >
                <strong>10. Vegyes Rendelkezések</strong><br />
              </h1>
              <p
                style="font-size: 1.1rem;font-weight: 500;margin-bottom: 30px;"
              >
                <strong>10.1,</strong>&nbsp;Szolgáltató kötelezettsége
                teljesítéséhez közreműködőt jogosult igénybe venni. Ennek
                jogellenes magatartásáért teljes felelősséggel tartozik, úgy,
                mintha a jogellenes magatartást saját maga követte volna el.<br />
              </p>

              <p
                style="font-size: 1.1rem;font-weight: 500;margin-bottom: 30px;"
              >
                <strong>10.2,&nbsp;</strong>Ha a jelen Szabályzat bármely része
                érvénytelenné, jogtalanná vagy érvényesíthetetlenné válik, az a
                fennmaradó részek érvényességét, jogszerűségét és
                érvényesíthetőségét nem érinti.<br />
              </p>
              <p
                style="font-size: 1.1rem;font-weight: 500;margin-bottom: 30px;"
              >
                <strong>10.3,&nbsp;</strong>Amennyiben Szolgáltató a Szabályzat
                alapján megillető jogát nem gyakorolja, a joggyakorlás
                elmulasztása nem tekinthető az adott jogról való lemondásnak.
                Bármilyen jogról történő lemondás csak az erre vonatkozó
                kifejezett írásbeli nyilatkozat esetén érvényes. Az hogy a
                Szolgáltató egy alkalommal nem ragaszkodik szigorúan a
                Szabályzat valamely lényegi feltételéhez, vagy kikötéséhez nem
                jelenti azt, hogy lemond arról, hogy a későbbiekben ragaszkodjon
                az adott feltétel vagy kikötés szigorú betartásához.<br />
              </p>

              <p
                style="font-size: 1.1rem;font-weight: 500;margin-bottom: 30px;"
              >
                <strong>10.4,&nbsp;</strong>Szolgáltató és Felhasználó vitás
                ügyeiket békés úton próbálják rendezni.<br />
              </p>
              <p
                style="font-size: 1.1rem;font-weight: 500;margin-bottom: 30px;"
              >
                <strong>10.5,&nbsp;</strong>Felek rögzítik, hogy a Szolgáltató
                weboldala Magyarországon működik, karbantartását is itt végzik.
                Mivel az oldal más országokból is meglátogatható, ezért a
                felhasználók kifejezetten tudomásul veszik, hogy a felhasználó
                és a Szolgáltató viszonylatában az irányadó jog a magyar jog.
                Amennyiben a felhasználó fogyasztó, úgy a Pp. 26. § (1)
                bekezdése alapján a fogyasztóval szemben a jelen szerződésből
                eredő vitás ügyekben az alperes (fogyasztó) belföldi lakóhelye
                szerinti bíróság kizárólagosan illetékes.<br />
              </p>

              <p
                style="font-size: 1.1rem;font-weight: 500;margin-bottom: 30px;"
              >
                <strong>10.6,&nbsp;</strong>Szolgáltató a weboldalon található
                szolgáltatáshoz való hozzáférés vonatkozásában nem alkalmaz
                eltérő általános hozzáférési feltételeket a Felhasználó
                állampolgárságával, lakóhelyével vagy letelepedési helyével
                kapcsolatos okokból.<br />
              </p>

              <p
                style="font-size: 1.1rem;font-weight: 500;margin-bottom: 30px;"
              >
                <strong>10.7,&nbsp;</strong>Szolgáltató – az általa elfogadott
                fizetési módok tekintetében – nem alkalmaz eltérő feltételeket a
                fizetési műveletre a Felhasználó állampolgárságával,
                lakóhelyével vagy letelepedési helyével, a fizetési számla
                számlavezetési helyével, a pénzforgalmi szolgáltató letelepedési
                helyével vagy a készpénz-helyettesítő fizetési eszköz Unión
                belüli kibocsátásának helyével kapcsolatos okok miatt.<br />
              </p>

              <p
                style="font-size: 1.1rem;font-weight: 500;margin-bottom: 30px;"
              >
                <strong>10.8,&nbsp;</strong>Szolgáltató megfelel a belső piacon
                belül a vevő állampolgársága, lakóhelye vagy letelepedési helye
                alapján történő indokolatlan területi alapú
                tartalomkorlátozással és a megkülönböztetés egyéb formáival
                szembeni fellépésről, valamint a 2006/2004/EK és az (EU)
                2017/2394 rendelet, továbbá a 2009/22/EK irányelv módosításáról
                szóló AZ EURÓPAI PARLAMENT ÉS A TANÁCS (EU) 2018/302
                RENDELET-nek.<br />
              </p>

              <h1
                style="font-size: 1.5rem;padding-top: 30px;padding-bottom: 30px;"
              >
                <strong
                  >11. Panaszkezelés rendje (fogyasztónak minősülő felhasználók
                  esetén)</strong
                ><br />
              </h1>
              <p
                style="font-size: 1.1rem;font-weight: 500;margin-bottom: 30px;"
              >
                <strong>11.1,</strong>&nbsp;Cégünk célja, hogy valamennyi
                szolgáltatást megfelelő minőségben, a megrendelő teljes
                megelégedettsége mellett teljesítsen. Amennyiben Felhasználónak
                mégis valamilyen panasza van a szerződéssel vagy annak
                teljesítésével kapcsolatban, úgy panaszát a fenti telefonon,
                e-mail címen, vagy levél útján is közölheti.<br />
              </p>

              <p
                style="font-size: 1.1rem;font-weight: 500;margin-bottom: 30px;"
              >
                <strong>11.2,&nbsp;</strong>Szolgáltató a szóbeli panaszt
                azonnal megvizsgálja, és szükség szerint orvosolja. Ha a vásárló
                a panasz kezelésével nem ért egyet, vagy a panasz azonnali
                kivizsgálása nem lehetséges, a Szolgáltató a panaszról és az
                azzal kapcsolatos álláspontjáról haladéktalanul jegyzőkönyvet
                vesz fel, s annak egy másolati példányát átadja a vásárlónak.<br />
              </p>

              <p
                style="font-size: 1.1rem;font-weight: 500;margin-bottom: 30px;"
              >
                <strong>11.3,&nbsp;</strong>Az írásbeli panaszt a Szolgáltatást
                30 napon belül írásban megválaszolja. A panaszt elutasító
                álláspontját megindokolja. A panaszról felvett jegyzőkönyvet és
                a válasz másolati példányát öt évig megőrzi a Szolgáltató, és
                azt az ellenőrző hatóságoknak kérésükre bemutatja.<br />
              </p>

              <p
                style="font-size: 1.1rem;font-weight: 500;margin-bottom: 30px;"
              >
                <strong>11.4,&nbsp;</strong>Tájékozatjuk, hogy a panaszának
                elutasítása esetén panaszával hatósági vagy békéltető testület
                eljárását kezdeményezheti, az alábbiak szerint:<br />
              </p>
              <p
                style="font-size: 1.1rem;font-weight: 500;margin-bottom: 30px;"
              >
                <strong>11.5,</strong>&nbsp;A Fogyasztó panasszal fordulhat a
                fogyasztóvédelmi hatósághoz:<br />
              </p>
              <p
                style="font-size: 1.1rem;font-weight: 500;margin-bottom: 30px;"
              >
                A&nbsp;fogyasztóvédelmi hatóság kijelöléséről szóló 387/2016.
                (XII. 2.) Korm. rendelet szerint&nbsp;közigazgatási hatósági
                ügyekben elsőfokon a járási hivatal, illetve a megyeszékhely
                szerinti járási hivatal, másodfokon országos illetékességgel a
                Pest Megyei Kormányhivatal jár el. A járási hivatalok
                elérhetőségei: http://jarasinfo.gov.hu<br />
              </p>

              <p
                style="font-size: 1.1rem;font-weight: 500;margin-bottom: 30px;"
              >
                <strong>11.6,</strong>&nbsp;A Fogyasztónak panasza esetén
                lehetősége van békéltető testülethez fordulni, melyek
                elérhetőségét itt találja:<br />
              </p>
              <p
                style="font-size: 1.1rem;font-weight: 500;margin-bottom: 30px;"
              >
                Bács-Kiskun Megyei Békéltető Testület<br />Címe: 6000 Kecskemét,
                Árpád krt. 4.<br />Telefonszáma: (76) 501-525, (76) 501-500<br />Fax
                száma: (76) 501-538<br />Név: Mátyus Mariann<br />E-mail cím:
                bkmkik@mail.datanet.hu;<br /><br />Baranya Megyei Békéltető
                Testület<br />Címe: 7625 Pécs, Majorossy Imre u. 36.<br />Levelezési
                címe: 7602 Pécs, Pf. 109.<br />Telefonszáma: (72) 507-154<br />Fax
                száma: (72) 507-152<br />Név: Dr. Bodnár József<br />E-mail cím:
                bekelteto@pbkik.hu;<br /><br />Békés Megyei Békéltető
                Testület<br />Címe: 5601 Békéscsaba, Penza ltp. 5.<br />Telefonszáma:
                (66) 324-976, 446-354, 451-775<br />Fax száma: (66) 324-976<br />Név:
                Dr. Bagdi László<br />E-mail cím: bmkik@bmkik.hu;<br /><br />Borsod-Abaúj-Zemplén
                Megyei Békéltető Testület<br />Címe: 3525 Miskolc, Szentpáli u.
                1.<br />Telefonszáma: (46) 501-091, 501-870<br />Fax száma: (46)
                501-099<br />Név: Dr. Tulipán Péter<br />E-mail cím:
                kalna.zsuzsa@bokik.hu;<br /><br />Budapesti Békéltető
                Testület<br />Címe: 1016 Budapest, Krisztina krt. 99.<br />Telefonszáma:
                (1) 488-2131<br />Fax száma: (1) 488-2186<br />Név: Dr.
                Baranovszky György<br />E-mail cím:
                bekelteto.testulet@bkik.hu;<br /><br />Csongrád Megyei Békéltető
                Testület<br />Címe: 6721 Szeged, Párizsi krt. 8-12.<br />Telefonszáma:
                (62) 554-250/118 mellék<br />Fax száma: (62) 426-149<br />Név:
                Dékány László, Jerney Zoltán<br />E-mail cím:
                bekelteto.testulet@csmkik.hu;<br /><br />Fejér Megyei Békéltető
                Testület<br />Címe: 8000 Székesfehérvár, Hosszúsétatér 4-6.<br />Telefonszáma:
                (22) 510-310<br />Fax száma: (22) 510-312<br />Név: Kirst
                László<br />E-mail cím: fmkik@fmkik.hu;<br /><br />Győr-Moson-Sopron
                Megyei Békéltető Testület<br />Címe: 9021 Győr, Szent István út
                10/a.<br />Telefonszáma: (96) 520-202; 520-217<br />Fax száma:
                (96) 520-218<br />Név: Horváth László<br />E-mail cím:
                bekeltetotestulet@gymskik.hu;<br /><br />Hajdú-Bihar Megyei
                Békéltető Testület<br />Címe: 4025 Debrecen, Petőfi tér 10.<br />Telefonszáma:
                (52) 500-749<br />Fax száma: (52) 500-720<br />Név: Dr. Hajnal
                Zsolt<br />E-mail cím: info@hbkik.hu;<br /><br />Heves Megyei
                Békéltető Testület<br />Címe: 3300 Eger, Faiskola út 15.<br />Levelezési
                címe: 3301 Eger, Pf. 440.<br />Telefonszáma: (36) 416-660/105
                mellék<br />Fax száma: (36) 323-615<br />Név: Pintérné Dobó
                Tünde<br />E-mail cím: tunde@hkik.hu;<br /><br />Jász-Nagykun-Szolnok
                Megyei Békéltető Testület<br />Címe: 5000 Szolnok, Verseghy park
                8.<br />Telefonszáma: (56) 510-610<br />Fax száma: (56)
                370-005<br />Név: Dr. Lajkóné dr. Vígh Judit<br />E-mail cím:
                kamara@jnszmkik.hu;<br /><br />Komárom-Esztergom Megyei
                Békéltető Testület<br />Címe: 2800 Tatabánya, Fő tér 36.<br />Telefonszáma:
                (34) 513-010<br />Fax száma: (34) 316-259<br />Név: Dr. Rozsnyói
                György<br />E-mail cím: kemkik@kemkik.hu;<br /><br />Nógrád
                Megyei Békéltető Testület<br />Címe: 3100 Salgótarján, Alkotmány
                út 9/a<br />Telefonszám: (32) 520-860<br />Fax száma: (32)
                520-862<br />Név: Dr. Pongó Erik<br />E-mail cím:
                nkik@nkik.hu;<br /><br />Pest Megyei Békéltető Testület<br />Címe:
                1119 Budapest, Etele út 59-61. 2. em. 240.<br />Telefonszáma:
                (1)-269-0703<br />Fax száma: (1)-269-0703<br />Név: dr. Csanádi
                Károly<br />E-mail cím: pmbekelteto@pmkik.hu<br />Honlap cím:
                www.panaszrendezes.hu<br /><br />Somogy Megyei Békéltető
                Testület<br />Címe: 7400 Kaposvár, Anna utca 6.<br />Telefonszáma:
                (82) 501-000<br />Fax száma: (82) 501-046<br />Név: Dr. Novák
                Ferenc<br />E-mail cím: skik@skik.hu;<br /><br />Szabolcs-Szatmár-Bereg
                Megyei Békéltető Testület<br />Címe: 4400 Nyíregyháza, Széchenyi
                u. 2.<br />Telefonszáma: (42) 311-544, (42) 420-180<br />Fax
                száma: (42) 311-750<br />Név: Görömbeiné dr. Balmaz Katalin<br />E-mail
                cím: bekelteto@szabkam.hu;<br /><br />Tolna Megyei Békéltető
                Testület<br />Címe: 7100 Szekszárd, Arany J. u. 23-25.<br />Telefonszáma:
                (74) 411-661<br />Fax száma: (74) 411-456<br />Név: Mátyás
                Tibor<br />E-mail cím: kamara@tmkik.hu;<br /><br />Vas Megyei
                Békéltető Testület<br />Címe: 9700 Szombathely, Honvéd tér 2.<br />Telefonszáma:
                (94) 312-356<br />Fax száma: (94) 316-936<br />Név: Dr. Kövesdi
                Zoltán<br />E-mail cím: pergel.bea@vmkik.hu<br /><br />Veszprém
                Megyei Békéltető Testület<br />Címe: 8200 Veszprém, Budapest u.
                3.<br />Telefonszáma: (88) 429-008<br />Fax száma: (88)
                412-150<br />Név: Dr. Óvári László<br />E-mail cím:
                vkik@veszpremikamara.hu<br /><br />Zala Megyei Békéltető
                Testület<br />Címe: 8900 Zalaegerszeg, Petőfi utca 24.<br />Telefonszáma:
                (92) 550-513<br />Fax száma: (92) 550-525<br />Név: dr. Koczka
                Csaba<br />E-mail cím: zmbekelteto@zmkik.hu<br /><br />
              </p>

              <p
                style="font-size: 1.1rem;font-weight: 500;margin-bottom: 30px;"
              >
                <strong>11.7,&nbsp;</strong>A&nbsp; békéltető testület
                hatáskörébe tartozik a&nbsp; fogyasztói jogvita bírósági
                eljáráson kívüli rendezése. A&nbsp; békéltető testület feladata,
                hogy megkísérelje a&nbsp; fogyasztói jogvita rendezése céljából
                egyezség létrehozását a&nbsp; felek között, ennek
                eredménytelensége esetén az&nbsp; ügyben döntést hoz a&nbsp;
                fogyasztói jogok egyszerű, gyors, hatékony és költségkímélő
                érvényesítésének biztosítása érdekében. A&nbsp;békéltető
                testület a&nbsp;fogyasztó vagy a&nbsp;Szolgáltató kérésére
                tanácsot ad a&nbsp;fogyasztót megillető jogokkal és
                a&nbsp;fogyasztót terhelő kötelezettségekkel kapcsolatban.<br />
              </p>

              <p
                style="font-size: 1.1rem;font-weight: 500;margin-bottom: 30px;"
              >
                <strong>11.8,&nbsp;</strong>Online adásvételi vagy online
                szolgáltatási szerződéssel összefüggő határon átnyúló fogyasztói
                jogvita esetén az&nbsp;eljárásra kizárólag a&nbsp;fővárosi
                kereskedelmi és iparkamara mellett működő békéltető testület
                illetékes.<br />
              </p>

              <p
                style="font-size: 1.1rem;font-weight: 500;margin-bottom: 30px;"
              >
                <strong>11.9,&nbsp;</strong>A Fogyasztó panasza esetén igénybe
                veheti az Uniós online vitarendezési platformot. A platform
                igénybe vétele egy egyszerű regisztrációt igényel az Európai
                Bizottság rendszerében,&nbsp;ide kattintva. Ezt követően
                pedig&nbsp;bejelentkezés után nyújthatja be panaszát&nbsp;a
                fogyasztó az online honlapon keresztül, amelynek
                címe:&nbsp;http://ec.europa.eu/odr<br />
              </p>

              <p
                style="font-size: 1.1rem;font-weight: 500;margin-bottom: 30px;"
              >
                <strong>11.10,&nbsp;</strong>Szolgáltatót a békéltető testületi
                eljárásban együttműködési kötelezettség terheli. Ennek keretében
                köteles a válasziratát megküldeni a békéltető testület számára
                és a meghallgatáson egyezség létrehozatalára feljogosított
                személy részvételét biztosítani. Amennyiben a vállalkozás
                székhelye vagy telephelye nem a területileg illetékes békéltető
                testületet működtető kamara szerinti megyébe van bejegyezve, a
                vállalkozás együttműködési kötelezettsége a fogyasztó igényének
                megfelelő írásbeli egyezségkötés lehetőségének felajánlására
                terjed ki.<br />
              </p>

              <p
                style="font-size: 1.1rem;font-weight: 500;margin-bottom: 30px;"
              >
                <strong>11.11,&nbsp;</strong>Amennyiben a fogyasztó nem fordul
                békéltető testülethez, vagy az eljárás nem vezetett eredményre,
                úgy a fogyasztónak a jogvita rendezése érdekében lehetősége van
                bírósághoz fordulni. A pert keresetlevéllel kell megindítani,
                amelyben az alábbi információkat kell feltüntetni:<br />&nbsp;-
                az eljáró bíróságot;<br />&nbsp;- a feleknek, valamint a felek
                képviselőinek nevét, lakóhelyét és perbeli állását;<br />&nbsp;-
                az érvényesíteni kívánt jogot, az annak alapjául szolgáló
                tényeknek és azok bizonyítékainak előadásával;<br />&nbsp;-
                azokat az adatokat, amelyekből a bíróság hatásköre és
                illetékessége megállapítható;<br />&nbsp;- a bíróság döntésére
                irányuló határozott kérelmet .<br />A keresetlevélhez csatolni
                kell azt az okiratot, illetve annak másolatát amelynek
                tartalmára bizonyítékként hivatkozik.<br />
              </p>

              <h1
                style="font-size: 1.5rem;padding-top: 30px;padding-bottom: 30px;"
              >
                <strong>12. Szerzői jogok</strong><br />
              </h1>
              <p
                style="font-size: 1.1rem;font-weight: 500;margin-bottom: 30px;"
              >
                <strong>12.1,</strong>&nbsp;Miután a Teslapilota.hu, mint
                weboldal szerzői jogi műnek minősül, tilos a Teslapilota.hu
                weboldalon megjelenő tartalmak vagy azok bármely részletének
                letöltése (többszörözése), újra nyilvánossághoz történő
                közvetítése, más módon való felhasználása, elektronikus
                tárolása, feldolgozása és értékesítése a Szolgáltató írásos
                hozzájárulása nélkül.<br />
              </p>

              <p
                style="font-size: 1.1rem;font-weight: 500;margin-bottom: 30px;"
              >
                <strong>12.2,&nbsp;</strong>A Teslapilota.hu weboldalról és
                annak adatbázisából bármilyen anyagot átvenni írásos
                hozzájárulás esetén is csak az adott weboldalra való
                hivatkozással lehet.<br />
              </p>
              <p
                style="font-size: 1.1rem;font-weight: 500;margin-bottom: 30px;"
              >
                <strong>12.3,&nbsp;</strong>A Szolgáltató fenntartja minden
                jogát szolgáltatásának valamennyi elemére, a domain-neveire, az
                azokkal képzett másodlagos domain nevekre valamint az internetes
                reklámfelületeire.<br />
              </p>
              <p
                style="font-size: 1.1rem;font-weight: 500;margin-bottom: 30px;"
              >
                <strong>12.4,&nbsp;</strong>Tilos a Teslapilota.hu weboldal
                tartalmának, illetve egyes részeinek adaptációja vagy
                visszafejtése; a felhasználói azonosítók és jelszavak
                tisztességtelen módon történő létesítése; bármely olyan
                alkalmazás használata, amellyel a Teslapilota.hu weboldalon vagy
                azok bármely része módosítható vagy indexelhető.<br />
              </p>

              <p
                style="font-size: 1.1rem;font-weight: 500;margin-bottom: 30px;"
              >
                <strong>12.5,&nbsp;</strong>A Teslapilota.hu név szerzői jogi
                védelmet élvez, felhasználása a hivatkozás kivételével kizárólag
                a Szolgáltató írásos hozzájárulásával lehetséges.<br />
              </p>
              <p
                style="font-size: 1.1rem;font-weight: 500;margin-bottom: 30px;"
              >
                <strong>12.6,&nbsp;</strong>Felhasználó tudomásul veszi, hogy a
                felhasználási engedély nélküli felhasználás esetén Szolgáltatót
                kötbér illeti meg. A kötbér összege képenként bruttó 60.000 Ft,
                illetve szavanként bruttó 20.000 Ft. Felhasználó tudomásul
                veszi, hogy ezen kötbérkikötés nem túlzó, és ennek tudatában
                böngészi az oldalt. A szerzői jogi jogsértés esetén Szolgáltató
                közjegyzői ténytanúsítást alkalmaz, melynek összegét szintén a
                jogsértő felhasználóra hárítja.<br />
              </p>

              <h1
                style="font-size: 1.5rem;padding-top: 30px;padding-bottom: 30px;"
              >
                <strong>13.&nbsp;Adattovábbítási nyilatkozat</strong><br />
              </h1>
              <p
                style="font-size: 1.1rem;font-weight: 500;margin-bottom: 30px;"
              >
                <br />Felhasználó tudomásul veszi, hogy a(z)&nbsp;Brazil
                Reklámügynökség Korlátolt Felelősségű Társaság (1014 Budapest,
                Országház utca 22. 1. em. 2.) adatkezelő által a(z)
                www.teslapilota.hu felhasználói adatbázisában tárolt alábbi
                személyes adatai átadásra kerülnek az OTP Mobil Kft. (1093
                Budapest, Közraktár u. 30-32.), mint adatfeldolgozó részére. Az
                adatkezelő által továbbított adatok köre az alábbi:<br />&nbsp;-
                email cím<br />Az adatfeldolgozóáltal végzett adatfeldolgozási
                tevékenység jellege és célja a SimplePay Adatkezelési
                tájékoztatóban, az alábbi linken tekinthető meg:&nbsp;<a
                  href="#"
                  style="color: #c1132c;font-size: 1.1rem;font-weight: 700;"
                  >http://simplepay.hu/vasarlo-aff</a
                ><br /><br />
              </p>

              <h1
                style="font-size: 1.5rem;padding-top: 30px;padding-bottom: 30px;"
              >
                <strong>14. Adatvédelem</strong><br />
              </h1>
              <p
                style="font-size: 1.1rem;font-weight: 500;margin-bottom: 30px;"
              >
                A weboldal adatkezelési tájékoztatója elérhető a következő
                oldalon:&nbsp;<a
                  href="#"
                  style="color: #c1132c;font-weight: 700;font-size: 1.1rem;"
                  >http://teslapilota.hu/adatvedelem</a
                ><br />
              </p>
              <h1
                style="font-size: 1.5rem;padding-top: 30px;padding-bottom: 30px;"
              >
                <strong>Budapest, 2019. augusztus 01.</strong><br />
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import TopNavBar from '../general/top-nav-bar';

  export default {
    name: "aszf",
    components: {
      TopNavBar
    }
  };
</script>

<style scoped>

</style>

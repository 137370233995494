<template>
    <div class="home" style="font-family: Montserrat, sans-serif;">
        <top-nav-bar />
        <div class="home-header">
            <header>
                <gift-box />
                <img class="tesla-logo-header" id="tesla-logo-header" src="~assets/img/img-header-logo-small.png">
                <div class="container text-center home-head-btn-ctn"><a class="btn active shadow none home-head-btn" :href="goToH('reservation')">IDŐPONTFOGLALÁS</a></div>
                <div>
                    <div class="container home-head-ctn">
                        <div class="row home-head-ctn-row">
                            <div class="col home-head-row1 col-xs-6 col-sm-6 col-lg-4">
                                <h2>LEGYÉL TE IS<br /></h2>
                                <h1><strong>TESLAPILÓTA!</strong><br /></h1>
                            </div>
                            <div class="col d-none d-lg-block home-head-row2 col-lg-4">
                                <h1><strong>2.6 S</strong><br /></h1>
                                <h2>0 – 100 KM/H<br /></h2>
                                <p>UTOLÉRHETETLEN GYORSULÁS<br /></p>
                            </div>
                            <div class="col home-head-row3 col-xs-6 col-sm-6 col-lg-4">
                                <h1><strong>NE ÁLMODOZZ,</strong><br /></h1>
                                <h2>HANEM ÉLD<br />AZ ÁLMODAT!<br /></h2>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </div>
        <main style="margin-top: 100px;">
            <div class="container-md text-left text-sm-center text-md-center text-lg-center text-xl-left" id="home-elmeny">
                <h1 class="text-uppercase">Élménytesla vezetés</h1>
                <h2 class="text-uppercase">Magyarországon</h2>
                <p>Kilövésre felkészültél? Tesztelheted magad mint TeslaPilóta és mit tud a<br>772 lóerős P100D CsúcsTesla!</p>
            </div>
        </main>
        <div id="kiloves-block">
            <div class="container">
                <div class="row">
                    <div class="col-md-4" style="line-height: 1.1rem;">
                        <h1 class="text-uppercase"><img src="~assets/img/line.png"><br>Velünk</h1>
                        <p>TeslaPilótaként forgalomban vezetheted a méregdrága sportkocsikat porig alázó P100D-t.</p>
                    </div>
                    <div class="col-md-4">
                        <h1 class="text-uppercase"><img src="~assets/img/line.png"><br>Kilövés 0-100</h1>
                        <p>Reméljük jól bírod a gyorsulást, mert egy TeslaPilótának bírnia kell a 0-100-at 2.6 mp alatt is.</p>
                    </div>
                    <div class="col-md-4" style="padding-right: 0px;">
                        <h1 class="text-uppercase"><img src="~assets/img/line.png"><br>Autópilóta</h1>
                        <p>Próbáld ki milyen, amikor átadod a vezetést a Robotpilótának (autopilot üzemmód).</p>
                    </div>
                    <div class="col"><button class="btn text-uppercase" @click="goToC('reservation')" style="background-color: rgba(255,255,255,0);color: rgb(0,0,0);font-size: 1.5rem;font-family: Montserrat, sans-serif;margin-right: 0px;padding-right: 15px;padding-top: 35px;padding-left: 0px;"><strong>tovább</strong><img src="~assets/img/btn-more.png" style="padding-left: 35px;"></button></div>
                </div>
            </div>
        </div>
        <div class="row justify-content-sm-center justify-content-md-center col-12" style="padding-left: 0px;padding-right: 15px;margin-top: 80px;margin-right: 0px;margin-left: 0px;width: auto;">
            <div class="col-md-6 col-xl-7 text-sm-center text-md-center align-self-center" style="padding-right: 0px;padding-left: 0px;margin-right: 0px;">
                <div class="video-container"><iframe allowfullscreen="" frameborder="0" src="https://www.youtube.com/embed/Kx8buCK-VBA?autoplay=1&amp;mute=1&amp;controls=0" width="auto" height="auto"></iframe></div>
            </div>
            <div class="col-xl-4 text-sm-center text-md-center text-lg-center text-xl-left align-self-center" id="ajandekozz">
                <h1 class="text-uppercase text-sm-center text-md-center text-lg-center text-xl-left justify-content-xl-center align-items-xl-end">ajándékozz<br></h1>
                <h3 class="text-uppercase text-sm-center text-md-center text-lg-center text-xl-left">teslapilóta élményt!</h3>
                <h5 class="text-sm-center text-md-center text-lg-center text-xl-left">Ne a tárgyakat gyűjtsd,<br>hanem az élményeket!</h5>
                <p>Férjed, barátod, feleséged, barátnőd imádja a fejletépő gyorsulát, a suhanós száguldást? Ajándékozd meg egy felejthetetlen TeslaPilóta élménnyel! Garantáltan életreszóló élmény lesz ez az adrenalin-fröccs! Rendeld meg a 30, 60 vagy 90 perces
                    TeslaPilóta élményt még ma és postázzuk az ajándékkártyát, mely 12 hónapon belül felhasználható.</p>
                <h4 class="text-uppercase">Remek ajándék<br>minden alkalomra!</h4><button class="btn active shadow-none" @click="goToC('reservation', { g: true })" id="btn-giftcard">AJÁNDÉKKÁRTYA</button></div>
        </div>
        <div class="container-md" style="margin-top: 100px;margin-bottom: 100px;">
            <div class="row">
                <div class="col text-sm-center text-md-center text-lg-center text-xl-left align-self-center col-sm-12 col-md-12 col-lg-12 col-xl-4" style="border-left: 1px solid black;border-right: 1px solid black;padding-top: 16px;padding-bottom: 16px;">
                    <h1 class="text-uppercase" id="teambuild_header" style="font-weight: 700;font-size: 2.8rem;margin-bottom: 0px;">Csapatépítő</h1>
                    <h2 class="text-uppercase" style="font-weight: 700;font-size: 1.8rem;">Teslateszt-kilövés</h2>
                </div>
                <div class="col col-sm-12 col-md-12 col-lg-12 col-xl-4">
                    <h1 class="text-center counter" style="font-size: 18rem; font-weight: 800">
                        <span id="counter3">3</span>
                        <span id="counter2">2</span>
                        <span id="counter1">1</span>
                    </h1>
                </div>
                <div class="col text-sm-center text-md-center text-lg-center text-xl-left align-self-center col-sm-12 col-md-12 col-lg-12 col-xl-4" style="border-left: 1px solid black; border-right: 1px solid black">
                    <h2 class="text-uppercase" style="font-size: 1.8rem;font-weight: 700;margin-bottom: 0px;">Mert van amit csak</h2>
                    <h1 class="text-uppercase" style="font-weight: 700;font-size: 2.8rem;margin-bottom: 0px;">a teslával</h1>
                    <h2 class="text-uppercase" style="font-size: 1.8rem;font-weight: 700;">élhetsz át</h2>
                </div>
            </div>
        </div>
        <div><div class="module" id="wrapper2">
            <header>
                <h1>
                    CSAPATÉPÍTŐ
                </h1>
                <h2>
                    PROGRAMOT SZERVEZEL?
                </h2>
                <p>A TeslaPilóta programja garantáltan<br>
                    szórakoztató élmény lesz a csapat tagjainak.</p>
                <button id="teambutton" class="btn text-uppercase" @click="goToC('company')" style="background-color: rgba(255,255,255,0);color: rgb(255,255,255);font-size: 1.5rem;font-family: Montserrat, sans-serif;margin-right: 0px;padding-right: 15px;padding-top: 35px;padding-left: 0px;"><strong>tovább</strong><img src="~assets/img/btn-more-white.png" style="padding-left: 35px;" /></button>
            </header>
        </div></div>
        <div class="container" style="margin-top: 100px;">
            <h1 class="text-uppercase" style="font-family: Montserrat, sans-serif;font-weight: 700;margin-bottom: 0px;font-size: 3rem;">Foglalj helyet</h1>
            <h2 class="text-uppercase" style="font-family: Montserrat, sans-serif;font-weight: 500;margin-bottom: 35px;font-size: 2rem;">A teslapilóta-ülésben!</h2>
            <p style="font-size: 1.1rem;">Válassz a TeslaPilóta csomagok közül!<br>Vigyázat! Erősen addiktív élmény. :)</p><img src="~assets/img/line.png" style="margin-top: 20px;margin-bottom: 36px;"></div>
            <section class="pricing-table" style="font-family: Montserrat, sans-serif;">
                <div class="container">
                    <div class="pricing-area text-center">
                        <div class="row">
                            <div class="col-sm-4 plan price red wow fadeInDown">
                                <ul class="list-group">
                                    <li class="list-group-item heading" style="margin-left: 0px;background-color: rgb(255,255,255);">
                                        <h1 class="text-uppercase" style="color: rgb(0,0,0);margin-bottom: 0px;font-family: Montserrat, sans-serif;font-weight: 600;font-size: 2rem;">Start</h1>
                                        <p style="color: rgb(0,0,0);">Szólóban vezethetsz</p><span class="price">49.900 Ft</span></li>
                                    <li class="list-group-item" style="color: rgb(0,0,0);background-color: rgb(255,255,255);height: 320px;">
                                        <p>TeslaPilóta extrém gyorsulás</p>
                                        <p>Autopilot mód</p>
                                        <p style="margin-bottom: 40%;">Élményvezetés forgalomban</p>
                                        <p style="margin-top: 0px;">Legfeljebb 1 fő vezethet</p>
                                        <p>30 perc TeslaPilóta élményvezetés</p>
                                    </li>
                                    <li class="list-group-item" style="color: rgb(0,0,0);background-color: rgb(255,255,255);"><button class="btn active shadow-none" @click="goToC('reservation', { p: 0 })">LEFOGLALOM</button></li>
                                </ul>
                            </div>
                            <div class="col-sm-4 plan price yellow wow fadeInDown"><img src="~assets/img/bg-package-ribbon.png">
                                <ul class="list-group">
                                    <li class="list-group-item heading" style="margin-left: 0px;background-color: rgb(255,255,255);">
                                        <h1 class="text-uppercase" style="color: rgb(0,0,0);margin-bottom: 0px;font-family: Montserrat, sans-serif;font-weight: 600;font-size: 2rem;">Dupla</h1>
                                        <p style="color: rgb(0,0,0);">Párban vezethettek</p><span class="price">59.900 Ft</span></li>
                                    <li class="list-group-item" style="height: 320px;background-color: rgb(255,255,255);">
                                        <p>TeslaPilóta extrém gyorsulás</p>
                                        <p>Autopilot mód</p>
                                        <p style="color: rgb(0,0,0);margin-bottom: 20%;">Élményvezetés forgalomban</p>
                                        <p style="color: rgb(0,0,0);">Legfeljebb 2 fő vezethet</p>
                                        <p>(29.950 Ft/fő)*</p>
                                        <p>*2 fő foglalása esetén</p>
                                        <p>60 perc TeslaPilóta élményvezetés</p>
                                    </li>
                                    <li class="list-group-item" style="color: rgb(0,0,0);background-color: rgb(255,255,255);"><button class="btn active shadow-none" @click="goToC('reservation', { p: 1 })">LEFOGLALOM</button></li>
                                </ul>
                            </div>
                            <div class="col-sm-4 plan price green wow fadeInDown">
                                <ul class="list-group">
                                    <li class="list-group-item heading" style="margin-left: 0px;background-color: rgb(255,255,255);">
                                        <h1 class="text-uppercase" style="color: rgb(0,0,0);margin-bottom: 0px;font-family: Montserrat, sans-serif;font-weight: 600;font-size: 2rem;">Tripla</h1>
                                        <p style="color: rgb(0,0,0);">Hárman vezethettek</p><span class="price">83.900 Ft</span></li>
                                    <li class="list-group-item" style="background-color: rgb(255,255,255);height: 320px;">
                                        <p>TeslaPilóta extrém gyorsulás</p>
                                        <p>Autopilot mód</p>
                                        <p style="color: rgb(0,0,0);margin-bottom: 20%;">Élményvezetés forgalomban</p>
                                        <p>Legfeljebb 3 fő vezethet</p>
                                        <p>(27.966 Ft/fő)*</p>
                                        <p>*3 fő foglalása esetén</p>
                                        <p>90 perc TeslaPilóta élményvezetés</p>
                                    </li>
                                    <li class="list-group-item" style="color: rgb(0,0,0);background-color: rgb(255,255,255);"><button class="btn active shadow-none" @click="goToC('reservation', { p: 2 })">LEFOGLALOM</button></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container" id="legkozelebbi">
                    <h1 class="text-uppercase" style="font-family: Montserrat, sans-serif;font-weight: 700;margin-bottom: 0px;font-size: 2.5rem;">legközelebbi</h1>
                    <h1 class="text-uppercase" style="font-family: Montserrat, sans-serif;font-weight: 700;margin-bottom: 0px;font-size: 3rem;">szabad időpontok!</h1>
                    <p style="font-size: 1.1rem;">Nézd meg a közelgő TeslaPilóta élményvezetés időpontokat és válaszd<br>ki a hozzád legközelebb eső helyszínt!</p><img src="~assets/img/line.png">
                </div>
                <div style="margin-top: 20px;">
                    <tesla-pilot-calendar />
                </div>
                <div style="margin-top: 20px; margin-bottom: 70px;" class="text-center"><button class="btn active shadow-none" @click="goToC('reservation')" id="btn-reservation">IDŐPONTFOGLALÁS</button></div>
                <div class="col nolicence">
                    <h1 class="text-uppercase">Nincs még<br>JOGOSÍTVÁNYOD?<br></h1>
                    <h2>akkor<br>mi leszünk<br>a teslapilótád!</h2>
                    <p>Ha nincs még jogosítványod vagy<br>annak, akit meg szeretnél lepni az<br>élménnyel, akkor sincs gond, mert<br>akkor mi leszünk a TeslaPilótátok. Min-<br>dent ugyanúgy meg tudunk mutatni,<br>pl. hogy vezet az autopilot és a gyor-<br>sulás élménye
                        pedig ugyanolyan<br>mélyreható lesz, ezt megígérhetjük :)</p><button class="btn text-uppercase" @click="goToC('reservation')"><strong>tovább</strong><img src="~assets/img/btn-more.png" style="padding-left: 35px;"></button></div>
                <div class="container home-joyridectn">
                    <h2>a teslapilóta</h2>
                    <h1>élményvezetés menete:</h1>
                </div>
            </section>
            <div class="container drivefaq" id="container-drivefaq">
                <div class="table-responsive table-borderless faqtable">
                    <table class="table table-bordered table-sm">
                        <tbody>
                        <tr>
                            <td>
                                <p>1</p>
                            </td>
                            <td>
                                <p>2</p>
                            </td>
                            <td>
                                <p>3</p>
                            </td>
                            <td>
                                <p>4</p>
                            </td>
                        </tr>
                        <tr>
                            <td><span><br>Lehetőség szerint az egyeztetett időpont előtt pár perccel érkezz a helyszínre.<br><br></span></td>
                            <td><span><br>Elintézzük a formaságokat, kitöltjük a szükséges iratokat, legyen nálad ÉRVÉNYES jogosítvány, megfújod a szondát. Kérünk, hogy a vezetést 12 órával megelőzően ne fogyassz alkoholt, drogokat meg egyáltalán ne!<br><br></span></td>
                            <td><span><br>Instruktor TeslaPilótánk bemutatja neked a Tesla P100D-t és a legfontosabb tudnivalókat, nyugodtan tedd fel kérdéseidet, ez nem a te idődből megy!<br><br></span></td>
                            <td><span><br>Ha hoztál magaddal GoPro kamerát, akkor azt használhatod. Csak szólj időben, hogy még vezetés előtt beélesíthessük.<br><br></span></td>
                        </tr>
                        <tr>
                            <td>
                                <p>5</p>
                            </td>
                            <td>
                                <p>6</p>
                            </td>
                            <td>
                                <p>7</p>
                            </td>
                            <td></td>
                        </tr>
                        <tr>
                            <td><span><br>Ha minden tudnivavalót megbeszéltünk, helyet foglalsz a TeslaPilóta ülésében, beállítod az ülést és a tükröket, hogy ezt már ne vezetés közben kelljen állítgatni.<br><br></span></td>
                            <td><span><br>Elindulást követően eleinte óvatosan vezess, hogy még megszokd, érezd az autót, és főleg a gázpedált ;) Aztán jöhet a tempósabb vezetés, kipróbálhatod a Tesla minden funkcióját és képességét.<br><br></span></td>
                            <td><span><br>Visszaérkezés a kiindulási pontra, igény esetén fotózás, szelfizés.<br><br></span></td>
                            <td></td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="container enjoyment-ctn d-none d-md-block">
                <h1>A tesla</h1>
                <h2>élménymérő</h2>
            </div>
        <div class="container speedbar d-none d-md-block" style="margin-top: 90px;margin-bottom: 90px;">
            <div class="row">
                <div class="col-xl-3"><span><br><strong>Gyorsulás élménye</strong><br></span></div>
                <div class="col-xl-7 col-lg-10 col-md-10">  <div id="ui">
                    <div class="night"></div>
                    <div class="night"></div>
                    <div class="night"></div>
                    <div class="night"></div>
                    <div class="night"></div>
                    <div class="night"></div>
                    <div class="night"></div>
                    <div class="night"></div>
                    <div class="night"></div>
                    <div class="night"></div>
                    <div class="night"></div>
                </div></div>
                <div class="col-xl-2 col-lg-2 col-md-2"><span> <br><strong>11/10</strong><br></span></div>
            </div>
            <div class="row">
                <div class="col-xl-3"><span><br><strong>A Tesla Robotpilóta élménye</strong><br></span></div>
                <div class="col-xl-7 col-lg-10 col-md-10">  <div id="ui2">
                    <div class="night"></div>
                    <div class="night"></div>
                    <div class="night"></div>
                    <div class="night"></div>
                    <div class="night"></div>
                    <div class="night"></div>
                    <div class="night"></div>
                    <div class="night"></div>
                    <div class="night"></div>
                    <div class="night"></div>
                </div></div>
                <div class="col-xl-2 col-lg-2 col-md-2"><span><br><strong>10/10</strong><br></span></div>
            </div>
            <div class="row">
                <div class="col-xl-3"><span><br><strong>Útfekvés, stabilitás</strong><br></span></div>
                <div class="col-lg-10 col-xl-7 col-md-10">  <div id="ui3">
                    <div class="night"></div>
                    <div class="night"></div>
                    <div class="night"></div>
                    <div class="night"></div>
                    <div class="night"></div>
                    <div class="night"></div>
                    <div class="night"></div>
                    <div class="night"></div>
                    <div class="night"></div>
                    <div class="night"></div>
                </div></div>
                <div class="col-lg-2 col-xl-2 col-md-2"><span><br><strong>10/10</strong><br></span></div>
            </div>
        </div>
        <div class="d-none d-xl-block" style="margin-bottom: 90px;">
            <div class="row justify-content-sm-center justify-content-md-center col-12" style="padding-left: 0px;padding-right: 15px;margin-top: 80px;margin-right: 0px;margin-left: 0px;width: auto;">
                <div class="col-md-6 col-xl-7 text-sm-center text-md-center align-self-center" style="padding-right: 0px;padding-left: 0px;margin-right: 0px;">
                    <div class="video-container"><iframe allowfullscreen="" frameborder="0" src="https://www.youtube.com/embed/plR4SfLRMjQ" width="auto" height="auto"></iframe></div>
                </div>
                <div class="col-xl-4 text-sm-center text-md-center text-lg-center text-xl-left align-self-center" id="elmenyvideo">
                    <h1 class="text-uppercase text-sm-center text-md-center text-lg-center text-xl-left justify-content-xl-center align-items-xl-end">még több<br></h1>
                    <h3 class="text-uppercase text-sm-center text-md-center text-lg-center text-xl-left">teslapilóta <br />élmény videó!</h3>
                    <button class="btn active shadow-none" id="btn-giftcard" @click="goVideos()">TOVÁBB A VIDEÓKHOZ</button>
                </div>
            </div>
        </div>
        <div class="d-none d-xl-block">
            <div class="text-uppercase">
                <div class="container">
                    <div class="row">
                        <div class="col-md-4 col-xl-10 offset-xl-1">
                            <h1 class="text-uppercase text-left"><strong>KÖVESS</strong></h1>
                        </div>
                    </div>
                </div>
            </div>
        <div>
            <div class="container">
                <div class="row">
                    <div class="col-md-4 col-xl-2 offset-xl-5 text-right align-self-center">
                        <a href="http://www.facebook.com/teslapilota"><img style="padding-right: 1.875rem" src="~assets/img/btn-facebook.png"></a><a href="http://www.instagram.com/teslapilota"><img src="~assets/img/btn-instagram.png"></a>
                    </div>
                    <div class="col-md-4 col-xl-5 offset-xl-0">
                        <h1 class="text-uppercase text-right"><strong>MINKET</strong></h1>
                    </div>
                    <div class="clearfix"></div>
                </div>
            </div>
        </div>
    </div>
        <div class="mobile d-block d-xl-none">
            Kövess minket
            <div>
                <a href="https://www.facebook.com/TeslaPilota/" target="_blank">
                    <img src="~assets/img/btn-facebook.png">
                </a>
                <a href="https://www.instagram.com/teslapilota/" target="_blank">
                    <img src="~assets/img/btn-instagram.png">
                </a>
            </div>
        </div>
    </div>
</template>

<script>
    import TopNavBar from '../general/top-nav-bar';
    import * as ScrollMagic from 'ScrollMagic';
    import "ScrollMagic/scrollmagic/minified/plugins/debug.addIndicators.min.js";
    import router from "assets/js/plugins/router";
    import TeslaPilotCalendar from '../general/tesla-pilot-calendar';
    import GiftBox from '../general/giftbox';

    export default {
        name: "home",
        components: {
            TopNavBar,
            TeslaPilotCalendar,
            GiftBox
        },

        data() {
            return {}
        },

        mounted() {
            const controller = new ScrollMagic.Controller();
            const animateElem3 = document.getElementById("counter3");
            const animateElem2 = document.getElementById("counter2");
            const animateElem1 = document.getElementById("counter1");
            animateElem3.style.opacity = 100;
            animateElem2.style.opacity = 0;
            animateElem1.style.opacity = 0;

            const windowHeight = window.innerHeight;
            const duration = windowHeight / 3;
            const positionCorrection = -100;

            const counter3 = new ScrollMagic.Scene({
                triggerElement: "#counter3",
                duration: duration * 2,
                offset: (duration * -2) + positionCorrection
            })
                .on("enter", function () {
                    // trigger animation by changing inline style.
                    animateElem3.style.opacity = 100;
                })
                .on("leave", function () {
                    // reset style
                    animateElem3.style.opacity = 0;
                })
                //.addIndicators({name: "checkpoint"}) // add indicators (requires plugin)
                .addTo(controller);


            const counter2 = new ScrollMagic.Scene({
                triggerElement: "#counter2",
                duration: duration * 1.2,
                offset: positionCorrection
            })
                .on("enter", function () {
                    // trigger animation by changing inline style.
                    animateElem2.style.opacity = 100;
                })
                .on("leave", function () {
                    // reset style
                    animateElem2.style.opacity = 0;
                })
                //.addIndicators({name: "checkpoint"}) // add indicators (requires plugin)
                .addTo(controller);

            const counter1 = new ScrollMagic.Scene({
                triggerElement: "#counter1",
                duration: duration * 2,
                offset: (duration * 1.2) + positionCorrection
            })
                .on("enter", function () {
                    // trigger animation by changing inline style.
                    animateElem1.style.opacity = 100;
                })
                .on("leave", function () {
                    // reset style
                    animateElem1.style.opacity = 0;
                })
                //.addIndicators({name: "checkpoint"}) // add indicators (requires plugin)
                .addTo(controller);

            const loader1 = new ScrollMagic.Scene({triggerElement: "#ui", offset: windowHeight / 2 * -1})
                .setClassToggle("#ui", "loaded") // add class toggle
                //.addIndicators() // add indicators (requires plugin)
                .addTo(controller);

            const loader2 = new ScrollMagic.Scene({triggerElement: "#ui2", offset: windowHeight / 2 * -1})
                .setClassToggle("#ui2", "loaded") // add class toggle
                //.addIndicators() // add indicators (requires plugin)
                .addTo(controller);

            const loader3 = new ScrollMagic.Scene({triggerElement: "#ui3", offset: windowHeight / 2 * -1})
                .setClassToggle("#ui3", "loaded") // add class toggle
                //.addIndicators() // add indicators (requires plugin)
                .addTo(controller);
        },

        methods:
        {
            goToH: function(name, query = {})
            {
                return router.resolve({name: name, query: query}).href;
            },

            goToC: function(name, query = {})
            {
                document.location = router.resolve({name: name, query: query}).href;
            },

            goVideos()
            {
                document.location = 'https://www.youtube.com/channel/UCG6NYETa6O-jtRkVCtbazFw';
            }
        }
    };
</script>

<style scoped>
    #home-elmeny h1 {
        font-family: Montserrat, sans-serif;
        font-weight: 700;
        margin-bottom: 0px;
        font-size: 3rem;
    }

    @media (max-width: 576px) {
        #home-elmeny h1 {
            font-family: Montserrat, sans-serif;
            font-weight: 700;
            margin-bottom: 0px;
            font-size: 2rem;
            text-align: center;
        }
    }

    #home-elmeny h2 {
        font-family: Montserrat, sans-serif;
        font-weight: 600;
        margin-bottom: 35px;
        font-size: 2.3rem;
    }

    @media (max-width: 576px) {
        #home-elmeny h2 {
            font-family: Montserrat, sans-serif;
            font-weight: 600;
            margin-bottom: 35px;
            font-size: 1.7rem;
            text-align: center;
        }
    }

    #home-elmeny p {
        font-family: Montserrat, sans-serif;
        font-size: 1.1rem;
        font-weight: 500;
    }

    @media (max-width: 576px) {
        #home-elmeny p {
            font-family: Montserrat, sans-serif;
            font-size: 1rem;
            font-weight: 500;
            text-align: center;
        }
    }

    body {
        overflow-wrap: break-word;
    }

    #kiloves-block {
        margin-top: 90px;
    }

    @media (max-width: 767px) {
        #kiloves-block {
            text-align: center;
        }
    }

    #kiloves-block h1 {
        margin-bottom: 20px;
        font-family: Montserrat, sans-serif;
        font-weight: 700;
        font-size: 2rem;
    }

    @media (max-width: 992px) {
        #kiloves-block h1 {
            margin-bottom: 20px;
            font-family: Montserrat, sans-serif;
            font-weight: 700;
            font-size: 1.7rem;
        }
    }

    #kiloves-block p {
        font-size: 1rem;
        line-height: 25px;
        font-family: Montserrat, sans-serif;
        font-weight: 500;
    }

    #ajandekozz {
        padding-left: 50px;
        padding-right: 50px;
        margin-left: 0px;
    }

    @media (max-width: 1199px) {
        #ajandekozz {
            padding-left: 15px;
            padding-right: 15px;
            margin-left: 0px;
        }
    }

    #ajandekozz h1 {
        font-size: 3.3rem;
        font-family: Montserrat, sans-serif;
        font-weight: 800;
        margin-bottom: 0px;
        margin-right: 0px;
    }

    @media (min-width: 1200px) {
        #ajandekozz h1 {
            font-size: 2.3rem;
            font-family: Montserrat, sans-serif;
            font-weight: 800;
            margin-bottom: 0px;
            margin-right: 0px;
        }
    }

    @media (max-width: 600px) {
        #ajandekozz h1 {
            font-size: 2.3rem;
            font-family: Montserrat, sans-serif;
            font-weight: 800;
            margin-bottom: 0px;
            margin-right: 0px;
            text-align: center;
        }
    }

    #ajandekozz h3 {
        font-size: 2rem;
        font-family: Montserrat, sans-serif;
        font-weight: 700;
        margin-bottom: 30px;
    }

    @media (min-width: 1200px) {
        #ajandekozz h3 {
            font-size: 1.4rem;
            font-family: Montserrat, sans-serif;
            font-weight: 700;
            margin-bottom: 30px;
        }
    }

    @media (max-width: 600px) {
        #ajandekozz h3 {
            font-size: 1.4rem;
            font-family: Montserrat, sans-serif;
            font-weight: 700;
            margin-bottom: 30px;
            text-align: center;
        }
    }

    #ajandekozz h4 {
        font-family: Montserrat, sans-serif;
        font-weight: 700;
        font-size: 1.2rem;
        margin-top: 30px;
        margin-bottom: 30px;
    }

    @media (max-width: 576px) {
        #ajandekozz h4 {
            font-family: Montserrat, sans-serif;
            font-weight: 700;
            font-size: 1.2rem;
            margin-top: 30px;
            margin-bottom: 30px;
            text-align: center;
        }
    }

    #ajandekozz h5 {
        font-size: 1.8rem;
        font-family: Montserrat, sans-serif;
        font-weight: 700;
        margin-bottom: 30px;
    }

    @media (min-width: 1200px) {
        #ajandekozz h5 {
            font-size: 1.4rem;
            font-family: Montserrat, sans-serif;
            font-weight: 700;
            margin-bottom: 30px;
        }
    }

    @media (max-width: 600px) {
        #ajandekozz h5 {
            font-size: 1.4rem;
            font-family: Montserrat, sans-serif;
            font-weight: 700;
            margin-bottom: 30px;
            text-align: center;
        }
    }

    #ajandekozz p {
        font-size: 1.2rem;
        font-weight: 400;
    }

    @media (min-width: 1200px) {
        #ajandekozz p {
            font-size: 1rem;
            font-weight: 400;
        }
    }

    @media (max-width: 600px) {
        #ajandekozz p {
            font-size: 1rem;
            font-weight: 400;
            text-align: center;
        }
    }

    video {
        max-width: 100%;
        height: auto;
        width: 100%;
    }

    .video-container {
        position: relative;
        padding-bottom: 56.25%;
        padding-top: 0;
        height: 0;
        overflow: hidden;
    }

    .video-container iframe, .video-container object, .video-container embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    #btn-giftcard {
        background-image: url('~assets/img/btn-reservation-red.png');
        width: 298px;
        height: 74px;
        background-color: rgba(0,0,0,0);
        font-weight: 700;
        font-size: 1.1rem;
        background-repeat: no-repeat;
        background-size: cover;
        color: rgb(255,255,255);
    }

    #btn-reservation {
        background-image: url('~assets/img/btn-reservation-red.png');
        width: 298px;
        height: 74px;
        background-color: rgba(0,0,0,0);
        font-weight: 700;
        font-size: 1.1rem;
        background-repeat: no-repeat;
        background-size: cover;
        color: rgb(255,255,255);
    }


    .pricing-table {
        padding: 70px 0;
    }

    .price h1, .price h2, .price h3, .price h4, .price h5, .price h6 {
        color: #4e4e4e;
        font-family: "Open Sans";
        font-weight: 600;
    }

    .plan {
        margin-bottom: 70px;
        position: relative;
    }

    .pricing-area ul {
        background: #fafafa;
        margin: 0;
        padding: 0;
    }

    .pricing-area li {
        background: #fafafa;
    }

    .pricing-area .heading {
        margin-left: -10px;
        border-radius: 2px 2px 0 0;
        padding: 17px 0;
    }

    .pricing-area ul li {
        font-size: 16px;
        font-weight: 400;
        list-style: outside none none;
        padding: 15px 0;
        color: #666666;
    }

    .pricing-area h1, .pricing-area .price {
        font-weight: 300;
        margin-bottom: 15px;
        margin-top: 0;
        font-size: 36px;
        color: #ffffff;
    }

    .pricing-area .price {
        font-size: 18px;
        font-weight: 700;
    }

    .pricing-area ul li:nth-child(2n) {
        background: #f5f5f5 none repeat scroll 0 0;
    }

    .pricing-area .price.red .heading, .pricing-area .price.red .plan-action .btn {
        background: #e24f43 none repeat scroll 0 0;
        color: #ffffff;
    }

    .pricing-area .price.green .heading, .pricing-area .price.green .plan-action .btn {
        background: #27ae60 none repeat scroll 0 0;
        color: #ffffff;
    }

    .pricing-area .price.yellow .heading, .pricing-area .price.yellow .plan-action .btn {
        background: #f39c12 none repeat scroll 0 0;
        color: #ffffff;
    }

    .pricing-area .plan {
        margin-bottom: 70px;
        position: relative;
    }

    .pricing-area img {
        position: absolute;
        right: 15px;
        top: 0;
        z-index: 1;
    }

    .price p {
        margin: 0 0 10px;
        font-size: 1rem;
        color: rgb(0,0,0);
    }

    @media (max-width: 990px) {
        .price p {
            margin: 0 0 10px;
            font-size: 0.9rem;
            color: rgb(0,0,0);
        }
    }

    @media (max-width: 768px) {
        .price p {
            margin: 0 0 10px;
            font-size: 0.7rem;
            color: rgb(0,0,0);
        }
    }

    @media (max-width: 576px) {
        .price p {
            margin: 0 0 10px;
            font-size: 1rem;
            color: rgb(0,0,0);
        }
    }

    .pricing-table {
        padding: 70px 0;
    }

    .plan {
        margin-bottom: 70px;
        position: relative;
    }

    .pricing-area ul {
        background: #fafafa;
        margin: 0;
        padding: 0;
    }

    .pricing-area li {
        background: #fafafa;
    }

    .pricing-area .heading {
        margin-left: -10px;
        border-radius: 2px 2px 0 0;
        padding: 17px 0;
    }

    .pricing-area ul li {
        font-size: 16px;
        font-weight: 400;
        list-style: outside none none;
        padding: 15px 0;
        color: #666666;
    }

    .pricing-area h1, .pricing-area .price {
        font-weight: 300;
        margin-bottom: 15px;
        margin-top: 0;
        font-size: 36px;
        color: #ffffff;
    }

    .pricing-area .price {
        font-weight: 700;
        color: rgb(0,0,0);
        font-size: 3rem;
    }

    @media (max-width: 1200px) {
        .pricing-area .price {
            font-weight: 700;
            color: rgb(0,0,0);
            font-size: 2rem;
        }
    }

    @media (max-width: 576px) {
        .pricing-area .price {
            font-weight: 700;
            color: rgb(0,0,0);
            font-size: 1rem;
        }
    }

    .pricing-area ul li:nth-child(2n) {
        background: #f5f5f5 none repeat scroll 0 0;
    }

    .pricing-area .price.green .heading, .pricing-area .price.green .plan-action .btn {
        background: #27ae60 none repeat scroll 0 0;
        color: #ffffff;
    }

    .pricing-area .price.yellow .heading, .pricing-area .price.yellow .plan-action .btn {
        background: #f39c12 none repeat scroll 0 0;
        color: #ffffff;
    }

    .pricing-area .plan {
        margin-bottom: 70px;
        position: relative;
    }

    .pricing-area img {
        position: absolute;
        right: 15px;
        top: 0;
        z-index: 1;
        width: 135px;
    }

    @media (max-width: 1200px) {
        .pricing-area img {
            position: absolute;
            right: 15px;
            top: 0;
            z-index: 1;
            width: 100px;
        }
    }

    @media (max-width: 768px) {
        .pricing-area img {
            position: absolute;
            right: 15px;
            top: 0;
            z-index: 1;
            width: 75px;
        }
    }

    @media (max-width: 576px) {
        .pricing-area img {
            position: absolute;
            right: 15px;
            top: 0;
            z-index: 1;
            opacity: 0.5;
            width: 75px;
        }
    }

    .price button {
        background-image: url('~assets/img/btn-reservation-red.png');
        background-size: contain;
        width: 298px;
        height: 74px;
        background-color: rgba(0,0,0,0);
        font-weight: 700;
        font-size: 1.1rem;
        background-repeat: no-repeat;
        background-size: cover;
        color: rgb(255,255,255);
    }

    @media (max-width: 1200px) {
        .price button {
            background-image: url('~assets/img/btn-reservation-red.png');
            background-size: contain;
            width: 260px;
            height: 64px;
            background-color: rgba(0,0,0,0);
            font-weight: 700;
            font-size: 1.1rem;
            background-repeat: no-repeat;
            background-size: cover;
            color: rgb(255,255,255);
        }
    }

    @media (max-width: 992px) {
        .price button {
            background-image: url('~assets/img/btn-reservation-red.png');
            background-size: contain;
            width: 180px;
            height: 44px;
            background-color: rgba(0,0,0,0);
            font-weight: 700;
            font-size: 1.1rem;
            background-repeat: no-repeat;
            background-size: cover;
            color: rgb(255,255,255);
        }
    }

    @media (max-width: 768px) {
        .price button {
            background-image: url('~assets/img/btn-reservation-red.png');
            background-size: contain;
            width: 120px;
            height: 30px;
            background-color: rgba(0,0,0,0);
            font-weight: 700;
            font-size: 0.5rem;
            background-repeat: no-repeat;
            background-size: cover;
            color: rgb(255,255,255);
        }
    }

    @media (max-width: 360px) {
        .price button {
            background-image: url('~assets/img/btn-reservation-red.png');
            background-size: contain;
            width: 170px;
            height: 42px;
            background-color: rgba(0,0,0,0);
            font-weight: 700;
            font-size: 1rem;
            background-repeat: no-repeat;
            background-size: cover;
            color: rgb(255,255,255);
        }
    }

    .col.nolicence {
        padding-left: 0px;
        padding-right: 0px;
        background-image: url('~assets/img/bg-tesla-pilota.png');
        background-repeat: no-repeat;
        background-size: cover;
        padding-top: 340px;
        margin-top: 30px;
        width: 1820px;
        height: 1090px;
        margin: 0 auto;
    }

    @media (max-width: 992px) {
        .col.nolicence {
            padding-left: 0px;
            padding-right: 0px;
            background-image: url('~assets/img/bg-tesla-pilota-text.png');
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            padding-top: 50px;
            margin-top: 30px;
            width: auto;
            height: 800px;
        }
    }

    @media (max-width: 578px) {
        .col.nolicence {
            padding-left: 0px;
            padding-right: 0px;
            background-image: url('~assets/img/bg-tesla-pilota-text.png');
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            padding-top: 0px;
            margin-top: 30px;
            width: auto;
            height: 580px;
        }
    }

    .nolicence h1 {
        font-weight: 700;
        font-size: 3rem;
        text-transform: uppercase;
        margin-left: 90px;
    }

    @media (max-width: 992px) {
        .nolicence h1 {
            font-weight: 700;
            font-size: 2.5rem;
            text-transform: uppercase;
            margin-left: 0px;
            text-align: center;
        }
    }

    @media (max-width: 576px) {
        .nolicence h1 {
            font-weight: 700;
            font-size: 2rem;
            text-transform: uppercase;
            margin-left: 0px;
            text-align: center;
        }
    }

    .nolicence h2 {
        font-weight: 700;
        font-size: 2.5rem;
        text-transform: uppercase;
        margin-left: 90px;
        margin-top: 40px;
    }

    @media (max-width: 992px) {
        .nolicence h2 {
            font-weight: 700;
            font-size: 2.2rem;
            text-transform: uppercase;
            margin-left: 0px;
            margin-top: 40px;
            text-align: center;
        }
    }

    @media (max-width: 576px) {
        .nolicence h2 {
            font-weight: 700;
            font-size: 1.6rem;
            text-transform: uppercase;
            margin-left: 0px;
            margin-top: 40px;
            text-align: center;
        }
    }

    .nolicence p {
        font-weight: 500;
        font-size: 1.1rem;
        margin-left: 90px;
        margin-top: 40px;
    }

    @media (max-width: 992px) {
        .nolicence p {
            font-weight: 500;
            font-size: 1.8rem;
            margin-left: 0px;
            margin-top: 40px;
            text-align: center;
        }
    }

    @media (max-width: 576px) {
        .nolicence p {
            font-weight: 500;
            font-size: 1.1rem;
            margin-left: 0px;
            margin-top: 40px;
            text-align: center;
        }
    }

    .nolicence button {
        background-color: rgba(255,255,255,0);
        color: rgb(0,0,0);
        font-size: 1.5rem;
        font-family: Montserrat, sans-serif;
        margin-right: 0px;
        padding-right: 15px;
        padding-top: 35px;
        padding-left: 0px;
        margin-left: 90px;
    }

    @media (max-width: 992px) {
        .nolicence button {
            background-color: rgba(255,255,255,0);
            color: rgb(0,0,0);
            font-size: 1.5rem;
            font-family: Montserrat, sans-serif;
            padding-right: 15px;
            padding-top: 35px;
            padding-left: 32%;
        }
    }

    @media (max-width: 768px) {
        .nolicence button {
            background-color: rgba(255,255,255,0);
            color: rgb(0,0,0);
            font-size: 1.5rem;
            font-family: Montserrat, sans-serif;
            padding-right: 15px;
            padding-top: 35px;
            padding-left: 27%;
        }
    }

    @media (max-width: 576px) {
        .nolicence button {
            background-color: rgba(255,255,255,0);
            color: rgb(0,0,0);
            font-size: 1.5rem;
            font-family: Montserrat, sans-serif;
            padding-right: 15px;
            padding-top: 35px;
            padding-left: 18%;
        }
    }

    @media (max-width: 360px) {
        .nolicence button {
            background-color: rgba(255,255,255,0);
            color: rgb(0,0,0);
            font-size: 1.2rem;
            font-family: Montserrat, sans-serif;
            padding-right: 15px;
            padding-top: 35px;
            padding-left: 0%;
        }
    }

    .container.home-joyridectn {
        margin-top: 100px;
    }

    .container.home-joyridectn h1 {
        font-size: 3rem;
        font-weight: 600;
        text-transform: uppercase;
    }

    .container.home-joyridectn h2 {
        font-size: 2.5rem;
        font-weight: 600;
        text-transform: uppercase;
    }

    .faqtable row {
        width: 100%;
        margin-bottom: 0px;
    }

    .faqtable p {
        font-size: 8rem;
        font-weight: 700;
        color: grey;
        text-decoration: underline black;
        margin-bottom: 0px;
        margin-top: 0px;
    }

    @media (max-width: 991px) {
        .faqtable p {
            font-size: 6rem;
            font-weight: 700;
            color: grey;
            text-decoration: underline black;
        }
    }

    @media (max-width: 575px) {
        .faqtable p {
            font-size: 3rem;
            font-weight: 700;
            color: grey;
            text-decoration: underline black;
        }
    }

    .faqtable span {
        font-size: 1rem;
        font-weight: 400;
        margin-top: 0px;
        margin-bottom: 0px;
        line-height: 23px;
    }

    @media (max-width: 991px) {
        .faqtable span {
            font-size: 0.8rem;
            font-weight: 400;
            line-height: 17px;
        }
    }

    @media (max-width: 575px) {
        .faqtable span {
            font-size: 0.5rem;
            font-weight: 400;
            line-height: 11px;
        }
    }

    .faqtable td {
        width: 25%;
        margin-top: 0px;
        line-height: normal;
    }

    .faqtable tr {
        line-height: normal;
    }

    @media (max-width: 575px) {
        .faqtable td {
            width: 25%;
            margin-top: 0px;
            line-height: normal;
        }
    }

    #container-drivefaq {
        margin: auto;
        margin-top: 5px;
    }

    .container.enjoyment-ctn h1 {
        text-transform: uppercase;
        font-size: 5rem;
        font-weight: 700;
        margin-bottom: 0px;
    }

    .container.enjoyment-ctn h2 {
        text-transform: uppercase;
        font-size: 3rem;
        font-weight: 700;
        margin-top: 0px;
    }

    @media (min-width: 300px) {
        .container.enjoyment-ctn h2 {
            text-transform: uppercase;
            font-size: 3rem;
            font-weight: 700;
            margin-top: 0px;
        }
    }

    .module {
        background: url('~assets/img/bg-team-building.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        width: 100%;
        height: 800px;
        position: relative;
        overflow: hidden;
        font-family: Montserrat, sans-serif;
        font-weight: 500;
    }

    @media (max-width: 992px) {
        .module {
            background: url('~assets/img/bg-team-building.png');
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            width: 100%;
            height: 500px;
            position: relative;
            overflow: hidden;
            font-family: Montserrat, sans-serif;
            font-weight: 500;
        }
    }

    .module > header {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 110px 15%;
    }

    .module > header > h1 {
        margin: 0;
        color: white;
        font-size: 5rem;
        font-weight: 700;
    }

    @media (max-width: 992px) {
        .module > header > h1 {
            margin: 0;
            color: white;
            font-size: 2.2rem;
            font-weight: 700;
        }
    }

    @media (max-width: 400px) {
        .module > header > h1 {
            margin: 0;
            color: white;
            font-size: 1.9rem;
            font-weight: 700;
            text-align: center;
        }
    }

    .module > header > h2 {
        margin: 0;
        color: white;
        font-size: 2.7rem;
        font-weight: 700;
    }

    @media (max-width: 992px) {
        .module > header > h2 {
            margin: 0;
            color: white;
            font-size: 1.7rem;
            font-weight: 700;
        }
    }

    @media (max-width: 400px) {
        .module > header > h2 {
            margin: 0;
            color: white;
            font-size: 1.1rem;
            font-weight: 700;
            text-align: center;
        }
    }

    .module > header > P {
        color: white;
        margin-top: 50px;
        font-size: 1.7rem;
        font-weight: 500;
    }

    @media (max-width: 992px) {
        .module > header > P {
            color: white;
            margin-top: 50px;
            font-size: 1.2rem;
            font-weight: 500;
        }
    }

    @media (max-width: 400px) {
        .module > header > P {
            color: white;
            margin-top: 50px;
            font-size: 0.8rem;
            font-weight: 500;
            text-align: center;
        }
    }

    * {
        box-sizing: border-box;
    }

    #teambutton {
        position: absolute;
        right: 15%;
        bottom: 20%;
    }

    @media (max-width: 1200px) {
        #teambutton {
            position: absolute;
            right: 15%;
            bottom: 10%;
        }
    }

    .container.speedbar h4 {
        margin-bottom: 50px;
        font-size: 1.3rem;
        font-weight: 500;
    }

    @media (max-width: 1200px) {
        .container.speedbar h4 {
            margin-bottom: 50px;
            font-size: 1.2rem;
            font-weight: 500;
        }
    }

    @media (max-width: 992px) {
        .container.speedbar h4 {
            margin-bottom: 70px;
            font-size: 0.6rem;
            font-weight: 500;
        }
    }

    .text-uppercase.text-right {
        -webkit-tap-highlight-color: transparent;
        --breakpoint-xs: 0;
        --breakpoint-sm: 36rem;
        --breakpoint-md: 48rem;
        --breakpoint-lg: 62rem;
        --breakpoint-xl: 75rem;
        --font-family-sans-serif: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
        --font-family-monospace: SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
        text-align: right;
        text-transform: uppercase;
        font-family: Raleway;
        font-weight: 800;
        color: #b6b6b6;
        box-sizing: border-box;
        margin-bottom: 1rem;
        font-size: 7.0625rem;
        line-height: 7.0625rem;
        margin-top: -1.875rem;
    }

    .text-uppercase {
        margin-bottom: 0rem;
    }

    .text-uppercase.text-left {
        -webkit-tap-highlight-color: transparent;
        --breakpoint-xs: 0;
        --breakpoint-sm: 36rem;
        --breakpoint-md: 48rem;
        --breakpoint-lg: 62rem;
        --breakpoint-xl: 75rem;
        text-align: left;
        text-transform: uppercase;
        font-family: Raleway;
        font-weight: 800;
        color: #b6b6b6;
        box-sizing: border-box;
        margin-top: 0;
        font-size: 11.875rem;
        line-height: 11.875rem;
        margin-bottom: 0;
    }

    .d-xl-none {
        -webkit-tap-highlight-color: transparent;
        line-height: 1.5;
        text-align: center;
        color: #2b2b2b;
        text-transform: uppercase;
        font-family: Raleway;
        font-weight: 800;
        box-sizing: border-box;
        font-size: 41px;
        padding-bottom: 2rem;
    }

    .counter span {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        transition: opacity 1s;
    }

    #ui.loaded {
        height: 100%;
        display: flex;
        width: 100%;
        margin-top: 23px;
    }

    #ui2.loaded {
        height: 100%;
        display: flex;
        width: 90%;
        margin-top: 23px;
    }

    #ui3.loaded {
        height: 100%;
        display: flex;
        width: 90%;
        margin-top: 23px;
    }

    #ui.loaded .night {
        width: 50px;
        height: 50%;
        margin: 0 2px;
        opacity: 1;
        background-color: white;
    }

    #ui2.loaded .night {
        width: 50px;
        height: 50%;
        margin: 0 2px;
        opacity: 1;
        background-color: white;
    }

    #ui3.loaded .night {
        width: 50px;
        height: 50%;
        margin: 0 2px;
        opacity: 1;
        background-color: white;
    }

    #ui.loaded .night:nth-child(1), #ui2.loaded .night:nth-child(1), #ui3.loaded .night:nth-child(1) {
        animation-name: fadein;
        animation-duration: 50ms;
        animation-timing-function: linear;
        animation-delay: 50ms;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
    }

    #ui.loaded .night:nth-child(2), #ui2.loaded .night:nth-child(2), #ui3.loaded .night:nth-child(2) {
        animation-name: fadein;
        animation-duration: 50ms;
        animation-timing-function: linear;
        animation-delay: 100ms;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
    }

    #ui.loaded .night:nth-child(3), #ui2.loaded .night:nth-child(3), #ui3.loaded .night:nth-child(3) {
        animation-name: fadein;
        animation-duration: 50ms;
        animation-timing-function: linear;
        animation-delay: 150ms;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
    }

    #ui.loaded .night:nth-child(4), #ui2.loaded .night:nth-child(4), #ui3.loaded .night:nth-child(4) {
        animation-name: fadein;
        animation-duration: 50ms;
        animation-timing-function: linear;
        animation-delay: 200ms;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
    }

    #ui.loaded .night:nth-child(5), #ui2.loaded .night:nth-child(5), #ui3.loaded .night:nth-child(5)  {
        animation-name: fadein;
        animation-duration: 50ms;
        animation-timing-function: linear;
        animation-delay: 250ms;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
    }

    #ui.loaded .night:nth-child(6), #ui2.loaded .night:nth-child(6), #ui3.loaded .night:nth-child(6) {
        animation-name: fadein;
        animation-duration: 50ms;
        animation-timing-function: linear;
        animation-delay: 300ms;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
    }

    #ui.loaded .night:nth-child(7), #ui2.loaded .night:nth-child(7), #ui3.loaded .night:nth-child(7) {
        animation-name: fadein;
        animation-duration: 50ms;
        animation-timing-function: linear;
        animation-delay: 350ms;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
    }

    #ui.loaded .night:nth-child(8), #ui2.loaded .night:nth-child(8), #ui3.loaded .night:nth-child(8) {
        animation-name: fadein;
        animation-duration: 50ms;
        animation-timing-function: linear;
        animation-delay: 400ms;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
    }

    #ui.loaded .night:nth-child(9), #ui2.loaded .night:nth-child(9), #ui3.loaded .night:nth-child(9) {
        animation-name: fadein;
        animation-duration: 50ms;
        animation-timing-function: linear;
        animation-delay: 450ms;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
    }

    #ui.loaded .night:nth-child(10), #ui2.loaded .night:nth-child(10), #ui3.loaded .night:nth-child(10) {
        animation-name: fadein;
        animation-duration: 50ms;
        animation-timing-function: linear;
        animation-delay: 500ms;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
    }

    #ui.loaded .night:nth-child(11) {
        animation-name: fadeout;
        animation-duration: 50ms;
        animation-timing-function: linear;
        animation-delay: 550ms;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
    }

    @keyframes fadein {
        0% {
            background-color: white;
        }
        100% {
            background-color: #C1132C;
        }
    }

    @keyframes fadeout {
        0% {
            background-color: white;
        }
        100% {
            background-image: linear-gradient(to left, rgba(255,0,0,0), rgba(193, 19, 44, 1));
        }
    }

    #ui span, #ui2 span, #ui3 span {
        font-size: 1rem;
        font-family: montserrat;
        font-weight: 500;
    }

    #elmenyvideo {
  padding-left: 100px;
  padding-right: 50px;
  margin-left: 0px;
}

@media (max-width: 1199px) {
  #elmenyvideo {
    padding-left: 15px;
    padding-right: 15px;
    margin-left: 0px;
  }
}

#elmenyvideo h1 {
  font-size: 3.3rem;
  font-family: Montserrat, sans-serif;
  font-weight: 800;
  margin-bottom: 0px;
  margin-right: 0px;
}

@media (min-width: 1200px) {
  #elmenyvideo h1 {
    font-size: 3.1rem;
    font-family: Montserrat, sans-serif;
    font-weight: 800;
    margin-bottom: 0px;
    margin-right: 0px;
  }
}

@media (max-width: 600px) {
  #elmenyvideo h1 {
    font-size: 2.3rem;
    font-family: Montserrat, sans-serif;
    font-weight: 800;
    margin-bottom: 0px;
    margin-right: 0px;
    text-align: center;
  }
}

#elmenyvideo h3 {
  font-size: 2rem;
  font-family: Montserrat, sans-serif;
  font-weight: 700;
  margin-bottom: 30px;
}

@media (min-width: 1200px) {
  #elmenyvideo h3 {
    font-size: 2rem;
    font-family: Montserrat, sans-serif;
    font-weight: 700;
    margin-bottom: 30px;
  }
}

@media (max-width: 600px) {
  #elmenyvideo h3 {
    font-size: 1.4rem;
    font-family: Montserrat, sans-serif;
    font-weight: 700;
    margin-bottom: 30px;
    text-align: center;
  }
}

#elmenyvideo h4 {
  font-family: Montserrat, sans-serif;
  font-weight: 700;
  font-size: 1.2rem;
  margin-top: 30px;
  margin-bottom: 30px;
}

@media (max-width: 576px) {
  #elmenyvideo h4 {
    font-family: Montserrat, sans-serif;
    font-weight: 700;
    font-size: 1.2rem;
    margin-top: 30px;
    margin-bottom: 30px;
    text-align: center;
  }
}

#elmenyvideo h5 {
  font-size: 1.8rem;
  font-family: Montserrat, sans-serif;
  font-weight: 700;
  margin-bottom: 30px;
}

@media (min-width: 1200px) {
  #elmenyvideo h5 {
    font-size: 1.4rem;
    font-family: Montserrat, sans-serif;
    font-weight: 700;
    margin-bottom: 30px;
  }
}

@media (max-width: 600px) {
  #elmenyvideo h5 {
    font-size: 1.4rem;
    font-family: Montserrat, sans-serif;
    font-weight: 700;
    margin-bottom: 30px;
    text-align: center;
  }
}

#elmenyvideo p {
  font-size: 1.2rem;
  font-weight: 450;
}

@media (min-width: 1200px) {
  #elmenyvideo p {
    font-size: 1rem;
    font-weight: 450;
  }
}

@media (max-width: 600px) {
  #elmenyvideo p {
    font-size: 1rem;
    font-weight: 450;
    text-align: center;
  }
}
</style>

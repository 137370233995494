<template>
  <div class="tours">
   <div class="tours-header">
      <top-nav-bar />
      <header>
<!--        <h1>SUPERCHARGER TÚRÁK</h1>-->
      </header>
    </div>
    <div class="container sucha-heading">
      <h1>Supercharger túrák</h1>
      <h2>Kapcsold össze a kellemest<br>a hasznossal!</h2>
    </div>
    <div class="row sucha-wrapper1">
      <div class="col-xl-8 sucha-wrapper1-l"></div>
      <div class="col-xl-4 sucha-wrapper1-r"><img src="~assets/img/line.png">
        <h1>Lenne kedvetek egy kicsit<br>kiruccanni</h1>
        <h2>Győrbe, Zólyomra, Pozsonyba vagy Bécsbe (stb.)?</h2>
        <h3>Közben kicsit megtapasztalni a<br>P100D<br>lopakodó fenevadat?</h3>
        <p>A TeslaPilótánál minderre van lehetőség. Mivel a Teslákkal zökkenőmentesen lehet utazni olyan helyeken, ahol már kiépült a Supercharger hálózat, ezért ilyen helyekre tervezünk túrákat. Ez lehet egyirányú is: csak oda vagy csak vissza is. De
          lehet oda-vissza út is. Ilyenkor a célállomáson a töltés mellett belefér egy rövidebb séta, max 1-2 órás időtöltés.</p>
      </div>
    </div>
    <div class="container sucha-important">
      <h1>Fontos!</h1>
      <p>Pozsonyi vagy Bécsi (Schwehati) túrák esetén Győrben megállunk rátölteni oda-<br>és visszafelé is. Ez az Arrabona pihenőnél van, az ÖMV töltőállomásoknál. Egész<br>korrekt étkezési lehetőség is van, ha valaki megéhezik a túra közben.</p>
    </div>
    <div class="row sucha-wrapper2">
      <div class="col-xl-4 sucha-wrapper2-l">
        <h1>Teslapilótával a</h1>
        <h2>Schwehat<br>Reptérre</h2>
        <p><strong>Ha a Schwehati reptérre mennénk,</strong><br><strong>és utazol - azaz indul a géped vagy érkezik,</strong><br><strong>velünk egy élményvezetéssel kombinálhatod</strong><br><strong>az oda- vagy visszajutásod!</strong></p>
        <p>Gondolj csak bele, mennyivel szórakoztatóbb így az utazás! Persze nem muszáj élményvezetned, ez csak egy lehetőség. Szívesen leszünk mi a TeslaPilótád. A Győri SC töltéssel mindenképpen számolni kell (kb. 30-40 perc). Oda is és vissza is be
          kell iktatni.</p>
      </div>
      <div class="col-xl-8 sucha-wrapper2-r"></div>
    </div>
    <div class="container sucha-reservation-head">
      <h1>Foglalj helyet</h1>
      <h2>A teslapilótaülésben!</h2>
      <p>Válassz a TeslaPilóta "Szupercharger túrák" csomagok közül!<br>Vigyázat! Erősen addiktív élmény. :)</p><img src="~assets/img/line.png"></div>
    <div class="container sucha-reservation-form">
        <a class="btn active shadow-none" role="button" id="btn-sucha-res" :href="goToH('reservation')">IDŐPONTFOGLALÁS</a>
    </div>
    <div class="container text-center sucha-center-line"><img src="~assets/img/line.png"></div>
    <div class="container sucha-tel">
      <h1>Javasoljuk, hogy SuperCharger túrákról<br>érdeklődj nálunk telefonon:</h1>
      <h2>+36 30 222 3005</h2>
    </div>
    <div style="padding-top: 90px;">
      <div class="container">
        <div class="row">
          <div class="col-md-6 contact-text">
            <h1 class="text-uppercase" style="margin-bottom: 0px;"><strong>Üzenetküldés</strong></h1>
            <h3 class="text-uppercase">A Teslapilótának</h3>
            <p>Ha kérésed vagy kérdésed lenne, akkor küldj üzenetet a Teslapilóta irányítóközpontba! Add meg adataidat, hogy felvehessük veled a kapcsolatot.</p>
          </div>
          <div class="col-md-6">
            <contact-form />
          </div>
        </div>
      </div>
    </div>
    <div><img class="center" src="~assets/img/img-header-logo-small-gray.png" style="padding-top: 8rem;"></div>
    <div class="container" id="followus"><div class="d-none d-xl-block">
      <div class="text-uppercase">
        <div class="container">
          <div class="row">
            <div class="col-md-4 col-xl-10 offset-xl-2">
              <h1 class="text-uppercase text-left"><strong>KÖVESS</strong></h1>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="container">
          <div class="row">
            <div class="col-md-4 col-xl-2 offset-xl-5 text-right align-self-center">
              <a href="http://www.facebook.com/teslapilota"><img style="padding-right: 1.875rem" src="~assets/img/btn-facebook.png"></a><a href="http://www.instagram.com/teslapilota"><img src="~assets/img/btn-instagram.png"></a>
            </div>
            <div class="col-md-4 col-xl-4 offset-xl-0">
              <h1 class="text-uppercase text-right"><strong>MINKET</strong></h1>
            </div>
            <div class="clearfix"></div>
          </div>
        </div>
      </div>
    </div>
      <div class="mobile d-block d-xl-none">
        Kövess minket
        <div>
          <a href="https://www.facebook.com/TeslaPilota/" target="_blank">
            <img src="~assets/img/btn-facebook.png">
          </a>
          <a href="https://www.instagram.com/teslapilota/" target="_blank">
            <img src="~assets/img/btn-instagram.png">
          </a>
        </div>
      </div></div>
  </div>
</template>

<script>
  import TopNavBar from '../general/top-nav-bar';
  import ContactForm from '../general/contact-form';
  import router from "assets/js/plugins/router";

  export default {
    name: "tours",
    components: {
        TopNavBar,
        ContactForm
    },
    methods:
    {
        goToH: function(name, query = {})
        {
            return router.resolve({name: name, query: query}).href;
        }
    }
  };
</script>

<style scoped>
  .tours-header {
    background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url('~assets/img/teslapilota-superch-header.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 770px;
    position: relative;
    overflow: hidden;
    font-family: Montserrat, sans-serif;
    font-weight: 500;
  }

  @media (max-width: 1200px) {
    .tours-header {
      width: 100%;
      height: 770px;
      position: relative;
      overflow: hidden;
    }
  }

  @media (max-width: 1024px) {
    .tours-header {
      width: 100%;
      height: 650px;
      position: relative;
      overflow: hidden;
    }
  }

  @media (max-width: 768px) {
    .tours-header {
      width: 100%;
      height: 550px;
      position: relative;
      overflow: hidden;
      text-align: center;
    }
  }

  @media (max-width: 480px) {
    .tours-header {
      width: 100%;
      height: 420px;
      position: relative;
      overflow: hidden;
    }
  }

  .tours-header > header {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 50px 15%;
  }

  * {
    box-sizing: border-box;
  }

  .container.sucha-heading {
    margin-top: 100px;
    margin-bottom: 100px;
    text-transform: uppercase;
  }

  .container.sucha-heading h1 {
    font-weight: 700;
    font-size: 3.5rem;
    margin-bottom: 0px;
  }

  .container.sucha-heading h2 {
    font-weight: 700;
    font-size: 2.3rem;
    margin-top: 0px;
  }

  .row.sucha-wrapper1 {
    max-width: 1700px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 100px;
    height: 800px;
  }

  .sucha-wrapper1-l {
    background: url('~assets/img/wrapper-suchatura.png');
    background-position: center;
    height: auto;
    background-repeat: no-repeat;
    background-size: contain;
    height: 800px;
  }

  .sucha-wrapper1-r {
    margin: auto;
    padding-left: 70px;
  }

  .sucha-wrapper1-r img {
    margin-bottom: 30px;
    margin-top: 30px;
  }

  .sucha-wrapper1-r h1 {
    text-transform: uppercase;
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 0px;
  }

  .sucha-wrapper1-r h2 {
    font-size: 1.5rem;
    font-weight: 700;
  }

  .sucha-wrapper1-r h3 {
    font-size: 1.5rem;
    text-transform: uppercase;
    font-weight: 400;
  }

  .sucha-wrapper1-r p {
    font-size: 1.2rem;
  }

  .sucha-important {
    font-stretch: semi-expanded;
    margin-top: 120px;
    margin-bottom: 120px;
  }

  .sucha-important h1 {
    text-align: center;
    text-transform: uppercase;
    font-size: 4rem;
    font-weight: 700;
  }

  .sucha-important p {
    text-align: center;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 110%;
  }

  .row.sucha-wrapper2 {
    max-width: 1700px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 100px;
    height: 750px;
  }

  .sucha-wrapper2-r {
    background: url('~assets/img/wrapper-suchatura2.png');
    background-position: center;
    height: auto;
    background-repeat: no-repeat;
    background-size: contain;
    height: 800px;
  }

  .sucha-wrapper2-l {
    margin: auto;
    padding-left: 70px;
  }

  .sucha-wrapper2-l h1 {
    text-transform: uppercase;
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 0px;
  }

  .sucha-wrapper2-l h2 {
    font-size: 3rem;
    font-weight: 700;
    text-transform: uppercase;
  }

  .sucha-wrapper2-l p {
    font-size: 1.2rem;
    margin-top: 25px;
  }

  .sucha-reservation-head {
    margin-top: 15px;
  }

  .sucha-reservation-head h2 {
    text-transform: uppercase;
    font-size: 2.3rem;
    font-weight: 600;
    margin-bottom: 20px;
  }

  .sucha-reservation-head p {
    font-size: 1rem;
    margin-bottom: 30px;
  }

  .sucha-winter-warning h1 {
    color: red;
    text-align: center;
    text-transform: uppercase;
    font-size: 2rem;
    font-weight: 600;
    margin-top: 50px;
  }

  .sucha-center-line {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .sucha-tel {
    text-align: center;
  }

  .sucha-tel h1 {
    font-size: 1.5rem;
    font-weight: 700;
  }

  .sucha-tel h2 {
    font-size: 3.5rem;
    font-weight: 700;
  }

  img.center {
    display: block;
    margin: 0 auto;
  }

  .form-control.form-control-lg {
    font-size: 1rem;
  }

  .text-uppercase.text-right {
    -webkit-tap-highlight-color: transparent;
    --breakpoint-xs: 0;
    --breakpoint-sm: 36rem;
    --breakpoint-md: 48rem;
    --breakpoint-lg: 62rem;
    --breakpoint-xl: 75rem;
    --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    text-align: right;
    text-transform: uppercase;
    font-family: Raleway;
    font-weight: 800;
    color: #b6b6b6;
    box-sizing: border-box;
    margin-bottom: 1rem;
    font-size: 7.0625rem;
    line-height: 7.0625rem;
    margin-top: -1.875rem;
  }

  .text-uppercase {
    margin-bottom: 0rem;
  }

  .text-uppercase.text-left {
    -webkit-tap-highlight-color: transparent;
    --breakpoint-xs: 0;
    --breakpoint-sm: 36rem;
    --breakpoint-md: 48rem;
    --breakpoint-lg: 62rem;
    --breakpoint-xl: 75rem;
    text-align: left;
    text-transform: uppercase;
    font-family: Raleway;
    font-weight: 800;
    color: #b6b6b6;
    box-sizing: border-box;
    margin-top: 0;
    font-size: 11.875rem;
    line-height: 11.875rem;
    margin-bottom: 0;
  }

  .d-xl-none {
    -webkit-tap-highlight-color: transparent;
    line-height: 1.5;
    text-align: center;
    color: #2b2b2b;
    text-transform: uppercase;
    font-family: Raleway;
    font-weight: 800;
    box-sizing: border-box;
    font-size: 41px;
    padding-bottom: 2rem;
  }

  #followus {
    margin-bottom: 90px;
    margin-top: 100px;
  }

  @media (max-width: 1199px) {
    .sucha-wrapper1-l {
      background: url('~assets/img/wrapper-suchatura.png');
      background-position: center;
      height: auto;
      background-repeat: no-repeat;
      background-size: contain;
      height: 800px;
    }

    .sucha-wrapper1-r {
      padding-left: 15px;
      padding-right: 15px;
    }

    .sucha-important {
      font-stretch: semi-expanded;
      margin-top: 430px;
      margin-bottom: 125px;
    }

    .sucha-important p {
      text-align: center;
      font-size: 1.3rem;
      font-weight: 400;
      line-height: 110%;
    }

    .sucha-wrapper2-r {
      background: url('~assets/img/wrapper-suchatura2.png');
      background-position: center;
      height: auto;
      background-repeat: no-repeat;
      background-size: contain;
      height: 800px;
    }

    .sucha-reservation-head {
      margin-top: 500px;
    }
  }

  @media (max-width: 991px) {
    .sucha-wrapper1-l {
      background: url('~assets/img/wrapper-suchatura.png');
      background-position: center;
      height: auto;
      background-repeat: no-repeat;
      background-size: contain;
      height: 700px;
    }

    .sucha-important {
      font-stretch: semi-expanded;
      margin-top: 370px;
      margin-bottom: 90px;
    }

    .sucha-wrapper2-r {
      background: url('~assets/img/wrapper-suchatura2.png');
      background-position: center;
      height: auto;
      background-repeat: no-repeat;
      background-size: contain;
      height: 700px;
    }

    .sucha-reservation-head {
      margin-top: 425px;
    }

    .contact-text {
      text-align: center;
    }
  }

  @media (max-width: 767px) {
    .container.sucha-heading {
      margin-top: 100px;
      margin-bottom: 100px;
      text-transform: uppercase;
      text-align: center;
    }

    .sucha-wrapper1-l {
      background: url('~assets/img/wrapper-suchatura.png');
      background-position: center;
      height: auto;
      background-repeat: no-repeat;
      background-size: contain;
      height: 545px;
    }

    .sucha-wrapper1-r {
      margin: auto;
      padding-left: 70px;
      text-align: center;
    }

    .sucha-important {
      font-stretch: semi-expanded;
      margin-top: 270px;
      margin-bottom: 90px;
    }

    .sucha-wrapper2-r {
      background: url('~assets/img/wrapper-suchatura2.png');
      background-position: center;
      height: auto;
      background-repeat: no-repeat;
      background-size: contain;
      height: 545px;
    }

    .sucha-wrapper2-l {
      margin: auto;
      padding-left: 15px;
      padding-right: 15px;
      text-align: center;
    }

    .sucha-reservation-head {
      margin-top: 310px;
      text-align: center;
    }
  }

  @media (max-width: 575px) {
    .container.sucha-heading h1 {
      font-weight: 700;
      font-size: 2.5rem;
      margin-bottom: 0px;
    }

    .container.sucha-heading h2 {
      font-weight: 700;
      font-size: 1.8rem;
      margin-top: 0px;
    }

    .sucha-wrapper1-l {
      background: url('~assets/img/wrapper-suchatura.png');
      background-position: center;
      height: auto;
      background-repeat: no-repeat;
      background-size: contain;
      height: 405px;
    }

    .sucha-important {
      font-stretch: semi-expanded;
      margin-top: 165px;
      margin-bottom: 90px;
    }

    .sucha-wrapper2-r {
      background: url('~assets/img/wrapper-suchatura2.png');
      background-position: center;
      height: auto;
      background-repeat: no-repeat;
      background-size: contain;
      height: 405px;
    }

    .sucha-reservation-head {
      margin-top: 200px;
    }

    .sucha-reservation-head h1 {
      text-transform: uppercase;
      font-size: 2rem;
      font-weight: 700;
      margin-bottom: 0px;
    }

    .sucha-reservation-head h2 {
      text-transform: uppercase;
      font-size: 1.3rem;
      font-weight: 600;
      margin-bottom: 20px;
    }

    .sucha-tel h1 {
      font-size: 1.2rem;
      font-weight: 700;
    }

    .sucha-tel h2 {
      font-size: 2.2rem;
      font-weight: 700;
    }

  }

  @media (max-width: 480px) {
    .sucha-wrapper1-l {
      background: url('~assets/img/wrapper-suchatura.png');
      background-position: center;
      height: auto;
      background-repeat: no-repeat;
      background-size: contain;
      height: 340px;
    }

    .sucha-important {
      font-stretch: semi-expanded;
      margin-top: 250px;
      margin-bottom: 90px;
    }

    .sucha-wrapper2-r {
      background: url('~assets/img/wrapper-suchatura2.png');
      background-position: center;
      height: auto;
      background-repeat: no-repeat;
      background-size: contain;
      height: 340px;
    }

    .sucha-reservation-head {
      margin-top: 200px;
    }

    .sucha-winter-warning h1 {
      color: red;
      text-align: center;
      text-transform: uppercase;
      font-size: 1.5rem;
      font-weight: 600;
      margin-top: 50px;
    }

    img.center {
      display: block;
      margin: 0 auto;
      width: 70%;
    }

    .contact-text h1 {
      font-size: 2rem;
    }

    .contact-text h3 {
      font-size: 1.5rem;
    }
  }

  @media (max-width: 400px) {
    .container.sucha-heading h1 {
      font-weight: 700;
      font-size: 2rem;
      margin-bottom: 0px;
    }

    .container.sucha-heading h2 {
      font-weight: 700;
      font-size: 1.4rem;
      margin-top: 0px;
    }
  }

  @media (max-width: 360px) {
    .sucha-wrapper1-l {
      background: url('~assets/img/wrapper-suchatura.png');
      background-position: center;
      height: auto;
      background-repeat: no-repeat;
      background-size: contain;
      height: 255px;
    }

    .sucha-important {
      font-stretch: semi-expanded;
      margin-top: 250px;
      margin-bottom: 90px;
    }

    .sucha-wrapper2-r {
      background: url('~assets/img/wrapper-suchatura2.png');
      background-position: center;
      height: auto;
      background-repeat: no-repeat;
      background-size: contain;
      height: 255px;
    }
  }

  .sucha-reservation-form {
      height: 80px;
      padding: 50px 0 20px 0;
  }

  #btn-sucha-res {
      background-image: url("~assets/img/btn-reservation-red.png");
      width: 298px;
      height: 74px;
      background-color: rgba(0,0,0,0);
      font-weight: 700;
      font-size: 1.1rem;
      background-repeat: no-repeat;
      background-size: cover;
      color: rgb(255,255,255);
      left: 50%;
      position: absolute;
      margin-right: -50%;
      transform: translate(-50%, -50%);
      padding-top: 22px;
  }

</style>

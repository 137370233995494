import home from '../components/pages/home';
import about from '../components/pages/about';
import reservation from '../components/pages/reservation';
import contact from '../components/pages/contact';
import aszf from '../components/pages/aszf';
import adatvedelem from '../components/pages/adatvedelem';
import tours from '../components/pages/tours';
import company from '../components/pages/company';
import pay from '../components/pages/pay';
import payment_status from '../components/pages/payment-status';
import not_found from '../components/pages/not-found';

const routes = [
    {
        path: "/",
        name: "home",
        component: home
    },
    {
        path: "/about",
        name: "about",
        component: about
    },
    {
        path: "/reservation",
        name: "reservation",
        component: reservation
    },
    /*{
        path: "/videos",
        name: "videos",
        component: videos
    },*/
    {
        path: "/contact",
        name: "contact",
        component: contact
    },
    {
        path: "/aszf",
        name: "aszf",
        component: aszf
    },
    {
        path: "/adatvedelem",
        name: "adatvedelem",
        component: adatvedelem
    },
    {
        path: "/tours",
        name: "tours",
        component: tours
    },
    {
        path: "/company",
        name: "company",
        component: company
    },
    /*{
        path: "/gyik",
        name: "gyik",
        component: gyik
    },
    {
        path: "/impresszum",
        name: "impresszum",
        component: impresszum
    },*/
    {
        path: "/pay/:uniqueId",
        name: "pay",
        component: pay
    },
    {
        path: "/payment-status/:uniqueId",
        name: "payment-status",
        component: payment_status
    },
    {
        path: "*",
        name: "not-found",
        component: not_found
    }
];

export default routes;

<template>
  <div class="about">
    <div class="about-header">
      <top-nav-bar />
      <header>
        <h1>RÓLUNK</h1>
      </header>
    </div>
    <div class="container" style="margin-top: 100px;">
      <div
        class="table-responsive table-borderless"
        style="font-size: 1.1rem;font-family: Montserrat, sans-serif;"
      >
        <table class="table">
          <tbody>
            <tr>
              <td>
                A 1990-es évek közepétől több, mint 20 évet töltöttünk a hazai
                elektronikus kaszinózás világában, így az elektronikus
                szórakoztatás a vérünkben van.<br />
              </td>
              <td
                style="border-right: 4px solid black;padding-top: 12px;padding-bottom: 13px;"
              ></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td style="border-left: 4px solid black"></td>
              <td>
                Budapest és az ország legszínvonalasabb elektronikus kaszinóit
                segítettük életrekelteni és gondoskodtunk róla, hogy itthon is
                nyugat-európai színvonalon lehessen élvezni a szórakoztatóipar
                ezen formáját.<br />
              </td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>
                Akit érdekel a múltunk, az az ügynökségi oldalunkon megnézheti
                hogyan teltek ezek az évek: www.brazilreklam.hu (az oldal
                flashes, így mobilról nem tudod megnézni, csak laptopról vagy
                asztali gépről).<br />
              </td>
              <td style="border-right: 4px solid black"></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td style="border-left: 4px solid black"></td>
              <td>
                Az elmúlt években mi is nagy érdeklődéssel figyeltük a Tesla
                térnyerését és miután vettünk egyet, kipróbáltuk ezt a csendesen
                gyomorbavágó, szédületes fenevadat - szerelem volt első
                látásra!<br />
              </td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>
                Úgy gondoltuk, hogy ezt az élményt meg kell oszatnunk minél több
                emberrel, és meg kell teremteni a lehetőséget, hogy ezt az
                eszméletlen élményt minél többen átélhessék.<br />
              </td>
              <td style="border-right: 4px solid black"></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td style="border-left: 4px solid black"></td>
              <td>
                Ezért született ez az oldal és ha velünk tartasz, belekóstolsz
                ebbe<br />
                <p id="textspan">A LOPAKODÓ, CSENDES ŐRÜLETBE,<br /></p>
                te is meg akarod zabolázni azt a bármikor ugrásra kész 772
                lovat!<br />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <h1 class="text-center" id="about-big">
        <br />AZ EGÉSZ ORSZÁGBAN<br />EGYEDÜL NÁLUNK TUDSZ FORGALOMBAN, ÉLES
        BEVETÉSEN<br /><strong>772 LÓERŐS TESLA MODEL S P100D-T VEZETNI.</strong
        ><br /><br />
      </h1>
    </div>
    <div class="container" id="followus">
      <div class="d-none d-xl-block">
        <div class="text-uppercase">
          <div class="container">
            <div class="row">
              <div class="col-md-4 col-xl-10 offset-xl-2">
                <h1 class="text-uppercase text-left">
                  <strong>KÖVESS</strong>
                </h1>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="container">
            <div class="row">
              <div
                class="col-md-4 col-xl-2 offset-xl-5 text-right align-self-center"
              >
                <a href="http://www.facebook.com/teslapilota"
                  ><img
                    style="padding-right: 1.875rem"
                    src="~assets/img/btn-facebook.png"/></a
                ><a href="http://www.instagram.com/teslapilota"
                  ><img src="~assets/img/btn-instagram.png"
                /></a>
              </div>
              <div class="col-md-4 col-xl-4 offset-xl-0">
                <h1 class="text-uppercase text-right">
                  <strong>MINKET</strong>
                </h1>
              </div>
              <div class="clearfix"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="mobile d-block d-xl-none">
        Kövess minket
        <div>
          <a href="https://www.facebook.com/TeslaPilota/" target="_blank">
            <img src="~assets/img/btn-facebook.png" />
          </a>
          <a href="https://www.instagram.com/teslapilota/" target="_blank">
            <img src="~assets/img/btn-instagram.png" />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import TopNavBar from '../general/top-nav-bar';

  export default {
    name: "about",
    components: {
      TopNavBar
    }
  };
</script>

<style scoped>
  @media (max-width: 768px) {
    td {
      font-size: 0.8rem;
    }
  }

  #textspan {
    margin-bottom: 0px;
    font-size: 1.6rem;
  }

  @media (max-width: 768px) {
    #textspan {
      font-size: 1rem;
    }
  }

  #about-big {
    font-weight: 400;
    margin-top: 20px;
    font-size: 2.5rem;
    margin-bottom: 90px;
  }

  @media (max-width: 768px) {
    #about-big {
      font-weight: 400;
      margin-top: 20px;
      font-size: 1.5rem;
    }
  }

  .text-uppercase.text-right {
    -webkit-tap-highlight-color: transparent;
    --breakpoint-xs: 0;
    --breakpoint-sm: 36rem;
    --breakpoint-md: 48rem;
    --breakpoint-lg: 62rem;
    --breakpoint-xl: 75rem;
    --font-family-sans-serif: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    --font-family-monospace: SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
    text-align: right;
    text-transform: uppercase;
    font-family: Raleway;
    font-weight: 800;
    color: #b6b6b6;
    box-sizing: border-box;
    margin-bottom: 1rem;
    font-size: 7.0625rem;
    line-height: 7.0625rem;
    margin-top: -1.875rem;
  }

  .text-uppercase {
    margin-bottom: 0rem;
  }

  .text-uppercase.text-left {
    -webkit-tap-highlight-color: transparent;
    --breakpoint-xs: 0;
    --breakpoint-sm: 36rem;
    --breakpoint-md: 48rem;
    --breakpoint-lg: 62rem;
    --breakpoint-xl: 75rem;
    text-align: left;
    text-transform: uppercase;
    font-family: Raleway;
    font-weight: 800;
    color: #b6b6b6;
    box-sizing: border-box;
    margin-top: 0;
    font-size: 11.875rem;
    line-height: 11.875rem;
    margin-bottom: 0;
  }

  .d-xl-none {
    -webkit-tap-highlight-color: transparent;
    line-height: 1.5;
    text-align: center;
    color: #2b2b2b;
    text-transform: uppercase;
    font-family: Raleway;
    font-weight: 800;
    box-sizing: border-box;
    font-size: 41px;
    padding-bottom: 2rem;
  }

  #followus {
    margin-bottom: 90px;
  }
</style>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c("router-view"),
      _vm._v(" "),
      _c(
        "footer",
        [
          _c("div", { staticClass: "footer" }, [
            _c("div", { staticClass: "content-container" }, [
              _c("div", { staticClass: "container" }, [
                _c("div", { staticClass: "row" }, [
                  _vm._m(0),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-12 col-xl-7" }, [
                    _c("div", { staticClass: "menu" }, [
                      _c("ul", [
                        _c("li", [
                          _c("a", { attrs: { href: _vm.goTo("aszf") } }, [
                            _vm._v("ÁSZF")
                          ])
                        ]),
                        _vm._v(" "),
                        _c("li", [
                          _c(
                            "a",
                            { attrs: { href: _vm.goTo("adatvedelem") } },
                            [_vm._v("Adatvédelem")]
                          )
                        ])
                      ])
                    ])
                  ])
                ])
              ])
            ]),
            _vm._v(" "),
            _vm._m(1)
          ]),
          _vm._v(" "),
          _c("cookie-law", {
            attrs: { theme: "tesla" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(props) {
                  return _c("div", {}, [
                    _vm._v(
                      "\n        Oldalunk a TeslaÉlmény fokozása érdekében sütiket használ. További információkért olvassa el\n        "
                    ),
                    _c("a", { attrs: { href: _vm.goTo("adatvedelem") } }, [
                      _vm._v("Adatvédelmi irányelveinket")
                    ]),
                    _vm._v(".\n        "),
                    _c(
                      "button",
                      {
                        staticClass: "accept-cookies",
                        on: { click: props.accept }
                      },
                      [_c("span", [_vm._v("Elfogadom")])]
                    )
                  ])
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-12 col-xl-5" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12 col-xl-6" }, [
          _c("p", { staticClass: "title" }, [_vm._v("Teslapilóta")]),
          _vm._v(" "),
          _c("p", { staticClass: "description" }, [
            _vm._v(
              "\n                    A TeslaPilóta életed élménye lesz, ha kipróbálod! A Tesla\n                    csúcsgépe, a P100D rád vár, hogy megzabolázd a 772 lóerőt,\n                    teszteld a pokoli gyorsulását, vagy kapcsold be az\n                    autopilot módot és engedd el a kormányt!\n                    "
            ),
            _c("strong", [_vm._v("Vigyázat! Erősen addiktív élmény. :)")])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-12 col-xl-6" }, [
          _c("p", { staticClass: "title" }, [_vm._v("Kapcsolat")]),
          _vm._v(" "),
          _c("p", { staticClass: "description" }, [
            _c("span", { staticClass: "ico--phone" }, [
              _vm._v("+36 30 222 3005 "),
              _c("br"),
              _vm._v(
                "\n                      Hétfőtől péntekig: 9-19 óráig\n                    "
              )
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "ico--email" }, [
              _vm._v("hello@teslapilota.hu")
            ])
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "copyright-container d-flex align-items-center justify-content-center"
      },
      [
        _c("div", [
          _c("span", { staticClass: "text-uppercase" }, [
            _vm._v("Teslapilóta")
          ]),
          _vm._v(" Copyright © 2020\n        ")
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }
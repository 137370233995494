<template>
  <div class="payment-status">
    <top-nav-bar />
    <div v-if="!loading" class="container" style="margin-top: 100px;margin-bottom: 50px;">
      <div class="row">
        <div class="col-12 text-center">
          <p v-html="response_message"></p>
          <p>{{message}}</p>
          <router-link to="/" class="button" tag="button" style="margin-bottom: 10px">Vissza a főoldalra</router-link><br />
          <button v-if="invoice_url" @click="go(invoice_url)" style="margin-bottom: 10px">Számla</button><br />
          <button v-if="payment_url" @click="go(payment_url)" style="margin-bottom: 10px">Fizetés</button><br />
          <button v-if="gift_card_url" @click="go(gift_card_url)" style="margin-bottom: 10px">Ajándékkártya letöltése</button>
        </div>
      </div>
    </div>
    <div v-else style="margin-top: 100px;margin-bottom: 50px;">Betöltés...</div>
  </div>
</template>

<script>
  import TopNavBar from '../general/top-nav-bar';

  export default
  {
    name: "payment-status",
    components:
    {
      TopNavBar
    },

    data()
    {
      return {
        loading: true,
        invoice_url: null,
        payment_url: null,
        gift_card_url: null,
        message: '',
        response_message: ''
      }
    },

    methods:
    {
      go: function (loc)
      {
        location.href = loc;
      }
    },

    mounted()
    {
      this.loading = true;
      this.$api
              .process_payment(this.$route.params.uniqueId, {r: this.$route.query.r || null, s: this.$route.query.s || null})
              .then((res1) =>
              {
                if (!res1.success)
                {
                  let error = '';
                  switch (res1.code)
                  {
                    case 2001:
                      error = 'Hiba: Foglalás nem létezik.';
                      break;
                    case 2002:
                      error = 'Hiba: Tranzakció nem létezik.';
                      break;
                  }

                  this.$bvModal.msgBoxOk(error, {okVariant: 'danger'});
                }
                else
                {
                  this.$api
                          .payment_status(this.$route.params.uniqueId, {r: this.$route.query.r || null, s: this.$route.query.s || null})
                          .then((res1) =>
                          {
                            if (!res1.success)
                            {
                              let error = '';
                              switch (res1.code)
                              {
                                case 2001:
                                  error = 'Hiba: Foglalás nem létezik.';
                                  break;
                              }

                              this.$bvModal.msgBoxOk(error, {okVariant: 'danger'});
                            }
                            else
                            {
                              this.$set(this, 'invoice_url', res1.invoice_url);
                              this.$set(this, 'payment_url', res1.payment_url);
                              this.$set(this, 'gift_card_url', res1.gift_card_url);
                              this.$set(this, 'response_message', res1.response_message);
                              this.loading = false;
                            }
                          });
                }
              });
    }
  };
</script>

<style scoped>
  button {
    background-image: url("~assets/img/btn-reservation-red.png");
    width: 298px;
    height: 74px;
    background-color: rgba(0,0,0,0);
    font-weight: 700;
    font-size: 1.1rem;
    text-transform: uppercase;
    background-repeat: no-repeat;
    background-size: cover;
    color: rgb(255,255,255);
  }
</style>

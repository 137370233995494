var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "reservation" }, [
    _c(
      "div",
      { staticClass: "reservation-header" },
      [_c("top-nav-bar"), _vm._v(" "), _vm._m(0)],
      1
    ),
    _vm._v(" "),
    _c("main", [
      _c(
        "div",
        {
          staticClass: "container-md text-left",
          staticStyle: { "margin-top": "100px" }
        },
        [
          _c(
            "h1",
            {
              staticClass: "text-uppercase",
              staticStyle: {
                "font-family": "Montserrat, sans-serif",
                "font-weight": "700",
                "margin-bottom": "0px",
                "font-size": "3rem"
              }
            },
            [_vm._v("\n                    Foglalj helyet\n                ")]
          ),
          _vm._v(" "),
          _c(
            "h2",
            {
              staticClass: "text-uppercase",
              staticStyle: {
                "font-family": "Montserrat, sans-serif",
                "font-weight": "500",
                "margin-bottom": "35px",
                "font-size": "2rem"
              }
            },
            [
              _vm._v(
                "\n                    A teslapilóta-ülésben!\n                "
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "h3",
            {
              staticClass: "text-uppercase",
              staticStyle: {
                "font-family": "Montserrat, sans-serif",
                "font-weight": "400",
                "margin-bottom": "5px",
                "font-size": "1.4rem"
              }
            },
            [
              _vm._v(
                "\n                    Kérlek, töltsd ki az űrlapot\n                "
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "p",
            {
              staticStyle: {
                "font-family": "Montserrat, sans-serif",
                "font-size": "1.1rem"
              }
            },
            [
              _vm._v(
                '\n                    FONTOS: A megrendelés a "Távollévők között kötött szerződésnek"'
              ),
              _c("br"),
              _vm._v(
                "minősül\n                    és részedről fizetési kötelezettséggel jár. További információkért"
              ),
              _c("br"),
              _vm._v("olvasd\n                    el az "),
              _c(
                "router-link",
                {
                  staticStyle: { color: "#c1132c", "font-size": "1.1rem" },
                  attrs: { to: "/aszf", target: "_blank" }
                },
                [_c("strong", [_vm._v("ászf")])]
              ),
              _vm._v("-et!\n                ")
            ],
            1
          ),
          _vm._v(" "),
          !_vm.show_summary
            ? _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "col-xl-12 text-sm-center text-md-center text-lg-left text-xl-left",
                    staticStyle: { "margin-top": "30px" }
                  },
                  [
                    _c(
                      "h3",
                      {
                        staticClass: "text-uppercase",
                        staticStyle: {
                          "font-family": "Montserrat, sans-serif",
                          "font-weight": "400",
                          "font-size": "1.4rem",
                          "margin-bottom": "0px"
                        }
                      },
                      [
                        _vm._v(
                          "\n                            Ha már van ajándékkártyád, akkor kattints ide!"
                        ),
                        _c(
                          "a",
                          {
                            staticClass: "btn text-uppercase",
                            staticStyle: {
                              "background-color": "rgba(255,255,255,0)",
                              color: "rgb(0,0,0)",
                              "font-size": "1.5rem",
                              "font-family": "Montserrat, sans-serif",
                              "margin-right": "0px",
                              "padding-right": "15px",
                              "padding-top": "0px",
                              "margin-top": "0px"
                            },
                            attrs: { role: "button", href: "#" },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                _vm.has_gift_card = !_vm.has_gift_card
                              }
                            }
                          },
                          [
                            _c("img", {
                              staticStyle: {
                                "padding-left": "0px",
                                width: "45px",
                                "margin-left": "10px"
                              },
                              attrs: {
                                src: require("assets/img/btn-reservation-red2.png")
                              }
                            })
                          ]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _vm.has_gift_card
                      ? _c(
                          "div",
                          {
                            staticClass: "form-group",
                            staticStyle: {
                              "margin-top": "30px",
                              "margin-bottom": "70px"
                            }
                          },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.$v.gift_code.$model,
                                  expression: "$v.gift_code.$model"
                                }
                              ],
                              staticClass: "border rounded col-12",
                              staticStyle: {
                                height: "60px",
                                "margin-right": "5px",
                                "padding-left": "1.5rem",
                                "margin-left": "0px",
                                width: "50%"
                              },
                              attrs: {
                                type: "text",
                                id: "giftcard",
                                placeholder: "Ajándékkártya száma",
                                name: "name"
                              },
                              domProps: { value: _vm.$v.gift_code.$model },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.$v.gift_code,
                                    "$model",
                                    $event.target.value
                                  )
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass: "btn active shadow-none",
                                attrs: {
                                  type: "submit",
                                  disabled:
                                    _vm.gift_code.length === 0 || _vm.send
                                },
                                on: { click: _vm.sendGiftCode }
                              },
                              [_vm._v("KÜLDÉS")]
                            ),
                            _vm._v(" "),
                            !_vm.$v.gift_code.required
                              ? _c("div", { staticClass: "error" }, [
                                  _vm._v("Töltsd ki a ajándékkártya mezőt!")
                                ])
                              : _vm._e()
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.has_gift_card
                      ? _c("div", { staticStyle: { "margin-bottom": "70px" } })
                      : _vm._e()
                  ]
                )
              ])
            : _vm._e()
        ]
      )
    ]),
    _vm._v(" "),
    _c("section", { ref: "top" }, [
      !_vm.show_summary
        ? _c(
            "div",
            {
              staticClass: "container-md",
              staticStyle: {
                "margin-top": "99px",
                "margin-bottom": "100px",
                "font-family": "Montserrat, sans-serif"
              }
            },
            [
              _c(
                "div",
                { staticClass: "row justify-content-center" },
                [
                  !_vm.has_gift_card
                    ? [
                        _c(
                          "div",
                          {
                            staticClass: "col-12 col-xl-6 pr-xl-5 feature-box",
                            staticStyle: {
                              "margin-bottom": "55px",
                              "padding-right": "30px"
                            }
                          },
                          [
                            _c(
                              "h4",
                              {
                                staticClass: "text-uppercase",
                                staticStyle: {
                                  "font-size": "2rem",
                                  "line-height": "35px",
                                  "letter-spacing": "0px",
                                  "font-weight": "400",
                                  "margin-bottom": "50px"
                                }
                              },
                              [
                                _vm._v(
                                  "\n                                Melyik csomag?\n                            "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.$v.data.package.$model,
                                    expression: "$v.data.package.$model"
                                  }
                                ],
                                staticClass: "package",
                                staticStyle: { "margin-left": "0px" },
                                attrs: { id: "package" },
                                on: {
                                  change: function($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call($event.target.options, function(o) {
                                        return o.selected
                                      })
                                      .map(function(o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      _vm.$v.data.package,
                                      "$model",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  }
                                }
                              },
                              [
                                _c(
                                  "option",
                                  { attrs: { value: "none", selected: "" } },
                                  [_vm._v("Válassz csomagot")]
                                ),
                                _vm._v(" "),
                                _vm._l(_vm.packages, function(p, index) {
                                  return _c(
                                    "option",
                                    { domProps: { value: index } },
                                    [
                                      _vm._v(
                                        _vm._s(p.name) +
                                          " - " +
                                          _vm._s(p.short_name)
                                      )
                                    ]
                                  )
                                })
                              ],
                              2
                            ),
                            _vm._v(" "),
                            !_vm.$v.data.package.minValue
                              ? _c("div", { staticClass: "error" }, [
                                  _vm._v("Válassz csomagot!")
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "h4",
                              {
                                staticClass: "text-uppercase",
                                staticStyle: {
                                  "font-weight": "700",
                                  "padding-top": "0px",
                                  "margin-top": "40px",
                                  "font-size": "1.5rem"
                                }
                              },
                              [
                                _vm._v(
                                  "\n                                Videóval"
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _vm._l(_vm.extras, function(e, index) {
                              return [
                                _c(
                                  "p",
                                  {
                                    staticStyle: {
                                      "font-weight": "500",
                                      "font-size": "1.1rem"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                                    " +
                                        _vm._s(e.name) +
                                        " (+" +
                                        _vm._s(e.price) +
                                        " Ft)\n                                "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c("section", [
                                  _c("div", { staticClass: "container" }, [
                                    _c("div", { staticClass: "row" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "col-md-12 col-xl-4",
                                          staticStyle: { "padding-left": "0px" }
                                        },
                                        [
                                          _c("div", [
                                            _c("p", [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.extraClicks[index],
                                                    expression:
                                                      "extraClicks[index]"
                                                  }
                                                ],
                                                attrs: {
                                                  type: "radio",
                                                  id: "extra1_" + index,
                                                  name: "extra"
                                                },
                                                domProps: {
                                                  value: true,
                                                  checked: _vm._q(
                                                    _vm.extraClicks[index],
                                                    true
                                                  )
                                                },
                                                on: {
                                                  change: [
                                                    function($event) {
                                                      return _vm.$set(
                                                        _vm.extraClicks,
                                                        index,
                                                        true
                                                      )
                                                    },
                                                    function($event) {
                                                      return _vm.addExtra(index)
                                                    }
                                                  ]
                                                }
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "label",
                                                {
                                                  attrs: {
                                                    for: "extra1_" + index
                                                  }
                                                },
                                                [_vm._v("Kérem")]
                                              )
                                            ])
                                          ])
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "col",
                                          staticStyle: { "padding-left": "0px" }
                                        },
                                        [
                                          _c("div", [
                                            _c("p", [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.extraClicks[index],
                                                    expression:
                                                      "extraClicks[index]"
                                                  }
                                                ],
                                                attrs: {
                                                  type: "radio",
                                                  id: "extra2_" + index,
                                                  name: "extra"
                                                },
                                                domProps: {
                                                  value: false,
                                                  checked: _vm._q(
                                                    _vm.extraClicks[index],
                                                    false
                                                  )
                                                },
                                                on: {
                                                  change: [
                                                    function($event) {
                                                      return _vm.$set(
                                                        _vm.extraClicks,
                                                        index,
                                                        false
                                                      )
                                                    },
                                                    function($event) {
                                                      return _vm.removeExtra(
                                                        index
                                                      )
                                                    }
                                                  ]
                                                }
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "label",
                                                {
                                                  attrs: {
                                                    for: "extra2_" + index
                                                  }
                                                },
                                                [_vm._v("Nem kérem")]
                                              )
                                            ])
                                          ])
                                        ]
                                      )
                                    ])
                                  ])
                                ])
                              ]
                            })
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "text-center text-sm-center text-md-center text-lg-center text-xl-center col-12 col-xl-6 pr-xl-5 feature-box",
                            staticStyle: {
                              "margin-top": "75px",
                              "margin-bottom": "55px",
                              "padding-top": "0px",
                              "padding-right": "0px",
                              "padding-bottom": "0px",
                              "padding-left": "0px"
                            }
                          },
                          [
                            _vm.data.package !== "none"
                              ? _c(
                                  "section",
                                  { staticClass: "pricing-table" },
                                  [
                                    _c("div", { staticClass: "container" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "pricing-area text-center"
                                        },
                                        [
                                          _c("div", { staticClass: "row" }, [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "col-sm-6 col-md-3 plan price red wow fadeInDown"
                                              },
                                              [
                                                _c(
                                                  "ul",
                                                  { staticClass: "list-group" },
                                                  [
                                                    _c(
                                                      "li",
                                                      {
                                                        staticClass:
                                                          "list-group-item shadow heading",
                                                        class: {
                                                          "best-seller":
                                                            _vm.getPackage
                                                              .best_seller
                                                        },
                                                        staticStyle: {
                                                          width: "400px",
                                                          "margin-left": "30px",
                                                          "background-color":
                                                            "rgb(255,255,255)",
                                                          "font-family":
                                                            "Montserrat, sans-serif"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "h1",
                                                          {
                                                            staticClass:
                                                              "package-title",
                                                            staticStyle: {
                                                              color:
                                                                "rgb(0,0,0)",
                                                              "font-family":
                                                                "Montserrat, sans-serif",
                                                              "font-size":
                                                                "2rem",
                                                              "font-weight":
                                                                "500"
                                                            }
                                                          },
                                                          [
                                                            _c("strong", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.getPackage
                                                                    .name
                                                                )
                                                              )
                                                            ])
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "p",
                                                          {
                                                            staticClass:
                                                              "package-text",
                                                            staticStyle: {
                                                              "font-size":
                                                                "1.1rem",
                                                              "margin-bottom":
                                                                "0px",
                                                              color:
                                                                "rgb(0,0,0)"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.getPackage
                                                                  .short_name
                                                              )
                                                            )
                                                          ]
                                                        ),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "price",
                                                            staticStyle: {
                                                              color:
                                                                "rgb(0,0,0)",
                                                              "font-size":
                                                                "3rem",
                                                              "margin-bottom":
                                                                "0px"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.formatPrice(
                                                                  _vm.getPackage
                                                                    .actual_price
                                                                )
                                                              ) + " Ft"
                                                            )
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c("p", {
                                                          staticClass:
                                                            "package-text",
                                                          staticStyle: {
                                                            color: "rgb(0,0,0)",
                                                            "margin-top": "10px"
                                                          },
                                                          domProps: {
                                                            innerHTML: _vm._s(
                                                              _vm.getPackage
                                                                .description
                                                            )
                                                          }
                                                        }),
                                                        _vm._v(" "),
                                                        _vm.getPackage
                                                          .discount > 0
                                                          ? _c(
                                                              "p",
                                                              {
                                                                staticClass:
                                                                  "package-text",
                                                                staticStyle: {
                                                                  color:
                                                                    "rgb(255,0,0)",
                                                                  "margin-top":
                                                                    "10px"
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm
                                                                      .getPackage
                                                                      .discount
                                                                  ) +
                                                                    "% kedvezménnyel!"
                                                                )
                                                              ]
                                                            )
                                                          : _vm._e()
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ])
                                        ]
                                      )
                                    ])
                                  ]
                                )
                              : _vm._e()
                          ]
                        )
                      ]
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "col-12 col-xl-6 pr-xl-5 feature-box",
                      staticStyle: { "padding-right": "0px" }
                    },
                    [
                      !_vm.has_gift_card && !_vm.distributing
                        ? [
                            _vm._m(1),
                            _vm._v(" "),
                            _c("section", [
                              _c(
                                "div",
                                {
                                  staticClass: "container",
                                  staticStyle: {
                                    "padding-right": "0px",
                                    "padding-left": "0px"
                                  }
                                },
                                [
                                  _c("div", { staticClass: "row" }, [
                                    _c(
                                      "div",
                                      { staticClass: "col-md-12 col-xl-5" },
                                      [
                                        _c("div", [
                                          _c("p", [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.data.is_gift,
                                                  expression: "data.is_gift"
                                                }
                                              ],
                                              attrs: {
                                                type: "radio",
                                                id: "gift1",
                                                name: "gift"
                                              },
                                              domProps: {
                                                value: false,
                                                checked: _vm._q(
                                                  _vm.data.is_gift,
                                                  false
                                                )
                                              },
                                              on: {
                                                change: function($event) {
                                                  return _vm.$set(
                                                    _vm.data,
                                                    "is_gift",
                                                    false
                                                  )
                                                }
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "label",
                                              { attrs: { for: "gift1" } },
                                              [_vm._v("Saját részre")]
                                            )
                                          ])
                                        ])
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "col" }, [
                                      _c("div", [
                                        _c("p", [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.data.is_gift,
                                                expression: "data.is_gift"
                                              }
                                            ],
                                            attrs: {
                                              type: "radio",
                                              id: "gift2",
                                              name: "gift"
                                            },
                                            domProps: {
                                              value: true,
                                              checked: _vm._q(
                                                _vm.data.is_gift,
                                                true
                                              )
                                            },
                                            on: {
                                              change: function($event) {
                                                return _vm.$set(
                                                  _vm.data,
                                                  "is_gift",
                                                  true
                                                )
                                              }
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "label",
                                            { attrs: { for: "gift2" } },
                                            [_vm._v("Ajándékba")]
                                          )
                                        ])
                                      ])
                                    ])
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "form-group",
                                      staticStyle: { "margin-bottom": "15px" }
                                    },
                                    [
                                      _vm.data.is_gift
                                        ? _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.$v.data.user_last_name
                                                    .$model,
                                                expression:
                                                  "$v.data.user_last_name.$model"
                                              }
                                            ],
                                            staticClass: "border rounded col-6",
                                            staticStyle: {
                                              height: "60px",
                                              "margin-right": "5px",
                                              "padding-left": "1.5rem",
                                              "margin-left": "0px",
                                              width: "47%"
                                            },
                                            attrs: {
                                              type: "text",
                                              placeholder: "Vezetéknév",
                                              name: "lastname"
                                            },
                                            domProps: {
                                              value:
                                                _vm.$v.data.user_last_name
                                                  .$model
                                            },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.$v.data.user_last_name,
                                                  "$model",
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.data.is_gift
                                        ? _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.$v.data.user_first_name
                                                    .$model,
                                                expression:
                                                  "$v.data.user_first_name.$model"
                                              }
                                            ],
                                            staticClass: "border rounded col-6",
                                            staticStyle: {
                                              height: "60px",
                                              "padding-left": "1.5rem",
                                              width: "47%",
                                              "margin-bottom": "20px"
                                            },
                                            attrs: {
                                              type: "text",
                                              placeholder: "Utónév",
                                              name: "firstname"
                                            },
                                            domProps: {
                                              value:
                                                _vm.$v.data.user_first_name
                                                  .$model
                                            },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.$v.data.user_first_name,
                                                  "$model",
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.data.is_gift &&
                                      !_vm.$v.data.user_last_name.required
                                        ? _c("div", { staticClass: "error" }, [
                                            _vm._v(
                                              "Töltsd ki a vezetéknév mezőt!"
                                            )
                                          ])
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.data.is_gift &&
                                      !_vm.$v.data.user_first_name.required
                                        ? _c("div", { staticClass: "error" }, [
                                            _vm._v("Töltsd ki a utónév mezőt!")
                                          ])
                                        : _vm._e(),
                                      _vm._v(" "),
                                      !_vm.data.is_gift
                                        ? _c("div", [
                                            _c("p", [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.data
                                                        .is_late_appointment_and_location,
                                                    expression:
                                                      "data.is_late_appointment_and_location"
                                                  }
                                                ],
                                                attrs: {
                                                  type: "checkbox",
                                                  id:
                                                    "lateAppointmentAndLocation",
                                                  name:
                                                    "lateAppointmentAndLocation"
                                                },
                                                domProps: {
                                                  value: true,
                                                  checked: Array.isArray(
                                                    _vm.data
                                                      .is_late_appointment_and_location
                                                  )
                                                    ? _vm._i(
                                                        _vm.data
                                                          .is_late_appointment_and_location,
                                                        true
                                                      ) > -1
                                                    : _vm.data
                                                        .is_late_appointment_and_location
                                                },
                                                on: {
                                                  change: function($event) {
                                                    var $$a =
                                                        _vm.data
                                                          .is_late_appointment_and_location,
                                                      $$el = $event.target,
                                                      $$c = $$el.checked
                                                        ? true
                                                        : false
                                                    if (Array.isArray($$a)) {
                                                      var $$v = true,
                                                        $$i = _vm._i($$a, $$v)
                                                      if ($$el.checked) {
                                                        $$i < 0 &&
                                                          _vm.$set(
                                                            _vm.data,
                                                            "is_late_appointment_and_location",
                                                            $$a.concat([$$v])
                                                          )
                                                      } else {
                                                        $$i > -1 &&
                                                          _vm.$set(
                                                            _vm.data,
                                                            "is_late_appointment_and_location",
                                                            $$a
                                                              .slice(0, $$i)
                                                              .concat(
                                                                $$a.slice(
                                                                  $$i + 1
                                                                )
                                                              )
                                                          )
                                                      }
                                                    } else {
                                                      _vm.$set(
                                                        _vm.data,
                                                        "is_late_appointment_and_location",
                                                        $$c
                                                      )
                                                    }
                                                  }
                                                }
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "label",
                                                {
                                                  attrs: {
                                                    for:
                                                      "lateAppointmentAndLocation"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "Későbbi időpont és helyszín egyeztetéssel kérem!"
                                                  )
                                                ]
                                              )
                                            ])
                                          ])
                                        : _vm._e()
                                    ]
                                  )
                                ]
                              )
                            ])
                          ]
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.has_gift_card ||
                      (_vm.has_gift_card && _vm.data.package !== "none")
                        ? [
                            !_vm.lateOrGift
                              ? [
                                  _c(
                                    "h4",
                                    {
                                      staticClass: "text-uppercase",
                                      staticStyle: {
                                        "font-size": "2rem",
                                        "font-weight": "400",
                                        "margin-bottom": "25px",
                                        "margin-top": "50px"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                    Melyik nap?\n                                "
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "row" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "col",
                                        staticStyle: { "margin-left": "0px" }
                                      },
                                      [
                                        _c(
                                          "select",
                                          {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.$v.data.reservation_date
                                                    .$model,
                                                expression:
                                                  "$v.data.reservation_date.$model"
                                              }
                                            ],
                                            staticClass: "date",
                                            staticStyle: {
                                              "margin-left": "0px"
                                            },
                                            attrs: { id: "date" },
                                            on: {
                                              change: function($event) {
                                                var $$selectedVal = Array.prototype.filter
                                                  .call(
                                                    $event.target.options,
                                                    function(o) {
                                                      return o.selected
                                                    }
                                                  )
                                                  .map(function(o) {
                                                    var val =
                                                      "_value" in o
                                                        ? o._value
                                                        : o.value
                                                    return val
                                                  })
                                                _vm.$set(
                                                  _vm.$v.data.reservation_date,
                                                  "$model",
                                                  $event.target.multiple
                                                    ? $$selectedVal
                                                    : $$selectedVal[0]
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c(
                                              "option",
                                              {
                                                attrs: {
                                                  value: "none",
                                                  selected: ""
                                                }
                                              },
                                              [_vm._v("Válassz dátumot")]
                                            ),
                                            _vm._v(" "),
                                            _vm._l(_vm.dates, function(
                                              date,
                                              index
                                            ) {
                                              return _c(
                                                "option",
                                                { domProps: { value: date } },
                                                [_vm._v(_vm._s(date))]
                                              )
                                            })
                                          ],
                                          2
                                        )
                                      ]
                                    )
                                  ]),
                                  _vm._v(" "),
                                  !_vm.$v.data.reservation_date.minValue
                                    ? _c("div", { staticClass: "error" }, [
                                        _vm._v("Válassz dátumot!")
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "h4",
                                    {
                                      staticClass: "text-uppercase",
                                      staticStyle: {
                                        "font-size": "2rem",
                                        "font-weight": "400",
                                        "margin-bottom": "25px",
                                        "margin-top": "50px"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                    Hova?\n                                "
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "row" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "col",
                                        staticStyle: { "margin-left": "0px" }
                                      },
                                      [
                                        _c(
                                          "select",
                                          {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.$v.data.charger_location
                                                    .$model,
                                                expression:
                                                  "$v.data.charger_location.$model"
                                              }
                                            ],
                                            staticClass: "chargerLocation",
                                            staticStyle: {
                                              "margin-left": "0px"
                                            },
                                            attrs: { id: "chargerLocation" },
                                            on: {
                                              change: function($event) {
                                                var $$selectedVal = Array.prototype.filter
                                                  .call(
                                                    $event.target.options,
                                                    function(o) {
                                                      return o.selected
                                                    }
                                                  )
                                                  .map(function(o) {
                                                    var val =
                                                      "_value" in o
                                                        ? o._value
                                                        : o.value
                                                    return val
                                                  })
                                                _vm.$set(
                                                  _vm.$v.data.charger_location,
                                                  "$model",
                                                  $event.target.multiple
                                                    ? $$selectedVal
                                                    : $$selectedVal[0]
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c(
                                              "option",
                                              {
                                                attrs: {
                                                  value: "none",
                                                  selected: ""
                                                }
                                              },
                                              [_vm._v("Válassz helyszínt")]
                                            ),
                                            _vm._v(" "),
                                            _vm._l(
                                              _vm.charger_locations,
                                              function(cl, index) {
                                                return _c(
                                                  "option",
                                                  {
                                                    domProps: { value: index }
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(cl.location.name) +
                                                        ", " +
                                                        _vm._s(cl.address)
                                                    )
                                                  ]
                                                )
                                              }
                                            )
                                          ],
                                          2
                                        )
                                      ]
                                    )
                                  ]),
                                  _vm._v(" "),
                                  !_vm.$v.data.charger_location.minValue
                                    ? _c("div", { staticClass: "error" }, [
                                        _vm._v("Válassz helyszínt!")
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "h4",
                                    {
                                      staticClass: "text-uppercase",
                                      staticStyle: {
                                        "font-size": "2rem",
                                        "font-weight": "400",
                                        "margin-bottom": "25px",
                                        "margin-top": "50px"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                    Mikor?\n                                "
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "row" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "col",
                                        staticStyle: { "margin-left": "0px" }
                                      },
                                      [
                                        _c(
                                          "select",
                                          {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.$v.data
                                                    .reservation_duration
                                                    .$model,
                                                expression:
                                                  "$v.data.reservation_duration.$model"
                                              }
                                            ],
                                            staticClass: "time",
                                            staticStyle: {
                                              "margin-left": "0px"
                                            },
                                            attrs: {
                                              id: "time",
                                              disabled: _vm.loading_slots
                                            },
                                            on: {
                                              change: function($event) {
                                                var $$selectedVal = Array.prototype.filter
                                                  .call(
                                                    $event.target.options,
                                                    function(o) {
                                                      return o.selected
                                                    }
                                                  )
                                                  .map(function(o) {
                                                    var val =
                                                      "_value" in o
                                                        ? o._value
                                                        : o.value
                                                    return val
                                                  })
                                                _vm.$set(
                                                  _vm.$v.data
                                                    .reservation_duration,
                                                  "$model",
                                                  $event.target.multiple
                                                    ? $$selectedVal
                                                    : $$selectedVal[0]
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c(
                                              "option",
                                              {
                                                attrs: {
                                                  value: "none",
                                                  selected: ""
                                                }
                                              },
                                              [_vm._v("--:--")]
                                            ),
                                            _vm._v(" "),
                                            _vm._l(_vm.slots, function(
                                              time,
                                              index
                                            ) {
                                              return _c(
                                                "option",
                                                { domProps: { value: time } },
                                                [_vm._v(_vm._s(time))]
                                              )
                                            })
                                          ],
                                          2
                                        )
                                      ]
                                    )
                                  ]),
                                  _vm._v(" "),
                                  !_vm.$v.data.reservation_duration.minValue
                                    ? _c("div", { staticClass: "error" }, [
                                        _vm._v("Válassz időpontot!")
                                      ])
                                    : _vm._e()
                                ]
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.has_gift_card
                              ? [
                                  _c(
                                    "h4",
                                    {
                                      staticClass: "text-uppercase",
                                      staticStyle: {
                                        "font-size": "2rem",
                                        "font-weight": "400",
                                        "margin-bottom": "25px",
                                        "margin-top": "50px"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                    ki?\n                                "
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "form-group",
                                      staticStyle: {
                                        "margin-bottom": "0px",
                                        "margin-top": "30px"
                                      }
                                    },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.$v.data.payer_last_name
                                                .$model,
                                            expression:
                                              "$v.data.payer_last_name.$model"
                                          }
                                        ],
                                        staticClass: "border rounded col-6",
                                        staticStyle: {
                                          height: "60px",
                                          "margin-right": "5px",
                                          "padding-left": "1.5rem",
                                          "margin-left": "0px",
                                          width: "47%"
                                        },
                                        attrs: {
                                          type: "text",
                                          placeholder: "Vezetéknév",
                                          name: "lastname"
                                        },
                                        domProps: {
                                          value:
                                            _vm.$v.data.payer_last_name.$model
                                        },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              _vm.$v.data.payer_last_name,
                                              "$model",
                                              $event.target.value
                                            )
                                          }
                                        }
                                      }),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.$v.data.payer_first_name
                                                .$model,
                                            expression:
                                              "$v.data.payer_first_name.$model"
                                          }
                                        ],
                                        staticClass: "border rounded col-6",
                                        staticStyle: {
                                          height: "60px",
                                          "padding-left": "1.5rem",
                                          width: "47%",
                                          "margin-bottom": "0px"
                                        },
                                        attrs: {
                                          type: "text",
                                          placeholder: "Utónév",
                                          name: "firstname"
                                        },
                                        domProps: {
                                          value:
                                            _vm.$v.data.payer_first_name.$model
                                        },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              _vm.$v.data.payer_first_name,
                                              "$model",
                                              $event.target.value
                                            )
                                          }
                                        }
                                      })
                                    ]
                                  ),
                                  _vm._v(" "),
                                  !_vm.$v.data.payer_last_name.required
                                    ? _c("div", { staticClass: "error" }, [
                                        _vm._v("Töltsd ki a vezetéknév mezőt!")
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  !_vm.$v.data.payer_first_name.required
                                    ? _c("div", { staticClass: "error" }, [
                                        _vm._v("Töltsd ki az utónév mezőt!")
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "form-group text-nowrap",
                                      staticStyle: {
                                        "margin-bottom": "0px",
                                        "margin-top": "15px"
                                      }
                                    },
                                    [
                                      _c("div", { staticClass: "row" }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "col-xl-3 col-5 col-xs-6 col-sm-3 col-md-3 col-lg-2",
                                            staticStyle: {
                                              "padding-right": "0px",
                                              width: "20%"
                                            }
                                          },
                                          [
                                            _c(
                                              "select",
                                              {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: _vm.phone_start_num,
                                                    expression:
                                                      "phone_start_num"
                                                  }
                                                ],
                                                staticClass: "package",
                                                staticStyle: {
                                                  "margin-left": "0px"
                                                },
                                                attrs: { id: "package-1" },
                                                on: {
                                                  change: function($event) {
                                                    var $$selectedVal = Array.prototype.filter
                                                      .call(
                                                        $event.target.options,
                                                        function(o) {
                                                          return o.selected
                                                        }
                                                      )
                                                      .map(function(o) {
                                                        var val =
                                                          "_value" in o
                                                            ? o._value
                                                            : o.value
                                                        return val
                                                      })
                                                    _vm.phone_start_num = $event
                                                      .target.multiple
                                                      ? $$selectedVal
                                                      : $$selectedVal[0]
                                                  }
                                                }
                                              },
                                              [
                                                _c(
                                                  "option",
                                                  {
                                                    attrs: { selected: "" },
                                                    domProps: { value: 0 }
                                                  },
                                                  [_vm._v("+36")]
                                                )
                                              ]
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "col-xl-9 col-7 col-xs-6 col-sm-9 col-md-9 col-lg-10",
                                            staticStyle: {
                                              "padding-left": "5px",
                                              width: "100%"
                                            }
                                          },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.$v.data.payer_phone
                                                      .$model,
                                                  expression:
                                                    "$v.data.payer_phone.$model"
                                                }
                                              ],
                                              staticClass: "border rounded",
                                              staticStyle: {
                                                height: "60px",
                                                "padding-left": "1.5rem",
                                                width: "80%"
                                              },
                                              attrs: {
                                                type: "text",
                                                placeholder: "Telefon szám",
                                                name: "phone"
                                              },
                                              domProps: {
                                                value:
                                                  _vm.$v.data.payer_phone.$model
                                              },
                                              on: {
                                                input: function($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.$set(
                                                    _vm.$v.data.payer_phone,
                                                    "$model",
                                                    $event.target.value
                                                  )
                                                }
                                              }
                                            })
                                          ]
                                        )
                                      ])
                                    ]
                                  ),
                                  _vm._v(" "),
                                  !_vm.$v.data.payer_phone.required
                                    ? _c("div", { staticClass: "error" }, [
                                        _vm._v(
                                          "Töltsd ki a telefon szám mezőt!"
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "form-group",
                                      staticStyle: {
                                        "margin-bottom": "0px",
                                        "margin-top": "15px"
                                      }
                                    },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.$v.data.payer_email.$model,
                                            expression:
                                              "$v.data.payer_email.$model"
                                          }
                                        ],
                                        staticClass: "border rounded col-12",
                                        staticStyle: {
                                          height: "60px",
                                          "margin-right": "0px",
                                          "padding-left": "1.5rem",
                                          "margin-left": "0px",
                                          width: "85%",
                                          "min-width": "85%"
                                        },
                                        attrs: {
                                          type: "text",
                                          placeholder: "E-mail cím",
                                          name: "mail"
                                        },
                                        domProps: {
                                          value: _vm.$v.data.payer_email.$model
                                        },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              _vm.$v.data.payer_email,
                                              "$model",
                                              $event.target.value
                                            )
                                          }
                                        }
                                      })
                                    ]
                                  ),
                                  _vm._v(" "),
                                  !_vm.$v.data.payer_email.required
                                    ? _c("div", { staticClass: "error" }, [
                                        _vm._v("Töltsd ki az e–mail cím mezőt!")
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  !_vm.$v.data.payer_email.email
                                    ? _c("div", { staticClass: "error" }, [
                                        _vm._v("Hibás e–mail cím!")
                                      ])
                                    : _vm._e()
                                ]
                              : _vm._e()
                          ]
                        : _vm._e()
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "col-12 col-xl-6 pl-xl-5 feature-box",
                      staticStyle: {
                        "padding-left": "15px",
                        "margin-left": "0px",
                        "margin-right": "0px",
                        "padding-right": "0px"
                      }
                    },
                    [
                      !_vm.has_gift_card
                        ? [
                            _vm._m(2),
                            _vm._v(" "),
                            _c(
                              "p",
                              { staticStyle: { "font-size": "1.1rem" } },
                              [
                                _vm._v(
                                  "\n                                Ha cégként kérsz számlát, akkor autóbérlésről küldünk számlát,\n                                amit a vállalkozásod el tud számolni.\n                            "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-md-12 col-xl-5" }, [
                                _c("div", [
                                  _c("p", [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.data.bill_is_firm,
                                          expression: "data.bill_is_firm"
                                        }
                                      ],
                                      attrs: {
                                        type: "radio",
                                        id: "szamla1",
                                        name: "group-szamla"
                                      },
                                      domProps: {
                                        value: false,
                                        checked: _vm._q(
                                          _vm.data.bill_is_firm,
                                          false
                                        )
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.$set(
                                            _vm.data,
                                            "bill_is_firm",
                                            false
                                          )
                                        }
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("label", { attrs: { for: "szamla1" } }, [
                                      _vm._v("Magánszemély")
                                    ])
                                  ])
                                ])
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "col" }, [
                                _c("div", [
                                  _c("p", [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.data.bill_is_firm,
                                          expression: "data.bill_is_firm"
                                        }
                                      ],
                                      attrs: {
                                        type: "radio",
                                        id: "szamla2",
                                        name: "group-szamla"
                                      },
                                      domProps: {
                                        value: true,
                                        checked: _vm._q(
                                          _vm.data.bill_is_firm,
                                          true
                                        )
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.$set(
                                            _vm.data,
                                            "bill_is_firm",
                                            true
                                          )
                                        }
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("label", { attrs: { for: "szamla2" } }, [
                                      _vm._v("Cég")
                                    ])
                                  ])
                                ])
                              ])
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "form-group",
                                staticStyle: { "margin-bottom": "15px" }
                              },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.$v.data.bill_name.$model,
                                      expression: "$v.data.bill_name.$model"
                                    }
                                  ],
                                  staticClass: "border rounded col-12",
                                  staticStyle: {
                                    height: "60px",
                                    "margin-right": "5px",
                                    "padding-left": "1.5rem",
                                    "margin-left": "0px",
                                    width: "100%"
                                  },
                                  attrs: {
                                    type: "text",
                                    placeholder: "Név/Cégnév",
                                    name: "name"
                                  },
                                  domProps: {
                                    value: _vm.$v.data.bill_name.$model
                                  },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.$v.data.bill_name,
                                        "$model",
                                        $event.target.value
                                      )
                                    }
                                  }
                                })
                              ]
                            ),
                            _vm._v(" "),
                            !_vm.$v.data.bill_name.required
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "error",
                                    staticStyle: { "margin-bottom": "15px" }
                                  },
                                  [_vm._v("Töltsd ki a név mezőt!")]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.data.bill_is_firm
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "form-group text-nowrap",
                                    staticStyle: { "margin-bottom": "15px" }
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.$v.vat1.$model,
                                          expression: "$v.vat1.$model"
                                        }
                                      ],
                                      staticClass: "border rounded col-12",
                                      staticStyle: {
                                        height: "60px",
                                        "margin-right": "1%",
                                        "padding-left": "1.5rem",
                                        "margin-left": "0px",
                                        width: "78%"
                                      },
                                      attrs: {
                                        type: "text",
                                        placeholder: "Adószám",
                                        name: "adoszam"
                                      },
                                      domProps: { value: _vm.$v.vat1.$model },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.$v.vat1,
                                            "$model",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    }),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.$v.vat2.$model,
                                          expression: "$v.vat2.$model"
                                        }
                                      ],
                                      staticClass: "border rounded col-12",
                                      staticStyle: {
                                        height: "60px",
                                        "margin-right": "1%",
                                        "padding-left": "1.5rem",
                                        "margin-left": "0px",
                                        width: "10%"
                                      },
                                      attrs: { type: "text" },
                                      domProps: { value: _vm.$v.vat2.$model },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.$v.vat2,
                                            "$model",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.$v.vat3.$model,
                                          expression: "$v.vat3.$model"
                                        }
                                      ],
                                      staticClass: "border rounded col-12",
                                      staticStyle: {
                                        height: "60px",
                                        "margin-right": "0px",
                                        "margin-left": "0px",
                                        width: "10%"
                                      },
                                      attrs: { type: "text" },
                                      domProps: { value: _vm.$v.vat3.$model },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.$v.vat3,
                                            "$model",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    })
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.data.bill_is_firm &&
                            (!_vm.$v.vat1.required ||
                              !_vm.$v.vat2.required ||
                              !_vm.$v.vat3.required)
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "error",
                                    staticStyle: { "margin-bottom": "15px" }
                                  },
                                  [_vm._v("Töltsd ki az adószám mezőt!")]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "form-group text-nowrap",
                                staticStyle: { "margin-bottom": "15px" }
                              },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.$v.data.bill_post_code.$model,
                                      expression:
                                        "$v.data.bill_post_code.$model"
                                    }
                                  ],
                                  staticClass: "border rounded col-12",
                                  staticStyle: {
                                    height: "60px",
                                    "margin-right": "1%",
                                    "padding-left": "1.5rem",
                                    "margin-left": "0px",
                                    width: "44%"
                                  },
                                  attrs: {
                                    type: "text",
                                    placeholder: "Irányítószám",
                                    name: "zip"
                                  },
                                  domProps: {
                                    value: _vm.$v.data.bill_post_code.$model
                                  },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.$v.data.bill_post_code,
                                        "$model",
                                        $event.target.value
                                      )
                                    }
                                  }
                                }),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.$v.data.bill_city.$model,
                                      expression: "$v.data.bill_city.$model"
                                    }
                                  ],
                                  staticClass: "border rounded col-12",
                                  staticStyle: {
                                    height: "60px",
                                    "margin-right": "0px",
                                    "padding-left": "1.5rem",
                                    "margin-left": "0px",
                                    width: "55%"
                                  },
                                  attrs: {
                                    type: "text",
                                    placeholder: "Település",
                                    name: "city"
                                  },
                                  domProps: {
                                    value: _vm.$v.data.bill_city.$model
                                  },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.$v.data.bill_city,
                                        "$model",
                                        $event.target.value
                                      )
                                    }
                                  }
                                })
                              ]
                            ),
                            _vm._v(" "),
                            !_vm.$v.data.bill_post_code.required
                              ? _c("div", { staticClass: "error" }, [
                                  _vm._v("Töltsd ki az irányítószám mezőt!")
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.$v.data.bill_city.required
                              ? _c("div", { staticClass: "error" }, [
                                  _vm._v("Töltsd ki a település mezőt!")
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.$v.data.bill_post_code.required ||
                            !_vm.$v.data.bill_city.required
                              ? _c("div", {
                                  staticStyle: { "margin-bottom": "15px" }
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "form-group",
                                staticStyle: { "margin-bottom": "15px" }
                              },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.$v.data.bill_address.$model,
                                      expression: "$v.data.bill_address.$model"
                                    }
                                  ],
                                  staticClass: "border rounded col-12",
                                  staticStyle: {
                                    height: "60px",
                                    "margin-right": "5px",
                                    "padding-left": "1.5rem",
                                    "margin-left": "0px",
                                    width: "100%"
                                  },
                                  attrs: {
                                    type: "text",
                                    placeholder: "Utca, házszám",
                                    name: "street"
                                  },
                                  domProps: {
                                    value: _vm.$v.data.bill_address.$model
                                  },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.$v.data.bill_address,
                                        "$model",
                                        $event.target.value
                                      )
                                    }
                                  }
                                })
                              ]
                            ),
                            _vm._v(" "),
                            !_vm.$v.data.bill_address.required
                              ? _c("div", { staticClass: "error" }, [
                                  _vm._v("Töltsd ki az utca, házszám mezőt!")
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "row",
                                staticStyle: { "margin-top": "60px" }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "col-md-12 col-xl-12",
                                    staticStyle: { "padding-left": "15px" }
                                  },
                                  [
                                    _c("div", [
                                      _c("p", [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.data.payer_is_pay_online,
                                              expression:
                                                "data.payer_is_pay_online"
                                            }
                                          ],
                                          attrs: {
                                            type: "radio",
                                            id: "fizetes1",
                                            name: "group-fizetes"
                                          },
                                          domProps: {
                                            value: true,
                                            checked: _vm._q(
                                              _vm.data.payer_is_pay_online,
                                              true
                                            )
                                          },
                                          on: {
                                            change: function($event) {
                                              return _vm.$set(
                                                _vm.data,
                                                "payer_is_pay_online",
                                                true
                                              )
                                            }
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "label",
                                          { attrs: { for: "fizetes1" } },
                                          [_vm._v("Bankkártyás fizetés")]
                                        )
                                      ])
                                    ])
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "col-xl-12",
                                    staticStyle: { "padding-left": "15px" }
                                  },
                                  [
                                    _c("div", [
                                      _c("p", [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.data.payer_is_pay_online,
                                              expression:
                                                "data.payer_is_pay_online"
                                            }
                                          ],
                                          attrs: {
                                            type: "radio",
                                            id: "fizetes2",
                                            name: "group-fizetes"
                                          },
                                          domProps: {
                                            value: false,
                                            checked: _vm._q(
                                              _vm.data.payer_is_pay_online,
                                              false
                                            )
                                          },
                                          on: {
                                            change: function($event) {
                                              return _vm.$set(
                                                _vm.data,
                                                "payer_is_pay_online",
                                                false
                                              )
                                            }
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "label",
                                          { attrs: { for: "fizetes2" } },
                                          [_vm._v("Banki átutalás")]
                                        )
                                      ])
                                    ])
                                  ]
                                )
                              ]
                            )
                          ]
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.has_gift_card ||
                      (_vm.has_gift_card && _vm.data.package !== "none")
                        ? _c(
                            "div",
                            {
                              staticClass: "row",
                              staticStyle: { "margin-top": "60px" }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "col-md-12 col-xl-12",
                                  staticStyle: {
                                    "padding-left": "15px",
                                    "font-size": "1rem"
                                  }
                                },
                                [
                                  _c("div", [
                                    _c("p", [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.$v.adatvedelem.$model,
                                            expression: "$v.adatvedelem.$model"
                                          }
                                        ],
                                        attrs: {
                                          type: "checkbox",
                                          id: "adatvedelem",
                                          name: "group-elfogad"
                                        },
                                        domProps: {
                                          checked: Array.isArray(
                                            _vm.$v.adatvedelem.$model
                                          )
                                            ? _vm._i(
                                                _vm.$v.adatvedelem.$model,
                                                null
                                              ) > -1
                                            : _vm.$v.adatvedelem.$model
                                        },
                                        on: {
                                          change: function($event) {
                                            var $$a = _vm.$v.adatvedelem.$model,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? true : false
                                            if (Array.isArray($$a)) {
                                              var $$v = null,
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  _vm.$set(
                                                    _vm.$v.adatvedelem,
                                                    "$model",
                                                    $$a.concat([$$v])
                                                  )
                                              } else {
                                                $$i > -1 &&
                                                  _vm.$set(
                                                    _vm.$v.adatvedelem,
                                                    "$model",
                                                    $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      )
                                                  )
                                              }
                                            } else {
                                              _vm.$set(
                                                _vm.$v.adatvedelem,
                                                "$model",
                                                $$c
                                              )
                                            }
                                          }
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "label",
                                        { attrs: { for: "adatvedelem" } },
                                        [
                                          _vm._v(
                                            "Elolvastam és elfogadom az\n                                            "
                                          ),
                                          _c(
                                            "router-link",
                                            {
                                              staticStyle: {
                                                "font-size": "1rem",
                                                color: "#c1132c"
                                              },
                                              attrs: {
                                                to: "/adatvedelem",
                                                target: "_blank"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                                                Adatvédelmi irányelveket."
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ])
                                  ])
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "col-xl-12",
                                  staticStyle: {
                                    "padding-left": "15px",
                                    "font-size": "1rem"
                                  }
                                },
                                [
                                  _c("div", [
                                    _c("p", [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.$v.aszf.$model,
                                            expression: "$v.aszf.$model"
                                          }
                                        ],
                                        attrs: {
                                          type: "checkbox",
                                          id: "aszf",
                                          name: "group-elfogad"
                                        },
                                        domProps: {
                                          checked: Array.isArray(
                                            _vm.$v.aszf.$model
                                          )
                                            ? _vm._i(_vm.$v.aszf.$model, null) >
                                              -1
                                            : _vm.$v.aszf.$model
                                        },
                                        on: {
                                          change: function($event) {
                                            var $$a = _vm.$v.aszf.$model,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? true : false
                                            if (Array.isArray($$a)) {
                                              var $$v = null,
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  _vm.$set(
                                                    _vm.$v.aszf,
                                                    "$model",
                                                    $$a.concat([$$v])
                                                  )
                                              } else {
                                                $$i > -1 &&
                                                  _vm.$set(
                                                    _vm.$v.aszf,
                                                    "$model",
                                                    $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      )
                                                  )
                                              }
                                            } else {
                                              _vm.$set(
                                                _vm.$v.aszf,
                                                "$model",
                                                $$c
                                              )
                                            }
                                          }
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "label",
                                        { attrs: { for: "aszf" } },
                                        [
                                          _vm._v(
                                            "Elolvastam és elfogadom az\n                                            "
                                          ),
                                          _c(
                                            "router-link",
                                            {
                                              staticStyle: {
                                                "font-size": "1rem",
                                                color: "#c1132c"
                                              },
                                              attrs: {
                                                to: "/aszf",
                                                target: "_blank"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                                                Általános szerződési feltételeket."
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ])
                                  ]),
                                  _vm._v(" "),
                                  !_vm.$v.adatvedelem.sameAs
                                    ? _c("div", { staticClass: "error" }, [
                                        _vm._v(
                                          "Adatvédelmi irányelvek elfogadása!"
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  !_vm.$v.aszf.sameAs
                                    ? _c("div", { staticClass: "error" }, [
                                        _vm._v(
                                          "Általános szerződési feltételek elfogadása!"
                                        )
                                      ])
                                    : _vm._e()
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "col",
                                  staticStyle: { "margin-top": "50px" }
                                },
                                [
                                  !_vm.has_gift_card
                                    ? _c(
                                        "button",
                                        {
                                          staticClass: "btn active shadow-none",
                                          attrs: {
                                            type: "submit",
                                            disabled:
                                              _vm.$v.$invalid || _vm.send
                                          },
                                          on: { click: _vm.summary }
                                        },
                                        [_vm._v("FIZETEK")]
                                      )
                                    : _c(
                                        "button",
                                        {
                                          staticClass: "btn active shadow-none",
                                          attrs: {
                                            type: "submit",
                                            disabled:
                                              _vm.$v.$invalid || _vm.send
                                          },
                                          on: { click: _vm.completeGift }
                                        },
                                        [_vm._v("LEFOGLALOM")]
                                      )
                                ]
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    2
                  )
                ],
                2
              )
            ]
          )
        : _c(
            "div",
            {
              staticClass: "container-md",
              staticStyle: {
                "margin-top": "99px",
                "margin-bottom": "100px",
                "font-family": "Montserrat, sans-serif"
              }
            },
            [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col" }, [
                  _vm._m(3),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      {
                        staticClass: "col-12",
                        attrs: { id: "confirm-package" }
                      },
                      [
                        _c(
                          "p",
                          {
                            staticClass: "font-weight-bold",
                            attrs: { id: "confirm-package--name" }
                          },
                          [_vm._v(_vm._s(_vm.getPackage.name))]
                        ),
                        _vm._v(" "),
                        _c(
                          "p",
                          {
                            staticClass: "font-size-14",
                            attrs: { id: "confirm-package--shortTitle" }
                          },
                          [_vm._v(_vm._s(_vm.getPackage.short_name))]
                        ),
                        _vm._v(" "),
                        _c("p", {
                          staticClass: "font-size-14",
                          attrs: { id: "confirm-package--features" },
                          domProps: {
                            innerHTML: _vm._s(_vm.getPackage.description)
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "p",
                          {
                            staticClass: "font-size-14",
                            attrs: { id: "confirm-package--addons" }
                          },
                          [
                            _vm._l(_vm.data.extras, function(extra) {
                              return [
                                _vm._v(
                                  _vm._s(extra.name) +
                                    " (+" +
                                    _vm._s(_vm.formatPrice(extra.price)) +
                                    " Ft), "
                                )
                              ]
                            })
                          ],
                          2
                        )
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("hr"),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _vm._m(4),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "col-12",
                        attrs: { id: "confirm-isGift" }
                      },
                      [_vm._v(_vm._s(_vm.data.is_gift ? "Igen" : "Nem"))]
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "row",
                      attrs: { id: "confirm-discussLater-row" }
                    },
                    [
                      _vm._m(5),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "col-12",
                          attrs: { id: "confirm-discussLater" }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.data.is_late_appointment_and_location
                                ? "Igen"
                                : "Nem"
                            )
                          )
                        ]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "row",
                      attrs: { id: "confirm-location-row" }
                    },
                    [
                      _vm._m(6),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "col-12",
                          attrs: { id: "confirm-location" }
                        },
                        [
                          _vm.data.charger_location !== "none"
                            ? [
                                _vm._v(
                                  _vm._s(
                                    _vm.charger_locations[
                                      _vm.data.charger_location
                                    ].location.name
                                  ) +
                                    ", " +
                                    _vm._s(
                                      _vm.charger_locations[
                                        _vm.data.charger_location
                                      ].address
                                    )
                                )
                              ]
                            : [_vm._v("Nincs megnevezve")]
                        ],
                        2
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "row",
                      attrs: { id: "confirm-dateReservation-row" }
                    },
                    [
                      _vm._m(7),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "col-12",
                          attrs: { id: "confirm-dateReservation" }
                        },
                        [
                          _vm.data.reservation_date !== "none" &&
                          _vm.data.reservation_duration !== "none"
                            ? [
                                _vm._v(
                                  _vm._s(_vm.data.reservation_date) +
                                    " " +
                                    _vm._s(_vm.data.reservation_duration)
                                )
                              ]
                            : [_vm._v("Nincs megnevezve")]
                        ],
                        2
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("hr"),
                  _vm._v(" "),
                  _vm._m(8),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _vm._m(9),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-12", attrs: { id: "confirm-name" } },
                      [
                        _vm._v(
                          _vm._s(_vm.data.payer_last_name) +
                            " " +
                            _vm._s(_vm.data.payer_first_name)
                        )
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _vm._m(10),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-12", attrs: { id: "confirm-phone" } },
                      [_vm._v("+36 " + _vm._s(_vm.data.payer_phone))]
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _vm._m(11),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-12", attrs: { id: "confirm-email" } },
                      [_vm._v(_vm._s(_vm.data.payer_email))]
                    )
                  ])
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col", staticStyle: { "margin-top": "73px" } },
                  [
                    _c("div", { staticClass: "container" }, [
                      _vm._m(12),
                      _vm._v(" "),
                      _c("div", { staticClass: "row" }, [
                        _vm._m(13),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "col-12",
                            attrs: { id: "confirm-bill_name" }
                          },
                          [_vm._v(_vm._s(_vm.data.bill_name))]
                        )
                      ]),
                      _vm._v(" "),
                      _vm.data.bill_vat_number.length > 0
                        ? _c("div", { staticClass: "row" }, [
                            _vm._m(14),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "col-12",
                                attrs: { id: "confirm-bill_vat_number" }
                              },
                              [_vm._v(_vm._s(_vm.data.bill_vat_number))]
                            )
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("div", { staticClass: "row" }, [
                        _vm._m(15),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "col-12",
                            attrs: { id: "confirm-address" }
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.data.bill_post_code) +
                                " " +
                                _vm._s(_vm.data.bill_city) +
                                " " +
                                _vm._s(_vm.data.bill_address)
                            )
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _c("hr"),
                      _vm._v(" "),
                      _vm._m(16),
                      _vm._v(" "),
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-12" }, [
                          _c("span", { attrs: { id: "confirm-total" } }),
                          _vm._v(" ("),
                          _c(
                            "span",
                            { attrs: { id: "confirm-paymentMethod" } },
                            [_vm._v(_vm._s(_vm.formatPrice(_vm.calPrice)))]
                          ),
                          _vm._v(" Ft)")
                        ])
                      ])
                    ])
                  ]
                )
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "row", staticStyle: { "margin-top": "30px" } },
                [
                  _c("div", { staticClass: "col-6" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn active shadow-none",
                        attrs: { id: "cancel-order" },
                        on: {
                          click: function($event) {
                            _vm.show_summary = false
                          }
                        }
                      },
                      [_vm._v("Mégsem")]
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-6 text-right" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn active shadow-none",
                        attrs: {
                          id: "accept-order",
                          type: "submit",
                          disabled: _vm.send
                        },
                        on: { click: _vm.submit }
                      },
                      [_vm._v("megrendelés beküldése")]
                    )
                  ])
                ]
              )
            ]
          )
    ]),
    _vm._v(" "),
    _c("div", { staticStyle: { "padding-top": "90px" } }, [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "row" }, [
          _vm._m(17),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "col-md-6",
              staticStyle: {
                "border-left": "1px solid black",
                "margin-bottom": "60px",
                "padding-right": "0px"
              }
            },
            [
              _vm._m(18),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticStyle: {
                    "font-family": "Montserrat, sans-serif",
                    "font-size": "15px",
                    "font-weight": "500"
                  }
                },
                [
                  _vm._v(
                    "\n                            A megadott adatokat a hatályos GDPR törvénynek megfelelően,\n                            biztonságos szerveren, titkosítva tároljuk, azt harmadik félnek\n                            nem adjuk ki. A szolgáltatás igénybevételét követően kérheted\n                            adataid törlését is emailben. Adatvédelmi irányelveinket \n                            "
                  ),
                  _c(
                    "router-link",
                    {
                      staticStyle: { color: "#c1132c" },
                      attrs: { to: "/adatvedelem", target: "_blank" }
                    },
                    [_c("strong", [_vm._v("itt")])]
                  ),
                  _vm._v(
                    "\n                            és a láblécben találod.\n                        "
                  )
                ],
                1
              )
            ]
          )
        ]),
        _vm._v(" "),
        _vm._m(19),
        _vm._v(" "),
        _vm._m(20)
      ])
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass:
          "row justify-content-sm-center justify-content-md-center col-12",
        staticStyle: {
          "padding-left": "0px",
          "padding-right": "15px",
          "margin-top": "80px",
          "margin-right": "0px",
          "margin-left": "0px"
        }
      },
      [
        _vm._m(21),
        _vm._v(" "),
        _c("div", {
          staticClass: "col-xl-1",
          staticStyle: { "padding-right": "0px", "padding-left": "0px" }
        }),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "col-xl-4 text-sm-center text-md-center text-lg-center text-xl-left align-self-center",
            staticStyle: {
              "padding-left": "0px",
              "padding-right": "0px",
              "margin-left": "0px"
            }
          },
          [
            _vm._m(22),
            _vm._v(" "),
            _c(
              "h3",
              {
                staticClass:
                  "text-uppercase text-sm-center text-md-center text-lg-center text-xl-left",
                staticStyle: {
                  "font-size": "1.75rem",
                  "font-family": "Montserrat, sans-serif",
                  "font-weight": "700",
                  "margin-bottom": "80px"
                },
                attrs: { id: "team_h3" }
              },
              [
                _vm._v(
                  "\n                    programot szervezel?\n                "
                )
              ]
            ),
            _vm._v(" "),
            _vm._m(23),
            _vm._v(" "),
            _c("p"),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "btn text-uppercase",
                staticStyle: {
                  "background-color": "rgba(255,255,255,0)",
                  color: "rgb(0,0,0)",
                  "font-size": "1.5rem",
                  "font-family": "Montserrat, sans-serif",
                  "margin-right": "0px",
                  "padding-right": "15px",
                  "padding-top": "35px",
                  "margin-top": "60px"
                },
                attrs: { role: "button", href: _vm.goToH("company") }
              },
              [
                _c("strong", [_vm._v("tovább")]),
                _c("img", {
                  staticStyle: { "padding-left": "35px" },
                  attrs: { src: require("assets/img/btn-more.png") }
                })
              ]
            )
          ]
        )
      ]
    ),
    _vm._v(" "),
    _c("div", { staticStyle: { "padding-top": "90px" } }, [
      _c(
        "div",
        { staticClass: "container", staticStyle: { "margin-top": "60px" } },
        [
          _vm._m(24),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _vm._m(25),
            _vm._v(" "),
            _c("div", { staticClass: "col-md-6" }, [_c("contact-form")], 1)
          ])
        ]
      )
    ]),
    _vm._v(" "),
    _vm._m(26),
    _vm._v(" "),
    _vm._m(27),
    _vm._v(" "),
    _vm._m(28)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("header", [
      _c("div", [
        _c("h1", [_vm._v("Időpontfoglalás")]),
        _vm._v(" "),
        _c("h2", [_vm._v("Válassz csomagot!")]),
        _vm._v(" "),
        _c("p", [
          _vm._v("Válaszd ki a neked tetsző helyszínt"),
          _c("br"),
          _vm._v("a megfelelő időponttal!")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "h4",
      {
        staticClass: "text-uppercase",
        staticStyle: { "font-size": "2rem", "font-weight": "400" }
      },
      [_vm._v("\n                                Kinek?"), _c("br")]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "h4",
      {
        staticClass: "text-uppercase",
        staticStyle: {
          "font-size": "2rem",
          "font-weight": "400",
          "margin-bottom": "15px",
          "margin-top": "0px"
        }
      },
      [
        _vm._v("\n                                számlázás és fizetés?"),
        _c("br")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-12" }, [
      _c("h4", [_c("strong", [_vm._v("Választott csomag")])])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-12" }, [
      _c("strong", [_vm._v("Ajándékba:")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-12" }, [
      _c("strong", [_vm._v("Későbbi időpont és helyszín egyezetéssel!")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-12" }, [
      _c("strong", [_vm._v("Helyszín:")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-12" }, [
      _c("strong", [_vm._v("Időpont:")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("h4", [_c("strong", [_vm._v("Megrendelő adatai")])])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-12" }, [
      _c("strong", [_vm._v("Név:")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-12" }, [
      _c("strong", [_vm._v("Telefonszám:")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-12" }, [
      _c("strong", [_vm._v("E-mail cím:")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("h4", [_c("strong", [_vm._v("Számlázás és fizetés")])])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-12" }, [
      _c("strong", [_vm._v("Név, cégnév:")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-12" }, [
      _c("strong", [_vm._v("Adószám:")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-12" }, [
      _c("strong", [_vm._v("Cím:")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("h4", [_c("strong", [_vm._v("Fizetendő összeg:")])])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "col-md-6",
        staticStyle: { "font-family": "Montserrat, sans-serif" }
      },
      [
        _c(
          "h3",
          {
            staticClass: "text-uppercase",
            staticStyle: {
              "padding-bottom": "20px",
              "font-family": "Montserrat, sans-serif",
              "font-size": "1.5rem"
            }
          },
          [_c("strong", [_vm._v("A fizetést követően")])]
        ),
        _vm._v(" "),
        _c(
          "ul",
          { staticStyle: { "font-size": "15px", "font-weight": "500" } },
          [
            _c("li", [_vm._v("számlát küldünk a megadott email címre")]),
            _vm._v(" "),
            _c("li", [
              _vm._v(
                "\n                                telefonon, vagy emailben egyeztetjük az időpontot és helyszínt\n                            "
              )
            ]),
            _vm._v(" "),
            _c("li", [
              _vm._v("testben és lélekben felkészülsz a TeslaPilóta élményre")
            ])
          ]
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "h3",
      {
        staticClass: "text-uppercase",
        staticStyle: {
          "padding-bottom": "20px",
          "font-family": "Montserrat, sans-serif",
          "font-size": "1.5rem"
        }
      },
      [_c("strong", [_vm._v("Adataid biztonsága")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-6" }, [
        _c(
          "h3",
          {
            staticClass: "text-uppercase",
            staticStyle: {
              "font-family": "Montserrat, sans-serif",
              "font-size": "1.5rem",
              "padding-bottom": "20px"
            }
          },
          [_c("strong", [_vm._v("Pénzvisszafizetési garancia")])]
        ),
        _vm._v(" "),
        _c(
          "p",
          {
            staticStyle: {
              "font-family": "Montserrat, sans-serif",
              "font-size": "15px",
              "font-weight": "500"
            }
          },
          [
            _vm._v(
              "\n                            Amennyiben úgy érzed, szolgáltatásunk vagy az élmény nem hozta az\n                            elvárható színvonalat, küldj egy emailt nekünk és a\n                            pénzvisszafizetési garanciánk alapján a teljes összeget\n                            visszautaljuk 5 munkanapon belül.\n                        "
            )
          ]
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "col-md-6",
          staticStyle: {
            "border-left": "1px solid black",
            "margin-bottom": "60px",
            "padding-right": "0px"
          }
        },
        [
          _c(
            "h3",
            {
              staticClass: "text-uppercase",
              staticStyle: {
                "font-family": "Montserrat, sans-serif",
                "font-size": "1.5rem",
                "padding-bottom": "20px"
              }
            },
            [
              _c("strong", [_vm._v("A bankkártyás fizetés")]),
              _c("br"),
              _c("strong", [_vm._v("a legnagyobb biztonságban")])
            ]
          ),
          _vm._v(" "),
          _c(
            "p",
            {
              staticStyle: {
                "font-family": "Montserrat, sans-serif",
                "font-size": "0.9rem",
                "font-weight": "500"
              }
            },
            [
              _vm._v(
                "\n                            A bankkártyás fizetéshez az OTP Simplepay rendszerét használjuk,\n                            ez"
              ),
              _c("br"),
              _vm._v(
                "neked is garanciát jelent a biztonságos fizetéshez. Itt\n                            olvashatsz a"
              ),
              _c("br"),
              _vm._v("rendszerükről: "),
              _c(
                "a",
                {
                  staticStyle: { color: "#c1132c", "font-size": "0.9rem" },
                  attrs: {
                    href: "https://simplepay.hu/vasarloknak",
                    target: "_blank"
                  }
                },
                [_c("strong", [_vm._v("https://simplepay.hu/vasarloknak")])]
              ),
              _vm._v(" és az ügyfélszol-"),
              _c("br"),
              _vm._v("gálatukat itt éred el\n                            "),
              _c(
                "a",
                {
                  staticStyle: { color: "#c1132c", "font-size": "0.9rem" },
                  attrs: { href: "tel:0613666611" }
                },
                [_c("strong", [_vm._v("Tel: 06 1 3666 611,")])]
              ),
              _vm._v(" \n                            "),
              _c(
                "a",
                {
                  staticStyle: { color: "#c1132c", "font-size": "0.9rem" },
                  attrs: { href: "mailto:ugyfelszolgalat@simple.hu" }
                },
                [_c("strong", [_vm._v("ugyfelszolgalat@simple.hu")])]
              )
            ]
          )
        ]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c(
        "div",
        {
          staticClass: "col-md-6",
          staticStyle: { "border-right": "1px solid black" }
        },
        [
          _c(
            "h3",
            {
              staticClass: "text-uppercase",
              staticStyle: {
                "font-family": "Montserrat, sans-serif",
                "font-size": "1.5rem",
                "padding-bottom": "20px"
              }
            },
            [_c("strong", [_vm._v("eltérő helyszín")])]
          ),
          _vm._v(" "),
          _c(
            "p",
            {
              staticStyle: {
                "font-family": "Montserrat, sans-serif",
                "font-weight": "500"
              }
            },
            [
              _vm._v(
                "\n                            Ez csak rendezvény (pl. csapatépítő) esetén jöhet szóba, de akkor\n                            is töltő közelébe kell szervezni az ilyen alkalmakat.\n                        "
              )
            ]
          )
        ]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "col-md-6 col-xl-7 text-sm-center text-md-center align-self-center",
        staticStyle: {
          "margin-left": "0px",
          "padding-right": "0px",
          "padding-left": "0px"
        }
      },
      [
        _c("img", {
          staticClass: "d-inline-flex",
          staticStyle: { "margin-left": "0px", width: "100%" },
          attrs: { src: require("assets/img/layer21.png") }
        })
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "h1",
      {
        staticClass:
          "text-uppercase text-sm-center text-md-center text-lg-center text-xl-left justify-content-xl-center align-items-xl-end",
        staticStyle: {
          "font-size": "3.3rem",
          "font-family": "Montserrat, sans-serif",
          "font-weight": "800",
          "margin-bottom": "0px",
          "margin-right": "0px"
        },
        attrs: { id: "team_h1" }
      },
      [_vm._v("\n                    Csapatépítő"), _c("br")]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "h5",
      {
        staticClass:
          "text-sm-center text-md-center text-lg-center text-xl-left",
        staticStyle: {
          "font-size": "1.2rem",
          "font-family": "Montserrat, sans-serif",
          "font-weight": "700",
          "margin-bottom": "30px"
        },
        attrs: { id: "team_h5" }
      },
      [
        _vm._v("\n                    A TeslaPilóta programja garantáltan"),
        _c("br"),
        _vm._v(
          "szórakoztató élmény lesz a\n                    csapat tagjainak.\n                "
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col" }, [_c("h1")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-6" }, [
      _c(
        "h1",
        {
          staticClass: "text-uppercase",
          staticStyle: { "margin-bottom": "0px" }
        },
        [_c("strong", [_vm._v("Üzenetküldés")])]
      ),
      _vm._v(" "),
      _c("h3", { staticClass: "text-uppercase" }, [_vm._v("A Teslapilótának")]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "\n                            Ha kérésed vagy kérdésed lenne, akkor küldj üzenetet a Teslapilóta\n                            irányítóközpontba! Add meg adataidat, hogy felvehessük veled a\n                            kapcsolatot.\n                        "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "text-right", staticStyle: { "margin-bottom": "90px" } },
      [
        _c("img", {
          staticClass: "center",
          staticStyle: { "padding-top": "8rem" },
          attrs: { src: require("assets/img/img-header-logo-small-gray.png") }
        })
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "d-none d-xl-block" }, [
      _c("div", { staticClass: "text-uppercase" }, [
        _c("div", { staticClass: "container" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-md-4 col-xl-10 offset-xl-2" }, [
              _c("h1", { staticClass: "text-uppercase text-left" }, [
                _c("strong", [_vm._v("KÖVESS")])
              ])
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", [
        _c("div", { staticClass: "container" }, [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              {
                staticClass:
                  "col-md-4 col-xl-2 offset-xl-5 text-right align-self-center"
              },
              [
                _c(
                  "a",
                  { attrs: { href: "http://www.facebook.com/teslapilota" } },
                  [
                    _c("img", {
                      staticStyle: { "padding-right": "1.875rem" },
                      attrs: { src: require("assets/img/btn-facebook.png") }
                    })
                  ]
                ),
                _c(
                  "a",
                  { attrs: { href: "http://www.instagram.com/teslapilota" } },
                  [
                    _c("img", {
                      attrs: { src: require("assets/img/btn-instagram.png") }
                    })
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "col-md-4 col-xl-4 offset-xl-0" }, [
              _c("h1", { staticClass: "text-uppercase text-right" }, [
                _c("strong", [_vm._v("MINKET")])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "clearfix" })
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mobile d-block d-xl-none" }, [
      _vm._v("\n            Kövess minket\n            "),
      _c("div", [
        _c(
          "a",
          {
            attrs: {
              href: "https://www.facebook.com/TeslaPilota/",
              target: "_blank"
            }
          },
          [
            _c("img", {
              attrs: { src: require("assets/img/btn-facebook.png") }
            })
          ]
        ),
        _vm._v(" "),
        _c(
          "a",
          {
            attrs: {
              href: "https://www.instagram.com/teslapilota/",
              target: "_blank"
            }
          },
          [
            _c("img", {
              attrs: { src: require("assets/img/btn-instagram.png") }
            })
          ]
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
import axios from "axios";

export default {

    get_calendar() {
        return new Promise((resolve, reject) => {
            axios
                .get('get-calendar')
                .then(({ data, status }) => {
                    if (status === 200) {
                        resolve(data);
                    }
                }).catch(error => {
                    reject(error);
                });
        });
    },

    get_extras() {
        return new Promise((resolve, reject) => {
            axios
                .get('get-extras')
                .then(({ data, status }) => {
                    if (status === 200) {
                        resolve(data);
                    }
                }).catch(error => {
                    reject(error);
                });
        });
    },

    get_packages(distributorUniqueId = null) {
        return new Promise((resolve, reject) => {
            axios
                .post('get-packages', {distributorUniqueId: distributorUniqueId})
                .then(({ data, status }) => {
                    if (status === 200) {
                        resolve(data);
                    }
                }).catch(error => {
                    reject(error);
                });
        });
    },

    get_charger_locations(date, distributing = false) {
        return new Promise((resolve, reject) => {
            axios
                .post('get-charger-locations', { date: date, distributing: distributing })
                .then(({ data, status }) => {
                    if (status === 200) {
                        resolve(data);
                    }
                }).catch(error => {
                    reject(error);
                });
        });
    },

    get_dates() {
        return new Promise((resolve, reject) => {
            axios
                .post('get-all-available-dates')
                .then(({ data, status }) => {
                    if (status === 200) {
                        resolve(data);
                    }
                }).catch(error => {
                    reject(error);
                });
        });
    },

    get_slots(packageId, chargerLocationId, date) {
        return new Promise((resolve, reject) => {
            axios
                .post('get-all-available-slots', { package_id: packageId, charger_location_id: chargerLocationId, date: date })
                .then(({ data, status }) => {
                    if (status === 200) {
                        resolve(data);
                    }
                }).catch(error => {
                    reject(error);
                });
        });
    },

    process_reservation(data) {
        return new Promise((resolve, reject) => {
            axios
                .post('process-reservation', data)
                .then(({ data, status }) => {
                    if (status === 200) {
                        resolve(data);
                    }
                }).catch(error => {
                    reject(error);
                });
        });
    },

    process_payment(uniqueId, data) {
        return new Promise((resolve, reject) => {
            axios
                .post('process-payment/' + uniqueId, data)
                .then(({ data, status }) => {
                    if (status === 200) {
                        resolve(data);
                    }
                }).catch(error => {
                    reject(error);
                });
        });
    },

    payment_status(uniqueId, data) {
        return new Promise((resolve, reject) => {
            axios
                .post('payment-status/' + uniqueId, data)
                .then(({ data, status }) => {
                    if (status === 200) {
                        resolve(data);
                    }
                }).catch(error => {
                    reject(error);
                });
        });
    },

    pay(uniqueId) {
        return new Promise((resolve, reject) => {
            axios
                .get('pay/' + uniqueId)
                .then(({ data, status }) => {
                    if (status === 200) {
                        resolve(data);
                    }
                }).catch(error => {
                    reject(error);
                });
        });
    },

    send_gift_code(giftCode) {
        return new Promise((resolve, reject) => {
            axios
                .get('send-gift-code/' + giftCode)
                .then(({ data, status }) => {
                    if (status === 200) {
                        resolve(data);
                    }
                }).catch(error => {
                    reject(error);
                });
        });
    },

    complete_gift(data) {
        return new Promise((resolve, reject) => {
            axios
                .post('complete-gift', data)
                .then(({ data, status }) => {
                    if (status === 200) {
                        resolve(data);
                    }
                }).catch(error => {
                    reject(error);
                });
        });
    },

    contact(data) {
        return new Promise((resolve, reject) => {
            axios
                .post('contact', data)
                .then(({data, status}) => {
                    if (status === 200) {
                        resolve(data);
                    }
                }).catch(error => {
                    reject(error);
                });
        });
    }
}

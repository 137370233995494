<template>
  <div id="app">
    <router-view></router-view>
    <footer>
      <div class="footer">
        <div class="content-container">
          <div class="container">
            <div class="row">
              <div class="col-12 col-xl-5">
                <div class="row">
                  <div class="col-12 col-xl-6">
                    <p class="title">Teslapilóta</p>
                    <p class="description">
                      A TeslaPilóta életed élménye lesz, ha kipróbálod! A Tesla
                      csúcsgépe, a P100D rád vár, hogy megzabolázd a 772 lóerőt,
                      teszteld a pokoli gyorsulását, vagy kapcsold be az
                      autopilot módot és engedd el a kormányt!
                      <strong>Vigyázat! Erősen addiktív élmény. :)</strong>
                    </p>
                  </div>
                  <div class="col-12 col-xl-6">
                    <p class="title">Kapcsolat</p>
                    <p class="description">
                      <span class="ico--phone"
                        >+36 30 222 3005 <br />
                        Hétfőtől péntekig: 9-19 óráig
                      </span>
                      <span class="ico--email">hello@teslapilota.hu</span
                      >
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-12 col-xl-7">
                <div class="menu">
                  <ul>
                    <li><a :href="goTo('aszf')">ÁSZF</a></li>
                    <li><a :href="goTo('adatvedelem')">Adatvédelem</a></li>
                    <!--<li><a :href="goTo('gyik')">Gyakori kérdések</a></li>-->
                    <!--<li><a :href="goTo('impresszum')">Impresszum</a></li>-->
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="copyright-container d-flex align-items-center justify-content-center">
          <div>
            <span class="text-uppercase">Teslapilóta</span> Copyright © 2020
          </div>
        </div>
      </div>
      <cookie-law theme="tesla">
        <div slot-scope="props">
          Oldalunk a TeslaÉlmény fokozása érdekében sütiket használ. További információkért olvassa el
          <a :href="goTo('adatvedelem')">Adatvédelmi irányelveinket</a>.
          <button class="accept-cookies" @click="props.accept"><span>Elfogadom</span></button>
        </div>
      </cookie-law>
    </footer>
  </div>
</template>

<script>
  import CookieLaw from 'vue-cookie-law';
  import router from "assets/js/plugins/router";

  export default {
    name: "app",
    components: { CookieLaw },
    methods: {
      goTo: function(name)
      {
        return router.resolve({ name: name }).href;
      }
    }
  };
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css?family=Montserrat|Raleway&display=swap");
@import url("https://maxcdn.icons8.com/fonts/line-awesome/1.1/css/line-awesome.min.css");
@import url("https://cdnjs.cloudflare.com/ajax/libs/simple-line-icons/2.4.1/css/simple-line-icons.min.css");

footer {
  width: 100%;
}

body {
  font-family: Montserrat;
  font-size: 18px;
  color: #2b2b2b;
}

body a {
  color: #2b2b2b;
}

.body .reservation-process-details-container .col-3:first-child, .footer ul li:first-child {
  padding-left: 0;
}

.ico--email, .ico--phone {
  display: block;
  position: relative;
  padding-left: 50px;
  margin-bottom: 25px;
}

.ico--email:before, .ico--phone:before {
  content: "";
  display: inline-block;
  width: 25px;
  height: 25px;
  top: 50%;
  left: 0;
  position: absolute;
  transform: translateY(-50%);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.ico--phone:before {
  background-image: url('~assets/img/ico-phone.png');
}

.ico--email:before {
  background-image: url('~assets/img/ico-email.png');
}

.footer {
  width: 100%;
}

.footer a {
  color: #fff;
}

.footer ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  font-size: 12px;
  list-style: none;
  padding: 0;
}

@media only screen and (min-width: 1200px) {
  .footer ul {
    display: initial;
    flex-wrap: initial;
    justify-content: initial;
    font-size: 18px;
  }
}

.footer ul li {
  margin-bottom: 5px;
  display: inline-block;
  border-right: 1px solid #fff;
  padding: 0 25px;
}

@media only screen and (min-width: 1200px) {
  .footer ul li {
    margin-bottom: initial;
  }
}

.footer ul li:last-child {
  border-right: none;
  padding-right: 0;
}

.footer .content-container, .footer .copyright-container {
  font-size: 12px;
  position: relative;
  width: 100%;
  color: #fff;
}

@media only screen and (min-width: 1200px) {
  .footer .content-container, .footer .copyright-container {
    font-size: 16px;
  }
}

.footer .content-container {
  padding: 85px 0;
  background: #363334;
}

.footer .content-container .title {
  margin-bottom: 10px;
  font-size: 20px;
  text-transform: uppercase;
}

@media only screen and (min-width: 1200px) {
  .footer .content-container .title {
    margin-bottom: 50px;
  }
}

.footer .content-container .description {
  margin-bottom: 40px;
}

@media only screen and (min-width: 1200px) {
  .footer .content-container .description {
    margin-bottom: initial;
  }
}

.footer .content-container .ico--phone {
  margin-bottom: 5px;
}

@media only screen and (min-width: 1200px) {
  .footer .content-container .ico--phone {
    margin-bottom: 25px;
  }
}

@media only screen and (min-width: 1200px) {
  .footer .content-container .ico--phone:before {
    top: 5px;
    transform: initial;
  }
}

@media only screen and (min-width: 1200px) {
  .footer .content-container .menu {
    position: absolute;
    bottom: 0;
    right: 0;
  }
}

.footer .copyright-container {
  height: 90px;
  background: #2b2b2b;
}

.Cookie--tesla {
  background: hsla(0, 0%, 44%, 0.8);
  color: #ffffff;
  margin: 0 !important;
  height: 60px;
  justify-content: center;
}
.Cookie--tesla a {
  text-decoration: underline;
  color: #c1132c;
  font-weight: 500;
}
.Cookie--tesla .accept-cookies {
  background-image: url('~assets/img/btn-reservation-red.png');
  background-repeat: no-repeat;
  background-size: cover;
  width: 145px;
  height: 34px;
  font-weight: 600;
  color: white;
  margin-left: 10px;
  vertical-align: baseline;
  font-size: 1rem;
  border-style: none;
  border-radius: 5px;
}
/*.Cookie--custom div.Cookie__button {}*/
/*.Cookie--custom div.Cookie__button:hover {}*/
</style>

<template>
  <div class="adatvedelem">
    <div class="adatvedelem-header">
      <top-nav-bar />
      <header>
        <h1>ADATVÉDELEM</h1>
      </header>
    </div>
    <div class="container" style="margin-top: 100px;">
      <div class="row">
        <div class="col">
          <p
            class="text-center"
            style="font-family: Montserrat, sans-serif;font-size: 2rem;font-weight: 600;margin-bottom: 90px;margin-top: 50px;"
          >
            Teslapilota.hu<br />
          </p>
          <h1
            class="text-uppercase text-center"
            style="font-size: 4rem;font-family: Montserrat, sans-serif;font-weight: 600;margin-bottom: 300px;"
          >
            Adatvédelmi<br />szabályzat
          </h1>
          <h2 class="text-uppercase" style="margin-bottom: 30px;">
            <strong>1. </strong>Bevezetés
          </h2>
          <p class="text-justify">
            A
            <strong
              >Brazil Reklámügynökség Korlátolt Felelősségű Társaság</strong
            >
            (cégjegyzékszám: 01-09-703588, adószám: 12791022-2-41, székhely:
            1014 Budapest, Országház utca 22. 1.em. 2.) (a továbbiakban:
            Szolgáltató, adatkezelő) alá veti magát a következőszabályzatnak:
            <br />
          </p>

          <p class="text-justify">
            A természetes személyeknek a személyes adatok kezelése tekintetében
            történő védelméről és az ilyen adatok szabad áramlásáról, valamint a
            95/46/EK rendelet hatályon kívül helyezéséről (általános adatvédelmi
            rendelet) AZ EURÓPAI PARLAMENT ÉS A TANÁCS (EU) 2016/679 RENDELETE
            (2016. április 27.) szerint az alábbi tájékoztatást adjuk. <br />
          </p>

          <p>
            Jelen adatvédelmi szabályzat az alábbi oldalak adatkezelését
            szabályozza: <br />
          </p>
          <p>
            <a href="teslapilota.hu" style="color: #c1132c;font-weight: 500;"
              >teslapilota.hu</a
            >
          </p>
          <p>
            <a href="teslapilóta.hu" style="color: #c1132c;font-weight: 500;"
              >teslapilóta.hu</a
            >
          </p>
          <p>
            <a href="elmenytesla.hu" style="color: #c1132c;font-weight: 500;"
              >elmenytesla.hu</a
            >
          </p>
          <p style="font-weight: 500;">
            <a href="élménytesla.hu" style="color: #c1132c;">élménytesla.hu</a>
          </p>
          <p>
            <a href="pilóta.hu" style="color: #c1132c;font-weight: 500;"
              >pilóta.hu</a
            >
          </p>
          <p>Az adatvédelmi szabályzat elérhető az alábbi oldalról: <br /></p>
          <p>
            <a
              href="http://www.teslapilota.hu/adatvedelem"
              style="color: #c1132c;font-weight: 500;"
              >http://teslapilota.hu/adatvedelem</a
            ><br />
          </p>
          <p>
            A szabályzat módosításai a fenti címen történő közzététellel lépnek
            hatályba. <br />
          </p>
          <h3 style="margin-top: 40px;margin-bottom: 40px;margin-left: 25px;">
            1.1. AZ ADATKEZELŐ ÉS ELÉRHETŐSÉGEI:<br />
          </h3>
          <p>
            Név:
            <strong
              >Brazil Reklámügynökség Korlátolt Felelősségű Társaság</strong
            ><br />Székhely: 1014 Budapest, Országház utca 22. 1. em. 2.<br />E-mail:&nbsp;<a
              href="mailto:hello@teslapilota.hu"
              style="color: #c1132c;font-weight: 500;"
              >hello@teslapilota.hu</a
            ><br />Telefon: <br />
          </p>

          <h2
            class="text-uppercase"
            style="margin-bottom: 30px;margin-top: 30px;"
          >
            <strong>2. </strong>FOGALOM MEGHATÁROZÁSOK<br />
          </h2>
          <p class="text-justify">
            <strong>1. </strong><em>„személyes adat”</em>: azonosított vagy
            azonosítható természetes személyre („érintett”) vonatkozó bármely
            információ; azonosítható az a természetes személy, aki közvetlen
            vagy közvetett módon, különösen valamely azonosító, például név,
            szám, helymeghatározó adat, online azonosító vagy a természetes
            személy testi, fiziológiai, genetikai, szellemi, gazdasági,
            kulturális vagy szociális azonosságára vonatkozó egy<br />vagy több
            tényező alapján azonosítható; <br />
          </p>

          <p class="text-justify">
            <strong>2.</strong> <em>„adatkezelés”</em>: a személyes adatokon
            vagy adatállományokon automatizált vagy nem automatizált módon
            végzett bármely művelet vagy műveletek összessége, így a gyűjtés,
            rögzítés, rendszerezés, tagolás, tárolás, átalakítás vagy
            megváltoztatás, lekérdezés, betekintés, felhasználás, közlés
            továbbítás, terjesztés vagy egyéb módon történő hozzáférhetővé tétel
            útján, összehangolás vagy összekapcsolás, korlátozás, törlés,
            illetve megsemmisítés; <br />
          </p>

          <p class="text-justify">
            <strong>3.</strong> <em>„adatkezelő”</em>: az a természetes vagy
            jogi személy, közhatalmi szerv, ügynökség vagy bármely egyéb szerv,
            amely a személyes adatok kezelésének céljait és eszközeit önállóan
            vagy másokkal együtt meghatározza; ha az adatkezelés céljait és
            eszközeit az uniós vagy a tagállami jog határozza meg, az
            adatkezelőt vagy az adatkezelő kijelölésére vonatkozó különös
            szempontokat az uniós vagy a tagállami jog is meghatározhatja;
            <br />
          </p>

          <p class="text-justify">
            <strong>4.</strong> <em>„adatfeldolgozó”</em>: az a természetes vagy
            jogi személy, közhatalmi szerv, ügynökség vagy bármely egyéb szerv,
            amely az adatkezelő nevében személyes adatokat kezel; <br />
          </p>
          <p class="text-justify">
            <strong>5.</strong> <em>„címzett”</em>: az a természetes vagy jogi
            személy, közhatalmi szerv, ügynökség vagy bármely egyéb szerv,
            akivel vagy amellyel a személyes adatot közlik, függetlenül attól,
            hogy harmadik fél-e. Azon közhatalmi szervek, amelyek egy egyedi
            vizsgálat keretében az uniós vagy a tagállami joggal összhangban
            férhetnek hozzá személyes adatokhoz, nem minősülnek címzettnek; az
            említett adatok e közhatalmi szervek általi kezelése meg kell, hogy
            feleljen az adatkezelés céljainak megfelelően az alkalmazandó
            adatvédelmi szabályoknak; <br />
          </p>

          <p class="text-justify">
            <strong>6.</strong> <em>„az érintett hozzájárulása”</em>: az
            érintett akaratának önkéntes, konkrét és megfelelő tájékoztatáson
            alapuló és egyértelmű kinyilvánítása, amellyel az érintett
            nyilatkozat vagy a megerősítést félreérthetetlenül kifejező
            cselekedet útján jelzi, hogy beleegyezését adja az őt érintő
            személyes adatok kezeléséhez;<br />
          </p>

          <p class="text-justify">
            <strong>7.</strong> <em>„adatvédelmi incidens”</em>: a biztonság
            olyan sérülése, amely a továbbított, tárolt vagy más módon kezelt
            személyes adatok véletlen vagy jogellenes megsemmisítését,
            elvesztését, megváltoztatását, jogosulatlan közlését vagy az azokhoz
            való jogosulatlan hozzáférést eredményezi. <br />
          </p>

          <h2
            class="text-uppercase"
            style="margin-bottom: 30px;margin-top: 30px;"
          >
            <strong>3. </strong>A SZEMÉLYES ADATOK KEZELÉSÉRE VONATKOZÓ ELVEK<br />
          </h2>
          <p>A személyes adatok: <br /></p>
          <p class="text-justify" style="margin-left: 30px;">
            <strong>a)</strong> kezelését jogszerűen és tisztességesen, valamint
            az érintett számára átlátható módon kell végezni (<em
              >„jogszerűség, tisztességes eljárás és átláthatóság”</em
            >); <br />
          </p>
          <p class="text-justify" style="margin-left: 30px;">
            <strong>b)</strong> gyűjtése csak meghatározott, egyértelmű és
            jogszerű célból történjen, és azokat ne kezeljék ezekkel a célokkal
            össze nem egyeztethető módon; a 89. cikk (1) bekezdésének
            megfelelően nem minősül az eredeti céllal össze nem egyeztethetőnek
            a közérdekű archiválás céljából, tudományos és történelmi kutatási
            célból vagy statisztikai célból történő további adatkezelés (<em
              >„célhoz kötöttség”</em
            >); <br />
          </p>

          <p class="text-justify" style="margin-left: 30px;">
            <strong>c)</strong> az adatkezelés céljai szempontjából megfelelőek
            és relevánsak kell, hogy legyenek, és a szükségesre kell
            korlátozódniuk (<em>„adattakarékosság”</em>); <br />
          </p>
          <p class="text-justify" style="margin-left: 30px;">
            <strong>d)</strong> pontosnak és szükség esetén naprakésznek kell
            lenniük; minden észszerű intézkedést meg kell tenni annak érdekében,
            hogy az adatkezelés céljai szempontjából pontatlan személyes
            adatokat haladéktalanul töröljék vagy helyesbítsék
            (<em>„pontosság”</em>); <br />
          </p>

          <p class="text-justify" style="margin-left: 30px;">
            <strong>e)</strong> tárolásának olyan formában kell történnie, amely
            az érintettek azonosítását csak a személyes adatok kezelése
            céljainak eléréséhez szükséges ideig teszi lehetővé; a személyes
            adatok ennél hosszabb ideig történő tárolására csak akkor kerülhet
            sor, amennyiben a személyes adatok kezelésére a 89. cikk (1)
            bekezdésének megfelelően közérdekű archiválás céljából, tudományos
            és történelmi kutatási<br />célból vagy statisztikai célból kerül
            majd sor, az e rendeletben az érintettek jogainak és szabadságainak
            védelme érdekében előírt megfelelő technikai és szervezési
            intézkedések végrehajtására is figyelemmel (<em
              >„korlátozott tárolhatóság”</em
            >); <br />
          </p>

          <p class="text-justify" style="margin-left: 30px;">
            <strong>f)</strong> kezelését oly módon kell végezni, hogy megfelelő
            technikai vagy szervezési intézkedések alkalmazásával biztosítva
            legyen a személyes adatok megfelelő biztonsága, az adatok
            jogosulatlan vagy jogellenes kezelésével, véletlen elvesztésével,
            megsemmisítésével vagy károsodásával szembeni védelmet is ideértve
            (<em>„integritás és bizalmas jelleg”</em>). <br />
          </p>

          <p class="text-justify">
            Az adatkezelő felelős a fentiek megfelelésért, továbbá képesnek kell
            lennie e megfelelés igazolására (<em>„elszámoltathatóság”</em>).
            <br />
          </p>
          <p class="text-justify">
            Az adatkezelő nyilatkozik, hogy adatkezelése a jelen pontban foglalt
            alapelveknek megfelelően történik. <br />
          </p>
          <h2
            class="text-uppercase"
            style="margin-bottom: 30px;margin-top: 30px;"
          >
            4. EGYES ADATKEZELÉSEK<br />
          </h2>
          <h3 style="margin-top: 40px;margin-bottom: 40px;margin-left: 25px;">
            4.1. JELENTKEZÉS VEZETÉSRE<br />
          </h3>
          <p class="text-justify" style="margin-left: 30px;">
            <strong>1. </strong>Az adatgyűjtés ténye, a kezelt adatok köre és az
            <strong>adatkezelés céljai</strong>: <br />
          </p>
          <div
            class="table-responsive text-left"
            style="margin-bottom: 30px;margin-top: 30px;"
          >
            <table class="table table-striped">
              <thead>
                <tr>
                  <th>Személyes adat</th>
                  <th>Az adatkezelés célja<br /></th>
                  <th>Jogalap <br /></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Jelentkező neve,<br />megajándékozott neve <br /></td>
                  <td>Azonosítás, szerződés<br />létrehozása <br /></td>
                  <td>a GDPR 6. cikk (1) bekezdés b) pont.<br /></td>
                </tr>
                <tr>
                  <td>Telefonszám <br /></td>
                  <td>Kapcsolattartás, egyeztetés <br /></td>
                  <td>a GDPR 6. cikk (1) bekezdés b) pont.<br /></td>
                </tr>
                <tr>
                  <td>E-mail cím <br /></td>
                  <td>
                    Kapcsolattartás, az információk<br />megküldéséhez
                    szükséges. <br />
                  </td>
                  <td>a GDPR 6. cikk (1) bekezdés b) pont.<br /></td>
                </tr>
                <tr>
                  <td>Számlázási név és cím <br /></td>
                  <td>
                    A szabályszerű számla kiállítása,<br />továbbá a szerződés
                    létrehozása,<br />tartalmának meghatározása, módosítása,<br />teljesítésének
                    figyelemmel kísérése,<br />az abból származó díjak
                    számlázása,<br />valamint az azzal kapcsolatos
                    követelések<br />érvényesítése. <br />
                  </td>

                  <td>
                    6. cikk (1) bekezdés c) pontja, és a<br />számvitelről szóló
                    2000. évi C. törvény<br />169. § (2) bekezdése.<br />
                  </td>
                </tr>
                <tr>
                  <td>A jelentkezés időpontja <br /></td>
                  <td>Technikai művelet végrehajtása. <br /></td>
                  <td>a GDPR 6. cikk (1) bekezdés b) pont.<br /></td>
                </tr>
                <tr>
                  <td>A jelentkezéskori IP cím <br /></td>
                  <td>Technikai művelet végrehajtása.&nbsp;<br /></td>
                  <td>a GDPR 6. cikk (1) bekezdés b) pont.<br /></td>
                </tr>
              </tbody>
            </table>
          </div>
          <p class="text-justify" style="margin-left: 30px;">
            Az e-mail cím esetében nem szükséges, hogy személyes adatot
            tartalmazzon. <br />
          </p>
          <p class="text-justify" style="margin-left: 30px;">
            <strong>2.</strong> <strong>Az érintettek köre:</strong> A
            weboldalon vezetésre jelentkező, időpontot foglaló, a szolgáltatást
            megrendelő valamennyi érintett. <br />
          </p>
          <p class="text-justify" style="margin-left: 30px;">
            <strong
              >3. Az adatkezelés időtartama, az adatok törlésének határideje: </strong
            >Az adatkezelés a tesztvezetés végéig tart. <br />
          </p>
          <p class="text-justify" style="margin-left: 30px;">
            Ha a GDPR 17. cikk (1) bekezdésében foglalt feltételek valamelyike
            fennáll, úgy az érintett törlési kérelméig tart. Az érintett által
            megadott bármely személyes adat törléséről az adatkezelő a GDPR 19.
            cikke alapján, elektronikus úton tájékoztatja az érintettet. Ha az
            érintett törlési kérelme kiterjed az általa megadott e-mail címre
            is, akkor az adatkezelő a tájékoztatást követően az e-mail címet is
            törli. <br />
          </p>

          <p class="text-justify" style="margin-left: 30px;">
            Kivéve a számviteli bizonylatok esetében, hiszen a számvitelről
            szóló 2000. évi C. törvény 169. § (2) bekezdése alapján 8 évig meg
            kell őrizni ezeket az adatokat. Az érintett szerződéses adatai a
            polgárjogi elévülési idő leteltével törölhetőek az érintett törlési
            kérelme alapján. <br />
          </p>

          <p class="text-justify" style="margin-left: 30px;font-weight: 300;">
            <em
              >"A könyvviteli elszámolást közvetlenül és közvetetten alátámasztó
              számviteli bizonylatot (ideértve a főkönyvi számlákat, az
              analitikus, illetve részletező nyilvántartásokat is), legalább 8
              évig kell olvasható formában, a könyvelési feljegyzések
              hivatkozása alapján visszakereshető módon megőrizni."</em
            ><br />
          </p>
          <p class="text-justify" style="margin-left: 30px;">
            <strong>4.</strong>
            <strong
              >Az adatok megismerésére jogosult lehetséges adatkezelők személye,
              a személyes adatok címzettjei:</strong
            >
            A személyes adatokat az adatkezelőnek az erre felhatalmazott
            munkatársai kezelhetik a jelen tájékoztatóban foglaltak alapján.
            <br />
          </p>

          <p class="text-justify" style="margin-left: 30px;">
            <strong
              >5. Az érintettek adatkezeléssel kapcsolatos jogainak ismertetése: </strong
            ><br />
          </p>
          <ul style="margin-left: 30px;">
            <li class="text-justify">
              Az érintett kérelmezheti az adatkezelőtől a rá vonatkozó személyes
              adatokhoz való hozzáférést, azok helyesbítését, törlését vagy
              kezelésének korlátozását, és<br />
            </li>
            <li>
              az érintettnek joga van az adathordozhatósághoz, továbbá a
              hozzájárulás bármely időpontban történő visszavonásához. <br />
            </li>
          </ul>
          <p class="text-justify" style="margin-left: 30px;">
            <strong>6.</strong>
            <strong
              >A személyes adatokhoz való hozzáférést, azok törlését,
              módosítását, vagy kezelésének korlátozását, az adatok
              hordozhatóságát az alábbi módokon tudja érintett kezdeményezni: </strong
            ><br />
          </p>
          <ul style="margin-left: 30px;">
            <li class="text-justify">
              postai úton a 1014 Budapest, Országház utca 22. 1. em. 2.
              címen,<br />
            </li>
            <li>
              e-mail útján a
              <a
                href="mailto:hello@teslapilota.hu"
                style="color: #c1132c;font-weight: 500;"
                >hello@teslapilota.hu</a
              >&nbsp;e-mail címen, <br />
            </li>
            <li>
              telefonon a&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
              &nbsp; &nbsp; &nbsp; &nbsp; számon. <br />
            </li>
          </ul>
          <p class="text-justify" style="margin-left: 30px;">
            <strong>7. Az adatkezelés jogalapja: </strong><br />
          </p>
          <p class="text-justify" style="margin-left: 50px;">
            7.1. a GDPR 6. cikk (1) bekezdés b) és c) pont, <br />
          </p>
          <p class="text-justify" style="margin-left: 50px;">
            7.2. Az elektronikus kereskedelemi szolgáltatások, valamint az
            információs társadalommal összefüggő szolgáltatások egyes
            kérdéseiről szóló 2001. évi CVIII. törvény (a továbbiakban: Elker
            tv.) 13/A. § (3) bekezdése: <br />
          </p>
          <p class="text-justify" style="margin-left: 50px;">
            <em
              >A szolgáltató a szolgáltatás nyújtása céljából kezelheti azon
              személyes adatokat, amelyek a szolgáltatás nyújtásához
              technikailag elengedhetetlenül szükségesek. A szolgáltatónak az
              egyéb feltételek azonossága esetén úgy kell megválasztania és
              minden esetben oly módon kell üzemeltetnie az információs
              társadalommal összefüggő szolgáltatás nyújtása során alkalmazott
              eszközöket, hogy személyes adatok kezelésére csak akkor kerüljön
              sor, ha ez a szolgáltatás nyújtásához és az e törvényben
              meghatározott egyéb célok teljesüléséhez feltétlenül szükséges,
              azonban ebben az esetben is csak a szükséges mértékben és
              ideig.</em
            >
            <br />
          </p>
          <p class="text-justify" style="margin-left: 50px;">
            7.3. A számviteli jogszabályoknak megfelelő számlát kiállítása
            esetén a 6. cikk (1) bekezdés c) pontja. <br />
          </p>
          <p class="text-justify" style="margin-left: 50px;">
            7.4. A szerződésből eredő követelések érvényesítése esetén a Polgári
            Törvénykönyvről szóló 2013. évi V. törvény 6:21. §-a szerint 5
            év.<br />
          </p>
          <p class="text-justify" style="margin-left: 80px;font-size: 15px;">
            <em>6:22. § [Elévülés]</em><br /><em
              >(1) Ha e törvény eltérően nem rendelkezik, a követelések öt év
              alatt évülnek el.</em
            ><br /><em
              >(2) Az elévülés akkor kezdődik, amikor a követelés esedékessé
              válik.</em
            ><br /><em
              >(3) Az elévülési idő megváltoztatására irányuló megállapodást
              írásba kell foglalni.</em
            ><br /><em>(4) Az elévülést kizáró megállapodás semmis. </em><br />
          </p>
          <p class="text-justify" style="margin-left: 30px;">
            <strong>8. Tájékoztatjuk, hogy</strong><br />
          </p>
          <ul style="margin-left: 30px;">
            <li class="text-justify">
              <strong
                ><span style="text-decoration: underline;"
                  >az adatkezelés a jelentkezéshez, majd szerződés
                  teljesítéséhez szükséges,</span
                ></strong
              ><br />
            </li>
            <li>
              <strong
                ><span style="text-decoration: underline;"
                  >köteles</span
                ></strong
              >
              a személyes adatokat megadni, hogy tudjon jelentkezni a
              vezetésre<br />
            </li>
            <li>
              az adatszolgáltatás elmaradása azzal a
              <strong
                ><span style="text-decoration: underline;"
                  >következménnyel jár</span
                ></strong
              >, hogy nem tud jelentkezni a vezetésre . <br />
            </li>
          </ul>
          <h3
            class="text-uppercase"
            style="margin-top: 40px;margin-bottom: 40px;margin-left: 25px;"
          >
            4.2. Kapcsolatfelvétel<br />
          </h3>
          <p class="text-justify" style="margin-left: 30px;">
            <strong>1. </strong>Az adatgyűjtés ténye, a kezelt adatok köre és az
            <strong>adatkezelés céljai</strong>: <br />
          </p>
          <div
            class="table-responsive text-left"
            style="margin-bottom: 30px;margin-top: 30px;"
          >
            <table class="table table-striped">
              <thead>
                <tr>
                  <th>Személyes adat</th>
                  <th>Az adatkezelés célja<br /></th>
                  <th>Jogalap <br /></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Jelentkező neve,<br />megajándékozott neve <br /></td>
                  <td>Azonosítás, szerződés<br />létrehozása <br /></td>
                  <td>a GDPR 6. cikk (1) bekezdés b) pont.<br /></td>
                </tr>
                <tr>
                  <td>Telefonszám <br /></td>
                  <td>Kapcsolattartás, egyeztetés <br /></td>
                  <td>a GDPR 6. cikk (1) bekezdés b) pont.<br /></td>
                </tr>
                <tr>
                  <td>E-mail cím <br /></td>
                  <td>
                    Kapcsolattartás, az információk<br />megküldéséhez
                    szükséges. <br />
                  </td>
                  <td>a GDPR 6. cikk (1) bekezdés b) pont.<br /></td>
                </tr>
                <tr>
                  <td>Számlázási név és cím <br /></td>
                  <td>
                    A szabályszerű számla kiállítása,<br />továbbá a szerződés
                    létrehozása,<br />tartalmának meghatározása, módosítása,<br />teljesítésének
                    figyelemmel kísérése,<br />az abból származó díjak
                    számlázása,<br />valamint az azzal kapcsolatos
                    követelések<br />érvényesítése. <br />
                  </td>

                  <td>
                    6. cikk (1) bekezdés c) pontja, és a<br />számvitelről szóló
                    2000. évi C. törvény<br />169. § (2) bekezdése.<br />
                  </td>
                </tr>
                <tr>
                  <td>A jelentkezés időpontja <br /></td>
                  <td>Technikai művelet végrehajtása. <br /></td>
                  <td>a GDPR 6. cikk (1) bekezdés b) pont.<br /></td>
                </tr>
                <tr>
                  <td>A jelentkezéskori IP cím <br /></td>
                  <td>Technikai művelet végrehajtása.&nbsp;<br /></td>
                  <td>a GDPR 6. cikk (1) bekezdés b) pont.<br /></td>
                </tr>
              </tbody>
            </table>
          </div>
          <p class="text-justify" style="margin-left: 30px;">
            Az e-mail cím esetében nem szükséges, hogy személyes adatot
            tartalmazzon. <br />
          </p>
          <p class="text-justify" style="margin-left: 30px;">
            <strong>2.</strong> <strong>Az érintettek köre</strong>: A
            kapcsolati űrlapon keresztül üzenetet küldő valamennyi érintett.
            <br />
          </p>
          <p class="text-justify" style="margin-left: 30px;">
            <strong>3.</strong>
            <strong
              >Az adatkezelés időtartama, az adatok törlésének
              határideje:</strong
            >
            Ha a GDPR 17. cikk (1) bekezdésében foglalt feltételek valamelyike
            fennáll, úgy az érintett törlési kérelméig tart. <br />
          </p>
          <p class="text-justify" style="margin-left: 30px;">
            <strong
              >4. Az adatok megismerésére jogosult lehetséges adatkezelők
              személye, a személyes adatok címzettjei</strong
            >: A személyes adatokat az adatkezelőnek az erre felhatalmazott
            munkatársai kezelhetik. <br />
          </p>
          <p class="text-justify" style="margin-left: 30px;">
            <strong
              >5. Az érintettek adatkezeléssel kapcsolatos jogainak ismertetése: </strong
            ><br />
          </p>
          <ul style="margin-left: 30px;">
            <li class="text-justify">
              Az érintett kérelmezheti az adatkezelőtől a rá vonatkozó személyes
              adatokhoz való hozzáférést, azok helyesbítését, törlését vagy
              kezelésének korlátozását, és<br />
            </li>
            <li>
              az érintettnek joga van az adathordozhatósághoz, továbbá a
              hozzájárulás bármely időpontban történő visszavonásához. <br />
            </li>
          </ul>
          <p class="text-justify" style="margin-left: 30px;">
            <strong>6.</strong>&nbsp;<strong
              >A személyes adatokhoz való hozzáférést, azok törlését,
              módosítását, vagy kezelésének korlátozását, az adatok
              hordozhatóságát az alábbi módokon tudja érintett kezdeményezni: </strong
            ><br />
          </p>
          <ul style="margin-left: 30px;">
            <li class="text-justify">
              postai úton a 1014 Budapest, Országház utca 22. 1. em. 2.
              címen,<br />
            </li>
            <li>
              e-mail útján a
              <a
                href="mailto:hello@teslapilota.hu"
                style="color: #c1132c;font-weight: 500;"
                >hello@teslapilota.hu</a
              >&nbsp;e-mail címen, <br />
            </li>
            <li>
              telefonon a&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
              &nbsp; &nbsp; &nbsp; &nbsp; számon. <br />
            </li>
          </ul>
          <p class="text-justify" style="margin-left: 30px;">
            <strong>7.</strong> <strong>Az adatkezelés jogalapja</strong>: az
            érintett hozzájárulása, 6. cikk (1) bekezdés a), b) és c) pont. Ha
            Ön felveszi velünk a kapcsolatot, hozzájárul, hogy a
            kapcsolatfelvétel során hozzánk került személyes adatait (név,
            telefonszám, e-mail cím) a jelen szabályzatnak megfelelően kezeljük.
            <br />
          </p>

          <p class="text-justify" style="margin-left: 30px;">
            <strong>8.</strong> Tájékoztatjuk, hogy <br />
          </p>
          <ul style="margin-left: 30px;">
            <li class="text-justify">
              <strong>a jelen adatkezelés az Ön hozzájárulásán</strong> alapul,
              illetve <strong>ajánlat adásához szükséges</strong> vagy
              szerződéses jogviszony esetén
              <strong>jogszabályi kötelezettségen</strong> (együttműködés)
              alapul. <br />
            </li>

            <li>
              <strong>köteles</strong> a személyes adatokat megadni, hogy
              kapcsolatba tudjon lépni velünk<br />
            </li>
            <li>
              az adatszolgáltatás elmaradása azzal a
              <strong>következménnyel jár</strong>, hogy nem tudja felvenni a
              kapcsolatot a Szolgáltatóval. <br />
            </li>
            <li>
              a hozzájárulás visszavonása nem érinti a hozzájáruláson alapuló, a
              visszavonás előtti adatkezelés jogszerűségét. <br />
            </li>
          </ul>
          <h3
            class="text-uppercase"
            style="margin-top: 40px;margin-bottom: 40px;margin-left: 25px;"
          >
            4.3.&nbsp;ÜGYFÉLKAPCSOLAT <br />
          </h3>
          <p class="text-justify" style="margin-left: 30px;">
            <strong>1. </strong>Az adatgyűjtés ténye, a kezelt adatok köre és az
            <strong>adatkezelés céljai</strong>: <br />
          </p>
          <div
            class="table-responsive text-left"
            style="margin-bottom: 30px;margin-top: 30px;"
          >
            <table class="table table-striped">
              <thead>
                <tr>
                  <th>Személyes adat</th>
                  <th>Az adatkezelés célja<br /></th>
                  <th>Jogalap <br /></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Név, e-mail cím, telefonszám.<br /></td>
                  <td>
                    Kapcsolattartás, azonosítás,<br />szerződések teljesítése,
                    üzleti<br />cél. <br />
                  </td>
                  <td>
                    6. cikk (1) bekezdés b) és c) pontja, a<br />szerződésből
                    eredő követelések<br />érvényesítése esetén a<br />Polgári
                    Törvénykönyvről szóló<br />2013. évi V. törvény 6:21. §-a
                    <br />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <p class="text-justify" style="margin-left: 30px;">
            <strong>2.</strong> Az érintettek köre: Az adatkezelővel
            telefonon/e-mailben/személyesen kapcsolatot tartó, vagy szerződéses
            jogviszonyban álló valamennyi érintett.<br />
          </p>
          <p class="text-justify" style="margin-left: 30px;">
            <strong>3.</strong> Az adatkezelés időtartama, az adatok törlésének
            határideje: A megkereséseket tartalmazó levelek az érintett törlési
            kérelméig tart, de maximum 2 év. <br />
          </p>
          <p class="text-justify" style="margin-left: 30px;">
            <strong>4.</strong>
            <strong
              >Az adatok megismerésére jogosult lehetséges adatkezelők személye,
              a személyes adatok címzettjei:</strong
            >
            A személyes adatokat az adatkezelő erre feljogosított munkatársai
            kezelhetik, a fenti alapelvek tiszteletben tartásával. <br />
          </p>

          <p class="text-justify" style="margin-left: 30px;">
            <strong>5.</strong>
            <strong
              >Az érintettek adatkezeléssel kapcsolatos jogainak ismertetése: </strong
            ><br />
          </p>
          <ul style="margin-left: 30px;">
            <li class="text-justify">
              Az érintett kérelmezheti az adatkezelőtől a rá vonatkozó személyes
              adatokhoz való hozzáférést, azok helyesbítését, törlését vagy
              kezelésének korlátozását, és<br />
            </li>
            <li>
              az érintettnek joga van az adathordozhatósághoz, továbbá a
              hozzájárulás bármely időpontban történő visszavonásához. <br />
            </li>
          </ul>
          <p class="text-justify" style="margin-left: 30px;">
            <strong>6.</strong>&nbsp;<strong
              >A személyes adatokhoz való hozzáférést, azok törlését,
              módosítását, vagy kezelésének korlátozását, az adatok
              hordozhatóságát az alábbi módokon tudja érintett kezdeményezni: </strong
            ><br />
          </p>
          <ul style="margin-left: 30px;">
            <li class="text-justify">
              postai úton a 1014 Budapest, Országház utca 22. 1. em. 2.
              címen,<br />
            </li>
            <li>
              e-mail útján a
              <a
                href="mailto:hello@teslapilota.hu"
                style="color: #c1132c;font-weight: 500;"
                >hello@teslapilota.hu</a
              >&nbsp;e-mail címen, <br />
            </li>
            <li>
              telefonon a&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
              &nbsp; &nbsp; &nbsp; &nbsp; számon. <br />
            </li>
          </ul>
          <p class="text-justify" style="margin-left: 30px;">
            <strong>7.</strong> <strong>Az adatkezelés jogalapja</strong>:<br />
          </p>
          <p class="text-justify" style="margin-left: 30px;">
            <strong>8.</strong> Tájékoztatjuk, hogy <br />
          </p>
          <ul style="margin-left: 30px;">
            <li class="text-justify">
              <strong
                ><span style="text-decoration: underline;"
                  >az adatkezelés a jelentkezéshez, majd szerződés
                  teljesítéséhez szükséges,</span
                ></strong
              ><br />
            </li>
            <li>
              <strong
                ><span style="text-decoration: underline;"
                  >köteles</span
                ></strong
              >
              a személyes adatokat megadni, hogy tudjuk a szerződést
              teljesíteni/egyéb kérését teljesíteni.<br />
            </li>
            <li>
              az adatszolgáltatás elmaradása azzal a
              <strong
                ><span style="text-decoration: underline;"
                  >következménnyel jár</span
                ></strong
              >, hogy nem tudjuk a szerződést teljesíteni/kérését feldolgozni.
              <br />
            </li>
          </ul>
          <h3
            class="text-uppercase"
            style="margin-top: 40px;margin-bottom: 40px;margin-left: 25px;"
          >
            4.4.&nbsp;panaszkezelés&nbsp;<br />
          </h3>
          <p class="text-justify" style="margin-left: 30px;">
            <strong>1. </strong>Az adatgyűjtés ténye, a kezelt adatok köre és az
            <strong>adatkezelés céljai</strong>: <br />
          </p>
          <div
            class="table-responsive text-left"
            style="margin-bottom: 30px;margin-top: 30px;"
          >
            <table class="table table-striped">
              <thead>
                <tr>
                  <th>Személyes adat</th>
                  <th>Az adatkezelés célja<br /></th>
                  <th>Jogalap <br /></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Vezeték-és keresztnév<br /></td>
                  <td>Azonosítás, kapcsolattartás. <br /></td>
                  <td>
                    6. cikk (1) bekezdés c) pontja, és a<br />fogyasztóvédelemről
                    szóló 1997. évi CLV.<br />törvény 17/A. § (7)
                    bekezdése.&nbsp;<br />
                  </td>
                </tr>
                <tr>
                  <td>E-mail cím <br /></td>
                  <td>Kapcsolattartás<br /></td>
                  <td>
                    6. cikk (1) bekezdés c) pontja, és a<br />fogyasztóvédelemről
                    szóló 1997. évi CLV.<br />törvény 17/A. § (7)
                    bekezdése.&nbsp;<br />
                  </td>
                </tr>
                <tr>
                  <td>Telefonszám <br /></td>
                  <td>Kapcsolattartás<br /></td>
                  <td>
                    6. cikk (1) bekezdés c) pontja, és a<br />fogyasztóvédelemről
                    szóló 1997. évi CLV.<br />törvény 17/A. § (7)
                    bekezdése.&nbsp;<br />
                  </td>
                </tr>
                <tr>
                  <td>Számlázási név és cím <br /></td>
                  <td>
                    Azonosítás, a megrendelt&nbsp;szolgáltatásokkal<br />kapcsolatosan
                    felmerülő minőségi kifogások,<br />kérdések és problémák
                    kezelése. <br />
                  </td>
                  <td>
                    6. cikk (1) bekezdés c) pontja, és a<br />fogyasztóvédelemről
                    szóló 1997. évi CLV.<br />törvény 17/A. § (7) bekezdése.
                    <br />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <p class="text-justify" style="margin-left: 30px;">
            <strong>2. </strong>Az érintettek köre: A weboldalon időpontot
            foglaló és minőségi kifogással élő, panaszt tevő valamennyi
            érintett. <br />
          </p>
          <p class="text-justify" style="margin-left: 30px;">
            <strong>3.</strong> Az adatkezelés időtartama, az adatok törlésének
            határideje: A kifogásról felvett jegyzőkönyv, átirat és az arra
            adott válasz másolati példányait a fogyasztóvédelemről szóló 1997.
            évi CLV. törvény 17/A. § (7) bekezdése alapján 5 évig kell
            megőrizni.<br />
          </p>

          <p class="text-justify" style="margin-left: 30px;">
            <strong>4.</strong>
            <strong
              >Az adatok megismerésére jogosult lehetséges adatkezelők személye,
              a személyes adatok címzettjei:</strong
            >
            A személyes adatokat az adatkezelő sales és marketing munkatársai
            kezelhetik, a fenti alapelvek tiszteletben tartásával. <br />
          </p>

          <p class="text-justify" style="margin-left: 30px;">
            <strong>5.</strong>
            <strong
              >Az érintettek adatkezeléssel kapcsolatos jogainak ismertetése: </strong
            ><br />
          </p>
          <ul style="margin-left: 30px;">
            <li class="text-justify">
              Az érintett kérelmezheti az adatkezelőtől a rá vonatkozó személyes
              adatokhoz való hozzáférést, azok helyesbítését, törlését vagy
              kezelésének korlátozását, és<br />
            </li>
            <li>
              az érintettnek joga van az adathordozhatósághoz, továbbá a
              hozzájárulás bármely időpontban történő visszavonásához. <br />
            </li>
          </ul>
          <p class="text-justify" style="margin-left: 30px;">
            <strong>6.</strong>&nbsp;<strong
              >A személyes adatokhoz való hozzáférést, azok törlését,
              módosítását, vagy kezelésének korlátozását, az adatok
              hordozhatóságát az alábbi módokon tudja érintett kezdeményezni: </strong
            ><br />
          </p>
          <ul style="margin-left: 30px;">
            <li class="text-justify">
              postai úton a 1014 Budapest, Országház utca 22. 1. em. 2.
              címen,<br />
            </li>
            <li>
              e-mail útján a
              <a
                href="mailto:hello@teslapilota.hu"
                style="color: #c1132c;font-weight: 500;"
                >hello@teslapilota.hu</a
              >&nbsp;e-mail címen, <br />
            </li>
            <li>
              telefonon a&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
              &nbsp; &nbsp; &nbsp; &nbsp; számon. <br />
            </li>
          </ul>
          <p class="text-justify" style="margin-left: 30px;">
            <strong>7.</strong> Tájékoztatjuk, hogy <br />
          </p>
          <ul style="margin-left: 30px;">
            <li class="text-justify">
              a személyes adat szolgáltatása
              <strong>jogi kötelezettségen</strong> alapul. &nbsp;<br />
            </li>
            <li>
              a szerződés megkötésének <strong>előfeltétele</strong> a személyes
              adatok kezelése.<br />
            </li>
            <li>
              <strong>köteles</strong> a személyes adatokat megadni, hogy
              panaszát kezelni tudjuk.<br />
            </li>
            <li>
              az adatszolgáltatás elmaradása azzal a
              <strong>következménnyel jár</strong>, hogy nem tudjuk kezelni a
              hozzánk beérkezett panaszát. <br />
            </li>
          </ul>
          <h2
            class="text-uppercase"
            style="margin-bottom: 30px;margin-top: 30px;"
          >
            5.&nbsp;CÍMZETTEK, AKIKKEL A SZEMÉLYES ADATOKAT KÖZLIK<br />
          </h2>
          <p class="text-justify">
            <em>„címzett”</em>: az a természetes vagy jogi személy, közhatalmi
            szerv, ügynökség vagy bármely egyéb szerv, akivel vagy amellyel a
            személyes adatot közlik, függetlenül attól, hogy harmadik fél-e.
            <br />
          </p>
          <h3
            class="text-uppercase"
            style="margin-top: 40px;margin-bottom: 40px;margin-left: 25px;"
          >
            5.1.ADATFELDOLGOZÓK (AKIK, AZ ADATKEZELŐ NEVÉBEN VÉGZIK AZ
            ADATKEZELÉST)<br />
          </h3>
          <p class="text-justify">
            Az adatkezelő a saját adatkezelési tevékenységeinek elősegítése
            céljából, továbbá érintettel kötött szerződés-, illetve a
            jogszabályok által támasztott kötelezettségeinek teljesítése
            érdekében adatfeldolgozókat vesz igénybe. <br />
          </p>

          <p class="text-justify">
            Az adatkezelő nagy hangsúlyt fektet arra, hogy kizárólag olyan
            adatfeldolgozókat vegyen igénybe, akik vagy amelyek megfelelő
            garanciákat nyújtanak az adatkezelés GDPR-ban foglalt
            követelményeinek való megfelelését és az érintettek jogainak
            védelmét biztosító, megfelelő technikai és szervezési intézkedések
            végrehajtására. <br />
          </p>

          <p class="text-justify">
            Az adatfeldolgozó és bármely, az adatkezelő vagy az adatfeldolgozó
            irányítása alatt eljáró, a személyes adatokhoz hozzáféréssel
            rendelkező személy a jelen szabályzatban foglalt személyes adatokat
            kizárólag az adatkezelő utasításának megfelelően kezeli. <br />
          </p>

          <p class="text-justify">
            Az adatfeldolgozó tevékenységeiért az adatkezelő jogi felelősséggel
            tartozik. Az adatfeldolgozó csak abban az esetben tartozik
            felelősséggel az adatkezelés által okozott károkért, ha nem tartotta
            be a GDPR-ban meghatározott, kifejezetten az adatfeldolgozókat
            terhelő kötelezettségeket, vagy ha az adatkezelő jogszerű
            utasításait figyelmen kívül hagyta, vagy azokkal ellentétesen járt
            el. <br />
          </p>

          <p class="text-justify">
            Az adatfeldolgozónak az adatok kezelését érintően érdemi
            döntéshozatala nincsen. <br />
          </p>
          <h3
            class="text-uppercase"
            style="margin-top: 40px;margin-bottom: 40px;margin-left: 25px;"
          >
            5.2. Egyes adatfeldolgozók<br />
          </h3>
          <div
            class="table-responsive text-left"
            style="margin-bottom: 30px;margin-top: 30px;"
          >
            <table class="table table-striped">
              <thead>
                <tr>
                  <th>ADATFELDOLGOZÓI TEVÉKENYSÉG<br /></th>
                  <th>NÉV<br /></th>
                  <th>CÍM, ELÉRHETŐSÉG<br /></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td><strong>Tárhely-szolgáltatás </strong><br /></td>
                  <td>DOTROLL KFT.<br /></td>
                  <td>
                    Cím: 1148 Budapest,<br />Fogarasi út 3-5.<br />Tel.: +36 - 1
                    - 432 - 3232<br />Fax: +36 - 1 - 432 - 3231<br />E-mail:<br /><a
                      href="mailto:support@dotroll.com"
                      style="color: #c1132c;font-weight: 500;"
                      >support@dotroll.com</a
                    >&nbsp;<br />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <h3
            class="text-uppercase"
            style="margin-top: 40px;margin-bottom: 40px;margin-left: 25px;"
          >
            5.3.ADATTOVÁBBÍTÁS HARMADIK FÉL RÉSZÉRE <br />
          </h3>
          <p class="text-justify">
            <em>„harmadik fél”</em>: az a természetes vagy jogi személy,
            közhatalmi szerv, ügynökség vagy bármely egyéb szerv, amely nem
            azonos az érintettel, az adatkezelővel, az adatfeldolgozóval vagy
            azokkal a személyekkel, akik az adatkezelő vagy adatfeldolgozó
            közvetlen irányítása alatt a személyes adatok kezelésére
            felhatalmazást kaptak;<br />
          </p>

          <p class="text-justify">
            A harmadik fél adatkezelők a saját nevükben, a saját adatvédelmi
            szabályzatuknak megfelelően kezelik az általunk közölt személyes
            adatokat. <br />
          </p>
          <div
            class="table-responsive text-left"
            style="margin-bottom: 30px;margin-top: 30px;"
          >
            <table class="table table-striped">
              <thead>
                <tr>
                  <th>ADATFELDOLGOZÓI TEVÉKENYSÉG<br /></th>
                  <th>NÉV<br /></th>
                  <th>CÍM, ELÉRHETŐSÉG<br /></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td><strong>Online fizetés </strong><br /></td>
                  <td>OTP Mobil Szolgáltató Kft. <br /></td>
                  <td>
                    Székhely: 1093 Budapest,<br />Közraktár u. 30-32.<br />E-mail:<br /><a
                      href="mailto:ugyfelszolgalat@simple.hu"
                      style="color: #c1132c;font-weight: 500;"
                      >ugyfelszolgalat@simple.hu</a
                    ><br />Telefon:
                    <a
                      href="tel:+3617766901"
                      style="color: #c1132c;font-weight: 500;"
                      >+36 1 776 6901</a
                    >&nbsp;<br />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <h2
            class="text-uppercase"
            style="margin-bottom: 30px;margin-top: 30px;"
          >
            6. COOKIE-K (SÜTIK) KEZELÉSE<br />
          </h2>
          <p class="text-justify">
            <strong>1.</strong> Az adatkezelés ténye, a kezelt adatok köre:
            Egyedi azonosítószám, dátumok, időpontok <br />
          </p>
          <p class="text-justify">
            <strong>2. </strong>Az érintettek köre: A weboldalt látogató
            valamennyi érintett. <br />
          </p>
          <p class="text-justify">
            <strong>3.</strong> Az adatkezelés célja: A felhasználók azonosítása
            és a látogatók nyomon követése. <br />
          </p>
          <p class="text-justify">
            <strong>4.</strong> Az adatkezelés időtartama, az adatok törlésének
            határideje: <br />
          </p>
          <div
            class="table-responsive text-center"
            style="margin-bottom: 30px;margin-top: 30px;"
          >
            <table class="table table-striped table-sm">
              <thead>
                <tr>
                  <th>Süti típusa<br /></th>
                  <th>Adatkezelés<br />jogalapja <br /></th>
                  <th>Adatkezelés<br />időtartama <br /></th>
                  <th>Kezelt adatkör <br /></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Munkamenet<br />sütik (session) <br /></td>
                  <td>
                    Az elektronikus kereskedelmi<br />szolgáltatások, valamint
                    az<br />információs társadalmi<br />szolgáltatások egyes
                    kérdéseiről<br />szóló 2001. CVIII. törvény<br />(Elkertv.)
                    13/A. § (3) bekezdése<br />
                  </td>
                  <td>
                    A vonatkozó<br />látogatói<br />munkamenet<br />lezárásáig
                    tartó<br />időszak <br />
                  </td>
                  <td>connect.sid <br /></td>
                </tr>
                <tr>
                  <td>Állandó vagy<br />mentett sütik <br /></td>
                  <td>
                    Az elektronikus&nbsp;kereskedelmi<br />szolgáltatások,&nbsp;valamint
                    az<br />információs&nbsp;társadalmi<br />szolgáltatások
                    egyes&nbsp;kérdéseiről<br />szóló&nbsp;2001. CVIII.
                    törvény<br />(Elkertv.) 13/A. § (3)&nbsp;bekezdése<br />
                  </td>

                  <td>az érintett<br />törléséig <br /></td>
                  <td></td>
                </tr>
                <tr>
                  <td>Statisztikai sütik<br /></td>
                  <td>
                    Az elektronikus&nbsp;kereskedelmi<br />szolgáltatások,&nbsp;valamint
                    az<br />információs&nbsp;társadalmi<br />szolgáltatások
                    egyes&nbsp;kérdéseiről<br />szóló&nbsp;2001. CVIII.
                    törvény<br />(Elkertv.) 13/A. § (3)&nbsp;bekezdése<br />
                  </td>

                  <td>1-2 hónap <br /></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
          <p class="text-justify">
            <strong>5.</strong> Az adatok megismerésére jogosult lehetséges
            adatkezelők személye: A cookie-k használatával nem kezel személyes
            adatokat az adatkezelő.<br />
          </p>
          <p class="text-justify">
            <strong>6.</strong> Az érintettek adatkezeléssel kapcsolatos
            jogainak ismertetése: Az érintettnek lehetőségük van a cookie-kat
            törölni a böngészők Eszközök/Beállítások menüjében általában az
            Adatvédelem menüpont beállításai alatt. <br />
          </p>

          <p class="text-justify">
            <strong>7.</strong> Az adatkezelés jogalapja: Az érintettől
            hozzájárulás nem szükséges, amennyiben a cookie-k használatának
            kizárólagos célja az elektronikus hírközlő hálózaton keresztül
            történő közléstovábbítás vagy arra az előfizető vagy felhasználó
            által kifejezetten kért, az információs társadalommal összefüggő
            szolgáltatás nyújtásához a szolgáltatónak feltétlenül szüksége
            van.&nbsp;<br />
          </p>

          <p class="text-justify">
            <strong>8.</strong> A legtöbb böngésző, amelyet felhasználóink
            használnak, lehetővé teszi annak beállítását, hogy mely cookie-kat
            kell menteni és lehetővé teszi, hogy (meghatározott) cookie-k újra
            törlésre kerüljenek. Amennyiben Ön a cookie mentését meghatározott
            weboldalakon korlátozza vagy harmadik fél cookie-jait nem
            engedélyezi, úgy ez bizonyos körülmények között oda vezethet, hogy
            weboldalunk többé nem használható teljes egészében. Itt talál
            információkat arra vonatkozóan, hogy a szokásos böngészők esetében
            hogyan tudja a cookie beállításokat testre szabni:<br />
          </p>

          <ul style="margin-left: 30px;">
            <li class="text-justify">
              <strong>Google Chrome </strong
              ><a
                href="https://support.google.com/chrome/answer/95647?hl=hu"
                style="color: #c1132c;font-weight: 500;"
                >https://support.google.com/chrome/answer/95647?hl=hu</a
              ><br />
            </li>
            <li>
              <strong>Internet Explorer </strong
              ><a
                href="https://support.microsoft.com/huhu/help/17442/windows-internet-explorer-delete-manage-cookies"
                style="color: #c1132c;font-weight: 500;"
                >https://support.microsoft.com/huhu/help/17442/windows-internet-explorer-delete-manage-cookies</a
              >
            </li>
            <li>
              <strong>Firefox</strong>&nbsp;<a
                href="https://support.mozilla.org/hu/kb/weboldalak-helyi-tarolasi-beallitasainak-kezelese"
                style="color: #c1132c;font-weight: 500;"
                >https://support.mozilla.org/hu/kb/weboldalak-helyi-tarolasi-beallitasainak-kezelese</a
              ><br />
            </li>
            <li>
              <strong>Safari</strong>
              <a
                href="https://support.apple.com/kb/PH21411?locale=hu_HU"
                style="color: #c1132c;font-weight: 500;"
                >https://support.apple.com/kb/PH21411?locale=hu_HU</a
              >&nbsp;<br />
            </li>
          </ul>
          <h2
            class="text-uppercase"
            style="margin-bottom: 30px;margin-top: 30px;"
          >
            7. GOOGLE ÉS FACEBOOK SZOLGÁLTATÁSOK HASZNÁLATA<br />
          </h2>
          <h3
            class="text-uppercase"
            style="margin-top: 40px;margin-bottom: 40px;margin-left: 25px;"
          >
            7.1. GOOGLE ADS KONVERZIÓKÖVETÉS HASZNÁLATA<br />
          </h3>
          <p class="text-justify" style="margin-left: 30px;">
            <strong>1.</strong> A „Google Ads” nevű online reklámprogramot
            használja az adatkezelő, továbbá annak keretein belül igénybe veszi
            a Google konverziókövető szolgáltatását. A Google konverziókövetés a
            Google Inc. elemző szolgáltatása (1600 Amphitheatre Parkway,
            Mountain View, CA 94043, USA; „Google“). <br />
          </p>

          <p class="text-justify" style="margin-left: 30px;">
            <strong>2.</strong> Amikor Felhasználó egy weboldalt Google-hirdetés
            által ér el, akkor egy a konverziókövetéshez szükséges cookie kerül
            a számítógépére. Ezeknek a cookieknak az érvényessége korlátozott,
            és nem tartalmaznak semmilyen személyes adatot, így a Felhasználó
            nem is azonosítható általuk. <br />
          </p>

          <p class="text-justify" style="margin-left: 30px;">
            <strong>3.</strong> Amikor a Felhasználó a weboldal bizonyos
            oldalait böngészi, és a cookie még nem járt le, akkor a Google és az
            adatkezelő is láthatja, hogy Felhasználó a hirdetésre kattintott.
            <br />
          </p>
          <p class="text-justify" style="margin-left: 30px;">
            <strong>4.</strong> Minden Google Ads ügyfél másik cookie-t kap, így
            azokat az Ads ügyfeleinek&nbsp;weboldalain keresztül nem lehet
            nyomon követni. <br />
          </p>
          <p class="text-justify" style="margin-left: 30px;">
            <strong>5.</strong> Az információk – melyeket a konverziókövető
            cookie-k segítségével szereztek – azt a célt szolgálják, hogy az Ads
            konverziókövetést választó ügyfeleinek számára konverziós
            statisztikákat készítsenek. Az ügyfelek így tájékozódnak a
            hirdetésükre kattintó és konverziókövető címkével ellátott oldalra
            továbbított felhasználók számáról. Azonban olyan információkhoz nem
            jutnak hozzá, melyekkel bármelyik felhasználót azonosítani lehetne.
            <br />
          </p>

          <p class="text-justify" style="margin-left: 30px;">
            <strong>6.</strong> Ha nem szeretne részt venni a
            konverziókövetésben, akkor ezt elutasíthatja azáltal, hogy
            böngészőjében letiltja a cookie-k telepítésének lehetőségét. Ezután
            Ön nem fog szerepelni a konverziókövetési statisztikákban. <br />
          </p>

          <p class="text-justify" style="margin-left: 30px;">
            <strong>7.</strong> További információ valamint a Google adatvédelmi
            nyilatkozata az alábbi oldalon érhető el:
            <a
              href="www.google.de/policies/privacy/"
              style="color: #c1132c;font-weight: 500;"
              >www.google.de/policies/privacy/</a
            ><br />
          </p>
          <h3
            class="text-uppercase"
            style="margin-top: 40px;margin-bottom: 40px;margin-left: 25px;"
          >
            7.2. A GOOGLE ANALYTICS ALKALMAZÁSA<br />
          </h3>
          <p class="text-justify" style="margin-left: 30px;">
            <strong>1.</strong> Ez a honlap a Google Analytics alkalmazást
            használja, amely a Google Inc. („Google”) webelemző szolgáltatása. A
            Google Analytics úgynevezett „cookie-kat”, szövegfájlokat használ,
            amelyeket a számítógépére mentenek, így elősegítik Felhasználó által
            látogatott weblap használatának elemzését. <br />
          </p>

          <p class="text-justify" style="margin-left: 30px;">
            <strong>2. </strong>A Felhasználó által használt weboldallal
            kapcsolatos cookie-kkal létrehozott információk rendszerint a Google
            egyik USA-beli szerverére kerülnek és tárolódnak. Az IP-anonimizálás
            weboldali aktiválásával a Google a Felhasználó IP-címét az Európai
            Unió tagállamain belül vagy az Európai Gazdasági Térségről szóló
            megállapodásban részes más államokban előzőleg megrövidíti.<br />
          </p>

          <p class="text-justify" style="margin-left: 30px;">
            <strong>3.</strong> A teljes IP-címnek a Google USA-ban lévő
            szerverére történő továbbítására és ottani lerövidítésére csak
            kivételes esetekben kerül sor. Eme weboldal üzemeltetőjének
            megbízásából a Google ezeket az információkat arra fogja használni,
            hogy kiértékelje, hogyan használta a Felhasználó a honlapot,
            továbbá, hogy a weboldal üzemeltetőjének a honlap aktivitásával
            összefüggő jelentéseket készítsen, valamint, hogy a weboldal- és az
            internethasználattal kapcsolatos további szolgáltatásokat
            teljesítsen. <br />
          </p>

          <p class="text-justify" style="margin-left: 30px;">
            <strong>4.</strong> A Google Analytics keretein belül a Felhasználó
            böngészője által továbbított IP-címet nem vezeti össze a Google más
            adataival. A cookie-k tárolását a Felhasználó a böngészőjének
            megfelelő beállításával megakadályozhatja, azonban felhívjuk
            figyelmét, hogy ebben az esetben előfordulhat, hogy ennek a
            honlapnak nem minden funkciója lesz teljes körűen használható.
            Megakadályozhatja továbbá, hogy a Google gyűjtse és feldolgozza a
            cookie-k általi, a Felhasználó weboldalhasználattal kapcsolatos
            adatait (beleértve az IP-címet is), ha letölti és telepíti a
            következő linken elérhető böngésző plugint.
            <a
              href="https://tools.google.com/dlpage/gaoptout?hl=hu"
              style="color: #c1132c;font-weight: 500;"
              >https://tools.google.com/dlpage/gaoptout?hl=hu</a
            >&nbsp;<br />
          </p>

          <h3
            class="text-uppercase"
            style="margin-top: 40px;margin-bottom: 40px;margin-left: 25px;"
          >
            7.3. FACEBOOK PIXEL <br />
          </h3>
          <p class="text-justify" style="margin-left: 30px;">
            <strong>1. </strong>A Facebook-képpont olyan kód, amelynek a
            segítségével a honlapon jelentés készül a konverziókról,
            célközönségek állíthatók össze, és az oldal tulajdonosa részletes
            elemzési adatokat kap a látogatók honlap használatáról. A Facebook
            remarketing pixel követőkód segítségével a weboldal látogatóinak
            személyre szabott ajánlatokat, hirdetéseket jeleníthet meg a
            Facebook felületén. A Facebook remarketing lista nem alkalmas
            személyazonosításra. A Facebook Pixellel / Facebook-képponttal
            kapcsolatosan további információt itt találhat:
            <a
              href="https://www.facebook.com/business/help/651294705016616"
              style="color: #c1132c;font-weight: 500;"
              >https://www.facebook.com/business/help/65129470516616</a
            >&nbsp;&nbsp;<br />
          </p>

          <h3
            class="text-uppercase"
            style="margin-top: 40px;margin-bottom: 40px;margin-left: 25px;"
          >
            7.4. KÖZÖSSÉGI OLDALAK<br />
          </h3>
          <p class="text-justify" style="margin-left: 30px;">
            <strong>1.</strong> Az adatgyűjtés ténye, a kezelt adatok köre:
            Facebook/Google+/Twitter/Pinterest/Youtube/Instagram stb. közösségi
            oldalakon regisztrált neve, illetve a felhasználó nyilvános
            profilképe. &nbsp;&nbsp;<br />
          </p>
          <p class="text-justify" style="margin-left: 30px;">
            <strong>2.</strong> Az érintettek köre: Valamennyi érintett, aki
            regisztrált a Facebook/Google+/Twitter/Pinterest/Youtube/Instagram
            stb. közösségi oldalakon, és „lájkolta” a Szolgáltató közösségi
            oldalát, illetve a közösségi oldalon keresztül felvette a
            kapcsolatot az adatkezelővel.<br />
          </p>

          <p class="text-justify" style="margin-left: 30px;">
            <strong>3.</strong> Az adatgyűjtés célja: A közösségi oldalakon, a
            weboldal egyes tartalmi elemeinek, akcióinak vagy magának a
            weboldalnak a megosztása, illetve „lájkolása”, követése,
            népszerűsítése. <br />
          </p>
          <p class="text-justify" style="margin-left: 30px;">
            <strong>4.</strong> Az adatkezelés időtartama, az adatok törlésének
            határideje, az adatok megismerésére jogosult lehetséges adatkezelők
            személye és az érintettek&nbsp;adatkezeléssel kapcsolatos jogainak
            ismertetése: Az adatok forrásáról, azok kezeléséről, illetve az
            átadás módjáról, és jogalapjáról az adott közösségi oldalon
            tájékozódhat az érintett. Az adatkezelés a közösségi oldalakon
            valósul meg, így az adatkezelés időtartamára, módjára, illetve az
            adatok törlési és módosítási lehetőségeire az adott közösségi oldal
            szabályozása vonatkozik. <br /><br />
          </p>

          <p class="text-justify" style="margin-left: 30px;">
            <strong>5.</strong> Az adatkezelés jogalapja: az érintett önkéntes
            hozzájárulása személyes adatai kezeléséhez a közösségi oldalakon.
            <br />
          </p>
          <h2
            class="text-uppercase"
            style="margin-bottom: 30px;margin-top: 30px;"
          >
            8. ÜGYFÉLKAPCSOLATOK ÉS EGYÉB ADATKEZELÉSEK<br />
          </h2>
          <p class="text-justify">
            <strong>1.</strong> Amennyiben az adatkezelő szolgáltatásaink
            igénybevétele során kérdés merülne fel, esetleg problémája lenne az
            érintettnek, a honlapon megadott módokon (telefon, email, közösségi
            oldalak stb.) kapcsolatba léphet az adatkezelővel. <br />
          </p>

          <p class="text-justify">
            <strong>2.</strong> Adatkezelő a beérkezett e-maileket, üzeneteket,
            telefonon, Facebook-on stb. megadott adatokat az érdeklődő nevével
            és e-mail címével, valamint más, önként megadott személyes adatával
            együtt, az adatközléstől számított legfeljebb 2 év elteltével törli.
            <br />
          </p>

          <p class="text-justify">
            <strong>3.</strong> E tájékoztatóban fel nem sorolt adatkezelésekről
            az adat felvételekor adunk tájékoztatást.<br />
          </p>
          <p class="text-justify">
            <strong>4.</strong> Kivételes hatósági megkeresésre, illetőleg
            jogszabály felhatalmazása alapján más szervek megkeresése esetén a
            Szolgáltató köteles tájékoztatás adására, adatok közlésére,
            átadására, illetőleg iratok rendelkezésre bocsátására.<br />
          </p>

          <p class="text-justify">
            <strong>5.</strong> A Szolgáltató ezen esetekben a megkereső részére
            – amennyiben az a pontos célt és az adatok körét megjelölte –
            személyes adatot csak annyit és olyan mértékben ad ki, amely a
            megkeresés céljának megvalósításához elengedhetetlenül szükséges.
            <br />
          </p>

          <h2
            class="text-uppercase"
            style="margin-bottom: 30px;margin-top: 30px;"
          >
            9. AZ ÉRINTETTEK JOGAI<br />
          </h2>
          <h3
            class="text-uppercase"
            style="margin-top: 40px;margin-bottom: 40px;margin-left: 25px;"
          >
            1. A hozzáférés joga<br />
          </h3>
          <p class="text-justify" style="margin-left: 30px;">
            Ön jogosult arra, hogy az adatkezelőtől visszajelzést kapjon arra
            vonatkozóan, hogy személyes adatainak kezelése folyamatban van-e, és
            ha ilyen adatkezelés folyamatban van, jogosult arra, hogy a
            személyes adatokhoz és a rendeletben felsorolt információkhoz
            hozzáférést kapjon.<br />
          </p>

          <h3
            class="text-uppercase"
            style="margin-top: 40px;margin-bottom: 40px;margin-left: 25px;"
          >
            2. A helyesbítéshez való jog <br />
          </h3>
          <p class="text-justify" style="margin-left: 30px;">
            Ön jogosult arra, hogy kérésére az adatkezelő indokolatlan késedelem
            nélkül helyesbítse az Önre vonatkozó pontatlan személyes adatokat.
            Figyelembe véve az adatkezelés célját, Ön jogosult arra, hogy kérje
            a hiányos személyes adatok – egyebek mellett kiegészítő nyilatkozat
            útján történő – kiegészítését. <br />
          </p>

          <h3
            class="text-uppercase"
            style="margin-top: 40px;margin-bottom: 40px;margin-left: 25px;"
          >
            3. A törléshez való jog <br />
          </h3>
          <p class="text-justify" style="margin-left: 30px;">
            Ön jogosult arra, hogy kérésére az adatkezelő indokolatlan késedelem
            nélkül törölje az Önre vonatkozó személyes adatokat, az adatkezelő
            pedig köteles arra, hogy Önre vonatkozó személyes adatokat
            indokolatlan késedelem nélkül törölje meghatározott feltételek
            esetén. <br />
          </p>

          <h3
            class="text-uppercase"
            style="margin-top: 40px;margin-bottom: 40px;margin-left: 25px;"
          >
            4. Az elfeledtetéshez való jog <br />
          </h3>
          <p class="text-justify" style="margin-left: 30px;">
            Ha az adatkezelő nyilvánosságra hozta a személyes adatot, és azt
            törölni köteles, az elérhető technológia és a megvalósítás
            költségeinek figyelembevételével megteszi az ésszerűen elvárható
            lépéseket – ideértve technikai intézkedéseket – annak érdekében,
            hogy tájékoztassa az adatokat kezelő adatkezelőket, hogy Ön
            kérelmezte a szóban forgó személyes adatokra mutató linkek vagy e
            személyes adatok másolatának, illetve másodpéldányának törlését.
            <br />
          </p>

          <h3
            class="text-uppercase"
            style="margin-top: 40px;margin-bottom: 40px;margin-left: 25px;"
          >
            5. Az adatkezelés korlátozásához való jog <br />
          </h3>
          <p class="text-justify" style="margin-left: 30px;">
            Ön jogosult arra, hogy kérésére az adatkezelő korlátozza az
            adatkezelést, ha az alábbi&nbsp;feltételek valamelyike teljesül:
            <br />
          </p>
          <ul style="margin-left: 30px;">
            <li class="text-justify">
              Ön vitatja a személyes adatok pontosságát, ez esetben a korlátozás
              arra az időtartamra vonatkozik, amely lehetővé teszi, hogy az
              adatkezelő ellenőrizze a személyes adatok pontosságát; <br />
            </li>
            <li>
              az adatkezelés jogellenes, és Ön ellenzi az adatok törlését, és
              ehelyett kéri azok felhasználásának korlátozását; <br />
            </li>
            <li>
              az adatkezelőnek már nincs szüksége a személyes adatokra
              adatkezelés céljából, de Ön igényli azokat jogi igények
              előterjesztéséhez, érvényesítéséhez vagy védelméhez;<br />
            </li>
            <li>
              Ön tiltakozott az adatkezelés ellen; ez esetben a korlátozás arra
              az időtartamra vonatkozik, amíg megállapításra nem kerül, hogy az
              adatkezelő jogos indokai elsőbbséget élveznek-e Ön jogos
              indokaival szemben. <br />
            </li>
          </ul>
          <h3
            class="text-uppercase"
            style="margin-top: 40px;margin-bottom: 40px;margin-left: 25px;"
          >
            6. Az adathordozhatósághoz való jog <br />
          </h3>
          <p class="text-justify" style="margin-left: 30px;">
            Ön jogosult arra, hogy az Önre vonatkozó, általa egy adatkezelő
            rendelkezésére bocsátott személyes adatokat tagolt, széles körben
            használt, géppel olvasható formátumban megkapja, továbbá jogosult
            arra, hogy ezeket az adatokat egy másik adatkezelőnek továbbítsa
            anélkül, hogy ezt akadályozná az az adatkezelő, amelynek a személyes
            adatokat a rendelkezésére bocsátotta (...) <br />
          </p>

          <h3
            class="text-uppercase"
            style="margin-top: 40px;margin-bottom: 40px;margin-left: 25px;"
          >
            7. A tiltakozáshoz való jog <br />
          </h3>
          <p class="text-justify" style="margin-left: 30px;">
            A jogos érdeken, illetve a közhatalmi jogosítványon, mint
            jogalapokon alapuló adatkezelések esetében Ön jogosult arra, hogy a
            saját helyzetével kapcsolatos okokból bármikor tiltakozzon személyes
            adatainak a (...) kezelése ellen, ideértve az említett
            rendelkezéseken alapuló profilalkotást is. <br />
          </p>

          <h3
            class="text-uppercase"
            style="margin-top: 40px;margin-bottom: 40px;margin-left: 25px;"
          >
            8. Tiltakozás közvetlen üzletszerzés estén <br />
          </h3>
          <p class="text-justify" style="margin-left: 30px;">
            Ha a személyes adatok kezelése közvetlen üzletszerzés érdekében
            történik, Ön jogosult arra, hogy bármikor tiltakozzon az Önre
            vonatkozó személyes adatok e célból történő kezelése ellen, ideértve
            a profilalkotást is, amennyiben az a közvetlen üzletszerzéshez
            kapcsolódik. Ha Ön tiltakozik a személyes adatok közvetlen
            üzletszerzés érdekében történő kezelése ellen, akkor a személyes
            adatok a továbbiakban e célból nem kezelhetők. <br />
          </p>

          <h3
            class="text-uppercase"
            style="margin-top: 40px;margin-bottom: 40px;margin-left: 25px;"
          >
            9. Automatizált döntéshozatal egyedi ügyekben, beleértve a
            profilalkotást <br />
          </h3>
          <p class="text-justify" style="margin-left: 30px;">
            Ön jogosult arra, hogy ne terjedjen ki Önre az olyan, kizárólag
            automatizált adatkezelésen – ideértve a profilalkotást is – alapuló
            döntés hatálya, amely rá nézve joghatással járna vagy őt
            hasonlóképpen jelentős mértékben érintené. Az előző bekezdés nem
            alkalmazandó abban az esetben, ha a döntés: <br />
          </p>

          <ul style="margin-left: 30px;">
            <li class="text-justify">
              Ön és az adatkezelő közötti szerződés megkötése vagy teljesítése
              érdekében szükséges; <br />
            </li>
            <li>
              meghozatalát az adatkezelőre alkalmazandó olyan uniós vagy
              tagállami jog teszi lehetővé, amely Ön jogainak és szabadságainak,
              valamint jogos érdekeinek védelmét szolgáló megfelelő
              intézkedéseket is megállapít; vagy<br />
            </li>

            <li>Ön kifejezett hozzájárulásán alapul. <br /></li>
          </ul>
          <h3
            class="text-uppercase"
            style="margin-top: 40px;margin-bottom: 40px;margin-left: 25px;"
          >
            10. INTÉZKEDÉSI HATÁRIDŐ<br />
          </h3>
          <p class="text-justify" style="margin-left: 30px;">
            Az adatkezelő indokolatlan késedelem nélkül, de mindenféleképpen a
            kérelem beérkezésétől számított
            <strong>1 hónapon belül</strong> tájékoztatja Önt a fenti kérelmek
            nyomán&nbsp;hozott intézkedésekről. <br />
          </p>

          <p class="text-justify" style="margin-left: 30px;">
            Szükség esetén ez <strong>2 hónappal meghosszabbítható</strong>. A
            határidő meghosszabbításáról az adatkezelő a késedelem okainak
            megjelölésével a kérelem kézhezvételétől számított
            <strong>1 hónapon belül</strong> tájékoztatja Önt. <br />
          </p>
          <p class="text-justify" style="margin-left: 30px;">
            Ha az adatkezelő nem tesz intézkedéseket Ön kérelme nyomán,
            <strong
              >késedelem nélkül, de legkésőbb a kérelem beérkezésétől számított
              egy hónapon belül tájékoztatja Önt az intézkedés elmaradásának
              okairól</strong
            >, valamint arról, hogy Ön panaszt nyújthat be valamely felügyeleti
            hatóságnál, és élhet bírósági jogorvoslati jogával.<br />
          </p>

          <h3
            class="text-uppercase"
            style="margin-top: 40px;margin-bottom: 40px;margin-left: 25px;"
          >
            11. AZ ADATKEZELÉS BIZTONSÁGA<br />
          </h3>
          <p class="text-justify" style="margin-left: 30px;">
            Az adatkezelő és az adatfeldolgozó a tudomány és technológia állása
            és a megvalósítás költségei, továbbá az adatkezelés jellege,
            hatóköre, körülményei és céljai, valamint a természetes személyek
            jogaira és szabadságaira jelentett, változó valószínűségű és
            súlyosságú kockázat figyelembevételével megfelelő technikai és
            szervezési intézkedéseket hajt végre annak érdekében, hogy a
            kockázat mértékének megfelelő szintű adatbiztonságot garantálja,
            ideértve, többek között, adott esetben:<br />
          </p>

          <p class="text-justify" style="margin-left: 50px;">
            <strong>a)</strong> a személyes adatok álnevesítését és
            titkosítását; <br />
          </p>
          <p class="text-justify" style="margin-left: 50px;">
            <strong>b)</strong> a személyes adatok kezelésére használt
            rendszerek és szolgáltatások folyamatos bizalmas jellegének
            biztosítását, integritását, rendelkezésre állását és ellenálló
            képességét; <br />
          </p>
          <p class="text-justify" style="margin-left: 50px;">
            <strong>c)</strong> fizikai vagy műszaki incidens esetén az arra
            való képességet, hogy a személyes adatokhoz való hozzáférést és az
            adatok rendelkezésre állását kellő időben vissza lehet állítani;
            <br />
          </p>
          <p class="text-justify" style="margin-left: 50px;">
            <strong>d)&nbsp;</strong>az adatkezelés biztonságának garantálására
            hozott technikai és szervezési intézkedések hatékonyságának
            rendszeres tesztelésére, felmérésére és értékelésére szolgáló
            eljárást. <br />
          </p>

          <p class="text-justify" style="margin-left: 50px;">
            <strong>e)</strong> A kezelt adatokat úgy kell tárolni, hogy azokhoz
            illetéktelenek ne férhessenek hozzá. Papír alapú adathordozók
            esetében a fizikai tárolás, irattárazás rendjének kialakításával,
            elektronikus formában kezelt adatok esetén központi
            jogosultságkezelő rendszer alkalmazásával. <br />
          </p>

          <p class="text-justify" style="margin-left: 50px;">
            <strong>f)</strong> Az adatok informatikai módszerrel történő
            tárolási módját úgy kell megválasztani, hogy azok törlése – az
            esetleg eltérő törlési határidőre is tekintettel – az adattörlési
            határidő lejártakor, illetve ha az egyéb okból szükséges,
            elvégezhető legyen. A törlésnek visszaállíthatatlannak kell lennie.
            <br />
          </p>

          <p class="text-justify" style="margin-left: 50px;">
            <strong>g)</strong> A papír alapú adathordozókat iratmegsemmisítő
            segítségével, vagy külső, iratmegsemmisítésre szakosodott szervezet
            igénybevételével kell a személyes adatoktól megfosztani.
            Elektronikus adathordozók esetében az elektronikus adathordozók
            selejtezésére vonatkozó szabályok szerint kell gondoskodni a fizikai
            megsemmisítésről, illetve szükség szerint előzetesen az adatoknak a
            biztonságos és visszaállíthatatlan törléséről.<br />
          </p>

          <p class="text-justify" style="margin-left: 50px;">
            <strong>h)</strong> Az adatkezelő az alábbi konkrét adatbiztonsági
            intézkedéseket teszi:<br />
          </p>
          <p class="text-justify" style="margin-left: 70px;">
            <strong>a.</strong> A papír alapon kezelt személyes adatok
            biztonsága érdekében a Szolgáltató az alábbi intézkedéseket
            alkalmazza (fizikai védelem): <br />
          </p>
          <p class="text-justify" style="margin-left: 90px;">
            <strong>I.</strong> A dokumentumokat biztonságos, jól zárható száraz
            helyiségben helyezni el.<br /><strong>II.</strong> A Szolgáltató
            épülete és helyiségei tűzvédelmi és vagyonvédelmi berendezéssel
            vannak ellátva.<br /><strong>III.</strong> A személyes adatokat csak
            az arra jogosult személyek ismerhetik meg, azokhoz harmadik
            személyek nem férhetnek hozzá.<br /><strong>IV.</strong> A
            Szolgáltató adatkezelést végző munkatársa a munkavégzése során csak
            úgy hagyhatja el azt a helyiséget, ahol adatkezelés folyik, hogy a
            rá bízott adathordozókat elzárja, vagy az adott helyiséget
            bezárja.<br /><strong>V.</strong> Amennyiben a papíralapon kezelt
            személyes adatok digitalizálására kerül sor, akkor a digitálisan
            tárolt dokumentumokra irányadó szabályokat kell alkalmazni. <br />
          </p>

          <p class="text-justify" style="margin-left: 70px;">
            <strong>b.</strong> Informatikai védelem <br />
          </p>
          <p class="text-justify" style="margin-left: 90px;">
            <strong>I.</strong> Az adatkezelés során használt számítógépek és
            mobil eszközök (egyéb adathordozók) a Szolgáltató birtokát
            képezik.<br /><strong>II.</strong> A számítógépeken található
            adatokhoz felhasználónévvel és jelszóval lehet csak hozzáférni.<br /><strong
              >III.</strong
            >
            A központi szerver géphez csak megfelelő jogosultsággal és csakis az
            arra kijelölt személyek férhetnek hozzá.<br /><strong>IV.</strong> A
            digitálisan tárolt adatok biztonsága érdekében a Szolgáltató
            adatmentéseket és archiválásokat alkalmaz.<br /><strong>V.</strong>
            A Szolgáltató által hasznát személyes adatokat tartalmazó
            számítógépes rendszer vírusvédelemmel van ellátva. <br /><br />
          </p>

          <h3
            class="text-uppercase"
            style="margin-top: 40px;margin-bottom: 40px;margin-left: 25px;"
          >
            12. AZ ÉRINTETT TÁJÉKOZTATÁSA AZ ADATVÉDELMI INCIDENSRŐL<br />
          </h3>
          <p class="text-justify" style="margin-left: 30px;">
            Ha az adatvédelmi incidens valószínűsíthetően magas kockázattal jár
            a természetes személyek jogaira és szabadságaira nézve, az
            adatkezelő indokolatlan késedelem nélkül tájékoztatja az érintettet
            az adatvédelmi incidensről. <br />
          </p>

          <p class="text-justify" style="margin-left: 30px;">
            Az érintett részére adott tájékoztatásban
            <strong>világosan és közérthetően</strong> ismertetni kell az
            adatvédelmi incidens jellegét, és közölni kell az adatvédelmi
            tisztviselő vagy a további tájékoztatást nyújtó egyéb kapcsolattartó
            nevét és elérhetőségeit; ismertetni kell az adatvédelmi incidensből
            eredő, valószínűsíthető következményeket; ismertetni kell az
            adatkezelő által az adatvédelmi incidens orvoslására tett vagy
            tervezett intézkedéseket, beleértve adott esetben az adatvédelmi
            incidensből eredő esetleges hátrányos következmények enyhítését
            célzó intézkedéseket. <br />
          </p>

          <p class="text-justify" style="margin-left: 30px;">
            Az érintettet nem kell tájékoztatni, ha a következő feltételek
            bármelyike teljesül:<br />
          </p>
          <ul style="margin-left: 30px;">
            <li class="text-justify">
              az adatkezelő
              <strong
                >megfelelő technikai és szervezési védelmi intézkedéseket
                hajtott végre</strong
              >, és ezeket az intézkedéseket az adatvédelmi incidens által
              érintett adatok tekintetében alkalmazták, különösen azokat az
              intézkedéseket – mint például a titkosítás alkalmazása –, amelyek
              a személyes adatokhoz való hozzáférésre fel nem jogosított
              személyek számára
              <strong>értelmezhetetlenné teszik az adatokat;</strong><br />
            </li>

            <li>
              az adatkezelő az adatvédelmi incidenst követően olyan további
              intézkedéseket tett, amelyek
              <strong
                >biztosítják, hogy az érintett jogaira és szabadságaira
                jelentett, magas kockázat a továbbiakban valószínűsíthetően nem
                valósul meg;</strong
              ><br />
            </li>
            <li>
              a tájékoztatás
              <strong>aránytalan erőfeszítést tenne szükségessé</strong>. Ilyen
              esetekben az érintetteket nyilvánosan közzétett információk útján
              kell tájékoztatni, vagy olyan hasonló intézkedést kell hozni,
              amely biztosítja az érintettek hasonlóan hatékony tájékoztatását.
              <br />
            </li>
          </ul>
          <p class="text-justify" style="margin-left: 30px;">
            Ha az adatkezelő még nem értesítette az érintettet az adatvédelmi
            incidensről, a felügyeleti hatóság, miután mérlegelte, hogy az
            adatvédelmi incidens valószínűsíthetően magas kockázattal jár-e,
            elrendelheti az érintett tájékoztatását. <br />
          </p>

          <h3
            class="text-uppercase"
            style="margin-top: 40px;margin-bottom: 40px;margin-left: 25px;"
          >
            13. ADATVÉDELMI INCIDENS BEJELENTÉSE A HATÓSÁGNAK<br />
          </h3>
          <p class="text-justify" style="margin-left: 30px;">
            Az adatvédelmi incidenst az adatkezelő indokolatlan késedelem
            nélkül, és ha lehetséges, legkésőbb 72 órával azután, hogy az
            adatvédelmi incidens a tudomására jutott, bejelenti az 55. cikk
            alapján illetékes felügyeleti hatóságnak, kivéve, ha az adatvédelmi
            incidens valószínűsíthetően nem jár kockázattal a természetes
            személyek jogaira és szabadságaira nézve. Ha a bejelentés nem
            történik meg 72 órán belül, mellékelni kell hozzá a késedelem
            igazolására szolgáló indokokat is. <br />
          </p>

          <h3
            class="text-uppercase"
            style="margin-top: 40px;margin-bottom: 40px;margin-left: 25px;"
          >
            14. FELÜLVIZSGÁLAT KÖTELEZŐ ADATKEZELÉS ESETÉN<br />
          </h3>
          <p class="text-justify" style="margin-left: 30px;">
            Ha a kötelező adatkezelés időtartamát, vagy szükségessége időszakos
            felülvizsgálatát törvény, helyi önkormányzat rendelete, vagy az
            Európai Unió kötelező jogi aktusa nem határozza meg,
            <strong
              >az adatkezelő az adatkezelés megkezdésétől legalább háromévente
              felülvizsgálja</strong
            >, hogy az általa, illetve a megbízásából vagy rendelkezése alapján
            eljáró adatfeldolgozó által kezelt személyes adat kezelése az
            adatkezelés céljának megvalósulásához <strong>szükséges-e.</strong>
            <br />
          </p>

          <p class="text-justify" style="margin-left: 30px;">
            Ezen felülvizsgálat körülményeit és eredményét az adatkezelő
            <strong
              >dokumentálja, e dokumentációt a felülvizsgálat elvégzését követő
              tíz évig megőrzi</strong
            >
            és azt a Nemzeti Adatvédelmi és Információszabadság Hatóság (a
            továbbiakban: Hatóság) kérésére a Hatóság rendelkezésére bocsátja.
            <br />
          </p>

          <h3
            class="text-uppercase"
            style="margin-top: 40px;margin-bottom: 40px;margin-left: 25px;"
          >
            15. PANASZTÉTELI LEHETŐSÉG<br />
          </h3>
          <p class="text-justify" style="margin-left: 30px;">
            Az adatkezelő esetleges jogsértése ellen panasszal a Nemzeti
            Adatvédelmi és Információszabadság Hatóságnál lehet élni: <br />
          </p>
          <p class="text-justify" style="margin-left: 30px;">
            <strong>Nemzeti Adatvédelmi és Információszabadság Hatóság</strong
            ><br />1125 Budapest, Szilágyi Erzsébet fasor 22/C.<br />Levelezési
            cím: 1530 Budapest, Postafiók: 5.<br />Telefon:
            <a
              href="tel:+36 -1-391-1400"
              style="color: #c1132c;font-weight: 500;"
              >+36 1 391 1400</a
            ><br />Fax: +36-1-391-1410<br />E-mail:
            <a
              href="mailto:ugyfelszolgalat@naih.hu"
              style="color: #c1132c;font-weight: 500;"
              >ugyfelszolgalat@naih.hu</a
            ><br />
          </p>

          <h3
            class="text-uppercase"
            style="margin-top: 40px;margin-bottom: 40px;margin-left: 25px;"
          >
            16. ZÁRSZÓ<br />
          </h3>
          <p class="text-justify" style="margin-left: 30px;">
            A tájékoztató elkészítése során figyelemmel voltunk az alábbi
            jogszabályokra: <br />
          </p>
          <p class="text-justify" style="margin-left: 30px;">
            <br />- A természetes személyeknek a személyes adatok kezelése
            tekintetében történő védelméről és az ilyen adatok szabad
            áramlásáról, valamint a 95/46/EK rendelet hatályon kívül
            helyezéséről (általános adatvédelmi rendelet)
            <strong
              >AZ EURÓPAI PARLAMENT ÉS A TANÁCS (EU) 2016/679 RENDELETE
              (GDPR)(2016. április 27.)</strong
            ><br />- <strong>2011. évi CXII. törvény</strong> – az információs
            önrendelkezési jogról és az információszabadságról (a továbbiakban:
            Infotv.)<br />- <strong>2001. évi CVIII. törvény</strong> – az
            elektronikus kereskedelmi szolgáltatások, valamint
            az&nbsp;információs társadalommal összefüggő szolgáltatások egyes
            kérdéseiről (<strong>főképp a 13/A. §-a</strong>)<br />-<strong>
              2008. évi XLVII. törvény</strong
            >
            – a fogyasztókkal szembeni tisztességtelen kereskedelmi gyakorlat
            tilalmáról;<br />- <strong>2008. évi XLVIII. törvény</strong> – a
            gazdasági reklámtevékenység alapvető feltételeiről és egyes
            korlátairól (különösen a 6.§-a)<br />-
            <strong>2005. évi XC. törvény</strong> az elektronikus
            információszabadságról<br />-
            <strong>2003. évi C. törvény</strong> az elektronikus hírközlésről
            (<strong>kifejezetten a 155.§-a</strong>)<br />-
            <strong>16/2011. sz. vélemény</strong> a viselkedésalapú online
            reklám bevált gyakorlatára vonatkozó EASA/IAB-ajánlásról<br />- A
            Nemzeti Adatvédelmi és Információszabadság Hatóság ajánlása az
            előzetes tájékoztatás adatvédelmi követelményeiről <br /><br />
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import TopNavBar from '../general/top-nav-bar';

  export default {
    name: "adatvedelem",
    components: {
      TopNavBar
    }
  };
</script>

<style scoped>

</style>

<template>
    <div class="tesla-pilot-calendar container">
        <div v-if="isLoaded" class="text-center text-uppercase mb-1">{{ getMonth }}</div>
        <vue-cal
                ref="vuecal"
                :selected-date="current"
                :time="false"
                :events="events"
                :disable-views="['years', 'year', 'month', 'day']"
                :hideTitleBar="true"
                :hideViewSelector="true"
                locale="hu"
                @ready="isLoaded = true"
        >
        </vue-cal>
        <div v-if="isLoaded" class="container mt-2">
            <div class="row">
                <div class="col-sm">
                    <button class="prev_cal" @click="$refs.vuecal.previous()">ELŐZŐ HÉT</button>
                </div>
                <div class="col-sm">
                    <div class="text-center text-uppercase">{{ getWeek }}</div>
                </div>
                <div class="col-sm text-right">
                    <button class="next_cal" @click="$refs.vuecal.next()">KÖVETKEZŐ HÉT</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import VueCal from 'vue-cal';
    import 'vue-cal/dist/i18n/hu.js';
    import 'vue-cal/dist/vuecal.css';
    import router from "assets/js/plugins/router";

    export default {
        name: "tesla-pilot-calendar",

        components: {VueCal},

        data()
        {
            return {
                isLoaded: false,
                current: new Date().toString(),
                events: []
            };
        },

        mounted() {
            this.send = true;
            this.$api
                .get_calendar()
                .then((res1) => {
                    if (res1.success) {
                        let events = [];

                        res1.events.forEach((e) => {
                            events.push({
                                start: e.date,
                                end: e.date,
                                title: e.name,
                                content: ''
                                //content: '<a href="' + this.goTo('reservation', { d: e.date, cl: e.id }) + '">Kiválaszt</a>'
                            });
                        });

                        this.$set(this, 'events', events);
                    }
                });
        },

        computed: {
            getMonth: function() {
                let str = '';
                if (typeof this.$refs.vuecal !== 'undefined') {
                    let s = this.$refs.vuecal.viewTitle.split(' (');
                    if (s.length === 2) {
                        let s2 = s[1].replace(')', '');
                        //let s2 = s[1].replace(' ' + (new Date()).getFullYear() + ')', '');

                        str += s2;
                    }
                }

                return str;
            },
            getWeek: function() {
                let str = '';
                if (typeof this.$refs.vuecal !== 'undefined') {
                    let s = this.$refs.vuecal.viewTitle.split(' (');
                    if (s.length === 2) {
                        let s2 = s[0].split(' ');

                        if (s2.length === 2)
                            str += s2[1] + '. ' + s2[0]
                    }
                }

                return str;
            }
        },

        methods:
        {
            goTo: function (name, query = {})
            {
                return router.resolve({name: name, query: query}).href;
            },
        }
    };
</script>

<style>
    .vuecal--week-view
    {
        border: 0px solid red !important;

    }
    .prev_cal
    {
        background: url('~assets/img/btn-more-back.png') 0 0 repeat;
        float:left;

        padding-left:40px;
        background-repeat: no-repeat;
        background-position:center left;
        background-size:27px;
        border:0;
        height: 30px;
        font-weight:bold;
        text-transform:uppercase;
        margin-left:10px;
    }


    .next_cal
    {
        background: url('~assets/img/btn-more.png') 0 0 repeat;
        float:right;
        padding-right:40px;
        background-repeat: no-repeat;
        background-position:center right;
        background-size:27px;
        border:0;
        height: 30px;
        font-weight:bold;
        text-transform:uppercase;
        margin-right:12px;
    }
    .vuecal__event
    {
        background-color:transparent;
        margin:auto;

    }
    .vuecal__event--focus
    {
        -webkit-box-shadow: 0px 0px 0px rgba(0,0,0,0);
        box-shadow: 0px 0px 0px rgba(0,0,0,0);
        z-index: 3;
        outline: none;
        background-color: transparent;


    }
    .weekday-label
    {
        text-transform: uppercase;

    }
    .vuecal__flex
    {
        border:0;

    }
    .vuecal__cell:before
    {
        border: 0px !important;

    }
    .vuecal__cell-content
    {
        border: 0px !important;
    }
    .vuecal__cell--selected
    {
        background-color: transparent;
    }
    .vuecal__cell--events
    {
        background-color:transparent;
        border-right: 1px solid #bbb6b6;
    }
    .vuecal__event {cursor: arrow; }

    .vuecal__event-title {
        position:relative;
        font-size: .9em;
        font-weight: thin;
        min-height:25px;
        margin: auto;
        margin-top:3px;
        color: black;
        width:95%;
        background-color:#e5e5e5;
    }
</style>

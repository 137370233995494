var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "aszf" }, [
    _c(
      "div",
      { staticClass: "aszf-header" },
      [_c("top-nav-bar"), _vm._v(" "), _vm._m(0)],
      1
    ),
    _vm._v(" "),
    _vm._m(1)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("header", [_c("h1", [_vm._v("ÁSZF")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _c(
          "div",
          { staticClass: "container", staticStyle: { "margin-top": "100px" } },
          [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col" }, [
                _c(
                  "h1",
                  {
                    staticStyle: {
                      "font-size": "2.5rem",
                      "margin-bottom": "50px",
                      "font-weight": "600"
                    }
                  },
                  [
                    _vm._v(
                      "\n              Általános Szerződési Feltételek\n            "
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticStyle: {
                      "font-size": "1.1rem",
                      "font-weight": "500",
                      "margin-bottom": "30px"
                    }
                  },
                  [
                    _vm._v(
                      "\n              Jelen dokumentum nem kerül iktatásra (utólag nem hozzáférhető),\n              kizárólag elektronikus formában kerül megkötésre, nem minősül\n              írásbeli szerződésnek, magyar nyelven íródik, magatartási\n              kódexre nem utal. A weboldal működésével, megrendelési\n              folyamatával kapcsolatosan felmerülő kérdések esetén a megadott\n              elérhetőségeinken rendelkezésére állunk."
                    ),
                    _c("br")
                  ]
                ),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticStyle: {
                      "font-size": "1.1rem",
                      "font-weight": "500",
                      "margin-bottom": "30px"
                    }
                  },
                  [
                    _vm._v(
                      "\n              Jelen Ászf hatálya a Szolgáltató weblapján (teslapilota.hu,\n              teslapilóta.hu, elmenytesla.hu, élménytesla.hu, pilóta.hu) és\n              aldomainjein történő jogviszonyokra terjed ki. Jelen Ászf\n              folyamatosan elérhető a következő weboldalról:\n              http://teslapilota.hu/aszf és letölthető, továbbá bármikor\n              kinyomtatható az alábbi linkről:\n              http://teslapilota.hu/aszf.pdf"
                    ),
                    _c("br")
                  ]
                ),
                _vm._v(" "),
                _c(
                  "h1",
                  {
                    staticStyle: {
                      "font-size": "1.5rem",
                      "padding-top": "30px",
                      "padding-bottom": "30px"
                    }
                  },
                  [_c("strong", [_vm._v("1. Szolgáltató adatai:")]), _c("br")]
                ),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticStyle: {
                      "font-size": "1.1rem",
                      "font-weight": "500",
                      "margin-bottom": "30px"
                    }
                  },
                  [
                    _vm._v(
                      "\n              A szolgáltató neve: Brazil Reklámügynökség Korlátolt Felelősségű\n              Társaság"
                    ),
                    _c("br"),
                    _vm._v(
                      "A szolgáltató székhelye (és egyben a\n              panaszügyintézés helye): 1014 Budapest, Országház utca 22. 1.\n              em. 2."
                    ),
                    _c("br"),
                    _vm._v(
                      "A szolgáltató elérhetősége, az igénybe vevőkkel való\n              kapcsolattartásra szolgáló, rendszeresen használt elektronikus\n              levelezési címe: hello@teslapilota.hu"
                    ),
                    _c("br"),
                    _vm._v("Cégjegyzékszáma:\n              01-09-703588"),
                    _c("br"),
                    _vm._v("Adószáma: 12791022-2-41"),
                    _c("br"),
                    _vm._v(
                      "Nyilvántartásban\n              bejegyző hatóság neve: Fővárosi Törvényszék"
                    ),
                    _c("br"),
                    _vm._v("Telefonszáma:"),
                    _c("br"),
                    _vm._v("A\n              szerződés nyelve: magyar"),
                    _c("br"),
                    _vm._v(
                      "A tárhely-szolgáltató neve, címe,\n              e-mail címe: Dotroll Kft., 1148 Budapest, Fogarasi út 3-5,\n              support@dotroll.com"
                    ),
                    _c("br")
                  ]
                ),
                _vm._v(" "),
                _c(
                  "h1",
                  {
                    staticStyle: {
                      "font-size": "1.5rem",
                      "padding-top": "30px",
                      "padding-bottom": "30px"
                    }
                  },
                  [
                    _c("strong", [_vm._v("2. Alapvető rendelkezések")]),
                    _c("br")
                  ]
                ),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticStyle: {
                      "font-size": "1.1rem",
                      "font-weight": "500",
                      "margin-bottom": "30px"
                    }
                  },
                  [
                    _c("strong", [_vm._v("2.1,")]),
                    _vm._v(
                      " A jelen Szabályzatban nem\n              szabályozott kérdésekre, valamint jelen Szabályzat értelmezésére\n              a magyar jog az irányadó, különös tekintettel a Polgári\n              Törvénykönyvről szóló 2013. évi V. törvény („Ptk.”) és az\n              elektronikus kereskedelmi szolgáltatások, az információs\n              társadalommal összefüggő szolgáltatások egyes kérdéseiről szóló\n              2001. évi CVIII. (Elker. tv.) törvény, valamint a fogyasztó és a\n              vállalkozás közötti szerződések részletes szabályairól szóló\n              45/2014. (II. 26.) Korm. rendelet vonatkozó rendelkezéseire. A\n              vonatkozó jogszabályok kötelező rendelkezései a felekre külön\n              kikötés nélkül is irányadók."
                    ),
                    _c("br")
                  ]
                ),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticStyle: {
                      "font-size": "1.1rem",
                      "font-weight": "500",
                      "margin-bottom": "30px"
                    }
                  },
                  [
                    _c("strong", [_vm._v("2.2, ")]),
                    _vm._v(
                      "A jelen Szabályzat 2019. augusztus\n              01. napjától hatályos és visszavonásig hatályban marad. A\n              Szolgáltató jogosult egyoldalúan módosítani a Szabályzatot (a\n              módosításra okot adó körülmények: jogszabály-változás, üzleti\n              érdek, céggel kapcsolatos változások). A módosításokat a\n              Szolgáltató azok hatályba lépése előtt 11 (tizenegy) nappal a\n              weboldalon közzéteszi - mely idő alatt a Felhasználó jogosult a\n              szerződéstől elállni, vagy azt felmondani. Felhasználók a\n              weboldal használatával elfogadják, hogy rájuk nézve a weboldal\n              használatával kapcsolatos valamennyi szabályozás automatikusan\n              érvényes."
                    ),
                    _c("br")
                  ]
                ),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticStyle: {
                      "font-size": "1.1rem",
                      "font-weight": "500",
                      "margin-bottom": "30px"
                    }
                  },
                  [
                    _c("strong", [_vm._v("2.3, ")]),
                    _vm._v(
                      "Szolgáltató fenntart magának minden\n              jogot a weboldal, annak bármely részlete és az azon megjelenő\n              tartalmak, valamint a weboldal terjesztésének tekintetében.\n              Tilos a weboldalon megjelenő tartalmak vagy azok bármely\n              részletének letöltése, elektronikus tárolása, feldolgozása és\n              értékesítése a Szolgáltató írásos hozzájárulása nélkül."
                    ),
                    _c("br")
                  ]
                ),
                _vm._v(" "),
                _c(
                  "h1",
                  {
                    staticStyle: {
                      "font-size": "1.5rem",
                      "padding-top": "30px",
                      "padding-bottom": "30px"
                    }
                  },
                  [
                    _c("strong", [
                      _vm._v("3. Vásárlás, jelentkezés vezetésre")
                    ]),
                    _c("br")
                  ]
                ),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticStyle: {
                      "font-size": "1.1rem",
                      "font-weight": "500",
                      "margin-bottom": "30px"
                    }
                  },
                  [
                    _c("strong", [_vm._v("3.1,")]),
                    _vm._v(
                      " Felhasználó a weboldalon történő\n              vásárlásával/jelentkezésével kijelenti, hogy jelen ÁSZF, és a\n              weboldalon közzétett Adatkezelési tájékoztató feltételeit\n              megismerte és elfogadja, az adatkezelésekhez hozzájárul."
                    ),
                    _c("br")
                  ]
                ),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticStyle: {
                      "font-size": "1.1rem",
                      "font-weight": "500",
                      "margin-bottom": "30px"
                    }
                  },
                  [
                    _c("strong", [_vm._v("3.2, ")]),
                    _vm._v(
                      "Felhasználó a vásárlás/jelentkezés\n              során köteles a saját, valós adatait megadni. A\n              vásárlás/jelentkezés során megadott valótlan, vagy más\n              személyhez köthető adatok esetén a létrejövő elektronikus\n              szerződés semmis. Szolgáltató kizárja felelősségét, amennyiben\n              Felhasználó más nevében, más személy adataival veszi igénybe\n              szolgáltatásait."
                    ),
                    _c("br")
                  ]
                ),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticStyle: {
                      "font-size": "1.1rem",
                      "font-weight": "500",
                      "margin-bottom": "30px"
                    }
                  },
                  [
                    _c("strong", [_vm._v("3.3, ")]),
                    _vm._v(
                      "A Szolgáltatót a Felhasználó által\n              tévesen és/vagy pontatlanul megadott adatokra visszavezethető\n              késedelemért, illetve egyéb problémáért, hibáért semminemű\n              felelősség nem terheli."
                    ),
                    _c("br")
                  ]
                ),
                _vm._v(" "),
                _c(
                  "h1",
                  {
                    staticStyle: {
                      "font-size": "1.5rem",
                      "padding-top": "30px",
                      "padding-bottom": "30px"
                    }
                  },
                  [
                    _c("strong", [
                      _vm._v("4. Megvásárolható szolgáltatások köre")
                    ]),
                    _c("br")
                  ]
                ),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticStyle: {
                      "font-size": "1.1rem",
                      "font-weight": "500",
                      "margin-bottom": "30px"
                    }
                  },
                  [
                    _c("strong", [_vm._v("4.1,")]),
                    _vm._v(
                      " A megjelenített szolgáltatások\n              kizárólag online rendelhetők meg. A szolgáltatásokra vonatkozóan\n              megjelenített árak forintban értendők, tartalmazzák a törvényben\n              előírt áfát."
                    ),
                    _c("br")
                  ]
                ),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticStyle: {
                      "font-size": "1.1rem",
                      "font-weight": "500",
                      "margin-bottom": "30px"
                    }
                  },
                  [
                    _c("strong", [_vm._v("4.2, ")]),
                    _vm._v(
                      "A weboldalon Szolgáltató részletesen\n              feltünteti a szolgáltatás nevét, leírását."
                    ),
                    _c("br")
                  ]
                ),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticStyle: {
                      "font-size": "1.1rem",
                      "font-weight": "500",
                      "margin-bottom": "30px"
                    }
                  },
                  [
                    _c("strong", [_vm._v("4.3, ")]),
                    _vm._v(
                      "Amennyiben akciós ár kerül\n              bevezetésre, Szolgáltató teljes körűen tájékoztatja\n              Felhasználókat az akcióról és annak pontos időtartamáról."
                    ),
                    _c("br")
                  ]
                ),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticStyle: {
                      "font-size": "1.1rem",
                      "font-weight": "500",
                      "margin-bottom": "30px"
                    }
                  },
                  [
                    _c("strong", [_vm._v("4.4, ")]),
                    _vm._v(
                      "Amennyiben a Szolgáltató minden\n              gondossága ellenére hibás ár kerül a weboldal felületére,\n              különös tekintettel a nyilvánvalóan téves, pl. a szolgáltatás\n              közismert, általánosan elfogadott vagy becsült árától jelentősen\n              eltérő, esetleg rendszerhiba miatt megjelenő “0” Ft-os vagy “1”\n              Ft-os árra, akkor a Szolgáltató nem köteles a szolgáltatást\n              hibás áron nyújtani, hanem felajánlhatja a helyes áron történő\n              szolgáltatást, amelynek ismeretében az Ügyfél elállhat vásárlási\n              szándékától. Jelentős eltérésnek minősül – a hazai bírói\n              joggyakorlatnak megfelelően – az adott szolgáltatás piaci\n              értékétől akár pozitív-, akár negatív irányban minimum 50 %-ban\n              történő eltérés. Tájékoztatjuk azonban a fogyasztókat, hogy a\n              feltűnő értékaránytalanság (Ptk. 6:98. § ) fogalmát a törvény\n              nem határozza meg."
                    ),
                    _c("br")
                  ]
                ),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticStyle: {
                      "font-size": "1.1rem",
                      "font-weight": "500",
                      "margin-bottom": "30px"
                    }
                  },
                  [
                    _c("strong", [_vm._v("4.5, ")]),
                    _vm._v(
                      "A 4.4 pontban ismertetett mértékű\n              hibás ár esetén feltűnő értékaránytalanság áll fenn a\n              szolgáltatás valódi és feltüntetett ára között, amit egy\n              átlagfogyasztónak is azonnal észlelnie szükséges. A Polgári\n              Törvénykönyvről szóló 2013. évi V. törvény (Ptk.) alapján a\n              szerződés a felek akaratának kölcsönös és egybehangzó\n              kifejezésével jön létre. Amennyiben a felek nem tudnak\n              megállapodni a szerződéses feltételekben, azaz nincs meg a felek\n              akaratát kölcsönösen és egybehangzóan kifejező nyilatkozat,\n              abban az esetben nem beszélhetünk érvényesen létrejött\n              szerződésről, amelyekből jogok és kötelezettségek fakadnának.\n              Ennek alapján a hibás/téves áron visszaigazolt megrendelés\n              semmis szerződésnek tekintendő."
                    ),
                    _c("br")
                  ]
                ),
                _vm._v(" "),
                _c(
                  "h1",
                  {
                    staticStyle: {
                      "font-size": "1.5rem",
                      "padding-top": "30px",
                      "padding-bottom": "30px"
                    }
                  },
                  [_c("strong", [_vm._v("5. Rendelés menete")]), _c("br")]
                ),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticStyle: {
                      "font-size": "1.1rem",
                      "font-weight": "500",
                      "margin-bottom": "30px"
                    }
                  },
                  [
                    _c("strong", [_vm._v("5.1,")]),
                    _vm._v(
                      " Felhasználó kiválasztja a megfelelő\n              csomagot, majd megadja a személyes adatait."
                    ),
                    _c("br")
                  ]
                ),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticStyle: {
                      "font-size": "1.1rem",
                      "font-weight": "500",
                      "margin-bottom": "30px"
                    }
                  },
                  [
                    _c("strong", [_vm._v("5.2, ")]),
                    _vm._v(
                      "Felhasználó kiválasztja a fizetési\n              módot, melynek típusai a következők:"
                    ),
                    _c("br")
                  ]
                ),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticStyle: {
                      "font-size": "1.1rem",
                      "font-weight": "500",
                      "margin-bottom": "30px",
                      "margin-left": "25px"
                    }
                  },
                  [_c("strong", [_vm._v("5.2.1, Fizetési módok:")]), _c("br")]
                ),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticStyle: {
                      "font-size": "1.1rem",
                      "font-weight": "500",
                      "margin-bottom": "30px",
                      "margin-left": "25px"
                    }
                  },
                  [
                    _vm._v(
                      "\n              Átutalással: Felhasználó a megrendelt szolgáltatás ellenértékét\n              a visszaigazoló e-mailben található bankszámlára 3 napon belül\n              köteles átutalni."
                    ),
                    _c("br")
                  ]
                ),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticStyle: {
                      "font-size": "1.1rem",
                      "font-weight": "500",
                      "margin-bottom": "30px",
                      "margin-left": "25px"
                    }
                  },
                  [
                    _vm._v(
                      "\n              Online bankkártyával: Felhasználónak lehetősége van a\n              szolgáltatás, tesztvezetés összértékét online, bankkártyával\n              fizetni a Szolgáltató által igénybe vett pénzügyi szolgáltató\n              (Simple) biztonságos fizetési rendszerén keresztül."
                    ),
                    _c("br")
                  ]
                ),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticStyle: {
                      "font-size": "1.1rem",
                      "font-weight": "500",
                      "margin-bottom": "30px"
                    }
                  },
                  [
                    _c("strong", [_vm._v("5.3, ")]),
                    _vm._v(
                      "Amennyiben a weboldalon hiba vagy\n              hiányosság lép fel a szolgáltatásnál, vagy az áraknál,\n              fenntartjuk a jogot a korrekcióra. Ilyen esetben a hiba\n              felismerése illetve módosítása után azonnal tájékoztatjuk a\n              Felhasználót az új adatokról. Felhasználó ezt követően még\n              egyszer megerősítheti a megrendelést, vagy elállhat a\n              szerződéstől."
                    ),
                    _c("br")
                  ]
                ),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticStyle: {
                      "font-size": "1.1rem",
                      "font-weight": "500",
                      "margin-bottom": "30px"
                    }
                  },
                  [
                    _c("strong", [_vm._v("5.4, ")]),
                    _vm._v(
                      "A fizetendő végösszeg a megrendelés\n              összesítése és visszaigazoló levél alapján minden költséget\n              tartalmaz."
                    ),
                    _c("br")
                  ]
                ),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticStyle: {
                      "font-size": "1.1rem",
                      "font-weight": "500",
                      "margin-bottom": "30px"
                    }
                  },
                  [
                    _c("strong", [_vm._v("5.5, ")]),
                    _vm._v(
                      "Az adatok megadását követően\n              Felhasználó a ”megrendelem” gombra kattintva tudja elküldeni\n              megrendelését, előtte azonban még egyszer ellenőrizheti a\n              megadott adatokat."
                    ),
                    _c("br")
                  ]
                ),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticStyle: {
                      "font-size": "1.1rem",
                      "font-weight": "500",
                      "margin-bottom": "30px"
                    }
                  },
                  [
                    _c("strong", [_vm._v("5.6, ")]),
                    _vm._v(
                      "Felhasználó a megrendeléssel\n              tudomásul veszi, hogy fizetési kötelezettsége keletkezik."
                    ),
                    _c("br")
                  ]
                ),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticStyle: {
                      "font-size": "1.1rem",
                      "font-weight": "500",
                      "margin-bottom": "30px"
                    }
                  },
                  [
                    _c("strong", [_vm._v("5.7, ")]),
                    _vm._v(
                      "Adatbeviteli hibák javítása:\n              Felhasználó a megrendelési folyamat lezárása előtt minden\n              esetben vissza tud lépni az előző fázisba, ahol javítani tudja a\n              bevitt adatokat."
                    ),
                    _c("br")
                  ]
                ),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticStyle: {
                      "font-size": "1.1rem",
                      "font-weight": "500",
                      "margin-bottom": "30px"
                    }
                  },
                  [
                    _c("strong", [_vm._v("5.8, ")]),
                    _vm._v(
                      "Felhasználó e-mail-ben a megrendelés\n              elküldését követően visszaigazolást kap. Amennyiben e\n              visszaigazolás Felhasználó megrendelésének elküldésétől\n              számított, a szolgáltatás jellegétől függő elvárható határidőn\n              belül, de legkésőbb 48 órán belül Felhasználóhoz nem érkezik\n              meg, Felhasználó az ajánlati kötöttség vagy szerződéses\n              kötelezettség alól mentesül. A megrendelés és annak\n              visszaigazolása akkor tekintendő a Szolgáltatóhoz, illetve az\n              Felhasználóhoz megérkezettnek, amikor az számára hozzáférhetővé\n              válik. Szolgáltató kizárja a visszaigazolási felelősségét, ha a\n              visszaigazolás azért nem érkezik meg időben, mert Felhasználó\n              rossz e-mail címet adott meg regisztrációja során, vagy a\n              fiókjához tartozó tárhely telítettsége miatt nem tud üzenetet\n              fogadni."
                    ),
                    _c("br")
                  ]
                ),
                _vm._v(" "),
                _c(
                  "h1",
                  {
                    staticStyle: {
                      "font-size": "1.5rem",
                      "padding-top": "30px",
                      "padding-bottom": "30px"
                    }
                  },
                  [
                    _c("strong", [
                      _vm._v("6. A megrendelések feldolgozása és teljesítés")
                    ]),
                    _c("br")
                  ]
                ),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticStyle: {
                      "font-size": "1.1rem",
                      "font-weight": "500",
                      "margin-bottom": "30px"
                    }
                  },
                  [
                    _c("strong", [_vm._v("6.1,")]),
                    _vm._v(
                      " A megrendelések feldolgozása\n              folyamatos."
                    ),
                    _c("br")
                  ]
                ),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticStyle: {
                      "font-size": "1.1rem",
                      "font-weight": "500",
                      "margin-bottom": "30px"
                    }
                  },
                  [
                    _c("strong", [_vm._v("6.2,")]),
                    _vm._v(
                      " A teljesítés a foglalt időpontban\n              történik."
                    ),
                    _c("br")
                  ]
                ),
                _vm._v(" "),
                _c(
                  "h1",
                  {
                    staticStyle: {
                      "font-size": "1.5rem",
                      "padding-top": "30px",
                      "padding-bottom": "30px"
                    }
                  },
                  [_c("strong", [_vm._v("7. Elállás joga")]), _c("br")]
                ),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticStyle: {
                      "font-size": "1.1rem",
                      "font-weight": "500",
                      "margin-bottom": "30px"
                    }
                  },
                  [
                    _c("strong", [_vm._v("7.1,")]),
                    _vm._v(
                      " A jelen ÁSZF elfogadásával a\n              fogyasztónak minősülő felhasználó kifejezetten tudomásul veszi,\n              hogy a 45/2014. (II. 26.) Korm. rendeletben rögzített felmondási\n              jog a rendelet 29. § (1) bekezdésének l) pontjára tekintettel\n              nem illeti meg Önt, tekintettel arra, hogy egy tesztvezetésre\n              irányuló szerződés esetében a szerződésben meghatározott\n              teljesítési határnap vagy határidő van kikötve."
                    ),
                    _c("br")
                  ]
                ),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticStyle: {
                      "font-size": "1.1rem",
                      "font-weight": "500",
                      "margin-bottom": "30px"
                    }
                  },
                  [
                    _c("strong", [_vm._v("7.2, ")]),
                    _vm._v(
                      "A felmondási jogról részletesebben a\n              fogyasztó és a vállalkozás közötti szerződések részletes\n              szabályairól szóló 45/2014. (II. 26.) Korm. rendeletben lehet\n              olvasni, mely hatályos állapotban itt érhető el."
                    ),
                    _c("br")
                  ]
                ),
                _vm._v(" "),
                _c(
                  "h1",
                  {
                    staticStyle: {
                      "font-size": "1.5rem",
                      "padding-top": "30px",
                      "padding-bottom": "30px"
                    }
                  },
                  [_c("strong", [_vm._v("8. Szavatosság")]), _c("br")]
                ),
                _vm._v(" "),
                _c(
                  "h1",
                  {
                    staticStyle: {
                      "font-size": "1.5rem",
                      "padding-top": "30px",
                      "padding-bottom": "30px"
                    }
                  },
                  [_c("strong", [_vm._v("Hibás teljesítés")]), _c("br")]
                ),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticStyle: {
                      "font-size": "1.1rem",
                      "font-weight": "500",
                      "margin-bottom": "30px"
                    }
                  },
                  [
                    _vm._v(
                      "\n              A kötelezett hibásan teljesít, ha a szolgáltatás a teljesítés\n              időpontjában nem felel meg a szerződésben vagy jogszabályban\n              megállapított minőségi követelményeknek. Nem teljesít hibásan a\n              kötelezett, ha a jogosult a hibát a szerződéskötés időpontjában\n              ismerte, vagy a hibát a szerződéskötés időpontjában ismernie\n              kellett."
                    ),
                    _c("br")
                  ]
                ),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticStyle: {
                      "font-size": "1.1rem",
                      "font-weight": "500",
                      "margin-bottom": "30px"
                    }
                  },
                  [
                    _vm._v(
                      "\n              Fogyasztó és vállalkozás közötti szerződésben semmis az a\n              kikötés, amely e fejezetnek a kellékszavatosságra és a\n              jótállásra vonatkozó rendelkezéseitől a fogyasztó hátrányára tér\n              el."
                    ),
                    _c("br")
                  ]
                ),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticStyle: {
                      "font-size": "1.1rem",
                      "font-weight": "500",
                      "margin-bottom": "30px"
                    }
                  },
                  [
                    _vm._v(
                      "\n              Több szavatossági jog csak a Polgári Törvénykönyv szerinti\n              fogyasztónak minősülő Felhasználókat illeti meg."
                    ),
                    _c("br")
                  ]
                ),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticStyle: {
                      "font-size": "1.1rem",
                      "font-weight": "500",
                      "margin-bottom": "30px"
                    }
                  },
                  [
                    _vm._v(
                      "\n              Vállalkozásnak minősülő Felhasználó: olyan személy, aki a\n              szakmája, önálló foglalkozása vagy üzleti tevékenysége körében\n              jár el."
                    ),
                    _c("br")
                  ]
                ),
                _vm._v(" "),
                _c(
                  "h1",
                  {
                    staticStyle: {
                      "font-size": "1.5rem",
                      "padding-top": "30px",
                      "padding-bottom": "30px"
                    }
                  },
                  [_c("strong", [_vm._v("Kellékszavatosság")]), _c("br")]
                ),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticStyle: {
                      "font-size": "1.1rem",
                      "font-weight": "500",
                      "margin-bottom": "30px"
                    }
                  },
                  [
                    _c("strong", [_vm._v("8.1,")]),
                    _vm._v(
                      " Milyen esetben élhet Felhasználó a\n              kellékszavatossági jogával?"
                    ),
                    _c("br")
                  ]
                ),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticStyle: {
                      "font-size": "1.1rem",
                      "font-weight": "500",
                      "margin-bottom": "30px"
                    }
                  },
                  [
                    _vm._v(
                      "\n              Felhasználó a Szolgáltató hibás teljesítése esetén a\n              Szolgáltatóval szemben kellékszavatossági igényt érvényesíthet a\n              Polgári Törvénykönyv szabályai szerint."
                    ),
                    _c("br")
                  ]
                ),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticStyle: {
                      "font-size": "1.1rem",
                      "font-weight": "500",
                      "margin-bottom": "30px"
                    }
                  },
                  [
                    _c("strong", [_vm._v("8.2,")]),
                    _vm._v(
                      " Milyen jogok illetik meg a\n              Felhasználót a kellékszavatossági igénye alapján?"
                    ),
                    _c("br")
                  ]
                ),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticStyle: {
                      "font-size": "1.1rem",
                      "font-weight": "500",
                      "margin-bottom": "30px"
                    }
                  },
                  [
                    _vm._v(
                      "\n              Felhasználó – választása szerint – az alábbi kellékszavatossági\n              igényekkel élhet: "
                    ),
                    _c("br"),
                    _vm._v(
                      "kérhet kijavítást vagy kicserélést,\n              kivéve, ha az ezek közül a Felhasználó által választott igény\n              teljesítése lehetetlen vagy a vállalkozás számára más igénye\n              teljesítéséhez képest aránytalan többletköltséggel járna. Ha a\n              kijavítást vagy a kicserélést nem kérte, illetve nem kérhette,\n              úgy igényelheti az ellenszolgáltatás arányos leszállítását vagy\n              a hibát a vállalkozás költségére Felhasználó is kijavíthatja,\n              illetve mással kijavíttathatja vagy – végső esetben – a\n              szerződéstől is elállhat. Választott kellékszavatossági jogáról\n              egy másikra is áttérhet, az áttérés költségét azonban\n              Felhasználó viseli, kivéve, ha az indokolt volt, vagy arra a\n              vállalkozás adott okot."
                    ),
                    _c("br")
                  ]
                ),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticStyle: {
                      "font-size": "1.1rem",
                      "font-weight": "500",
                      "margin-bottom": "30px"
                    }
                  },
                  [
                    _c("strong", [_vm._v("8.3,")]),
                    _vm._v(
                      " Milyen határidőben érvényesítheti\n              Felhasználó kellékszavatossági igényét?"
                    ),
                    _c("br")
                  ]
                ),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticStyle: {
                      "font-size": "1.1rem",
                      "font-weight": "500",
                      "margin-bottom": "30px"
                    }
                  },
                  [
                    _vm._v(
                      "\n              Felhasználó (ha fogyasztónak minősül) köteles a hibát annak\n              felfedezése után haladéktalanul, de nem később, mint a hiba\n              felfedezésétől számított kettő hónapon belül közölni. Ugyanakkor\n              felhívjuk a figyelmét, hogy a szerződés teljesítésétől számított\n              két éves (vállalkozás esetén 1 éves) elévülési határidőn túl\n              kellékszavatossági jogait már nem érvényesítheti."
                    ),
                    _c("br")
                  ]
                ),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticStyle: {
                      "font-size": "1.1rem",
                      "font-weight": "500",
                      "margin-bottom": "30px"
                    }
                  },
                  [
                    _c("strong", [_vm._v("8.4,")]),
                    _vm._v(
                      " Kivel szemben érvényesítheti\n              kellékszavatossági igényét?"
                    ),
                    _c("br")
                  ]
                ),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticStyle: {
                      "font-size": "1.1rem",
                      "font-weight": "500",
                      "margin-bottom": "30px"
                    }
                  },
                  [
                    _vm._v(
                      "\n              Felhasználó a Szolgáltatóval szemben érvényesítheti\n              kellékszavatossági igényét."
                    ),
                    _c("br")
                  ]
                ),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticStyle: {
                      "font-size": "1.1rem",
                      "font-weight": "500",
                      "margin-bottom": "30px"
                    }
                  },
                  [
                    _c("strong", [_vm._v("8.5,")]),
                    _vm._v(
                      " Milyen egyéb feltétele van\n              kellékszavatossági jogai érvényesítésének (amennyiben a\n              Felhasználó fogyasztónak minősül)?"
                    ),
                    _c("br")
                  ]
                ),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticStyle: {
                      "font-size": "1.1rem",
                      "font-weight": "500",
                      "margin-bottom": "30px"
                    }
                  },
                  [
                    _vm._v(
                      "\n              A teljesítéstől számított hat hónapon belül a kellékszavatossági\n              igénye érvényesítésének a hiba közlésén túl nincs egyéb\n              feltétele, ha Felhasználó igazolja, hogy a szolgáltatást a\n              weboldalt üzemeltető vállalkozás nyújtotta. A teljesítéstől\n              számított hat hónap eltelte után azonban már Felhasználó köteles\n              bizonyítani, hogy a Felhasználó által felismert hiba már a\n              teljesítés időpontjában is megvolt."
                    ),
                    _c("br")
                  ]
                ),
                _vm._v(" "),
                _c(
                  "h1",
                  {
                    staticStyle: {
                      "font-size": "1.5rem",
                      "padding-top": "30px",
                      "padding-bottom": "30px"
                    }
                  },
                  [
                    _c("strong", [
                      _vm._v(
                        "9. A szavatossági igény esetén történő eljárás (fogyasztónak\n                minősülő felhasználók esetén)"
                      )
                    ]),
                    _c("br")
                  ]
                ),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticStyle: {
                      "font-size": "1.1rem",
                      "font-weight": "500",
                      "margin-bottom": "30px"
                    }
                  },
                  [
                    _c("strong", [_vm._v("9.1,")]),
                    _vm._v(
                      " Fogyasztó és vállalkozás közötti\n              szerződésben a felek megállapodása a rendelet rendelkezéseitől a\n              fogyasztó hátrányára nem térhet el."
                    ),
                    _c("br")
                  ]
                ),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticStyle: {
                      "font-size": "1.1rem",
                      "font-weight": "500",
                      "margin-bottom": "30px"
                    }
                  },
                  [
                    _c("strong", [_vm._v("9.2, ")]),
                    _vm._v(
                      "A fogyasztó kötelessége a szerződés\n              megkötésének bizonyítása (számlával, vagy akár csak\n              nyugtával)."
                    ),
                    _c("br")
                  ]
                ),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticStyle: {
                      "font-size": "1.1rem",
                      "font-weight": "500",
                      "margin-bottom": "30px"
                    }
                  },
                  [
                    _c("strong", [_vm._v("9.3, ")]),
                    _vm._v(
                      "A szavatossági kötelezettség\n              teljesítésével kapcsolatos költségek a Szolgáltatót terhelik\n              (Ptk. 6:166. §)."
                    ),
                    _c("br")
                  ]
                ),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticStyle: {
                      "font-size": "1.1rem",
                      "font-weight": "500",
                      "margin-bottom": "30px"
                    }
                  },
                  [
                    _c("strong", [_vm._v("9.4, ")]),
                    _vm._v(
                      "A Szolgáltató a fogyasztó nála\n              bejelentett szavatossági igényéről jegyzőkönyvet köteles\n              felvenni."
                    ),
                    _c("br")
                  ]
                ),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticStyle: {
                      "font-size": "1.1rem",
                      "font-weight": "500",
                      "margin-bottom": "30px"
                    }
                  },
                  [
                    _c("strong", [_vm._v("9.5, ")]),
                    _vm._v(
                      "A jegyzőkönyv másolatát\n              haladéktalanul, igazolható módon a fogyasztó rendelkezésére kell\n              bocsátani."
                    ),
                    _c("br")
                  ]
                ),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticStyle: {
                      "font-size": "1.1rem",
                      "font-weight": "500",
                      "margin-bottom": "30px"
                    }
                  },
                  [
                    _c("strong", [_vm._v("9.6, ")]),
                    _vm._v(
                      "Ha a Szolgáltató a fogyasztó\n              szavatossági igényének teljesíthetőségéről annak bejelentésekor\n              nem tud nyilatkozni, álláspontjáról – az igény elutasítása\n              esetén az elutasítás indokáról és a békéltető testülethez\n              fordulás lehetőségéről is – öt munkanapon belül, igazolható\n              módon köteles értesíteni a fogyasztót."
                    ),
                    _c("br")
                  ]
                ),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticStyle: {
                      "font-size": "1.1rem",
                      "font-weight": "500",
                      "margin-bottom": "30px"
                    }
                  },
                  [
                    _c("strong", [_vm._v("9.7, ")]),
                    _vm._v(
                      "A Szolgáltató a jegyzőkönyvet az\n              annak felvételétől számított három évig köteles megőrizni, és\n              azt az ellenőrző hatóság kérésére bemutatni."
                    ),
                    _c("br")
                  ]
                ),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticStyle: {
                      "font-size": "1.1rem",
                      "font-weight": "500",
                      "margin-bottom": "30px"
                    }
                  },
                  [
                    _c("strong", [_vm._v("9.8, ")]),
                    _vm._v(
                      "A Szolgáltatónak törekednie kell\n              arra, hogy a kijavítást vagy kicserélést legfeljebb tizenöt\n              napon belül elvégezze."
                    ),
                    _c("br")
                  ]
                ),
                _vm._v(" "),
                _c(
                  "h1",
                  {
                    staticStyle: {
                      "font-size": "1.5rem",
                      "padding-top": "30px",
                      "padding-bottom": "30px"
                    }
                  },
                  [_c("strong", [_vm._v("10. Vegyes Rendelkezések")]), _c("br")]
                ),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticStyle: {
                      "font-size": "1.1rem",
                      "font-weight": "500",
                      "margin-bottom": "30px"
                    }
                  },
                  [
                    _c("strong", [_vm._v("10.1,")]),
                    _vm._v(
                      " Szolgáltató kötelezettsége\n              teljesítéséhez közreműködőt jogosult igénybe venni. Ennek\n              jogellenes magatartásáért teljes felelősséggel tartozik, úgy,\n              mintha a jogellenes magatartást saját maga követte volna el."
                    ),
                    _c("br")
                  ]
                ),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticStyle: {
                      "font-size": "1.1rem",
                      "font-weight": "500",
                      "margin-bottom": "30px"
                    }
                  },
                  [
                    _c("strong", [_vm._v("10.2, ")]),
                    _vm._v(
                      "Ha a jelen Szabályzat bármely része\n              érvénytelenné, jogtalanná vagy érvényesíthetetlenné válik, az a\n              fennmaradó részek érvényességét, jogszerűségét és\n              érvényesíthetőségét nem érinti."
                    ),
                    _c("br")
                  ]
                ),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticStyle: {
                      "font-size": "1.1rem",
                      "font-weight": "500",
                      "margin-bottom": "30px"
                    }
                  },
                  [
                    _c("strong", [_vm._v("10.3, ")]),
                    _vm._v(
                      "Amennyiben Szolgáltató a Szabályzat\n              alapján megillető jogát nem gyakorolja, a joggyakorlás\n              elmulasztása nem tekinthető az adott jogról való lemondásnak.\n              Bármilyen jogról történő lemondás csak az erre vonatkozó\n              kifejezett írásbeli nyilatkozat esetén érvényes. Az hogy a\n              Szolgáltató egy alkalommal nem ragaszkodik szigorúan a\n              Szabályzat valamely lényegi feltételéhez, vagy kikötéséhez nem\n              jelenti azt, hogy lemond arról, hogy a későbbiekben ragaszkodjon\n              az adott feltétel vagy kikötés szigorú betartásához."
                    ),
                    _c("br")
                  ]
                ),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticStyle: {
                      "font-size": "1.1rem",
                      "font-weight": "500",
                      "margin-bottom": "30px"
                    }
                  },
                  [
                    _c("strong", [_vm._v("10.4, ")]),
                    _vm._v(
                      "Szolgáltató és Felhasználó vitás\n              ügyeiket békés úton próbálják rendezni."
                    ),
                    _c("br")
                  ]
                ),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticStyle: {
                      "font-size": "1.1rem",
                      "font-weight": "500",
                      "margin-bottom": "30px"
                    }
                  },
                  [
                    _c("strong", [_vm._v("10.5, ")]),
                    _vm._v(
                      "Felek rögzítik, hogy a Szolgáltató\n              weboldala Magyarországon működik, karbantartását is itt végzik.\n              Mivel az oldal más országokból is meglátogatható, ezért a\n              felhasználók kifejezetten tudomásul veszik, hogy a felhasználó\n              és a Szolgáltató viszonylatában az irányadó jog a magyar jog.\n              Amennyiben a felhasználó fogyasztó, úgy a Pp. 26. § (1)\n              bekezdése alapján a fogyasztóval szemben a jelen szerződésből\n              eredő vitás ügyekben az alperes (fogyasztó) belföldi lakóhelye\n              szerinti bíróság kizárólagosan illetékes."
                    ),
                    _c("br")
                  ]
                ),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticStyle: {
                      "font-size": "1.1rem",
                      "font-weight": "500",
                      "margin-bottom": "30px"
                    }
                  },
                  [
                    _c("strong", [_vm._v("10.6, ")]),
                    _vm._v(
                      "Szolgáltató a weboldalon található\n              szolgáltatáshoz való hozzáférés vonatkozásában nem alkalmaz\n              eltérő általános hozzáférési feltételeket a Felhasználó\n              állampolgárságával, lakóhelyével vagy letelepedési helyével\n              kapcsolatos okokból."
                    ),
                    _c("br")
                  ]
                ),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticStyle: {
                      "font-size": "1.1rem",
                      "font-weight": "500",
                      "margin-bottom": "30px"
                    }
                  },
                  [
                    _c("strong", [_vm._v("10.7, ")]),
                    _vm._v(
                      "Szolgáltató – az általa elfogadott\n              fizetési módok tekintetében – nem alkalmaz eltérő feltételeket a\n              fizetési műveletre a Felhasználó állampolgárságával,\n              lakóhelyével vagy letelepedési helyével, a fizetési számla\n              számlavezetési helyével, a pénzforgalmi szolgáltató letelepedési\n              helyével vagy a készpénz-helyettesítő fizetési eszköz Unión\n              belüli kibocsátásának helyével kapcsolatos okok miatt."
                    ),
                    _c("br")
                  ]
                ),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticStyle: {
                      "font-size": "1.1rem",
                      "font-weight": "500",
                      "margin-bottom": "30px"
                    }
                  },
                  [
                    _c("strong", [_vm._v("10.8, ")]),
                    _vm._v(
                      "Szolgáltató megfelel a belső piacon\n              belül a vevő állampolgársága, lakóhelye vagy letelepedési helye\n              alapján történő indokolatlan területi alapú\n              tartalomkorlátozással és a megkülönböztetés egyéb formáival\n              szembeni fellépésről, valamint a 2006/2004/EK és az (EU)\n              2017/2394 rendelet, továbbá a 2009/22/EK irányelv módosításáról\n              szóló AZ EURÓPAI PARLAMENT ÉS A TANÁCS (EU) 2018/302\n              RENDELET-nek."
                    ),
                    _c("br")
                  ]
                ),
                _vm._v(" "),
                _c(
                  "h1",
                  {
                    staticStyle: {
                      "font-size": "1.5rem",
                      "padding-top": "30px",
                      "padding-bottom": "30px"
                    }
                  },
                  [
                    _c("strong", [
                      _vm._v(
                        "11. Panaszkezelés rendje (fogyasztónak minősülő felhasználók\n                esetén)"
                      )
                    ]),
                    _c("br")
                  ]
                ),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticStyle: {
                      "font-size": "1.1rem",
                      "font-weight": "500",
                      "margin-bottom": "30px"
                    }
                  },
                  [
                    _c("strong", [_vm._v("11.1,")]),
                    _vm._v(
                      " Cégünk célja, hogy valamennyi\n              szolgáltatást megfelelő minőségben, a megrendelő teljes\n              megelégedettsége mellett teljesítsen. Amennyiben Felhasználónak\n              mégis valamilyen panasza van a szerződéssel vagy annak\n              teljesítésével kapcsolatban, úgy panaszát a fenti telefonon,\n              e-mail címen, vagy levél útján is közölheti."
                    ),
                    _c("br")
                  ]
                ),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticStyle: {
                      "font-size": "1.1rem",
                      "font-weight": "500",
                      "margin-bottom": "30px"
                    }
                  },
                  [
                    _c("strong", [_vm._v("11.2, ")]),
                    _vm._v(
                      "Szolgáltató a szóbeli panaszt\n              azonnal megvizsgálja, és szükség szerint orvosolja. Ha a vásárló\n              a panasz kezelésével nem ért egyet, vagy a panasz azonnali\n              kivizsgálása nem lehetséges, a Szolgáltató a panaszról és az\n              azzal kapcsolatos álláspontjáról haladéktalanul jegyzőkönyvet\n              vesz fel, s annak egy másolati példányát átadja a vásárlónak."
                    ),
                    _c("br")
                  ]
                ),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticStyle: {
                      "font-size": "1.1rem",
                      "font-weight": "500",
                      "margin-bottom": "30px"
                    }
                  },
                  [
                    _c("strong", [_vm._v("11.3, ")]),
                    _vm._v(
                      "Az írásbeli panaszt a Szolgáltatást\n              30 napon belül írásban megválaszolja. A panaszt elutasító\n              álláspontját megindokolja. A panaszról felvett jegyzőkönyvet és\n              a válasz másolati példányát öt évig megőrzi a Szolgáltató, és\n              azt az ellenőrző hatóságoknak kérésükre bemutatja."
                    ),
                    _c("br")
                  ]
                ),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticStyle: {
                      "font-size": "1.1rem",
                      "font-weight": "500",
                      "margin-bottom": "30px"
                    }
                  },
                  [
                    _c("strong", [_vm._v("11.4, ")]),
                    _vm._v(
                      "Tájékozatjuk, hogy a panaszának\n              elutasítása esetén panaszával hatósági vagy békéltető testület\n              eljárását kezdeményezheti, az alábbiak szerint:"
                    ),
                    _c("br")
                  ]
                ),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticStyle: {
                      "font-size": "1.1rem",
                      "font-weight": "500",
                      "margin-bottom": "30px"
                    }
                  },
                  [
                    _c("strong", [_vm._v("11.5,")]),
                    _vm._v(
                      " A Fogyasztó panasszal fordulhat a\n              fogyasztóvédelmi hatósághoz:"
                    ),
                    _c("br")
                  ]
                ),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticStyle: {
                      "font-size": "1.1rem",
                      "font-weight": "500",
                      "margin-bottom": "30px"
                    }
                  },
                  [
                    _vm._v(
                      "\n              A fogyasztóvédelmi hatóság kijelöléséről szóló 387/2016.\n              (XII. 2.) Korm. rendelet szerint közigazgatási hatósági\n              ügyekben elsőfokon a járási hivatal, illetve a megyeszékhely\n              szerinti járási hivatal, másodfokon országos illetékességgel a\n              Pest Megyei Kormányhivatal jár el. A járási hivatalok\n              elérhetőségei: http://jarasinfo.gov.hu"
                    ),
                    _c("br")
                  ]
                ),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticStyle: {
                      "font-size": "1.1rem",
                      "font-weight": "500",
                      "margin-bottom": "30px"
                    }
                  },
                  [
                    _c("strong", [_vm._v("11.6,")]),
                    _vm._v(
                      " A Fogyasztónak panasza esetén\n              lehetősége van békéltető testülethez fordulni, melyek\n              elérhetőségét itt találja:"
                    ),
                    _c("br")
                  ]
                ),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticStyle: {
                      "font-size": "1.1rem",
                      "font-weight": "500",
                      "margin-bottom": "30px"
                    }
                  },
                  [
                    _vm._v(
                      "\n              Bács-Kiskun Megyei Békéltető Testület"
                    ),
                    _c("br"),
                    _vm._v(
                      "Címe: 6000 Kecskemét,\n              Árpád krt. 4."
                    ),
                    _c("br"),
                    _vm._v("Telefonszáma: (76) 501-525, (76) 501-500"),
                    _c("br"),
                    _vm._v("Fax\n              száma: (76) 501-538"),
                    _c("br"),
                    _vm._v("Név: Mátyus Mariann"),
                    _c("br"),
                    _vm._v(
                      "E-mail cím:\n              bkmkik@mail.datanet.hu;"
                    ),
                    _c("br"),
                    _c("br"),
                    _vm._v("Baranya Megyei Békéltető\n              Testület"),
                    _c("br"),
                    _vm._v("Címe: 7625 Pécs, Majorossy Imre u. 36."),
                    _c("br"),
                    _vm._v(
                      "Levelezési\n              címe: 7602 Pécs, Pf. 109."
                    ),
                    _c("br"),
                    _vm._v("Telefonszáma: (72) 507-154"),
                    _c("br"),
                    _vm._v("Fax\n              száma: (72) 507-152"),
                    _c("br"),
                    _vm._v("Név: Dr. Bodnár József"),
                    _c("br"),
                    _vm._v("E-mail cím:\n              bekelteto@pbkik.hu;"),
                    _c("br"),
                    _c("br"),
                    _vm._v("Békés Megyei Békéltető\n              Testület"),
                    _c("br"),
                    _vm._v("Címe: 5601 Békéscsaba, Penza ltp. 5."),
                    _c("br"),
                    _vm._v(
                      "Telefonszáma:\n              (66) 324-976, 446-354, 451-775"
                    ),
                    _c("br"),
                    _vm._v("Fax száma: (66) 324-976"),
                    _c("br"),
                    _vm._v("Név:\n              Dr. Bagdi László"),
                    _c("br"),
                    _vm._v("E-mail cím: bmkik@bmkik.hu;"),
                    _c("br"),
                    _c("br"),
                    _vm._v(
                      "Borsod-Abaúj-Zemplén\n              Megyei Békéltető Testület"
                    ),
                    _c("br"),
                    _vm._v(
                      "Címe: 3525 Miskolc, Szentpáli u.\n              1."
                    ),
                    _c("br"),
                    _vm._v("Telefonszáma: (46) 501-091, 501-870"),
                    _c("br"),
                    _vm._v("Fax száma: (46)\n              501-099"),
                    _c("br"),
                    _vm._v("Név: Dr. Tulipán Péter"),
                    _c("br"),
                    _vm._v("E-mail cím:\n              kalna.zsuzsa@bokik.hu;"),
                    _c("br"),
                    _c("br"),
                    _vm._v("Budapesti Békéltető\n              Testület"),
                    _c("br"),
                    _vm._v("Címe: 1016 Budapest, Krisztina krt. 99."),
                    _c("br"),
                    _vm._v("Telefonszáma:\n              (1) 488-2131"),
                    _c("br"),
                    _vm._v("Fax száma: (1) 488-2186"),
                    _c("br"),
                    _vm._v("Név: Dr.\n              Baranovszky György"),
                    _c("br"),
                    _vm._v(
                      "E-mail cím:\n              bekelteto.testulet@bkik.hu;"
                    ),
                    _c("br"),
                    _c("br"),
                    _vm._v("Csongrád Megyei Békéltető\n              Testület"),
                    _c("br"),
                    _vm._v("Címe: 6721 Szeged, Párizsi krt. 8-12."),
                    _c("br"),
                    _vm._v(
                      "Telefonszáma:\n              (62) 554-250/118 mellék"
                    ),
                    _c("br"),
                    _vm._v("Fax száma: (62) 426-149"),
                    _c("br"),
                    _vm._v("Név:\n              Dékány László, Jerney Zoltán"),
                    _c("br"),
                    _vm._v(
                      "E-mail cím:\n              bekelteto.testulet@csmkik.hu;"
                    ),
                    _c("br"),
                    _c("br"),
                    _vm._v("Fejér Megyei Békéltető\n              Testület"),
                    _c("br"),
                    _vm._v("Címe: 8000 Székesfehérvár, Hosszúsétatér 4-6."),
                    _c("br"),
                    _vm._v("Telefonszáma:\n              (22) 510-310"),
                    _c("br"),
                    _vm._v("Fax száma: (22) 510-312"),
                    _c("br"),
                    _vm._v("Név: Kirst\n              László"),
                    _c("br"),
                    _vm._v("E-mail cím: fmkik@fmkik.hu;"),
                    _c("br"),
                    _c("br"),
                    _vm._v(
                      "Győr-Moson-Sopron\n              Megyei Békéltető Testület"
                    ),
                    _c("br"),
                    _vm._v(
                      "Címe: 9021 Győr, Szent István út\n              10/a."
                    ),
                    _c("br"),
                    _vm._v("Telefonszáma: (96) 520-202; 520-217"),
                    _c("br"),
                    _vm._v("Fax száma:\n              (96) 520-218"),
                    _c("br"),
                    _vm._v("Név: Horváth László"),
                    _c("br"),
                    _vm._v(
                      "E-mail cím:\n              bekeltetotestulet@gymskik.hu;"
                    ),
                    _c("br"),
                    _c("br"),
                    _vm._v(
                      "Hajdú-Bihar Megyei\n              Békéltető Testület"
                    ),
                    _c("br"),
                    _vm._v("Címe: 4025 Debrecen, Petőfi tér 10."),
                    _c("br"),
                    _vm._v("Telefonszáma:\n              (52) 500-749"),
                    _c("br"),
                    _vm._v("Fax száma: (52) 500-720"),
                    _c("br"),
                    _vm._v("Név: Dr. Hajnal\n              Zsolt"),
                    _c("br"),
                    _vm._v("E-mail cím: info@hbkik.hu;"),
                    _c("br"),
                    _c("br"),
                    _vm._v("Heves Megyei\n              Békéltető Testület"),
                    _c("br"),
                    _vm._v("Címe: 3300 Eger, Faiskola út 15."),
                    _c("br"),
                    _vm._v(
                      "Levelezési\n              címe: 3301 Eger, Pf. 440."
                    ),
                    _c("br"),
                    _vm._v(
                      "Telefonszáma: (36) 416-660/105\n              mellék"
                    ),
                    _c("br"),
                    _vm._v("Fax száma: (36) 323-615"),
                    _c("br"),
                    _vm._v("Név: Pintérné Dobó\n              Tünde"),
                    _c("br"),
                    _vm._v("E-mail cím: tunde@hkik.hu;"),
                    _c("br"),
                    _c("br"),
                    _vm._v(
                      "Jász-Nagykun-Szolnok\n              Megyei Békéltető Testület"
                    ),
                    _c("br"),
                    _vm._v(
                      "Címe: 5000 Szolnok, Verseghy park\n              8."
                    ),
                    _c("br"),
                    _vm._v("Telefonszáma: (56) 510-610"),
                    _c("br"),
                    _vm._v("Fax száma: (56)\n              370-005"),
                    _c("br"),
                    _vm._v("Név: Dr. Lajkóné dr. Vígh Judit"),
                    _c("br"),
                    _vm._v("E-mail cím:\n              kamara@jnszmkik.hu;"),
                    _c("br"),
                    _c("br"),
                    _vm._v(
                      "Komárom-Esztergom Megyei\n              Békéltető Testület"
                    ),
                    _c("br"),
                    _vm._v("Címe: 2800 Tatabánya, Fő tér 36."),
                    _c("br"),
                    _vm._v("Telefonszáma:\n              (34) 513-010"),
                    _c("br"),
                    _vm._v("Fax száma: (34) 316-259"),
                    _c("br"),
                    _vm._v("Név: Dr. Rozsnyói\n              György"),
                    _c("br"),
                    _vm._v("E-mail cím: kemkik@kemkik.hu;"),
                    _c("br"),
                    _c("br"),
                    _vm._v("Nógrád\n              Megyei Békéltető Testület"),
                    _c("br"),
                    _vm._v(
                      "Címe: 3100 Salgótarján, Alkotmány\n              út 9/a"
                    ),
                    _c("br"),
                    _vm._v("Telefonszám: (32) 520-860"),
                    _c("br"),
                    _vm._v("Fax száma: (32)\n              520-862"),
                    _c("br"),
                    _vm._v("Név: Dr. Pongó Erik"),
                    _c("br"),
                    _vm._v("E-mail cím:\n              nkik@nkik.hu;"),
                    _c("br"),
                    _c("br"),
                    _vm._v("Pest Megyei Békéltető Testület"),
                    _c("br"),
                    _vm._v(
                      "Címe:\n              1119 Budapest, Etele út 59-61. 2. em. 240."
                    ),
                    _c("br"),
                    _vm._v("Telefonszáma:\n              (1)-269-0703"),
                    _c("br"),
                    _vm._v("Fax száma: (1)-269-0703"),
                    _c("br"),
                    _vm._v("Név: dr. Csanádi\n              Károly"),
                    _c("br"),
                    _vm._v("E-mail cím: pmbekelteto@pmkik.hu"),
                    _c("br"),
                    _vm._v("Honlap cím:\n              www.panaszrendezes.hu"),
                    _c("br"),
                    _c("br"),
                    _vm._v("Somogy Megyei Békéltető\n              Testület"),
                    _c("br"),
                    _vm._v("Címe: 7400 Kaposvár, Anna utca 6."),
                    _c("br"),
                    _vm._v("Telefonszáma:\n              (82) 501-000"),
                    _c("br"),
                    _vm._v("Fax száma: (82) 501-046"),
                    _c("br"),
                    _vm._v("Név: Dr. Novák\n              Ferenc"),
                    _c("br"),
                    _vm._v("E-mail cím: skik@skik.hu;"),
                    _c("br"),
                    _c("br"),
                    _vm._v(
                      "Szabolcs-Szatmár-Bereg\n              Megyei Békéltető Testület"
                    ),
                    _c("br"),
                    _vm._v(
                      "Címe: 4400 Nyíregyháza, Széchenyi\n              u. 2."
                    ),
                    _c("br"),
                    _vm._v("Telefonszáma: (42) 311-544, (42) 420-180"),
                    _c("br"),
                    _vm._v("Fax\n              száma: (42) 311-750"),
                    _c("br"),
                    _vm._v("Név: Görömbeiné dr. Balmaz Katalin"),
                    _c("br"),
                    _vm._v("E-mail\n              cím: bekelteto@szabkam.hu;"),
                    _c("br"),
                    _c("br"),
                    _vm._v("Tolna Megyei Békéltető\n              Testület"),
                    _c("br"),
                    _vm._v("Címe: 7100 Szekszárd, Arany J. u. 23-25."),
                    _c("br"),
                    _vm._v("Telefonszáma:\n              (74) 411-661"),
                    _c("br"),
                    _vm._v("Fax száma: (74) 411-456"),
                    _c("br"),
                    _vm._v("Név: Mátyás\n              Tibor"),
                    _c("br"),
                    _vm._v("E-mail cím: kamara@tmkik.hu;"),
                    _c("br"),
                    _c("br"),
                    _vm._v("Vas Megyei\n              Békéltető Testület"),
                    _c("br"),
                    _vm._v("Címe: 9700 Szombathely, Honvéd tér 2."),
                    _c("br"),
                    _vm._v("Telefonszáma:\n              (94) 312-356"),
                    _c("br"),
                    _vm._v("Fax száma: (94) 316-936"),
                    _c("br"),
                    _vm._v("Név: Dr. Kövesdi\n              Zoltán"),
                    _c("br"),
                    _vm._v("E-mail cím: pergel.bea@vmkik.hu"),
                    _c("br"),
                    _c("br"),
                    _vm._v("Veszprém\n              Megyei Békéltető Testület"),
                    _c("br"),
                    _vm._v(
                      "Címe: 8200 Veszprém, Budapest u.\n              3."
                    ),
                    _c("br"),
                    _vm._v("Telefonszáma: (88) 429-008"),
                    _c("br"),
                    _vm._v("Fax száma: (88)\n              412-150"),
                    _c("br"),
                    _vm._v("Név: Dr. Óvári László"),
                    _c("br"),
                    _vm._v(
                      "E-mail cím:\n              vkik@veszpremikamara.hu"
                    ),
                    _c("br"),
                    _c("br"),
                    _vm._v("Zala Megyei Békéltető\n              Testület"),
                    _c("br"),
                    _vm._v("Címe: 8900 Zalaegerszeg, Petőfi utca 24."),
                    _c("br"),
                    _vm._v("Telefonszáma:\n              (92) 550-513"),
                    _c("br"),
                    _vm._v("Fax száma: (92) 550-525"),
                    _c("br"),
                    _vm._v("Név: dr. Koczka\n              Csaba"),
                    _c("br"),
                    _vm._v("E-mail cím: zmbekelteto@zmkik.hu"),
                    _c("br"),
                    _c("br")
                  ]
                ),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticStyle: {
                      "font-size": "1.1rem",
                      "font-weight": "500",
                      "margin-bottom": "30px"
                    }
                  },
                  [
                    _c("strong", [_vm._v("11.7, ")]),
                    _vm._v(
                      "A  békéltető testület\n              hatáskörébe tartozik a  fogyasztói jogvita bírósági\n              eljáráson kívüli rendezése. A  békéltető testület feladata,\n              hogy megkísérelje a  fogyasztói jogvita rendezése céljából\n              egyezség létrehozását a  felek között, ennek\n              eredménytelensége esetén az  ügyben döntést hoz a \n              fogyasztói jogok egyszerű, gyors, hatékony és költségkímélő\n              érvényesítésének biztosítása érdekében. A békéltető\n              testület a fogyasztó vagy a Szolgáltató kérésére\n              tanácsot ad a fogyasztót megillető jogokkal és\n              a fogyasztót terhelő kötelezettségekkel kapcsolatban."
                    ),
                    _c("br")
                  ]
                ),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticStyle: {
                      "font-size": "1.1rem",
                      "font-weight": "500",
                      "margin-bottom": "30px"
                    }
                  },
                  [
                    _c("strong", [_vm._v("11.8, ")]),
                    _vm._v(
                      "Online adásvételi vagy online\n              szolgáltatási szerződéssel összefüggő határon átnyúló fogyasztói\n              jogvita esetén az eljárásra kizárólag a fővárosi\n              kereskedelmi és iparkamara mellett működő békéltető testület\n              illetékes."
                    ),
                    _c("br")
                  ]
                ),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticStyle: {
                      "font-size": "1.1rem",
                      "font-weight": "500",
                      "margin-bottom": "30px"
                    }
                  },
                  [
                    _c("strong", [_vm._v("11.9, ")]),
                    _vm._v(
                      "A Fogyasztó panasza esetén igénybe\n              veheti az Uniós online vitarendezési platformot. A platform\n              igénybe vétele egy egyszerű regisztrációt igényel az Európai\n              Bizottság rendszerében, ide kattintva. Ezt követően\n              pedig bejelentkezés után nyújthatja be panaszát a\n              fogyasztó az online honlapon keresztül, amelynek\n              címe: http://ec.europa.eu/odr"
                    ),
                    _c("br")
                  ]
                ),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticStyle: {
                      "font-size": "1.1rem",
                      "font-weight": "500",
                      "margin-bottom": "30px"
                    }
                  },
                  [
                    _c("strong", [_vm._v("11.10, ")]),
                    _vm._v(
                      "Szolgáltatót a békéltető testületi\n              eljárásban együttműködési kötelezettség terheli. Ennek keretében\n              köteles a válasziratát megküldeni a békéltető testület számára\n              és a meghallgatáson egyezség létrehozatalára feljogosított\n              személy részvételét biztosítani. Amennyiben a vállalkozás\n              székhelye vagy telephelye nem a területileg illetékes békéltető\n              testületet működtető kamara szerinti megyébe van bejegyezve, a\n              vállalkozás együttműködési kötelezettsége a fogyasztó igényének\n              megfelelő írásbeli egyezségkötés lehetőségének felajánlására\n              terjed ki."
                    ),
                    _c("br")
                  ]
                ),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticStyle: {
                      "font-size": "1.1rem",
                      "font-weight": "500",
                      "margin-bottom": "30px"
                    }
                  },
                  [
                    _c("strong", [_vm._v("11.11, ")]),
                    _vm._v(
                      "Amennyiben a fogyasztó nem fordul\n              békéltető testülethez, vagy az eljárás nem vezetett eredményre,\n              úgy a fogyasztónak a jogvita rendezése érdekében lehetősége van\n              bírósághoz fordulni. A pert keresetlevéllel kell megindítani,\n              amelyben az alábbi információkat kell feltüntetni:"
                    ),
                    _c("br"),
                    _vm._v(" -\n              az eljáró bíróságot;"),
                    _c("br"),
                    _vm._v(
                      " - a feleknek, valamint a felek\n              képviselőinek nevét, lakóhelyét és perbeli állását;"
                    ),
                    _c("br"),
                    _vm._v(
                      " -\n              az érvényesíteni kívánt jogot, az annak alapjául szolgáló\n              tényeknek és azok bizonyítékainak előadásával;"
                    ),
                    _c("br"),
                    _vm._v(
                      " -\n              azokat az adatokat, amelyekből a bíróság hatásköre és\n              illetékessége megállapítható;"
                    ),
                    _c("br"),
                    _vm._v(
                      " - a bíróság döntésére\n              irányuló határozott kérelmet ."
                    ),
                    _c("br"),
                    _vm._v(
                      "A keresetlevélhez csatolni\n              kell azt az okiratot, illetve annak másolatát amelynek\n              tartalmára bizonyítékként hivatkozik."
                    ),
                    _c("br")
                  ]
                ),
                _vm._v(" "),
                _c(
                  "h1",
                  {
                    staticStyle: {
                      "font-size": "1.5rem",
                      "padding-top": "30px",
                      "padding-bottom": "30px"
                    }
                  },
                  [_c("strong", [_vm._v("12. Szerzői jogok")]), _c("br")]
                ),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticStyle: {
                      "font-size": "1.1rem",
                      "font-weight": "500",
                      "margin-bottom": "30px"
                    }
                  },
                  [
                    _c("strong", [_vm._v("12.1,")]),
                    _vm._v(
                      " Miután a Teslapilota.hu, mint\n              weboldal szerzői jogi műnek minősül, tilos a Teslapilota.hu\n              weboldalon megjelenő tartalmak vagy azok bármely részletének\n              letöltése (többszörözése), újra nyilvánossághoz történő\n              közvetítése, más módon való felhasználása, elektronikus\n              tárolása, feldolgozása és értékesítése a Szolgáltató írásos\n              hozzájárulása nélkül."
                    ),
                    _c("br")
                  ]
                ),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticStyle: {
                      "font-size": "1.1rem",
                      "font-weight": "500",
                      "margin-bottom": "30px"
                    }
                  },
                  [
                    _c("strong", [_vm._v("12.2, ")]),
                    _vm._v(
                      "A Teslapilota.hu weboldalról és\n              annak adatbázisából bármilyen anyagot átvenni írásos\n              hozzájárulás esetén is csak az adott weboldalra való\n              hivatkozással lehet."
                    ),
                    _c("br")
                  ]
                ),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticStyle: {
                      "font-size": "1.1rem",
                      "font-weight": "500",
                      "margin-bottom": "30px"
                    }
                  },
                  [
                    _c("strong", [_vm._v("12.3, ")]),
                    _vm._v(
                      "A Szolgáltató fenntartja minden\n              jogát szolgáltatásának valamennyi elemére, a domain-neveire, az\n              azokkal képzett másodlagos domain nevekre valamint az internetes\n              reklámfelületeire."
                    ),
                    _c("br")
                  ]
                ),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticStyle: {
                      "font-size": "1.1rem",
                      "font-weight": "500",
                      "margin-bottom": "30px"
                    }
                  },
                  [
                    _c("strong", [_vm._v("12.4, ")]),
                    _vm._v(
                      "Tilos a Teslapilota.hu weboldal\n              tartalmának, illetve egyes részeinek adaptációja vagy\n              visszafejtése; a felhasználói azonosítók és jelszavak\n              tisztességtelen módon történő létesítése; bármely olyan\n              alkalmazás használata, amellyel a Teslapilota.hu weboldalon vagy\n              azok bármely része módosítható vagy indexelhető."
                    ),
                    _c("br")
                  ]
                ),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticStyle: {
                      "font-size": "1.1rem",
                      "font-weight": "500",
                      "margin-bottom": "30px"
                    }
                  },
                  [
                    _c("strong", [_vm._v("12.5, ")]),
                    _vm._v(
                      "A Teslapilota.hu név szerzői jogi\n              védelmet élvez, felhasználása a hivatkozás kivételével kizárólag\n              a Szolgáltató írásos hozzájárulásával lehetséges."
                    ),
                    _c("br")
                  ]
                ),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticStyle: {
                      "font-size": "1.1rem",
                      "font-weight": "500",
                      "margin-bottom": "30px"
                    }
                  },
                  [
                    _c("strong", [_vm._v("12.6, ")]),
                    _vm._v(
                      "Felhasználó tudomásul veszi, hogy a\n              felhasználási engedély nélküli felhasználás esetén Szolgáltatót\n              kötbér illeti meg. A kötbér összege képenként bruttó 60.000 Ft,\n              illetve szavanként bruttó 20.000 Ft. Felhasználó tudomásul\n              veszi, hogy ezen kötbérkikötés nem túlzó, és ennek tudatában\n              böngészi az oldalt. A szerzői jogi jogsértés esetén Szolgáltató\n              közjegyzői ténytanúsítást alkalmaz, melynek összegét szintén a\n              jogsértő felhasználóra hárítja."
                    ),
                    _c("br")
                  ]
                ),
                _vm._v(" "),
                _c(
                  "h1",
                  {
                    staticStyle: {
                      "font-size": "1.5rem",
                      "padding-top": "30px",
                      "padding-bottom": "30px"
                    }
                  },
                  [
                    _c("strong", [_vm._v("13. Adattovábbítási nyilatkozat")]),
                    _c("br")
                  ]
                ),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticStyle: {
                      "font-size": "1.1rem",
                      "font-weight": "500",
                      "margin-bottom": "30px"
                    }
                  },
                  [
                    _c("br"),
                    _vm._v(
                      "Felhasználó tudomásul veszi, hogy a(z) Brazil\n              Reklámügynökség Korlátolt Felelősségű Társaság (1014 Budapest,\n              Országház utca 22. 1. em. 2.) adatkezelő által a(z)\n              www.teslapilota.hu felhasználói adatbázisában tárolt alábbi\n              személyes adatai átadásra kerülnek az OTP Mobil Kft. (1093\n              Budapest, Közraktár u. 30-32.), mint adatfeldolgozó részére. Az\n              adatkezelő által továbbított adatok köre az alábbi:"
                    ),
                    _c("br"),
                    _vm._v(" -\n              email cím"),
                    _c("br"),
                    _vm._v(
                      "Az adatfeldolgozóáltal végzett adatfeldolgozási\n              tevékenység jellege és célja a SimplePay Adatkezelési\n              tájékoztatóban, az alábbi linken tekinthető meg: "
                    ),
                    _c(
                      "a",
                      {
                        staticStyle: {
                          color: "#c1132c",
                          "font-size": "1.1rem",
                          "font-weight": "700"
                        },
                        attrs: { href: "#" }
                      },
                      [_vm._v("http://simplepay.hu/vasarlo-aff")]
                    ),
                    _c("br"),
                    _c("br")
                  ]
                ),
                _vm._v(" "),
                _c(
                  "h1",
                  {
                    staticStyle: {
                      "font-size": "1.5rem",
                      "padding-top": "30px",
                      "padding-bottom": "30px"
                    }
                  },
                  [_c("strong", [_vm._v("14. Adatvédelem")]), _c("br")]
                ),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticStyle: {
                      "font-size": "1.1rem",
                      "font-weight": "500",
                      "margin-bottom": "30px"
                    }
                  },
                  [
                    _vm._v(
                      "\n              A weboldal adatkezelési tájékoztatója elérhető a következő\n              oldalon: "
                    ),
                    _c(
                      "a",
                      {
                        staticStyle: {
                          color: "#c1132c",
                          "font-weight": "700",
                          "font-size": "1.1rem"
                        },
                        attrs: { href: "#" }
                      },
                      [_vm._v("http://teslapilota.hu/adatvedelem")]
                    ),
                    _c("br")
                  ]
                ),
                _vm._v(" "),
                _c(
                  "h1",
                  {
                    staticStyle: {
                      "font-size": "1.5rem",
                      "padding-top": "30px",
                      "padding-bottom": "30px"
                    }
                  },
                  [
                    _c("strong", [_vm._v("Budapest, 2019. augusztus 01.")]),
                    _c("br")
                  ]
                )
              ])
            ])
          ]
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
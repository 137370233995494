<template>
  <div v-if="gift" id="giftbox">
    <a :href="goToH('reservation', { g: true })">
      <div class="back">
        <div class="giftcardlayer"><img class="cardsize" src="~assets/img/card.png"></div>
        <div class="number1"></div>
        <div class="number2"></div>
        <div class="number3"></div>
        <div class="number4"></div>
        <div class="number5"></div>
        <div class="number6"></div>
        <div class="number7"></div>
        <div class="number8"></div>
        <div class="number9"></div>
        <div class="number10"></div>
        <div class="number11"></div>
        <div class="number12"></div>
        <div class="number13"></div>
        <div class="number14"></div>
        <div class="number15"></div>
        <div class="number16"></div>
      </div>
    </a>
  </div>
</template>

<script>

  import router from "assets/js/plugins/router";

  export default
  {
    name: "giftbox",

    data()
    {
      return {
        'gift': true
      };
    },

    mounted()
    {
      setTimeout(this.animateGiftBox.bind(this), 30000);
    },

    methods:
    {
      animateGiftBox: function()
      {
        this.$set(this, 'gift', false);
        this.$nextTick(() =>
        {
          this.$set(this, 'gift', true);
          setTimeout(this.animateGiftBox.bind(this), this.getRandomInt(20, 40) * 1000);
        });
      },

      getRandomInt: function(min, max)
      {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min;
      },

      goToH: function(name, query = {})
      {
        return router.resolve({name: name, query: query}).href;
      },
    }
  };
</script>

<style scoped>
  #giftbox {
    position: absolute;
    bottom: 30%;
    right: 0;
    z-index: 100;
    animation:
      first-frame 4s 1 0s normal,
      second-frame 5s 1 4s normal;
  }

  @keyframes first-frame {
    0% {
      left: calc(100% - 232px);
      transition-timing-function: ease-in-out;
    }
    100% {
      left: 100%;
    }
  }
  @keyframes second-frame {
    0% {
      left: calc(0% - 232px);
      transition-timing-function: ease-in-out;
    }
    100% {
      left: calc(100% - 232px);
    }
  }

  .st0{fill:#A84655;}
  .st1{fill:#FFFFFF;}


  div.back
  {
    text-align:right;
    width: 220px;
    height: 100px;
    background-size: 100% auto;
  }
  img.cardsize
  {
    width:85px !important;
  }
  div.giftcardlayer
  {
    position: relative;
    z-index:3;
    top:73px;
    right:20px;
  }

  div.number1 {
    width: 83%;
    height: 3px;
    background-image: linear-gradient(to right, rgba(255,0,0,0), rgba(255,0,0,1));
    position: relative;
    animation: mymove1 1s infinite alternate;
    text-align:right;
    margin:1px;
    margin-top:1px;
    float:right;

  }
  div.number2 {
    width: 87%;
    height: 3px;
    background-image: linear-gradient(to right, rgba(255,0,0,0), rgba(255,0,0,1));
    position: relative;
    animation: mymove2 1s infinite alternate;
    animation-delay: 1s;
    text-align:right;
    margin:1px;
    margin-top:2px;
    float:right;
  }
  div.number3 {
    width: 95%;
    height: 3px;
    background-image: linear-gradient(to right, rgba(255,0,0,0), rgba(255,0,0,1));
    position: relative;
    animation: mymove3 1s infinite alternate;
    animation-delay: 1.5s;
    text-align:right;
    margin:1px;
    margin-top:2px;
    float:right;
  }
  div.number4 {
    width: 80%;
    height: 3px;
    background-image: linear-gradient(to right, rgba(255,0,0,0), rgba(255,0,0,1));
    position: relative;
    animation: mymove4 1s infinite alternate;
    text-align:right;
    margin:1px;
    margin-top:2px;
    float:right;
  }
  div.number5 {
    width: 85%;
    height: 3px;
    background-image: linear-gradient(to right, rgba(255,0,0,0), rgba(255,0,0,1));
    position: relative;
    animation: mymove5 1s infinite alternate;
    animation-delay: 1.5s;
    text-align:right;
    margin:1px;
    margin-top:2px;
    float:right;
  }
  div.number6 {
    width: 91%;
    height: 3px;
    background-image: linear-gradient(to right, rgba(255,0,0,0), rgba(255,0,0,1));
    position: relative;
    animation: mymove6 1s infinite alternate;
    text-align:right;
    margin:1px;
    margin-top:2px;
    float:right;
  }
  div.number7 {
    width: 93%;
    height: 3px;
    background-image: linear-gradient(to right, rgba(255,0,0,0), rgba(255,0,0,1));
    position: relative;
    animation: mymove7 1s infinite alternate;
    animation-delay: 1.3s;
    text-align:right;
    margin:1px;
    margin-top:2px;
    float:right;
  }
  div.number8 {
    width: 87%;
    height: 3px;
    background-image: linear-gradient(to right, rgba(255,0,0,0), rgba(255,0,0,1));
    position: relative;
    animation: mymove8 1s infinite alternate;
    text-align:right;
    margin:1px;
    margin-top:2px;
    float:right;
  }
  div.number9 {
    width: 98%;
    height: 3px;
    background-image: linear-gradient(to right, rgba(255,0,0,0), rgba(255,0,0,1));
    position: relative;
    animation: mymove9 1s infinite alternate;
    animation-delay: 1.2s;
    text-align:right;
    margin:1px;
    margin-top:2px;
    float:right;
  }
  div.number10 {
    width: 96%;
    height: 3px;
    background-image: linear-gradient(to right, rgba(255,0,0,0), rgba(255,0,0,1));
    position: relative;
    animation: mymove10 1s infinite alternate;
    animation-delay: 1.3s;
    text-align:right;
    margin:1px;
    margin-top:2px;
    float:right;
  }
  div.number11 {
    width: 81%;
    height: 3px;
    background-image: linear-gradient(to right, rgba(255,0,0,0), rgba(255,0,0,1));
    position: relative;
    animation: mymove11 1s infinite alternate;
    text-align:right;
    margin:1px;
    margin-top:2px;
    float:right;
  }
  div.number12 {
    width: 76%;
    height: 3px;
    background-image: linear-gradient(to right, rgba(255,0,0,0), rgba(255,0,0,1));
    position: relative;
    animation: mymove12 1s infinite alternate;
    animation-delay: 1.5s;
    text-align:right;
    margin:1px;
    margin-top:2px;
    float:right;
  }
  div.number13 {
    width: 77%;
    height: 3px;
    background-image: linear-gradient(to right, rgba(255,0,0,0), rgba(255,0,0,1));
    position: relative;
    animation: mymove13 1s infinite alternate;
    text-align:right;
    margin:1px;
    margin-top:2px;
    float:right;
  }
  div.number14 {
    width: 79%;
    height: 3px;
    background-image: linear-gradient(to right, rgba(255,0,0,0), rgba(255,0,0,1));
    position: relative;
    animation: mymove14 1s infinite alternate;
    animation-delay: 1.4s;
    text-align:right;
    margin:1px;
    margin-top:2px;
    float:right;
  }
  div.number15 {
    width: 92%;
    height: 3px;
    background-image: linear-gradient(to right, rgba(255,0,0,0), rgba(255,0,0,1));
    position: relative;
    animation: mymove15 1s infinite alternate;
    animation-delay: 1.2s;
    text-align:right;
    margin:1px;
    margin-top:2px;
    float:right;
  }
  div.number16 {
    width: 92%;
    height: 1.5px;
    background-image: linear-gradient(to right, rgba(255,0,0,0), rgba(255,0,0,1));
    position: relative;
    animation: mymove16 1s infinite alternate;
    text-align:right;
    margin:1px;
    margin-top:2px;
    float:right;
  }
  div.number17 {
    width: 84%;
    height: 3px;
    background-image: linear-gradient(to right, rgba(255,0,0,0), rgba(255,0,0,1));
    position: relative;
    animation: mymove17 1s infinite alternate;
    animation-delay: 1.3s;
    text-align:right;
    margin:1px;
    margin-top:2px;
    float:right;
  }

  @keyframes mymove1 {
    to {width: 73%;}
  }
  @keyframes mymove2 {
    to {width: 70%;}
  }
  @keyframes mymove3 {
    to {width: 85%;}
  }
  @keyframes mymove4 {
    to {width: 75%;}
  }
  @keyframes mymove5 {
    to {width: 70%;}
  }
  @keyframes mymove6 {
    to {width: 85%;}
  }
  @keyframes mymove7 {
    to {width: 80%;}
  }
  @keyframes mymove8 {
    to {width: 78%;}
  }
  @keyframes mymove9 {
    to {width: 85%;}
  }
  @keyframes mymove10 {
    to {width: 82%;}
  }
  @keyframes mymove11 {
    to {width: 75%;}
  }
  @keyframes mymove12 {
    to {width: 65%;}
  }
  @keyframes mymove13 {
    to {width: 60%;}
  }
  @keyframes mymove14 {
    to {width: 60%;}
  }
  @keyframes mymove15 {
    to {width: 75%;}
  }
  @keyframes mymove16 {
    to {width: 79%;}
  }
</style>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "payment-status" },
    [
      _c("top-nav-bar"),
      _vm._v(" "),
      !_vm.loading
        ? _c(
            "div",
            {
              staticClass: "container",
              staticStyle: { "margin-top": "100px", "margin-bottom": "50px" }
            },
            [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-12 text-center" },
                  [
                    _c("p", {
                      domProps: { innerHTML: _vm._s(_vm.response_message) }
                    }),
                    _vm._v(" "),
                    _c("p", [_vm._v(_vm._s(_vm.message))]),
                    _vm._v(" "),
                    _c(
                      "router-link",
                      {
                        staticClass: "button",
                        staticStyle: { "margin-bottom": "10px" },
                        attrs: { to: "/", tag: "button" }
                      },
                      [_vm._v("Vissza a főoldalra")]
                    ),
                    _c("br"),
                    _vm._v(" "),
                    _vm.invoice_url
                      ? _c(
                          "button",
                          {
                            staticStyle: { "margin-bottom": "10px" },
                            on: {
                              click: function($event) {
                                return _vm.go(_vm.invoice_url)
                              }
                            }
                          },
                          [_vm._v("Számla")]
                        )
                      : _vm._e(),
                    _c("br"),
                    _vm._v(" "),
                    _vm.payment_url
                      ? _c(
                          "button",
                          {
                            staticStyle: { "margin-bottom": "10px" },
                            on: {
                              click: function($event) {
                                return _vm.go(_vm.payment_url)
                              }
                            }
                          },
                          [_vm._v("Fizetés")]
                        )
                      : _vm._e(),
                    _c("br"),
                    _vm._v(" "),
                    _vm.gift_card_url
                      ? _c(
                          "button",
                          {
                            staticStyle: { "margin-bottom": "10px" },
                            on: {
                              click: function($event) {
                                return _vm.go(_vm.gift_card_url)
                              }
                            }
                          },
                          [_vm._v("Ajándékkártya letöltése")]
                        )
                      : _vm._e()
                  ],
                  1
                )
              ])
            ]
          )
        : _c(
            "div",
            { staticStyle: { "margin-top": "100px", "margin-bottom": "50px" } },
            [_vm._v("Betöltés...")]
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "not-found" }, [
    _c(
      "div",
      { staticClass: "not-found-header" },
      [_c("top-nav-bar"), _vm._v(" "), _vm._m(0)],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "carousel-caption" }, [
      _c(
        "h1",
        {
          staticClass: "title",
          staticStyle: {
            position: "relative",
            bottom: "11.3rem",
            "font-size": "8rem"
          }
        },
        [_vm._v("\n        404\n      ")]
      ),
      _vm._v(" "),
      _c(
        "h1",
        {
          staticClass: "sub-title",
          staticStyle: {
            position: "relative",
            bottom: "11.3rem",
            "font-size": "4rem"
          }
        },
        [_vm._v("\n        A keresett oldal nem található!\n      ")]
      ),
      _vm._v(" "),
      _c(
        "h3",
        {
          staticClass: "font-weight-light",
          staticStyle: {
            position: "relative",
            bottom: "8.8rem",
            "font-size": "2.5rem"
          }
        },
        [_vm._v("\n        Kérjük ellenőrizze a beírt címet!\n      ")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pay" },
    [
      _c("top-nav-bar"),
      _vm._v(" "),
      !_vm.loading
        ? _c(
            "div",
            {
              staticClass: "container",
              staticStyle: { "margin-top": "100px", "margin-bottom": "50px" }
            },
            [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-12 text-center" }, [
                  _c("p", [
                    _vm._v(
                      "Egyenlítse ki számláját azonnal, bankkártyája segítségével."
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", {
                    domProps: { innerHTML: _vm._s(_vm.payment_form) }
                  })
                ])
              ])
            ]
          )
        : _c(
            "div",
            { staticStyle: { "margin-top": "100px", "margin-bottom": "50px" } },
            [_vm._v("Betöltés...")]
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="company">
   <div class="company-header">
     <top-nav-bar />
      <header>
      </header>
    </div>
    <section class="company-section">
      <div class="container" id="company-ctn">
        <div class="row">
          <div class="col-lg-5 comp-head">
            <h1><strong>CÉGEKNEK</strong><br></h1>
          </div>
          <div class="col-lg-6"></div>
        </div>
        <div class="row">
          <div class="col-lg-5 comp-left" style="padding-left: 0px;">
            <h2><strong>CSAPATÉPÍTŐ PROGRAMOT</strong><br><strong>SZERVEZEL?</strong><br></h2>
            <p>A TeslaPilóta programja garantáltan szórakoztató<br>élmény lesz a csapat tagjainak. Hívj minket egyedi<br>ajánlatunkért! A helyszín közelében legalább<br>22kw-os töltőnek kell lennie.<br></p>
          </div>
          <div class="col-lg-6 offset-xl-0"></div>
        </div>
        <div class="row">
          <div class="col-lg-5"></div>
          <div class="col-lg-6 comp-right">
            <h2><strong>VAGY MOTIVÁLNI SZERETNÉD A HÓNAP DOLGOZÓJÁT, ÉRTÉKESÍTŐJÉT EGY ÉLETRESZÓLÓ ÉLMÉNNYEL?</strong><br></h2>
            <p>A P100D ÉlményTesla vezetése lehet, hogy komoly<br>motiváló tud lenni a csapatod számára. Kérdezd<br>csak meg őket!<br></p>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-5 comp-left">
            <h2><strong>NETÁN DÍJAZNI SZERETNÉD</strong><br><strong>ÜGYFELED HŰSÉGÉT?</strong><br></h2>
            <p>Lepd meg ügyfeledet egy ÉlményTesla vezetéssel,<br>és lehet, hogy ezzel még a köztetek lévő kapcsolat<br>is szorosabbá válik, a forgalmad pedig kilő, mint<br>egy P100D!<br></p>
          </div>
          <div class="col-lg-6"></div>
        </div>
      </div>
    </section>
    <div style="padding-top: 90px;">
      <div class="container">
        <div class="row">
          <div class="col-md-6 contactform-left">
            <h1 class="text-uppercase" style="margin-bottom: 0px;"><strong>Üzenetküldés</strong></h1>
            <h3 class="text-uppercase">A Teslapilótának</h3>
            <p>Ha kérésed vagy kérdésed lenne, akkor küldj üzenetet a Teslapilóta irányítóközpontba! Add meg adataidat, hogy felvehessük veled a kapcsolatot.</p>
          </div>
          <div class="col-md-6 contactform-right">
            <contact-form />
          </div>
        </div>
      </div>
    </div>
    <div><img class="center" src="~assets/img/img-header-logo-small-gray.png" style="padding-top: 8rem;"></div>
    <div class="container" id="followus">
      <div class="d-none d-xl-block">
        <div class="text-uppercase">
          <div class="container">
            <div class="row">
              <div class="col-md-4 col-xl-10 offset-xl-1">
                <h1 class="text-uppercase text-left"><strong>KÖVESS</strong></h1>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="container">
            <div class="row">
              <div class="col-md-4 col-xl-2 offset-xl-5 text-right align-self-center">
                <a href="http://www.facebook.com/teslapilota"><img style="padding-right: 1.875rem" src="~assets/img/btn-facebook.png"></a><a href="http://www.instagram.com/teslapilota"><img src="~assets/img/btn-instagram.png"></a>
              </div>
              <div class="col-md-4 col-xl-4 offset-xl-0">
                <h1 class="text-uppercase text-right"><strong>MINKET</strong></h1>
              </div>
              <div class="clearfix"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="mobile d-block d-xl-none">
        Kövess minket
        <div>
          <a href="https://www.facebook.com/TeslaPilota/" target="_blank">
            <img src="~assets/img/btn-facebook.png">
          </a>
          <a href="https://www.instagram.com/teslapilota/" target="_blank">
            <img src="~assets/img/btn-instagram.png">
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import TopNavBar from '../general/top-nav-bar';
  import ContactForm from '../general/contact-form';

  export default {
    name: "company",
    components: {
      TopNavBar,
      ContactForm
    }
  };
</script>

<style scoped>
  .company-header {
    background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url('~assets/img/Teslapilota_Cegeknek-header.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 770px;
    position: relative;
    overflow: hidden;
    font-family: Montserrat, sans-serif;
    font-weight: 500;
  }

  @media (max-width: 1200px) {
    .company-header {
      width: 100%;
      height: 770px;
      position: relative;
      overflow: hidden;
    }
  }

  @media (max-width: 1024px) {
    .company-header {
      width: 100%;
      height: 650px;
      position: relative;
      overflow: hidden;
    }
  }

  @media (max-width: 768px) {
    .company-header {
      width: 100%;
      height: 550px;
      position: relative;
      overflow: hidden;
      text-align: center;
    }
  }

  @media (max-width: 480px) {
    .company-header {
      width: 100%;
      height: 420px;
      position: relative;
      overflow: hidden;
    }
  }

  .company-header > header {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 50px 15%;
  }

  .company-header h1 {
    text-transform: uppercase;
    color: white;
    font-weight: 700;
    font-size: 4rem;
    margin-bottom: 0;
  }

  @media (max-width: 1200px) {
    .company-header h1 {
      text-transform: uppercase;
      color: white;
      font-weight: 700;
      font-size: 4rem;
      margin-bottom: 0;
    }
  }

  @media (max-width: 1024px) {
    .company-header h1 {
      text-transform: uppercase;
      color: white;
      font-weight: 700;
      font-size: 3.5rem;
      margin-bottom: 0;
    }
  }

  @media (max-width: 768px) {
    .company-header h1 {
      text-transform: uppercase;
      color: white;
      font-weight: 700;
      font-size: 2rem;
      margin-bottom: 0;
    }
  }

  @media (max-width: 480px) {
    .company-header h1 {
      text-transform: uppercase;
      color: white;
      font-weight: 700;
      font-size: 1.3rem;
      margin-bottom: 0;
    }
  }

  .company-header h2 {
    text-transform: uppercase;
    color: white;
    font-weight: 500;
    font-size: 2.3rem;
    margin-top: 0;
  }

  @media (max-width: 1200px) {
    .company-header h2 {
      text-transform: uppercase;
      color: white;
      font-weight: 500;
      font-size: 2.3rem;
      margin-top: 0;
    }
  }

  @media (max-width: 1024px) {
    .company-header h2 {
      text-transform: uppercase;
      color: white;
      font-weight: 500;
      font-size: 2rem;
      margin-top: 0;
    }
  }

  @media (max-width: 768px) {
    .company-header h2 {
      text-transform: uppercase;
      color: white;
      font-weight: 500;
      font-size: 1.4rem;
      margin-top: 0;
    }
  }

  @media (max-width: 480px) {
    .company-header h2 {
      text-transform: uppercase;
      color: white;
      font-weight: 500;
      font-size: 1rem;
      margin-top: 0;
    }
  }

  .company-header p {
    text-transform: uppercase;
    color: white;
    font-weight: 500;
    font-size: 1rem;
    margin-top: 0;
    margin-top: 40px;
  }

  @media (max-width: 1024px) {
    .company-header p {
      text-transform: uppercase;
      color: white;
      font-weight: 500;
      font-size: 1.2rem;
      margin-top: 0;
      margin-top: 60px;
    }
  }

  @media (max-width: 768px) {
    .company-header p {
      text-transform: uppercase;
      color: white;
      font-weight: 500;
      font-size: 1.1rem;
      margin-top: 0;
      margin-top: 50px;
    }
  }

  * {
    box-sizing: border-box;
  }

  #tesla-logo-header {
    position: absolute;
    top: -23rem;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 265px;
  }

  @media (max-width: 1200px) {
    #tesla-logo-header {
      position: absolute;
      top: -24rem;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 175px;
    }
  }

  @media (max-width: 1024px) {
    #tesla-logo-header {
      position: absolute;
      top: -18rem;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 150px;
    }
  }

  @media (max-width: 768px) {
    #tesla-logo-header {
      position: absolute;
      top: -15rem;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 125px;
    }
  }

  @media (max-width: 480px) {
    #tesla-logo-header {
      position: absolute;
      top: -9rem;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 120px;
    }
  }

  .company-section {
    margin-left: 150px;
    margin-top: 100px;
  }

  @media (max-width: 991px) {
    .company-section {
      margin-left: 15px;
      margin-right: 15px;
      text-align: center;
    }
  }

  #company-ctn h1 {
    font-size: 3rem;
    text-transform: uppercase;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    #company-ctn h1 {
      font-size: 3rem;
      text-transform: uppercase;
    }
  }

  #company-ctn h2 {
    font-size: 1.4rem;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    #company-ctn h2 {
      font-size: 1.3rem;
      text-transform: uppercase;
    }
  }

  #company-ctn p {
    font-size: 1rem;
    margin-bottom: 0px;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    #company-ctn p {
      font-size: 0.8rem;
      margin-bottom: 0px;
    }
  }

  .comp-head {
    text-align: right;
    padding-right: 55px;
  }

  @media (max-width: 991px) {
    .comp-head {
      text-align: center;
      padding-right: 0px;
      margin-bottom: 20px;
    }
  }

  .comp-left {
    padding-right: 50px;
    border-right: 4px solid black;
    text-align: right;
  }

  @media (max-width: 991px) {
    .comp-left {
      padding-right: 0px;
      border-right: 4px solid black;
      text-align: center;
      border: none;
      margin-bottom: 20px;
    }
  }

  .comp-right {
    padding-left: 50px;
    border-left: 4px solid black;
    margin-left: 35px;
    padding-right: 0px;
    text-align: left;
  }

  @media (max-width: 991px) {
    .comp-right {
      padding-left: 0px;
      border-left: 4px solid black;
      margin-left: 15px;
      padding-right: 0px;
      text-align: center;
      border: none;
      margin-bottom: 20px;
    }
  }

  #followus {
    margin-top: 90px;
  }

  * {
    /*box-sizing: inherit;*/
  }

  *:before, *:after {
    /*box-sizing: inherit;*/
  }

  img.center {
    display: block;
    margin: 0 auto;
  }

  * {
    /*box-sizing: inherit;*/
  }

  *:before, *:after {
    /*box-sizing: inherit;*/
  }

  .form-control.form-control-lg {
    font-size: 1rem;
  }

  .text-uppercase.text-right {
    -webkit-tap-highlight-color: transparent;
    --breakpoint-xs: 0;
    --breakpoint-sm: 36rem;
    --breakpoint-md: 48rem;
    --breakpoint-lg: 62rem;
    --breakpoint-xl: 75rem;
    --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    text-align: right;
    text-transform: uppercase;
    font-family: Raleway;
    font-weight: 800;
    color: #b6b6b6;
    box-sizing: border-box;
    margin-bottom: 1rem;
    font-size: 7.0625rem;
    line-height: 7.0625rem;
    margin-top: -1.875rem;
  }

  .text-uppercase {
    margin-bottom: 0rem;
  }

  .text-uppercase.text-left {
    -webkit-tap-highlight-color: transparent;
    --breakpoint-xs: 0;
    --breakpoint-sm: 36rem;
    --breakpoint-md: 48rem;
    --breakpoint-lg: 62rem;
    --breakpoint-xl: 75rem;
    text-align: left;
    text-transform: uppercase;
    font-family: Raleway;
    font-weight: 800;
    color: #b6b6b6;
    box-sizing: border-box;
    margin-top: 0;
    font-size: 11.875rem;
    line-height: 11.875rem;
    margin-bottom: 0;
  }

  .d-xl-none {
    -webkit-tap-highlight-color: transparent;
    line-height: 1.5;
    text-align: center;
    color: #2b2b2b;
    text-transform: uppercase;
    font-family: Raleway;
    font-weight: 800;
    box-sizing: border-box;
    font-size: 41px;
    padding-bottom: 2rem;
  }

  #followus {
    margin-bottom: 90px;
  }

  .contactform-left {

  }

  @media (max-width: 768px) {
    .contactform-left {
      text-align: center;
    }
  }
</style>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "about" }, [
    _c(
      "div",
      { staticClass: "about-header" },
      [_c("top-nav-bar"), _vm._v(" "), _vm._m(0)],
      1
    ),
    _vm._v(" "),
    _vm._m(1),
    _vm._v(" "),
    _vm._m(2)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("header", [_c("h1", [_vm._v("RÓLUNK")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "container", staticStyle: { "margin-top": "100px" } },
      [
        _c(
          "div",
          {
            staticClass: "table-responsive table-borderless",
            staticStyle: {
              "font-size": "1.1rem",
              "font-family": "Montserrat, sans-serif"
            }
          },
          [
            _c("table", { staticClass: "table" }, [
              _c("tbody", [
                _c("tr", [
                  _c("td", [
                    _vm._v(
                      "\n              A 1990-es évek közepétől több, mint 20 évet töltöttünk a hazai\n              elektronikus kaszinózás világában, így az elektronikus\n              szórakoztatás a vérünkben van."
                    ),
                    _c("br")
                  ]),
                  _vm._v(" "),
                  _c("td", {
                    staticStyle: {
                      "border-right": "4px solid black",
                      "padding-top": "12px",
                      "padding-bottom": "13px"
                    }
                  }),
                  _vm._v(" "),
                  _c("td")
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td"),
                  _vm._v(" "),
                  _c("td"),
                  _vm._v(" "),
                  _c("td")
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td"),
                  _vm._v(" "),
                  _c("td", {
                    staticStyle: { "border-left": "4px solid black" }
                  }),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      "\n              Budapest és az ország legszínvonalasabb elektronikus kaszinóit\n              segítettük életrekelteni és gondoskodtunk róla, hogy itthon is\n              nyugat-európai színvonalon lehessen élvezni a szórakoztatóipar\n              ezen formáját."
                    ),
                    _c("br")
                  ])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td"),
                  _vm._v(" "),
                  _c("td"),
                  _vm._v(" "),
                  _c("td")
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [
                    _vm._v(
                      "\n              Akit érdekel a múltunk, az az ügynökségi oldalunkon megnézheti\n              hogyan teltek ezek az évek: www.brazilreklam.hu (az oldal\n              flashes, így mobilról nem tudod megnézni, csak laptopról vagy\n              asztali gépről)."
                    ),
                    _c("br")
                  ]),
                  _vm._v(" "),
                  _c("td", {
                    staticStyle: { "border-right": "4px solid black" }
                  }),
                  _vm._v(" "),
                  _c("td")
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td"),
                  _vm._v(" "),
                  _c("td"),
                  _vm._v(" "),
                  _c("td")
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td"),
                  _vm._v(" "),
                  _c("td", {
                    staticStyle: { "border-left": "4px solid black" }
                  }),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      "\n              Az elmúlt években mi is nagy érdeklődéssel figyeltük a Tesla\n              térnyerését és miután vettünk egyet, kipróbáltuk ezt a csendesen\n              gyomorbavágó, szédületes fenevadat - szerelem volt első\n              látásra!"
                    ),
                    _c("br")
                  ])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td"),
                  _vm._v(" "),
                  _c("td"),
                  _vm._v(" "),
                  _c("td")
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [
                    _vm._v(
                      "\n              Úgy gondoltuk, hogy ezt az élményt meg kell oszatnunk minél több\n              emberrel, és meg kell teremteni a lehetőséget, hogy ezt az\n              eszméletlen élményt minél többen átélhessék."
                    ),
                    _c("br")
                  ]),
                  _vm._v(" "),
                  _c("td", {
                    staticStyle: { "border-right": "4px solid black" }
                  }),
                  _vm._v(" "),
                  _c("td")
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td"),
                  _vm._v(" "),
                  _c("td"),
                  _vm._v(" "),
                  _c("td")
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td"),
                  _vm._v(" "),
                  _c("td", {
                    staticStyle: { "border-left": "4px solid black" }
                  }),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      "\n              Ezért született ez az oldal és ha velünk tartasz, belekóstolsz\n              ebbe"
                    ),
                    _c("br"),
                    _vm._v(" "),
                    _c("p", { attrs: { id: "textspan" } }, [
                      _vm._v("A LOPAKODÓ, CSENDES ŐRÜLETBE,"),
                      _c("br")
                    ]),
                    _vm._v(
                      "\n              te is meg akarod zabolázni azt a bármikor ugrásra kész 772\n              lovat!"
                    ),
                    _c("br")
                  ])
                ])
              ])
            ])
          ]
        ),
        _vm._v(" "),
        _c("h1", { staticClass: "text-center", attrs: { id: "about-big" } }, [
          _c("br"),
          _vm._v("AZ EGÉSZ ORSZÁGBAN"),
          _c("br"),
          _vm._v("EGYEDÜL NÁLUNK TUDSZ FORGALOMBAN, ÉLES\n      BEVETÉSEN"),
          _c("br"),
          _c("strong", [_vm._v("772 LÓERŐS TESLA MODEL S P100D-T VEZETNI.")]),
          _c("br"),
          _c("br")
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "container", attrs: { id: "followus" } }, [
      _c("div", { staticClass: "d-none d-xl-block" }, [
        _c("div", { staticClass: "text-uppercase" }, [
          _c("div", { staticClass: "container" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-md-4 col-xl-10 offset-xl-2" }, [
                _c("h1", { staticClass: "text-uppercase text-left" }, [
                  _c("strong", [_vm._v("KÖVESS")])
                ])
              ])
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", [
          _c("div", { staticClass: "container" }, [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                {
                  staticClass:
                    "col-md-4 col-xl-2 offset-xl-5 text-right align-self-center"
                },
                [
                  _c(
                    "a",
                    { attrs: { href: "http://www.facebook.com/teslapilota" } },
                    [
                      _c("img", {
                        staticStyle: { "padding-right": "1.875rem" },
                        attrs: { src: require("assets/img/btn-facebook.png") }
                      })
                    ]
                  ),
                  _c(
                    "a",
                    { attrs: { href: "http://www.instagram.com/teslapilota" } },
                    [
                      _c("img", {
                        attrs: { src: require("assets/img/btn-instagram.png") }
                      })
                    ]
                  )
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "col-md-4 col-xl-4 offset-xl-0" }, [
                _c("h1", { staticClass: "text-uppercase text-right" }, [
                  _c("strong", [_vm._v("MINKET")])
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "clearfix" })
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "mobile d-block d-xl-none" }, [
        _vm._v("\n      Kövess minket\n      "),
        _c("div", [
          _c(
            "a",
            {
              attrs: {
                href: "https://www.facebook.com/TeslaPilota/",
                target: "_blank"
              }
            },
            [
              _c("img", {
                attrs: { src: require("assets/img/btn-facebook.png") }
              })
            ]
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              attrs: {
                href: "https://www.instagram.com/teslapilota/",
                target: "_blank"
              }
            },
            [
              _c("img", {
                attrs: { src: require("assets/img/btn-instagram.png") }
              })
            ]
          )
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "adatvedelem" }, [
    _c(
      "div",
      { staticClass: "adatvedelem-header" },
      [_c("top-nav-bar"), _vm._v(" "), _vm._m(0)],
      1
    ),
    _vm._v(" "),
    _vm._m(1)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("header", [_c("h1", [_vm._v("ADATVÉDELEM")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "container", staticStyle: { "margin-top": "100px" } },
      [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col" }, [
            _c(
              "p",
              {
                staticClass: "text-center",
                staticStyle: {
                  "font-family": "Montserrat, sans-serif",
                  "font-size": "2rem",
                  "font-weight": "600",
                  "margin-bottom": "90px",
                  "margin-top": "50px"
                }
              },
              [_vm._v("\n          Teslapilota.hu"), _c("br")]
            ),
            _vm._v(" "),
            _c(
              "h1",
              {
                staticClass: "text-uppercase text-center",
                staticStyle: {
                  "font-size": "4rem",
                  "font-family": "Montserrat, sans-serif",
                  "font-weight": "600",
                  "margin-bottom": "300px"
                }
              },
              [
                _vm._v("\n          Adatvédelmi"),
                _c("br"),
                _vm._v("szabályzat\n        ")
              ]
            ),
            _vm._v(" "),
            _c(
              "h2",
              {
                staticClass: "text-uppercase",
                staticStyle: { "margin-bottom": "30px" }
              },
              [_c("strong", [_vm._v("1. ")]), _vm._v("Bevezetés\n        ")]
            ),
            _vm._v(" "),
            _c("p", { staticClass: "text-justify" }, [
              _vm._v("\n          A\n          "),
              _c("strong", [
                _vm._v("Brazil Reklámügynökség Korlátolt Felelősségű Társaság")
              ]),
              _vm._v(
                "\n          (cégjegyzékszám: 01-09-703588, adószám: 12791022-2-41, székhely:\n          1014 Budapest, Országház utca 22. 1.em. 2.) (a továbbiakban:\n          Szolgáltató, adatkezelő) alá veti magát a következőszabályzatnak:\n          "
              ),
              _c("br")
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "text-justify" }, [
              _vm._v(
                "\n          A természetes személyeknek a személyes adatok kezelése tekintetében\n          történő védelméről és az ilyen adatok szabad áramlásáról, valamint a\n          95/46/EK rendelet hatályon kívül helyezéséről (általános adatvédelmi\n          rendelet) AZ EURÓPAI PARLAMENT ÉS A TANÁCS (EU) 2016/679 RENDELETE\n          (2016. április 27.) szerint az alábbi tájékoztatást adjuk. "
              ),
              _c("br")
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n          Jelen adatvédelmi szabályzat az alábbi oldalak adatkezelését\n          szabályozza: "
              ),
              _c("br")
            ]),
            _vm._v(" "),
            _c("p", [
              _c(
                "a",
                {
                  staticStyle: { color: "#c1132c", "font-weight": "500" },
                  attrs: { href: "teslapilota.hu" }
                },
                [_vm._v("teslapilota.hu")]
              )
            ]),
            _vm._v(" "),
            _c("p", [
              _c(
                "a",
                {
                  staticStyle: { color: "#c1132c", "font-weight": "500" },
                  attrs: { href: "teslapilóta.hu" }
                },
                [_vm._v("teslapilóta.hu")]
              )
            ]),
            _vm._v(" "),
            _c("p", [
              _c(
                "a",
                {
                  staticStyle: { color: "#c1132c", "font-weight": "500" },
                  attrs: { href: "elmenytesla.hu" }
                },
                [_vm._v("elmenytesla.hu")]
              )
            ]),
            _vm._v(" "),
            _c("p", { staticStyle: { "font-weight": "500" } }, [
              _c(
                "a",
                {
                  staticStyle: { color: "#c1132c" },
                  attrs: { href: "élménytesla.hu" }
                },
                [_vm._v("élménytesla.hu")]
              )
            ]),
            _vm._v(" "),
            _c("p", [
              _c(
                "a",
                {
                  staticStyle: { color: "#c1132c", "font-weight": "500" },
                  attrs: { href: "pilóta.hu" }
                },
                [_vm._v("pilóta.hu")]
              )
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v("Az adatvédelmi szabályzat elérhető az alábbi oldalról: "),
              _c("br")
            ]),
            _vm._v(" "),
            _c("p", [
              _c(
                "a",
                {
                  staticStyle: { color: "#c1132c", "font-weight": "500" },
                  attrs: { href: "http://www.teslapilota.hu/adatvedelem" }
                },
                [_vm._v("http://teslapilota.hu/adatvedelem")]
              ),
              _c("br")
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n          A szabályzat módosításai a fenti címen történő közzététellel lépnek\n          hatályba. "
              ),
              _c("br")
            ]),
            _vm._v(" "),
            _c(
              "h3",
              {
                staticStyle: {
                  "margin-top": "40px",
                  "margin-bottom": "40px",
                  "margin-left": "25px"
                }
              },
              [
                _vm._v("\n          1.1. AZ ADATKEZELŐ ÉS ELÉRHETŐSÉGEI:"),
                _c("br")
              ]
            ),
            _vm._v(" "),
            _c("p", [
              _vm._v("\n          Név:\n          "),
              _c("strong", [
                _vm._v("Brazil Reklámügynökség Korlátolt Felelősségű Társaság")
              ]),
              _c("br"),
              _vm._v("Székhely: 1014 Budapest, Országház utca 22. 1. em. 2."),
              _c("br"),
              _vm._v("E-mail: "),
              _c(
                "a",
                {
                  staticStyle: { color: "#c1132c", "font-weight": "500" },
                  attrs: { href: "mailto:hello@teslapilota.hu" }
                },
                [_vm._v("hello@teslapilota.hu")]
              ),
              _c("br"),
              _vm._v("Telefon: "),
              _c("br")
            ]),
            _vm._v(" "),
            _c(
              "h2",
              {
                staticClass: "text-uppercase",
                staticStyle: { "margin-bottom": "30px", "margin-top": "30px" }
              },
              [
                _c("strong", [_vm._v("2. ")]),
                _vm._v("FOGALOM MEGHATÁROZÁSOK"),
                _c("br")
              ]
            ),
            _vm._v(" "),
            _c("p", { staticClass: "text-justify" }, [
              _c("strong", [_vm._v("1. ")]),
              _c("em", [_vm._v("„személyes adat”")]),
              _vm._v(
                ": azonosított vagy\n          azonosítható természetes személyre („érintett”) vonatkozó bármely\n          információ; azonosítható az a természetes személy, aki közvetlen\n          vagy közvetett módon, különösen valamely azonosító, például név,\n          szám, helymeghatározó adat, online azonosító vagy a természetes\n          személy testi, fiziológiai, genetikai, szellemi, gazdasági,\n          kulturális vagy szociális azonosságára vonatkozó egy"
              ),
              _c("br"),
              _vm._v("vagy több\n          tényező alapján azonosítható; "),
              _c("br")
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "text-justify" }, [
              _c("strong", [_vm._v("2.")]),
              _vm._v(" "),
              _c("em", [_vm._v("„adatkezelés”")]),
              _vm._v(
                ": a személyes adatokon\n          vagy adatállományokon automatizált vagy nem automatizált módon\n          végzett bármely művelet vagy műveletek összessége, így a gyűjtés,\n          rögzítés, rendszerezés, tagolás, tárolás, átalakítás vagy\n          megváltoztatás, lekérdezés, betekintés, felhasználás, közlés\n          továbbítás, terjesztés vagy egyéb módon történő hozzáférhetővé tétel\n          útján, összehangolás vagy összekapcsolás, korlátozás, törlés,\n          illetve megsemmisítés; "
              ),
              _c("br")
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "text-justify" }, [
              _c("strong", [_vm._v("3.")]),
              _vm._v(" "),
              _c("em", [_vm._v("„adatkezelő”")]),
              _vm._v(
                ": az a természetes vagy\n          jogi személy, közhatalmi szerv, ügynökség vagy bármely egyéb szerv,\n          amely a személyes adatok kezelésének céljait és eszközeit önállóan\n          vagy másokkal együtt meghatározza; ha az adatkezelés céljait és\n          eszközeit az uniós vagy a tagállami jog határozza meg, az\n          adatkezelőt vagy az adatkezelő kijelölésére vonatkozó különös\n          szempontokat az uniós vagy a tagállami jog is meghatározhatja;\n          "
              ),
              _c("br")
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "text-justify" }, [
              _c("strong", [_vm._v("4.")]),
              _vm._v(" "),
              _c("em", [_vm._v("„adatfeldolgozó”")]),
              _vm._v(
                ": az a természetes vagy\n          jogi személy, közhatalmi szerv, ügynökség vagy bármely egyéb szerv,\n          amely az adatkezelő nevében személyes adatokat kezel; "
              ),
              _c("br")
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "text-justify" }, [
              _c("strong", [_vm._v("5.")]),
              _vm._v(" "),
              _c("em", [_vm._v("„címzett”")]),
              _vm._v(
                ": az a természetes vagy jogi\n          személy, közhatalmi szerv, ügynökség vagy bármely egyéb szerv,\n          akivel vagy amellyel a személyes adatot közlik, függetlenül attól,\n          hogy harmadik fél-e. Azon közhatalmi szervek, amelyek egy egyedi\n          vizsgálat keretében az uniós vagy a tagállami joggal összhangban\n          férhetnek hozzá személyes adatokhoz, nem minősülnek címzettnek; az\n          említett adatok e közhatalmi szervek általi kezelése meg kell, hogy\n          feleljen az adatkezelés céljainak megfelelően az alkalmazandó\n          adatvédelmi szabályoknak; "
              ),
              _c("br")
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "text-justify" }, [
              _c("strong", [_vm._v("6.")]),
              _vm._v(" "),
              _c("em", [_vm._v("„az érintett hozzájárulása”")]),
              _vm._v(
                ": az\n          érintett akaratának önkéntes, konkrét és megfelelő tájékoztatáson\n          alapuló és egyértelmű kinyilvánítása, amellyel az érintett\n          nyilatkozat vagy a megerősítést félreérthetetlenül kifejező\n          cselekedet útján jelzi, hogy beleegyezését adja az őt érintő\n          személyes adatok kezeléséhez;"
              ),
              _c("br")
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "text-justify" }, [
              _c("strong", [_vm._v("7.")]),
              _vm._v(" "),
              _c("em", [_vm._v("„adatvédelmi incidens”")]),
              _vm._v(
                ": a biztonság\n          olyan sérülése, amely a továbbított, tárolt vagy más módon kezelt\n          személyes adatok véletlen vagy jogellenes megsemmisítését,\n          elvesztését, megváltoztatását, jogosulatlan közlését vagy az azokhoz\n          való jogosulatlan hozzáférést eredményezi. "
              ),
              _c("br")
            ]),
            _vm._v(" "),
            _c(
              "h2",
              {
                staticClass: "text-uppercase",
                staticStyle: { "margin-bottom": "30px", "margin-top": "30px" }
              },
              [
                _c("strong", [_vm._v("3. ")]),
                _vm._v("A SZEMÉLYES ADATOK KEZELÉSÉRE VONATKOZÓ ELVEK"),
                _c("br")
              ]
            ),
            _vm._v(" "),
            _c("p", [_vm._v("A személyes adatok: "), _c("br")]),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "text-justify",
                staticStyle: { "margin-left": "30px" }
              },
              [
                _c("strong", [_vm._v("a)")]),
                _vm._v(
                  " kezelését jogszerűen és tisztességesen, valamint\n          az érintett számára átlátható módon kell végezni ("
                ),
                _c("em", [
                  _vm._v("„jogszerűség, tisztességes eljárás és átláthatóság”")
                ]),
                _vm._v("); "),
                _c("br")
              ]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "text-justify",
                staticStyle: { "margin-left": "30px" }
              },
              [
                _c("strong", [_vm._v("b)")]),
                _vm._v(
                  " gyűjtése csak meghatározott, egyértelmű és\n          jogszerű célból történjen, és azokat ne kezeljék ezekkel a célokkal\n          össze nem egyeztethető módon; a 89. cikk (1) bekezdésének\n          megfelelően nem minősül az eredeti céllal össze nem egyeztethetőnek\n          a közérdekű archiválás céljából, tudományos és történelmi kutatási\n          célból vagy statisztikai célból történő további adatkezelés ("
                ),
                _c("em", [_vm._v("„célhoz kötöttség”")]),
                _vm._v("); "),
                _c("br")
              ]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "text-justify",
                staticStyle: { "margin-left": "30px" }
              },
              [
                _c("strong", [_vm._v("c)")]),
                _vm._v(
                  " az adatkezelés céljai szempontjából megfelelőek\n          és relevánsak kell, hogy legyenek, és a szükségesre kell\n          korlátozódniuk ("
                ),
                _c("em", [_vm._v("„adattakarékosság”")]),
                _vm._v("); "),
                _c("br")
              ]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "text-justify",
                staticStyle: { "margin-left": "30px" }
              },
              [
                _c("strong", [_vm._v("d)")]),
                _vm._v(
                  " pontosnak és szükség esetén naprakésznek kell\n          lenniük; minden észszerű intézkedést meg kell tenni annak érdekében,\n          hogy az adatkezelés céljai szempontjából pontatlan személyes\n          adatokat haladéktalanul töröljék vagy helyesbítsék\n          ("
                ),
                _c("em", [_vm._v("„pontosság”")]),
                _vm._v("); "),
                _c("br")
              ]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "text-justify",
                staticStyle: { "margin-left": "30px" }
              },
              [
                _c("strong", [_vm._v("e)")]),
                _vm._v(
                  " tárolásának olyan formában kell történnie, amely\n          az érintettek azonosítását csak a személyes adatok kezelése\n          céljainak eléréséhez szükséges ideig teszi lehetővé; a személyes\n          adatok ennél hosszabb ideig történő tárolására csak akkor kerülhet\n          sor, amennyiben a személyes adatok kezelésére a 89. cikk (1)\n          bekezdésének megfelelően közérdekű archiválás céljából, tudományos\n          és történelmi kutatási"
                ),
                _c("br"),
                _vm._v(
                  "célból vagy statisztikai célból kerül\n          majd sor, az e rendeletben az érintettek jogainak és szabadságainak\n          védelme érdekében előírt megfelelő technikai és szervezési\n          intézkedések végrehajtására is figyelemmel ("
                ),
                _c("em", [_vm._v("„korlátozott tárolhatóság”")]),
                _vm._v("); "),
                _c("br")
              ]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "text-justify",
                staticStyle: { "margin-left": "30px" }
              },
              [
                _c("strong", [_vm._v("f)")]),
                _vm._v(
                  " kezelését oly módon kell végezni, hogy megfelelő\n          technikai vagy szervezési intézkedések alkalmazásával biztosítva\n          legyen a személyes adatok megfelelő biztonsága, az adatok\n          jogosulatlan vagy jogellenes kezelésével, véletlen elvesztésével,\n          megsemmisítésével vagy károsodásával szembeni védelmet is ideértve\n          ("
                ),
                _c("em", [_vm._v("„integritás és bizalmas jelleg”")]),
                _vm._v("). "),
                _c("br")
              ]
            ),
            _vm._v(" "),
            _c("p", { staticClass: "text-justify" }, [
              _vm._v(
                "\n          Az adatkezelő felelős a fentiek megfelelésért, továbbá képesnek kell\n          lennie e megfelelés igazolására ("
              ),
              _c("em", [_vm._v("„elszámoltathatóság”")]),
              _vm._v(").\n          "),
              _c("br")
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "text-justify" }, [
              _vm._v(
                "\n          Az adatkezelő nyilatkozik, hogy adatkezelése a jelen pontban foglalt\n          alapelveknek megfelelően történik. "
              ),
              _c("br")
            ]),
            _vm._v(" "),
            _c(
              "h2",
              {
                staticClass: "text-uppercase",
                staticStyle: { "margin-bottom": "30px", "margin-top": "30px" }
              },
              [_vm._v("\n          4. EGYES ADATKEZELÉSEK"), _c("br")]
            ),
            _vm._v(" "),
            _c(
              "h3",
              {
                staticStyle: {
                  "margin-top": "40px",
                  "margin-bottom": "40px",
                  "margin-left": "25px"
                }
              },
              [_vm._v("\n          4.1. JELENTKEZÉS VEZETÉSRE"), _c("br")]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "text-justify",
                staticStyle: { "margin-left": "30px" }
              },
              [
                _c("strong", [_vm._v("1. ")]),
                _vm._v(
                  "Az adatgyűjtés ténye, a kezelt adatok köre és az\n          "
                ),
                _c("strong", [_vm._v("adatkezelés céljai")]),
                _vm._v(": "),
                _c("br")
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "table-responsive text-left",
                staticStyle: { "margin-bottom": "30px", "margin-top": "30px" }
              },
              [
                _c("table", { staticClass: "table table-striped" }, [
                  _c("thead", [
                    _c("tr", [
                      _c("th", [_vm._v("Személyes adat")]),
                      _vm._v(" "),
                      _c("th", [_vm._v("Az adatkezelés célja"), _c("br")]),
                      _vm._v(" "),
                      _c("th", [_vm._v("Jogalap "), _c("br")])
                    ])
                  ]),
                  _vm._v(" "),
                  _c("tbody", [
                    _c("tr", [
                      _c("td", [
                        _vm._v("Jelentkező neve,"),
                        _c("br"),
                        _vm._v("megajándékozott neve "),
                        _c("br")
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v("Azonosítás, szerződés"),
                        _c("br"),
                        _vm._v("létrehozása "),
                        _c("br")
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v("a GDPR 6. cikk (1) bekezdés b) pont."),
                        _c("br")
                      ])
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("td", [_vm._v("Telefonszám "), _c("br")]),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v("Kapcsolattartás, egyeztetés "),
                        _c("br")
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v("a GDPR 6. cikk (1) bekezdés b) pont."),
                        _c("br")
                      ])
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("td", [_vm._v("E-mail cím "), _c("br")]),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v(
                          "\n                  Kapcsolattartás, az információk"
                        ),
                        _c("br"),
                        _vm._v("megküldéséhez\n                  szükséges. "),
                        _c("br")
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v("a GDPR 6. cikk (1) bekezdés b) pont."),
                        _c("br")
                      ])
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("td", [_vm._v("Számlázási név és cím "), _c("br")]),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v(
                          "\n                  A szabályszerű számla kiállítása,"
                        ),
                        _c("br"),
                        _vm._v(
                          "továbbá a szerződés\n                  létrehozása,"
                        ),
                        _c("br"),
                        _vm._v("tartalmának meghatározása, módosítása,"),
                        _c("br"),
                        _vm._v(
                          "teljesítésének\n                  figyelemmel kísérése,"
                        ),
                        _c("br"),
                        _vm._v(
                          "az abból származó díjak\n                  számlázása,"
                        ),
                        _c("br"),
                        _vm._v(
                          "valamint az azzal kapcsolatos\n                  követelések"
                        ),
                        _c("br"),
                        _vm._v("érvényesítése. "),
                        _c("br")
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v(
                          "\n                  6. cikk (1) bekezdés c) pontja, és a"
                        ),
                        _c("br"),
                        _vm._v(
                          "számvitelről szóló\n                  2000. évi C. törvény"
                        ),
                        _c("br"),
                        _vm._v("169. § (2) bekezdése."),
                        _c("br")
                      ])
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("td", [_vm._v("A jelentkezés időpontja "), _c("br")]),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v("Technikai művelet végrehajtása. "),
                        _c("br")
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v("a GDPR 6. cikk (1) bekezdés b) pont."),
                        _c("br")
                      ])
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("td", [_vm._v("A jelentkezéskori IP cím "), _c("br")]),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v("Technikai művelet végrehajtása. "),
                        _c("br")
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v("a GDPR 6. cikk (1) bekezdés b) pont."),
                        _c("br")
                      ])
                    ])
                  ])
                ])
              ]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "text-justify",
                staticStyle: { "margin-left": "30px" }
              },
              [
                _vm._v(
                  "\n          Az e-mail cím esetében nem szükséges, hogy személyes adatot\n          tartalmazzon. "
                ),
                _c("br")
              ]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "text-justify",
                staticStyle: { "margin-left": "30px" }
              },
              [
                _c("strong", [_vm._v("2.")]),
                _vm._v(" "),
                _c("strong", [_vm._v("Az érintettek köre:")]),
                _vm._v(
                  " A\n          weboldalon vezetésre jelentkező, időpontot foglaló, a szolgáltatást\n          megrendelő valamennyi érintett. "
                ),
                _c("br")
              ]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "text-justify",
                staticStyle: { "margin-left": "30px" }
              },
              [
                _c("strong", [
                  _vm._v(
                    "3. Az adatkezelés időtartama, az adatok törlésének határideje: "
                  )
                ]),
                _vm._v("Az adatkezelés a tesztvezetés végéig tart. "),
                _c("br")
              ]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "text-justify",
                staticStyle: { "margin-left": "30px" }
              },
              [
                _vm._v(
                  "\n          Ha a GDPR 17. cikk (1) bekezdésében foglalt feltételek valamelyike\n          fennáll, úgy az érintett törlési kérelméig tart. Az érintett által\n          megadott bármely személyes adat törléséről az adatkezelő a GDPR 19.\n          cikke alapján, elektronikus úton tájékoztatja az érintettet. Ha az\n          érintett törlési kérelme kiterjed az általa megadott e-mail címre\n          is, akkor az adatkezelő a tájékoztatást követően az e-mail címet is\n          törli. "
                ),
                _c("br")
              ]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "text-justify",
                staticStyle: { "margin-left": "30px" }
              },
              [
                _vm._v(
                  "\n          Kivéve a számviteli bizonylatok esetében, hiszen a számvitelről\n          szóló 2000. évi C. törvény 169. § (2) bekezdése alapján 8 évig meg\n          kell őrizni ezeket az adatokat. Az érintett szerződéses adatai a\n          polgárjogi elévülési idő leteltével törölhetőek az érintett törlési\n          kérelme alapján. "
                ),
                _c("br")
              ]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "text-justify",
                staticStyle: { "margin-left": "30px", "font-weight": "300" }
              },
              [
                _c("em", [
                  _vm._v(
                    '"A könyvviteli elszámolást közvetlenül és közvetetten alátámasztó\n            számviteli bizonylatot (ideértve a főkönyvi számlákat, az\n            analitikus, illetve részletező nyilvántartásokat is), legalább 8\n            évig kell olvasható formában, a könyvelési feljegyzések\n            hivatkozása alapján visszakereshető módon megőrizni."'
                  )
                ]),
                _c("br")
              ]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "text-justify",
                staticStyle: { "margin-left": "30px" }
              },
              [
                _c("strong", [_vm._v("4.")]),
                _vm._v(" "),
                _c("strong", [
                  _vm._v(
                    "Az adatok megismerésére jogosult lehetséges adatkezelők személye,\n            a személyes adatok címzettjei:"
                  )
                ]),
                _vm._v(
                  "\n          A személyes adatokat az adatkezelőnek az erre felhatalmazott\n          munkatársai kezelhetik a jelen tájékoztatóban foglaltak alapján.\n          "
                ),
                _c("br")
              ]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "text-justify",
                staticStyle: { "margin-left": "30px" }
              },
              [
                _c("strong", [
                  _vm._v(
                    "5. Az érintettek adatkezeléssel kapcsolatos jogainak ismertetése: "
                  )
                ]),
                _c("br")
              ]
            ),
            _vm._v(" "),
            _c("ul", { staticStyle: { "margin-left": "30px" } }, [
              _c("li", { staticClass: "text-justify" }, [
                _vm._v(
                  "\n            Az érintett kérelmezheti az adatkezelőtől a rá vonatkozó személyes\n            adatokhoz való hozzáférést, azok helyesbítését, törlését vagy\n            kezelésének korlátozását, és"
                ),
                _c("br")
              ]),
              _vm._v(" "),
              _c("li", [
                _vm._v(
                  "\n            az érintettnek joga van az adathordozhatósághoz, továbbá a\n            hozzájárulás bármely időpontban történő visszavonásához. "
                ),
                _c("br")
              ])
            ]),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "text-justify",
                staticStyle: { "margin-left": "30px" }
              },
              [
                _c("strong", [_vm._v("6.")]),
                _vm._v(" "),
                _c("strong", [
                  _vm._v(
                    "A személyes adatokhoz való hozzáférést, azok törlését,\n            módosítását, vagy kezelésének korlátozását, az adatok\n            hordozhatóságát az alábbi módokon tudja érintett kezdeményezni: "
                  )
                ]),
                _c("br")
              ]
            ),
            _vm._v(" "),
            _c("ul", { staticStyle: { "margin-left": "30px" } }, [
              _c("li", { staticClass: "text-justify" }, [
                _vm._v(
                  "\n            postai úton a 1014 Budapest, Országház utca 22. 1. em. 2.\n            címen,"
                ),
                _c("br")
              ]),
              _vm._v(" "),
              _c("li", [
                _vm._v("\n            e-mail útján a\n            "),
                _c(
                  "a",
                  {
                    staticStyle: { color: "#c1132c", "font-weight": "500" },
                    attrs: { href: "mailto:hello@teslapilota.hu" }
                  },
                  [_vm._v("hello@teslapilota.hu")]
                ),
                _vm._v(" e-mail címen, "),
                _c("br")
              ]),
              _vm._v(" "),
              _c("li", [
                _vm._v(
                  "\n            telefonon a               \n                    számon. "
                ),
                _c("br")
              ])
            ]),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "text-justify",
                staticStyle: { "margin-left": "30px" }
              },
              [
                _c("strong", [_vm._v("7. Az adatkezelés jogalapja: ")]),
                _c("br")
              ]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "text-justify",
                staticStyle: { "margin-left": "50px" }
              },
              [
                _vm._v(
                  "\n          7.1. a GDPR 6. cikk (1) bekezdés b) és c) pont, "
                ),
                _c("br")
              ]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "text-justify",
                staticStyle: { "margin-left": "50px" }
              },
              [
                _vm._v(
                  "\n          7.2. Az elektronikus kereskedelemi szolgáltatások, valamint az\n          információs társadalommal összefüggő szolgáltatások egyes\n          kérdéseiről szóló 2001. évi CVIII. törvény (a továbbiakban: Elker\n          tv.) 13/A. § (3) bekezdése: "
                ),
                _c("br")
              ]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "text-justify",
                staticStyle: { "margin-left": "50px" }
              },
              [
                _c("em", [
                  _vm._v(
                    "A szolgáltató a szolgáltatás nyújtása céljából kezelheti azon\n            személyes adatokat, amelyek a szolgáltatás nyújtásához\n            technikailag elengedhetetlenül szükségesek. A szolgáltatónak az\n            egyéb feltételek azonossága esetén úgy kell megválasztania és\n            minden esetben oly módon kell üzemeltetnie az információs\n            társadalommal összefüggő szolgáltatás nyújtása során alkalmazott\n            eszközöket, hogy személyes adatok kezelésére csak akkor kerüljön\n            sor, ha ez a szolgáltatás nyújtásához és az e törvényben\n            meghatározott egyéb célok teljesüléséhez feltétlenül szükséges,\n            azonban ebben az esetben is csak a szükséges mértékben és\n            ideig."
                  )
                ]),
                _vm._v(" "),
                _c("br")
              ]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "text-justify",
                staticStyle: { "margin-left": "50px" }
              },
              [
                _vm._v(
                  "\n          7.3. A számviteli jogszabályoknak megfelelő számlát kiállítása\n          esetén a 6. cikk (1) bekezdés c) pontja. "
                ),
                _c("br")
              ]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "text-justify",
                staticStyle: { "margin-left": "50px" }
              },
              [
                _vm._v(
                  "\n          7.4. A szerződésből eredő követelések érvényesítése esetén a Polgári\n          Törvénykönyvről szóló 2013. évi V. törvény 6:21. §-a szerint 5\n          év."
                ),
                _c("br")
              ]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "text-justify",
                staticStyle: { "margin-left": "80px", "font-size": "15px" }
              },
              [
                _c("em", [_vm._v("6:22. § [Elévülés]")]),
                _c("br"),
                _c("em", [
                  _vm._v(
                    "(1) Ha e törvény eltérően nem rendelkezik, a követelések öt év\n            alatt évülnek el."
                  )
                ]),
                _c("br"),
                _c("em", [
                  _vm._v(
                    "(2) Az elévülés akkor kezdődik, amikor a követelés esedékessé\n            válik."
                  )
                ]),
                _c("br"),
                _c("em", [
                  _vm._v(
                    "(3) Az elévülési idő megváltoztatására irányuló megállapodást\n            írásba kell foglalni."
                  )
                ]),
                _c("br"),
                _c("em", [
                  _vm._v("(4) Az elévülést kizáró megállapodás semmis. ")
                ]),
                _c("br")
              ]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "text-justify",
                staticStyle: { "margin-left": "30px" }
              },
              [_c("strong", [_vm._v("8. Tájékoztatjuk, hogy")]), _c("br")]
            ),
            _vm._v(" "),
            _c("ul", { staticStyle: { "margin-left": "30px" } }, [
              _c("li", { staticClass: "text-justify" }, [
                _c("strong", [
                  _c(
                    "span",
                    { staticStyle: { "text-decoration": "underline" } },
                    [
                      _vm._v(
                        "az adatkezelés a jelentkezéshez, majd szerződés\n                teljesítéséhez szükséges,"
                      )
                    ]
                  )
                ]),
                _c("br")
              ]),
              _vm._v(" "),
              _c("li", [
                _c("strong", [
                  _c(
                    "span",
                    { staticStyle: { "text-decoration": "underline" } },
                    [_vm._v("köteles")]
                  )
                ]),
                _vm._v(
                  "\n            a személyes adatokat megadni, hogy tudjon jelentkezni a\n            vezetésre"
                ),
                _c("br")
              ]),
              _vm._v(" "),
              _c("li", [
                _vm._v(
                  "\n            az adatszolgáltatás elmaradása azzal a\n            "
                ),
                _c("strong", [
                  _c(
                    "span",
                    { staticStyle: { "text-decoration": "underline" } },
                    [_vm._v("következménnyel jár")]
                  )
                ]),
                _vm._v(", hogy nem tud jelentkezni a vezetésre . "),
                _c("br")
              ])
            ]),
            _vm._v(" "),
            _c(
              "h3",
              {
                staticClass: "text-uppercase",
                staticStyle: {
                  "margin-top": "40px",
                  "margin-bottom": "40px",
                  "margin-left": "25px"
                }
              },
              [_vm._v("\n          4.2. Kapcsolatfelvétel"), _c("br")]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "text-justify",
                staticStyle: { "margin-left": "30px" }
              },
              [
                _c("strong", [_vm._v("1. ")]),
                _vm._v(
                  "Az adatgyűjtés ténye, a kezelt adatok köre és az\n          "
                ),
                _c("strong", [_vm._v("adatkezelés céljai")]),
                _vm._v(": "),
                _c("br")
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "table-responsive text-left",
                staticStyle: { "margin-bottom": "30px", "margin-top": "30px" }
              },
              [
                _c("table", { staticClass: "table table-striped" }, [
                  _c("thead", [
                    _c("tr", [
                      _c("th", [_vm._v("Személyes adat")]),
                      _vm._v(" "),
                      _c("th", [_vm._v("Az adatkezelés célja"), _c("br")]),
                      _vm._v(" "),
                      _c("th", [_vm._v("Jogalap "), _c("br")])
                    ])
                  ]),
                  _vm._v(" "),
                  _c("tbody", [
                    _c("tr", [
                      _c("td", [
                        _vm._v("Jelentkező neve,"),
                        _c("br"),
                        _vm._v("megajándékozott neve "),
                        _c("br")
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v("Azonosítás, szerződés"),
                        _c("br"),
                        _vm._v("létrehozása "),
                        _c("br")
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v("a GDPR 6. cikk (1) bekezdés b) pont."),
                        _c("br")
                      ])
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("td", [_vm._v("Telefonszám "), _c("br")]),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v("Kapcsolattartás, egyeztetés "),
                        _c("br")
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v("a GDPR 6. cikk (1) bekezdés b) pont."),
                        _c("br")
                      ])
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("td", [_vm._v("E-mail cím "), _c("br")]),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v(
                          "\n                  Kapcsolattartás, az információk"
                        ),
                        _c("br"),
                        _vm._v("megküldéséhez\n                  szükséges. "),
                        _c("br")
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v("a GDPR 6. cikk (1) bekezdés b) pont."),
                        _c("br")
                      ])
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("td", [_vm._v("Számlázási név és cím "), _c("br")]),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v(
                          "\n                  A szabályszerű számla kiállítása,"
                        ),
                        _c("br"),
                        _vm._v(
                          "továbbá a szerződés\n                  létrehozása,"
                        ),
                        _c("br"),
                        _vm._v("tartalmának meghatározása, módosítása,"),
                        _c("br"),
                        _vm._v(
                          "teljesítésének\n                  figyelemmel kísérése,"
                        ),
                        _c("br"),
                        _vm._v(
                          "az abból származó díjak\n                  számlázása,"
                        ),
                        _c("br"),
                        _vm._v(
                          "valamint az azzal kapcsolatos\n                  követelések"
                        ),
                        _c("br"),
                        _vm._v("érvényesítése. "),
                        _c("br")
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v(
                          "\n                  6. cikk (1) bekezdés c) pontja, és a"
                        ),
                        _c("br"),
                        _vm._v(
                          "számvitelről szóló\n                  2000. évi C. törvény"
                        ),
                        _c("br"),
                        _vm._v("169. § (2) bekezdése."),
                        _c("br")
                      ])
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("td", [_vm._v("A jelentkezés időpontja "), _c("br")]),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v("Technikai művelet végrehajtása. "),
                        _c("br")
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v("a GDPR 6. cikk (1) bekezdés b) pont."),
                        _c("br")
                      ])
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("td", [_vm._v("A jelentkezéskori IP cím "), _c("br")]),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v("Technikai művelet végrehajtása. "),
                        _c("br")
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v("a GDPR 6. cikk (1) bekezdés b) pont."),
                        _c("br")
                      ])
                    ])
                  ])
                ])
              ]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "text-justify",
                staticStyle: { "margin-left": "30px" }
              },
              [
                _vm._v(
                  "\n          Az e-mail cím esetében nem szükséges, hogy személyes adatot\n          tartalmazzon. "
                ),
                _c("br")
              ]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "text-justify",
                staticStyle: { "margin-left": "30px" }
              },
              [
                _c("strong", [_vm._v("2.")]),
                _vm._v(" "),
                _c("strong", [_vm._v("Az érintettek köre")]),
                _vm._v(
                  ": A\n          kapcsolati űrlapon keresztül üzenetet küldő valamennyi érintett.\n          "
                ),
                _c("br")
              ]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "text-justify",
                staticStyle: { "margin-left": "30px" }
              },
              [
                _c("strong", [_vm._v("3.")]),
                _vm._v(" "),
                _c("strong", [
                  _vm._v(
                    "Az adatkezelés időtartama, az adatok törlésének\n            határideje:"
                  )
                ]),
                _vm._v(
                  "\n          Ha a GDPR 17. cikk (1) bekezdésében foglalt feltételek valamelyike\n          fennáll, úgy az érintett törlési kérelméig tart. "
                ),
                _c("br")
              ]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "text-justify",
                staticStyle: { "margin-left": "30px" }
              },
              [
                _c("strong", [
                  _vm._v(
                    "4. Az adatok megismerésére jogosult lehetséges adatkezelők\n            személye, a személyes adatok címzettjei"
                  )
                ]),
                _vm._v(
                  ": A személyes adatokat az adatkezelőnek az erre felhatalmazott\n          munkatársai kezelhetik. "
                ),
                _c("br")
              ]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "text-justify",
                staticStyle: { "margin-left": "30px" }
              },
              [
                _c("strong", [
                  _vm._v(
                    "5. Az érintettek adatkezeléssel kapcsolatos jogainak ismertetése: "
                  )
                ]),
                _c("br")
              ]
            ),
            _vm._v(" "),
            _c("ul", { staticStyle: { "margin-left": "30px" } }, [
              _c("li", { staticClass: "text-justify" }, [
                _vm._v(
                  "\n            Az érintett kérelmezheti az adatkezelőtől a rá vonatkozó személyes\n            adatokhoz való hozzáférést, azok helyesbítését, törlését vagy\n            kezelésének korlátozását, és"
                ),
                _c("br")
              ]),
              _vm._v(" "),
              _c("li", [
                _vm._v(
                  "\n            az érintettnek joga van az adathordozhatósághoz, továbbá a\n            hozzájárulás bármely időpontban történő visszavonásához. "
                ),
                _c("br")
              ])
            ]),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "text-justify",
                staticStyle: { "margin-left": "30px" }
              },
              [
                _c("strong", [_vm._v("6.")]),
                _vm._v(" "),
                _c("strong", [
                  _vm._v(
                    "A személyes adatokhoz való hozzáférést, azok törlését,\n            módosítását, vagy kezelésének korlátozását, az adatok\n            hordozhatóságát az alábbi módokon tudja érintett kezdeményezni: "
                  )
                ]),
                _c("br")
              ]
            ),
            _vm._v(" "),
            _c("ul", { staticStyle: { "margin-left": "30px" } }, [
              _c("li", { staticClass: "text-justify" }, [
                _vm._v(
                  "\n            postai úton a 1014 Budapest, Országház utca 22. 1. em. 2.\n            címen,"
                ),
                _c("br")
              ]),
              _vm._v(" "),
              _c("li", [
                _vm._v("\n            e-mail útján a\n            "),
                _c(
                  "a",
                  {
                    staticStyle: { color: "#c1132c", "font-weight": "500" },
                    attrs: { href: "mailto:hello@teslapilota.hu" }
                  },
                  [_vm._v("hello@teslapilota.hu")]
                ),
                _vm._v(" e-mail címen, "),
                _c("br")
              ]),
              _vm._v(" "),
              _c("li", [
                _vm._v(
                  "\n            telefonon a               \n                    számon. "
                ),
                _c("br")
              ])
            ]),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "text-justify",
                staticStyle: { "margin-left": "30px" }
              },
              [
                _c("strong", [_vm._v("7.")]),
                _vm._v(" "),
                _c("strong", [_vm._v("Az adatkezelés jogalapja")]),
                _vm._v(
                  ": az\n          érintett hozzájárulása, 6. cikk (1) bekezdés a), b) és c) pont. Ha\n          Ön felveszi velünk a kapcsolatot, hozzájárul, hogy a\n          kapcsolatfelvétel során hozzánk került személyes adatait (név,\n          telefonszám, e-mail cím) a jelen szabályzatnak megfelelően kezeljük.\n          "
                ),
                _c("br")
              ]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "text-justify",
                staticStyle: { "margin-left": "30px" }
              },
              [
                _c("strong", [_vm._v("8.")]),
                _vm._v(" Tájékoztatjuk, hogy "),
                _c("br")
              ]
            ),
            _vm._v(" "),
            _c("ul", { staticStyle: { "margin-left": "30px" } }, [
              _c("li", { staticClass: "text-justify" }, [
                _c("strong", [
                  _vm._v("a jelen adatkezelés az Ön hozzájárulásán")
                ]),
                _vm._v(" alapul,\n            illetve "),
                _c("strong", [_vm._v("ajánlat adásához szükséges")]),
                _vm._v(
                  " vagy\n            szerződéses jogviszony esetén\n            "
                ),
                _c("strong", [_vm._v("jogszabályi kötelezettségen")]),
                _vm._v(" (együttműködés)\n            alapul. "),
                _c("br")
              ]),
              _vm._v(" "),
              _c("li", [
                _c("strong", [_vm._v("köteles")]),
                _vm._v(
                  " a személyes adatokat megadni, hogy\n            kapcsolatba tudjon lépni velünk"
                ),
                _c("br")
              ]),
              _vm._v(" "),
              _c("li", [
                _vm._v(
                  "\n            az adatszolgáltatás elmaradása azzal a\n            "
                ),
                _c("strong", [_vm._v("következménnyel jár")]),
                _vm._v(
                  ", hogy nem tudja felvenni a\n            kapcsolatot a Szolgáltatóval. "
                ),
                _c("br")
              ]),
              _vm._v(" "),
              _c("li", [
                _vm._v(
                  "\n            a hozzájárulás visszavonása nem érinti a hozzájáruláson alapuló, a\n            visszavonás előtti adatkezelés jogszerűségét. "
                ),
                _c("br")
              ])
            ]),
            _vm._v(" "),
            _c(
              "h3",
              {
                staticClass: "text-uppercase",
                staticStyle: {
                  "margin-top": "40px",
                  "margin-bottom": "40px",
                  "margin-left": "25px"
                }
              },
              [_vm._v("\n          4.3. ÜGYFÉLKAPCSOLAT "), _c("br")]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "text-justify",
                staticStyle: { "margin-left": "30px" }
              },
              [
                _c("strong", [_vm._v("1. ")]),
                _vm._v(
                  "Az adatgyűjtés ténye, a kezelt adatok köre és az\n          "
                ),
                _c("strong", [_vm._v("adatkezelés céljai")]),
                _vm._v(": "),
                _c("br")
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "table-responsive text-left",
                staticStyle: { "margin-bottom": "30px", "margin-top": "30px" }
              },
              [
                _c("table", { staticClass: "table table-striped" }, [
                  _c("thead", [
                    _c("tr", [
                      _c("th", [_vm._v("Személyes adat")]),
                      _vm._v(" "),
                      _c("th", [_vm._v("Az adatkezelés célja"), _c("br")]),
                      _vm._v(" "),
                      _c("th", [_vm._v("Jogalap "), _c("br")])
                    ])
                  ]),
                  _vm._v(" "),
                  _c("tbody", [
                    _c("tr", [
                      _c("td", [
                        _vm._v("Név, e-mail cím, telefonszám."),
                        _c("br")
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v(
                          "\n                  Kapcsolattartás, azonosítás,"
                        ),
                        _c("br"),
                        _vm._v(
                          "szerződések teljesítése,\n                  üzleti"
                        ),
                        _c("br"),
                        _vm._v("cél. "),
                        _c("br")
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v(
                          "\n                  6. cikk (1) bekezdés b) és c) pontja, a"
                        ),
                        _c("br"),
                        _vm._v(
                          "szerződésből\n                  eredő követelések"
                        ),
                        _c("br"),
                        _vm._v("érvényesítése esetén a"),
                        _c("br"),
                        _vm._v(
                          "Polgári\n                  Törvénykönyvről szóló"
                        ),
                        _c("br"),
                        _vm._v(
                          "2013. évi V. törvény 6:21. §-a\n                  "
                        ),
                        _c("br")
                      ])
                    ])
                  ])
                ])
              ]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "text-justify",
                staticStyle: { "margin-left": "30px" }
              },
              [
                _c("strong", [_vm._v("2.")]),
                _vm._v(
                  " Az érintettek köre: Az adatkezelővel\n          telefonon/e-mailben/személyesen kapcsolatot tartó, vagy szerződéses\n          jogviszonyban álló valamennyi érintett."
                ),
                _c("br")
              ]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "text-justify",
                staticStyle: { "margin-left": "30px" }
              },
              [
                _c("strong", [_vm._v("3.")]),
                _vm._v(
                  " Az adatkezelés időtartama, az adatok törlésének\n          határideje: A megkereséseket tartalmazó levelek az érintett törlési\n          kérelméig tart, de maximum 2 év. "
                ),
                _c("br")
              ]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "text-justify",
                staticStyle: { "margin-left": "30px" }
              },
              [
                _c("strong", [_vm._v("4.")]),
                _vm._v(" "),
                _c("strong", [
                  _vm._v(
                    "Az adatok megismerésére jogosult lehetséges adatkezelők személye,\n            a személyes adatok címzettjei:"
                  )
                ]),
                _vm._v(
                  "\n          A személyes adatokat az adatkezelő erre feljogosított munkatársai\n          kezelhetik, a fenti alapelvek tiszteletben tartásával. "
                ),
                _c("br")
              ]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "text-justify",
                staticStyle: { "margin-left": "30px" }
              },
              [
                _c("strong", [_vm._v("5.")]),
                _vm._v(" "),
                _c("strong", [
                  _vm._v(
                    "Az érintettek adatkezeléssel kapcsolatos jogainak ismertetése: "
                  )
                ]),
                _c("br")
              ]
            ),
            _vm._v(" "),
            _c("ul", { staticStyle: { "margin-left": "30px" } }, [
              _c("li", { staticClass: "text-justify" }, [
                _vm._v(
                  "\n            Az érintett kérelmezheti az adatkezelőtől a rá vonatkozó személyes\n            adatokhoz való hozzáférést, azok helyesbítését, törlését vagy\n            kezelésének korlátozását, és"
                ),
                _c("br")
              ]),
              _vm._v(" "),
              _c("li", [
                _vm._v(
                  "\n            az érintettnek joga van az adathordozhatósághoz, továbbá a\n            hozzájárulás bármely időpontban történő visszavonásához. "
                ),
                _c("br")
              ])
            ]),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "text-justify",
                staticStyle: { "margin-left": "30px" }
              },
              [
                _c("strong", [_vm._v("6.")]),
                _vm._v(" "),
                _c("strong", [
                  _vm._v(
                    "A személyes adatokhoz való hozzáférést, azok törlését,\n            módosítását, vagy kezelésének korlátozását, az adatok\n            hordozhatóságát az alábbi módokon tudja érintett kezdeményezni: "
                  )
                ]),
                _c("br")
              ]
            ),
            _vm._v(" "),
            _c("ul", { staticStyle: { "margin-left": "30px" } }, [
              _c("li", { staticClass: "text-justify" }, [
                _vm._v(
                  "\n            postai úton a 1014 Budapest, Országház utca 22. 1. em. 2.\n            címen,"
                ),
                _c("br")
              ]),
              _vm._v(" "),
              _c("li", [
                _vm._v("\n            e-mail útján a\n            "),
                _c(
                  "a",
                  {
                    staticStyle: { color: "#c1132c", "font-weight": "500" },
                    attrs: { href: "mailto:hello@teslapilota.hu" }
                  },
                  [_vm._v("hello@teslapilota.hu")]
                ),
                _vm._v(" e-mail címen, "),
                _c("br")
              ]),
              _vm._v(" "),
              _c("li", [
                _vm._v(
                  "\n            telefonon a               \n                    számon. "
                ),
                _c("br")
              ])
            ]),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "text-justify",
                staticStyle: { "margin-left": "30px" }
              },
              [
                _c("strong", [_vm._v("7.")]),
                _vm._v(" "),
                _c("strong", [_vm._v("Az adatkezelés jogalapja")]),
                _vm._v(":"),
                _c("br")
              ]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "text-justify",
                staticStyle: { "margin-left": "30px" }
              },
              [
                _c("strong", [_vm._v("8.")]),
                _vm._v(" Tájékoztatjuk, hogy "),
                _c("br")
              ]
            ),
            _vm._v(" "),
            _c("ul", { staticStyle: { "margin-left": "30px" } }, [
              _c("li", { staticClass: "text-justify" }, [
                _c("strong", [
                  _c(
                    "span",
                    { staticStyle: { "text-decoration": "underline" } },
                    [
                      _vm._v(
                        "az adatkezelés a jelentkezéshez, majd szerződés\n                teljesítéséhez szükséges,"
                      )
                    ]
                  )
                ]),
                _c("br")
              ]),
              _vm._v(" "),
              _c("li", [
                _c("strong", [
                  _c(
                    "span",
                    { staticStyle: { "text-decoration": "underline" } },
                    [_vm._v("köteles")]
                  )
                ]),
                _vm._v(
                  "\n            a személyes adatokat megadni, hogy tudjuk a szerződést\n            teljesíteni/egyéb kérését teljesíteni."
                ),
                _c("br")
              ]),
              _vm._v(" "),
              _c("li", [
                _vm._v(
                  "\n            az adatszolgáltatás elmaradása azzal a\n            "
                ),
                _c("strong", [
                  _c(
                    "span",
                    { staticStyle: { "text-decoration": "underline" } },
                    [_vm._v("következménnyel jár")]
                  )
                ]),
                _vm._v(
                  ", hogy nem tudjuk a szerződést teljesíteni/kérését feldolgozni.\n            "
                ),
                _c("br")
              ])
            ]),
            _vm._v(" "),
            _c(
              "h3",
              {
                staticClass: "text-uppercase",
                staticStyle: {
                  "margin-top": "40px",
                  "margin-bottom": "40px",
                  "margin-left": "25px"
                }
              },
              [_vm._v("\n          4.4. panaszkezelés "), _c("br")]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "text-justify",
                staticStyle: { "margin-left": "30px" }
              },
              [
                _c("strong", [_vm._v("1. ")]),
                _vm._v(
                  "Az adatgyűjtés ténye, a kezelt adatok köre és az\n          "
                ),
                _c("strong", [_vm._v("adatkezelés céljai")]),
                _vm._v(": "),
                _c("br")
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "table-responsive text-left",
                staticStyle: { "margin-bottom": "30px", "margin-top": "30px" }
              },
              [
                _c("table", { staticClass: "table table-striped" }, [
                  _c("thead", [
                    _c("tr", [
                      _c("th", [_vm._v("Személyes adat")]),
                      _vm._v(" "),
                      _c("th", [_vm._v("Az adatkezelés célja"), _c("br")]),
                      _vm._v(" "),
                      _c("th", [_vm._v("Jogalap "), _c("br")])
                    ])
                  ]),
                  _vm._v(" "),
                  _c("tbody", [
                    _c("tr", [
                      _c("td", [_vm._v("Vezeték-és keresztnév"), _c("br")]),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v("Azonosítás, kapcsolattartás. "),
                        _c("br")
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v(
                          "\n                  6. cikk (1) bekezdés c) pontja, és a"
                        ),
                        _c("br"),
                        _vm._v(
                          "fogyasztóvédelemről\n                  szóló 1997. évi CLV."
                        ),
                        _c("br"),
                        _vm._v(
                          "törvény 17/A. § (7)\n                  bekezdése. "
                        ),
                        _c("br")
                      ])
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("td", [_vm._v("E-mail cím "), _c("br")]),
                      _vm._v(" "),
                      _c("td", [_vm._v("Kapcsolattartás"), _c("br")]),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v(
                          "\n                  6. cikk (1) bekezdés c) pontja, és a"
                        ),
                        _c("br"),
                        _vm._v(
                          "fogyasztóvédelemről\n                  szóló 1997. évi CLV."
                        ),
                        _c("br"),
                        _vm._v(
                          "törvény 17/A. § (7)\n                  bekezdése. "
                        ),
                        _c("br")
                      ])
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("td", [_vm._v("Telefonszám "), _c("br")]),
                      _vm._v(" "),
                      _c("td", [_vm._v("Kapcsolattartás"), _c("br")]),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v(
                          "\n                  6. cikk (1) bekezdés c) pontja, és a"
                        ),
                        _c("br"),
                        _vm._v(
                          "fogyasztóvédelemről\n                  szóló 1997. évi CLV."
                        ),
                        _c("br"),
                        _vm._v(
                          "törvény 17/A. § (7)\n                  bekezdése. "
                        ),
                        _c("br")
                      ])
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("td", [_vm._v("Számlázási név és cím "), _c("br")]),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v(
                          "\n                  Azonosítás, a megrendelt szolgáltatásokkal"
                        ),
                        _c("br"),
                        _vm._v(
                          "kapcsolatosan\n                  felmerülő minőségi kifogások,"
                        ),
                        _c("br"),
                        _vm._v(
                          "kérdések és problémák\n                  kezelése. "
                        ),
                        _c("br")
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v(
                          "\n                  6. cikk (1) bekezdés c) pontja, és a"
                        ),
                        _c("br"),
                        _vm._v(
                          "fogyasztóvédelemről\n                  szóló 1997. évi CLV."
                        ),
                        _c("br"),
                        _vm._v(
                          "törvény 17/A. § (7) bekezdése.\n                  "
                        ),
                        _c("br")
                      ])
                    ])
                  ])
                ])
              ]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "text-justify",
                staticStyle: { "margin-left": "30px" }
              },
              [
                _c("strong", [_vm._v("2. ")]),
                _vm._v(
                  "Az érintettek köre: A weboldalon időpontot\n          foglaló és minőségi kifogással élő, panaszt tevő valamennyi\n          érintett. "
                ),
                _c("br")
              ]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "text-justify",
                staticStyle: { "margin-left": "30px" }
              },
              [
                _c("strong", [_vm._v("3.")]),
                _vm._v(
                  " Az adatkezelés időtartama, az adatok törlésének\n          határideje: A kifogásról felvett jegyzőkönyv, átirat és az arra\n          adott válasz másolati példányait a fogyasztóvédelemről szóló 1997.\n          évi CLV. törvény 17/A. § (7) bekezdése alapján 5 évig kell\n          megőrizni."
                ),
                _c("br")
              ]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "text-justify",
                staticStyle: { "margin-left": "30px" }
              },
              [
                _c("strong", [_vm._v("4.")]),
                _vm._v(" "),
                _c("strong", [
                  _vm._v(
                    "Az adatok megismerésére jogosult lehetséges adatkezelők személye,\n            a személyes adatok címzettjei:"
                  )
                ]),
                _vm._v(
                  "\n          A személyes adatokat az adatkezelő sales és marketing munkatársai\n          kezelhetik, a fenti alapelvek tiszteletben tartásával. "
                ),
                _c("br")
              ]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "text-justify",
                staticStyle: { "margin-left": "30px" }
              },
              [
                _c("strong", [_vm._v("5.")]),
                _vm._v(" "),
                _c("strong", [
                  _vm._v(
                    "Az érintettek adatkezeléssel kapcsolatos jogainak ismertetése: "
                  )
                ]),
                _c("br")
              ]
            ),
            _vm._v(" "),
            _c("ul", { staticStyle: { "margin-left": "30px" } }, [
              _c("li", { staticClass: "text-justify" }, [
                _vm._v(
                  "\n            Az érintett kérelmezheti az adatkezelőtől a rá vonatkozó személyes\n            adatokhoz való hozzáférést, azok helyesbítését, törlését vagy\n            kezelésének korlátozását, és"
                ),
                _c("br")
              ]),
              _vm._v(" "),
              _c("li", [
                _vm._v(
                  "\n            az érintettnek joga van az adathordozhatósághoz, továbbá a\n            hozzájárulás bármely időpontban történő visszavonásához. "
                ),
                _c("br")
              ])
            ]),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "text-justify",
                staticStyle: { "margin-left": "30px" }
              },
              [
                _c("strong", [_vm._v("6.")]),
                _vm._v(" "),
                _c("strong", [
                  _vm._v(
                    "A személyes adatokhoz való hozzáférést, azok törlését,\n            módosítását, vagy kezelésének korlátozását, az adatok\n            hordozhatóságát az alábbi módokon tudja érintett kezdeményezni: "
                  )
                ]),
                _c("br")
              ]
            ),
            _vm._v(" "),
            _c("ul", { staticStyle: { "margin-left": "30px" } }, [
              _c("li", { staticClass: "text-justify" }, [
                _vm._v(
                  "\n            postai úton a 1014 Budapest, Országház utca 22. 1. em. 2.\n            címen,"
                ),
                _c("br")
              ]),
              _vm._v(" "),
              _c("li", [
                _vm._v("\n            e-mail útján a\n            "),
                _c(
                  "a",
                  {
                    staticStyle: { color: "#c1132c", "font-weight": "500" },
                    attrs: { href: "mailto:hello@teslapilota.hu" }
                  },
                  [_vm._v("hello@teslapilota.hu")]
                ),
                _vm._v(" e-mail címen, "),
                _c("br")
              ]),
              _vm._v(" "),
              _c("li", [
                _vm._v(
                  "\n            telefonon a               \n                    számon. "
                ),
                _c("br")
              ])
            ]),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "text-justify",
                staticStyle: { "margin-left": "30px" }
              },
              [
                _c("strong", [_vm._v("7.")]),
                _vm._v(" Tájékoztatjuk, hogy "),
                _c("br")
              ]
            ),
            _vm._v(" "),
            _c("ul", { staticStyle: { "margin-left": "30px" } }, [
              _c("li", { staticClass: "text-justify" }, [
                _vm._v(
                  "\n            a személyes adat szolgáltatása\n            "
                ),
                _c("strong", [_vm._v("jogi kötelezettségen")]),
                _vm._v(" alapul.  "),
                _c("br")
              ]),
              _vm._v(" "),
              _c("li", [
                _vm._v("\n            a szerződés megkötésének "),
                _c("strong", [_vm._v("előfeltétele")]),
                _vm._v(" a személyes\n            adatok kezelése."),
                _c("br")
              ]),
              _vm._v(" "),
              _c("li", [
                _c("strong", [_vm._v("köteles")]),
                _vm._v(
                  " a személyes adatokat megadni, hogy\n            panaszát kezelni tudjuk."
                ),
                _c("br")
              ]),
              _vm._v(" "),
              _c("li", [
                _vm._v(
                  "\n            az adatszolgáltatás elmaradása azzal a\n            "
                ),
                _c("strong", [_vm._v("következménnyel jár")]),
                _vm._v(
                  ", hogy nem tudjuk kezelni a\n            hozzánk beérkezett panaszát. "
                ),
                _c("br")
              ])
            ]),
            _vm._v(" "),
            _c(
              "h2",
              {
                staticClass: "text-uppercase",
                staticStyle: { "margin-bottom": "30px", "margin-top": "30px" }
              },
              [
                _vm._v(
                  "\n          5. CÍMZETTEK, AKIKKEL A SZEMÉLYES ADATOKAT KÖZLIK"
                ),
                _c("br")
              ]
            ),
            _vm._v(" "),
            _c("p", { staticClass: "text-justify" }, [
              _c("em", [_vm._v("„címzett”")]),
              _vm._v(
                ": az a természetes vagy jogi személy, közhatalmi\n          szerv, ügynökség vagy bármely egyéb szerv, akivel vagy amellyel a\n          személyes adatot közlik, függetlenül attól, hogy harmadik fél-e.\n          "
              ),
              _c("br")
            ]),
            _vm._v(" "),
            _c(
              "h3",
              {
                staticClass: "text-uppercase",
                staticStyle: {
                  "margin-top": "40px",
                  "margin-bottom": "40px",
                  "margin-left": "25px"
                }
              },
              [
                _vm._v(
                  "\n          5.1.ADATFELDOLGOZÓK (AKIK, AZ ADATKEZELŐ NEVÉBEN VÉGZIK AZ\n          ADATKEZELÉST)"
                ),
                _c("br")
              ]
            ),
            _vm._v(" "),
            _c("p", { staticClass: "text-justify" }, [
              _vm._v(
                "\n          Az adatkezelő a saját adatkezelési tevékenységeinek elősegítése\n          céljából, továbbá érintettel kötött szerződés-, illetve a\n          jogszabályok által támasztott kötelezettségeinek teljesítése\n          érdekében adatfeldolgozókat vesz igénybe. "
              ),
              _c("br")
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "text-justify" }, [
              _vm._v(
                "\n          Az adatkezelő nagy hangsúlyt fektet arra, hogy kizárólag olyan\n          adatfeldolgozókat vegyen igénybe, akik vagy amelyek megfelelő\n          garanciákat nyújtanak az adatkezelés GDPR-ban foglalt\n          követelményeinek való megfelelését és az érintettek jogainak\n          védelmét biztosító, megfelelő technikai és szervezési intézkedések\n          végrehajtására. "
              ),
              _c("br")
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "text-justify" }, [
              _vm._v(
                "\n          Az adatfeldolgozó és bármely, az adatkezelő vagy az adatfeldolgozó\n          irányítása alatt eljáró, a személyes adatokhoz hozzáféréssel\n          rendelkező személy a jelen szabályzatban foglalt személyes adatokat\n          kizárólag az adatkezelő utasításának megfelelően kezeli. "
              ),
              _c("br")
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "text-justify" }, [
              _vm._v(
                "\n          Az adatfeldolgozó tevékenységeiért az adatkezelő jogi felelősséggel\n          tartozik. Az adatfeldolgozó csak abban az esetben tartozik\n          felelősséggel az adatkezelés által okozott károkért, ha nem tartotta\n          be a GDPR-ban meghatározott, kifejezetten az adatfeldolgozókat\n          terhelő kötelezettségeket, vagy ha az adatkezelő jogszerű\n          utasításait figyelmen kívül hagyta, vagy azokkal ellentétesen járt\n          el. "
              ),
              _c("br")
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "text-justify" }, [
              _vm._v(
                "\n          Az adatfeldolgozónak az adatok kezelését érintően érdemi\n          döntéshozatala nincsen. "
              ),
              _c("br")
            ]),
            _vm._v(" "),
            _c(
              "h3",
              {
                staticClass: "text-uppercase",
                staticStyle: {
                  "margin-top": "40px",
                  "margin-bottom": "40px",
                  "margin-left": "25px"
                }
              },
              [_vm._v("\n          5.2. Egyes adatfeldolgozók"), _c("br")]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "table-responsive text-left",
                staticStyle: { "margin-bottom": "30px", "margin-top": "30px" }
              },
              [
                _c("table", { staticClass: "table table-striped" }, [
                  _c("thead", [
                    _c("tr", [
                      _c("th", [
                        _vm._v("ADATFELDOLGOZÓI TEVÉKENYSÉG"),
                        _c("br")
                      ]),
                      _vm._v(" "),
                      _c("th", [_vm._v("NÉV"), _c("br")]),
                      _vm._v(" "),
                      _c("th", [_vm._v("CÍM, ELÉRHETŐSÉG"), _c("br")])
                    ])
                  ]),
                  _vm._v(" "),
                  _c("tbody", [
                    _c("tr", [
                      _c("td", [
                        _c("strong", [_vm._v("Tárhely-szolgáltatás ")]),
                        _c("br")
                      ]),
                      _vm._v(" "),
                      _c("td", [_vm._v("DOTROLL KFT."), _c("br")]),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v("\n                  Cím: 1148 Budapest,"),
                        _c("br"),
                        _vm._v("Fogarasi út 3-5."),
                        _c("br"),
                        _vm._v("Tel.: +36 - 1\n                  - 432 - 3232"),
                        _c("br"),
                        _vm._v("Fax: +36 - 1 - 432 - 3231"),
                        _c("br"),
                        _vm._v("E-mail:"),
                        _c("br"),
                        _c(
                          "a",
                          {
                            staticStyle: {
                              color: "#c1132c",
                              "font-weight": "500"
                            },
                            attrs: { href: "mailto:support@dotroll.com" }
                          },
                          [_vm._v("support@dotroll.com")]
                        ),
                        _vm._v(" "),
                        _c("br")
                      ])
                    ])
                  ])
                ])
              ]
            ),
            _vm._v(" "),
            _c(
              "h3",
              {
                staticClass: "text-uppercase",
                staticStyle: {
                  "margin-top": "40px",
                  "margin-bottom": "40px",
                  "margin-left": "25px"
                }
              },
              [
                _vm._v("\n          5.3.ADATTOVÁBBÍTÁS HARMADIK FÉL RÉSZÉRE "),
                _c("br")
              ]
            ),
            _vm._v(" "),
            _c("p", { staticClass: "text-justify" }, [
              _c("em", [_vm._v("„harmadik fél”")]),
              _vm._v(
                ": az a természetes vagy jogi személy,\n          közhatalmi szerv, ügynökség vagy bármely egyéb szerv, amely nem\n          azonos az érintettel, az adatkezelővel, az adatfeldolgozóval vagy\n          azokkal a személyekkel, akik az adatkezelő vagy adatfeldolgozó\n          közvetlen irányítása alatt a személyes adatok kezelésére\n          felhatalmazást kaptak;"
              ),
              _c("br")
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "text-justify" }, [
              _vm._v(
                "\n          A harmadik fél adatkezelők a saját nevükben, a saját adatvédelmi\n          szabályzatuknak megfelelően kezelik az általunk közölt személyes\n          adatokat. "
              ),
              _c("br")
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "table-responsive text-left",
                staticStyle: { "margin-bottom": "30px", "margin-top": "30px" }
              },
              [
                _c("table", { staticClass: "table table-striped" }, [
                  _c("thead", [
                    _c("tr", [
                      _c("th", [
                        _vm._v("ADATFELDOLGOZÓI TEVÉKENYSÉG"),
                        _c("br")
                      ]),
                      _vm._v(" "),
                      _c("th", [_vm._v("NÉV"), _c("br")]),
                      _vm._v(" "),
                      _c("th", [_vm._v("CÍM, ELÉRHETŐSÉG"), _c("br")])
                    ])
                  ]),
                  _vm._v(" "),
                  _c("tbody", [
                    _c("tr", [
                      _c("td", [
                        _c("strong", [_vm._v("Online fizetés ")]),
                        _c("br")
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v("OTP Mobil Szolgáltató Kft. "),
                        _c("br")
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v("\n                  Székhely: 1093 Budapest,"),
                        _c("br"),
                        _vm._v("Közraktár u. 30-32."),
                        _c("br"),
                        _vm._v("E-mail:"),
                        _c("br"),
                        _c(
                          "a",
                          {
                            staticStyle: {
                              color: "#c1132c",
                              "font-weight": "500"
                            },
                            attrs: { href: "mailto:ugyfelszolgalat@simple.hu" }
                          },
                          [_vm._v("ugyfelszolgalat@simple.hu")]
                        ),
                        _c("br"),
                        _vm._v("Telefon:\n                  "),
                        _c(
                          "a",
                          {
                            staticStyle: {
                              color: "#c1132c",
                              "font-weight": "500"
                            },
                            attrs: { href: "tel:+3617766901" }
                          },
                          [_vm._v("+36 1 776 6901")]
                        ),
                        _vm._v(" "),
                        _c("br")
                      ])
                    ])
                  ])
                ])
              ]
            ),
            _vm._v(" "),
            _c(
              "h2",
              {
                staticClass: "text-uppercase",
                staticStyle: { "margin-bottom": "30px", "margin-top": "30px" }
              },
              [_vm._v("\n          6. COOKIE-K (SÜTIK) KEZELÉSE"), _c("br")]
            ),
            _vm._v(" "),
            _c("p", { staticClass: "text-justify" }, [
              _c("strong", [_vm._v("1.")]),
              _vm._v(
                " Az adatkezelés ténye, a kezelt adatok köre:\n          Egyedi azonosítószám, dátumok, időpontok "
              ),
              _c("br")
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "text-justify" }, [
              _c("strong", [_vm._v("2. ")]),
              _vm._v(
                "Az érintettek köre: A weboldalt látogató\n          valamennyi érintett. "
              ),
              _c("br")
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "text-justify" }, [
              _c("strong", [_vm._v("3.")]),
              _vm._v(
                " Az adatkezelés célja: A felhasználók azonosítása\n          és a látogatók nyomon követése. "
              ),
              _c("br")
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "text-justify" }, [
              _c("strong", [_vm._v("4.")]),
              _vm._v(
                " Az adatkezelés időtartama, az adatok törlésének\n          határideje: "
              ),
              _c("br")
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "table-responsive text-center",
                staticStyle: { "margin-bottom": "30px", "margin-top": "30px" }
              },
              [
                _c("table", { staticClass: "table table-striped table-sm" }, [
                  _c("thead", [
                    _c("tr", [
                      _c("th", [_vm._v("Süti típusa"), _c("br")]),
                      _vm._v(" "),
                      _c("th", [
                        _vm._v("Adatkezelés"),
                        _c("br"),
                        _vm._v("jogalapja "),
                        _c("br")
                      ]),
                      _vm._v(" "),
                      _c("th", [
                        _vm._v("Adatkezelés"),
                        _c("br"),
                        _vm._v("időtartama "),
                        _c("br")
                      ]),
                      _vm._v(" "),
                      _c("th", [_vm._v("Kezelt adatkör "), _c("br")])
                    ])
                  ]),
                  _vm._v(" "),
                  _c("tbody", [
                    _c("tr", [
                      _c("td", [
                        _vm._v("Munkamenet"),
                        _c("br"),
                        _vm._v("sütik (session) "),
                        _c("br")
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v(
                          "\n                  Az elektronikus kereskedelmi"
                        ),
                        _c("br"),
                        _vm._v(
                          "szolgáltatások, valamint\n                  az"
                        ),
                        _c("br"),
                        _vm._v("információs társadalmi"),
                        _c("br"),
                        _vm._v(
                          "szolgáltatások egyes\n                  kérdéseiről"
                        ),
                        _c("br"),
                        _vm._v("szóló 2001. CVIII. törvény"),
                        _c("br"),
                        _vm._v(
                          "(Elkertv.)\n                  13/A. § (3) bekezdése"
                        ),
                        _c("br")
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v("\n                  A vonatkozó"),
                        _c("br"),
                        _vm._v("látogatói"),
                        _c("br"),
                        _vm._v("munkamenet"),
                        _c("br"),
                        _vm._v("lezárásáig\n                  tartó"),
                        _c("br"),
                        _vm._v("időszak "),
                        _c("br")
                      ]),
                      _vm._v(" "),
                      _c("td", [_vm._v("connect.sid "), _c("br")])
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("td", [
                        _vm._v("Állandó vagy"),
                        _c("br"),
                        _vm._v("mentett sütik "),
                        _c("br")
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v(
                          "\n                  Az elektronikus kereskedelmi"
                        ),
                        _c("br"),
                        _vm._v(
                          "szolgáltatások, valamint\n                  az"
                        ),
                        _c("br"),
                        _vm._v("információs társadalmi"),
                        _c("br"),
                        _vm._v(
                          "szolgáltatások\n                  egyes kérdéseiről"
                        ),
                        _c("br"),
                        _vm._v("szóló 2001. CVIII.\n                  törvény"),
                        _c("br"),
                        _vm._v("(Elkertv.) 13/A. § (3) bekezdése"),
                        _c("br")
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v("az érintett"),
                        _c("br"),
                        _vm._v("törléséig "),
                        _c("br")
                      ]),
                      _vm._v(" "),
                      _c("td")
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("td", [_vm._v("Statisztikai sütik"), _c("br")]),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v(
                          "\n                  Az elektronikus kereskedelmi"
                        ),
                        _c("br"),
                        _vm._v(
                          "szolgáltatások, valamint\n                  az"
                        ),
                        _c("br"),
                        _vm._v("információs társadalmi"),
                        _c("br"),
                        _vm._v(
                          "szolgáltatások\n                  egyes kérdéseiről"
                        ),
                        _c("br"),
                        _vm._v("szóló 2001. CVIII.\n                  törvény"),
                        _c("br"),
                        _vm._v("(Elkertv.) 13/A. § (3) bekezdése"),
                        _c("br")
                      ]),
                      _vm._v(" "),
                      _c("td", [_vm._v("1-2 hónap "), _c("br")]),
                      _vm._v(" "),
                      _c("td")
                    ])
                  ])
                ])
              ]
            ),
            _vm._v(" "),
            _c("p", { staticClass: "text-justify" }, [
              _c("strong", [_vm._v("5.")]),
              _vm._v(
                " Az adatok megismerésére jogosult lehetséges\n          adatkezelők személye: A cookie-k használatával nem kezel személyes\n          adatokat az adatkezelő."
              ),
              _c("br")
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "text-justify" }, [
              _c("strong", [_vm._v("6.")]),
              _vm._v(
                " Az érintettek adatkezeléssel kapcsolatos\n          jogainak ismertetése: Az érintettnek lehetőségük van a cookie-kat\n          törölni a böngészők Eszközök/Beállítások menüjében általában az\n          Adatvédelem menüpont beállításai alatt. "
              ),
              _c("br")
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "text-justify" }, [
              _c("strong", [_vm._v("7.")]),
              _vm._v(
                " Az adatkezelés jogalapja: Az érintettől\n          hozzájárulás nem szükséges, amennyiben a cookie-k használatának\n          kizárólagos célja az elektronikus hírközlő hálózaton keresztül\n          történő közléstovábbítás vagy arra az előfizető vagy felhasználó\n          által kifejezetten kért, az információs társadalommal összefüggő\n          szolgáltatás nyújtásához a szolgáltatónak feltétlenül szüksége\n          van. "
              ),
              _c("br")
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "text-justify" }, [
              _c("strong", [_vm._v("8.")]),
              _vm._v(
                " A legtöbb böngésző, amelyet felhasználóink\n          használnak, lehetővé teszi annak beállítását, hogy mely cookie-kat\n          kell menteni és lehetővé teszi, hogy (meghatározott) cookie-k újra\n          törlésre kerüljenek. Amennyiben Ön a cookie mentését meghatározott\n          weboldalakon korlátozza vagy harmadik fél cookie-jait nem\n          engedélyezi, úgy ez bizonyos körülmények között oda vezethet, hogy\n          weboldalunk többé nem használható teljes egészében. Itt talál\n          információkat arra vonatkozóan, hogy a szokásos böngészők esetében\n          hogyan tudja a cookie beállításokat testre szabni:"
              ),
              _c("br")
            ]),
            _vm._v(" "),
            _c("ul", { staticStyle: { "margin-left": "30px" } }, [
              _c("li", { staticClass: "text-justify" }, [
                _c("strong", [_vm._v("Google Chrome ")]),
                _c(
                  "a",
                  {
                    staticStyle: { color: "#c1132c", "font-weight": "500" },
                    attrs: {
                      href:
                        "https://support.google.com/chrome/answer/95647?hl=hu"
                    }
                  },
                  [
                    _vm._v(
                      "https://support.google.com/chrome/answer/95647?hl=hu"
                    )
                  ]
                ),
                _c("br")
              ]),
              _vm._v(" "),
              _c("li", [
                _c("strong", [_vm._v("Internet Explorer ")]),
                _c(
                  "a",
                  {
                    staticStyle: { color: "#c1132c", "font-weight": "500" },
                    attrs: {
                      href:
                        "https://support.microsoft.com/huhu/help/17442/windows-internet-explorer-delete-manage-cookies"
                    }
                  },
                  [
                    _vm._v(
                      "https://support.microsoft.com/huhu/help/17442/windows-internet-explorer-delete-manage-cookies"
                    )
                  ]
                )
              ]),
              _vm._v(" "),
              _c("li", [
                _c("strong", [_vm._v("Firefox")]),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticStyle: { color: "#c1132c", "font-weight": "500" },
                    attrs: {
                      href:
                        "https://support.mozilla.org/hu/kb/weboldalak-helyi-tarolasi-beallitasainak-kezelese"
                    }
                  },
                  [
                    _vm._v(
                      "https://support.mozilla.org/hu/kb/weboldalak-helyi-tarolasi-beallitasainak-kezelese"
                    )
                  ]
                ),
                _c("br")
              ]),
              _vm._v(" "),
              _c("li", [
                _c("strong", [_vm._v("Safari")]),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticStyle: { color: "#c1132c", "font-weight": "500" },
                    attrs: {
                      href: "https://support.apple.com/kb/PH21411?locale=hu_HU"
                    }
                  },
                  [_vm._v("https://support.apple.com/kb/PH21411?locale=hu_HU")]
                ),
                _vm._v(" "),
                _c("br")
              ])
            ]),
            _vm._v(" "),
            _c(
              "h2",
              {
                staticClass: "text-uppercase",
                staticStyle: { "margin-bottom": "30px", "margin-top": "30px" }
              },
              [
                _vm._v(
                  "\n          7. GOOGLE ÉS FACEBOOK SZOLGÁLTATÁSOK HASZNÁLATA"
                ),
                _c("br")
              ]
            ),
            _vm._v(" "),
            _c(
              "h3",
              {
                staticClass: "text-uppercase",
                staticStyle: {
                  "margin-top": "40px",
                  "margin-bottom": "40px",
                  "margin-left": "25px"
                }
              },
              [
                _vm._v(
                  "\n          7.1. GOOGLE ADS KONVERZIÓKÖVETÉS HASZNÁLATA"
                ),
                _c("br")
              ]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "text-justify",
                staticStyle: { "margin-left": "30px" }
              },
              [
                _c("strong", [_vm._v("1.")]),
                _vm._v(
                  " A „Google Ads” nevű online reklámprogramot\n          használja az adatkezelő, továbbá annak keretein belül igénybe veszi\n          a Google konverziókövető szolgáltatását. A Google konverziókövetés a\n          Google Inc. elemző szolgáltatása (1600 Amphitheatre Parkway,\n          Mountain View, CA 94043, USA; „Google“). "
                ),
                _c("br")
              ]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "text-justify",
                staticStyle: { "margin-left": "30px" }
              },
              [
                _c("strong", [_vm._v("2.")]),
                _vm._v(
                  " Amikor Felhasználó egy weboldalt Google-hirdetés\n          által ér el, akkor egy a konverziókövetéshez szükséges cookie kerül\n          a számítógépére. Ezeknek a cookieknak az érvényessége korlátozott,\n          és nem tartalmaznak semmilyen személyes adatot, így a Felhasználó\n          nem is azonosítható általuk. "
                ),
                _c("br")
              ]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "text-justify",
                staticStyle: { "margin-left": "30px" }
              },
              [
                _c("strong", [_vm._v("3.")]),
                _vm._v(
                  " Amikor a Felhasználó a weboldal bizonyos\n          oldalait böngészi, és a cookie még nem járt le, akkor a Google és az\n          adatkezelő is láthatja, hogy Felhasználó a hirdetésre kattintott.\n          "
                ),
                _c("br")
              ]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "text-justify",
                staticStyle: { "margin-left": "30px" }
              },
              [
                _c("strong", [_vm._v("4.")]),
                _vm._v(
                  " Minden Google Ads ügyfél másik cookie-t kap, így\n          azokat az Ads ügyfeleinek weboldalain keresztül nem lehet\n          nyomon követni. "
                ),
                _c("br")
              ]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "text-justify",
                staticStyle: { "margin-left": "30px" }
              },
              [
                _c("strong", [_vm._v("5.")]),
                _vm._v(
                  " Az információk – melyeket a konverziókövető\n          cookie-k segítségével szereztek – azt a célt szolgálják, hogy az Ads\n          konverziókövetést választó ügyfeleinek számára konverziós\n          statisztikákat készítsenek. Az ügyfelek így tájékozódnak a\n          hirdetésükre kattintó és konverziókövető címkével ellátott oldalra\n          továbbított felhasználók számáról. Azonban olyan információkhoz nem\n          jutnak hozzá, melyekkel bármelyik felhasználót azonosítani lehetne.\n          "
                ),
                _c("br")
              ]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "text-justify",
                staticStyle: { "margin-left": "30px" }
              },
              [
                _c("strong", [_vm._v("6.")]),
                _vm._v(
                  " Ha nem szeretne részt venni a\n          konverziókövetésben, akkor ezt elutasíthatja azáltal, hogy\n          böngészőjében letiltja a cookie-k telepítésének lehetőségét. Ezután\n          Ön nem fog szerepelni a konverziókövetési statisztikákban. "
                ),
                _c("br")
              ]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "text-justify",
                staticStyle: { "margin-left": "30px" }
              },
              [
                _c("strong", [_vm._v("7.")]),
                _vm._v(
                  " További információ valamint a Google adatvédelmi\n          nyilatkozata az alábbi oldalon érhető el:\n          "
                ),
                _c(
                  "a",
                  {
                    staticStyle: { color: "#c1132c", "font-weight": "500" },
                    attrs: { href: "www.google.de/policies/privacy/" }
                  },
                  [_vm._v("www.google.de/policies/privacy/")]
                ),
                _c("br")
              ]
            ),
            _vm._v(" "),
            _c(
              "h3",
              {
                staticClass: "text-uppercase",
                staticStyle: {
                  "margin-top": "40px",
                  "margin-bottom": "40px",
                  "margin-left": "25px"
                }
              },
              [
                _vm._v("\n          7.2. A GOOGLE ANALYTICS ALKALMAZÁSA"),
                _c("br")
              ]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "text-justify",
                staticStyle: { "margin-left": "30px" }
              },
              [
                _c("strong", [_vm._v("1.")]),
                _vm._v(
                  " Ez a honlap a Google Analytics alkalmazást\n          használja, amely a Google Inc. („Google”) webelemző szolgáltatása. A\n          Google Analytics úgynevezett „cookie-kat”, szövegfájlokat használ,\n          amelyeket a számítógépére mentenek, így elősegítik Felhasználó által\n          látogatott weblap használatának elemzését. "
                ),
                _c("br")
              ]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "text-justify",
                staticStyle: { "margin-left": "30px" }
              },
              [
                _c("strong", [_vm._v("2. ")]),
                _vm._v(
                  "A Felhasználó által használt weboldallal\n          kapcsolatos cookie-kkal létrehozott információk rendszerint a Google\n          egyik USA-beli szerverére kerülnek és tárolódnak. Az IP-anonimizálás\n          weboldali aktiválásával a Google a Felhasználó IP-címét az Európai\n          Unió tagállamain belül vagy az Európai Gazdasági Térségről szóló\n          megállapodásban részes más államokban előzőleg megrövidíti."
                ),
                _c("br")
              ]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "text-justify",
                staticStyle: { "margin-left": "30px" }
              },
              [
                _c("strong", [_vm._v("3.")]),
                _vm._v(
                  " A teljes IP-címnek a Google USA-ban lévő\n          szerverére történő továbbítására és ottani lerövidítésére csak\n          kivételes esetekben kerül sor. Eme weboldal üzemeltetőjének\n          megbízásából a Google ezeket az információkat arra fogja használni,\n          hogy kiértékelje, hogyan használta a Felhasználó a honlapot,\n          továbbá, hogy a weboldal üzemeltetőjének a honlap aktivitásával\n          összefüggő jelentéseket készítsen, valamint, hogy a weboldal- és az\n          internethasználattal kapcsolatos további szolgáltatásokat\n          teljesítsen. "
                ),
                _c("br")
              ]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "text-justify",
                staticStyle: { "margin-left": "30px" }
              },
              [
                _c("strong", [_vm._v("4.")]),
                _vm._v(
                  " A Google Analytics keretein belül a Felhasználó\n          böngészője által továbbított IP-címet nem vezeti össze a Google más\n          adataival. A cookie-k tárolását a Felhasználó a böngészőjének\n          megfelelő beállításával megakadályozhatja, azonban felhívjuk\n          figyelmét, hogy ebben az esetben előfordulhat, hogy ennek a\n          honlapnak nem minden funkciója lesz teljes körűen használható.\n          Megakadályozhatja továbbá, hogy a Google gyűjtse és feldolgozza a\n          cookie-k általi, a Felhasználó weboldalhasználattal kapcsolatos\n          adatait (beleértve az IP-címet is), ha letölti és telepíti a\n          következő linken elérhető böngésző plugint.\n          "
                ),
                _c(
                  "a",
                  {
                    staticStyle: { color: "#c1132c", "font-weight": "500" },
                    attrs: {
                      href: "https://tools.google.com/dlpage/gaoptout?hl=hu"
                    }
                  },
                  [_vm._v("https://tools.google.com/dlpage/gaoptout?hl=hu")]
                ),
                _vm._v(" "),
                _c("br")
              ]
            ),
            _vm._v(" "),
            _c(
              "h3",
              {
                staticClass: "text-uppercase",
                staticStyle: {
                  "margin-top": "40px",
                  "margin-bottom": "40px",
                  "margin-left": "25px"
                }
              },
              [_vm._v("\n          7.3. FACEBOOK PIXEL "), _c("br")]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "text-justify",
                staticStyle: { "margin-left": "30px" }
              },
              [
                _c("strong", [_vm._v("1. ")]),
                _vm._v(
                  "A Facebook-képpont olyan kód, amelynek a\n          segítségével a honlapon jelentés készül a konverziókról,\n          célközönségek állíthatók össze, és az oldal tulajdonosa részletes\n          elemzési adatokat kap a látogatók honlap használatáról. A Facebook\n          remarketing pixel követőkód segítségével a weboldal látogatóinak\n          személyre szabott ajánlatokat, hirdetéseket jeleníthet meg a\n          Facebook felületén. A Facebook remarketing lista nem alkalmas\n          személyazonosításra. A Facebook Pixellel / Facebook-képponttal\n          kapcsolatosan további információt itt találhat:\n          "
                ),
                _c(
                  "a",
                  {
                    staticStyle: { color: "#c1132c", "font-weight": "500" },
                    attrs: {
                      href:
                        "https://www.facebook.com/business/help/651294705016616"
                    }
                  },
                  [
                    _vm._v(
                      "https://www.facebook.com/business/help/65129470516616"
                    )
                  ]
                ),
                _vm._v("  "),
                _c("br")
              ]
            ),
            _vm._v(" "),
            _c(
              "h3",
              {
                staticClass: "text-uppercase",
                staticStyle: {
                  "margin-top": "40px",
                  "margin-bottom": "40px",
                  "margin-left": "25px"
                }
              },
              [_vm._v("\n          7.4. KÖZÖSSÉGI OLDALAK"), _c("br")]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "text-justify",
                staticStyle: { "margin-left": "30px" }
              },
              [
                _c("strong", [_vm._v("1.")]),
                _vm._v(
                  " Az adatgyűjtés ténye, a kezelt adatok köre:\n          Facebook/Google+/Twitter/Pinterest/Youtube/Instagram stb. közösségi\n          oldalakon regisztrált neve, illetve a felhasználó nyilvános\n          profilképe.   "
                ),
                _c("br")
              ]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "text-justify",
                staticStyle: { "margin-left": "30px" }
              },
              [
                _c("strong", [_vm._v("2.")]),
                _vm._v(
                  " Az érintettek köre: Valamennyi érintett, aki\n          regisztrált a Facebook/Google+/Twitter/Pinterest/Youtube/Instagram\n          stb. közösségi oldalakon, és „lájkolta” a Szolgáltató közösségi\n          oldalát, illetve a közösségi oldalon keresztül felvette a\n          kapcsolatot az adatkezelővel."
                ),
                _c("br")
              ]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "text-justify",
                staticStyle: { "margin-left": "30px" }
              },
              [
                _c("strong", [_vm._v("3.")]),
                _vm._v(
                  " Az adatgyűjtés célja: A közösségi oldalakon, a\n          weboldal egyes tartalmi elemeinek, akcióinak vagy magának a\n          weboldalnak a megosztása, illetve „lájkolása”, követése,\n          népszerűsítése. "
                ),
                _c("br")
              ]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "text-justify",
                staticStyle: { "margin-left": "30px" }
              },
              [
                _c("strong", [_vm._v("4.")]),
                _vm._v(
                  " Az adatkezelés időtartama, az adatok törlésének\n          határideje, az adatok megismerésére jogosult lehetséges adatkezelők\n          személye és az érintettek adatkezeléssel kapcsolatos jogainak\n          ismertetése: Az adatok forrásáról, azok kezeléséről, illetve az\n          átadás módjáról, és jogalapjáról az adott közösségi oldalon\n          tájékozódhat az érintett. Az adatkezelés a közösségi oldalakon\n          valósul meg, így az adatkezelés időtartamára, módjára, illetve az\n          adatok törlési és módosítási lehetőségeire az adott közösségi oldal\n          szabályozása vonatkozik. "
                ),
                _c("br"),
                _c("br")
              ]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "text-justify",
                staticStyle: { "margin-left": "30px" }
              },
              [
                _c("strong", [_vm._v("5.")]),
                _vm._v(
                  " Az adatkezelés jogalapja: az érintett önkéntes\n          hozzájárulása személyes adatai kezeléséhez a közösségi oldalakon.\n          "
                ),
                _c("br")
              ]
            ),
            _vm._v(" "),
            _c(
              "h2",
              {
                staticClass: "text-uppercase",
                staticStyle: { "margin-bottom": "30px", "margin-top": "30px" }
              },
              [
                _vm._v(
                  "\n          8. ÜGYFÉLKAPCSOLATOK ÉS EGYÉB ADATKEZELÉSEK"
                ),
                _c("br")
              ]
            ),
            _vm._v(" "),
            _c("p", { staticClass: "text-justify" }, [
              _c("strong", [_vm._v("1.")]),
              _vm._v(
                " Amennyiben az adatkezelő szolgáltatásaink\n          igénybevétele során kérdés merülne fel, esetleg problémája lenne az\n          érintettnek, a honlapon megadott módokon (telefon, email, közösségi\n          oldalak stb.) kapcsolatba léphet az adatkezelővel. "
              ),
              _c("br")
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "text-justify" }, [
              _c("strong", [_vm._v("2.")]),
              _vm._v(
                " Adatkezelő a beérkezett e-maileket, üzeneteket,\n          telefonon, Facebook-on stb. megadott adatokat az érdeklődő nevével\n          és e-mail címével, valamint más, önként megadott személyes adatával\n          együtt, az adatközléstől számított legfeljebb 2 év elteltével törli.\n          "
              ),
              _c("br")
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "text-justify" }, [
              _c("strong", [_vm._v("3.")]),
              _vm._v(
                " E tájékoztatóban fel nem sorolt adatkezelésekről\n          az adat felvételekor adunk tájékoztatást."
              ),
              _c("br")
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "text-justify" }, [
              _c("strong", [_vm._v("4.")]),
              _vm._v(
                " Kivételes hatósági megkeresésre, illetőleg\n          jogszabály felhatalmazása alapján más szervek megkeresése esetén a\n          Szolgáltató köteles tájékoztatás adására, adatok közlésére,\n          átadására, illetőleg iratok rendelkezésre bocsátására."
              ),
              _c("br")
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "text-justify" }, [
              _c("strong", [_vm._v("5.")]),
              _vm._v(
                " A Szolgáltató ezen esetekben a megkereső részére\n          – amennyiben az a pontos célt és az adatok körét megjelölte –\n          személyes adatot csak annyit és olyan mértékben ad ki, amely a\n          megkeresés céljának megvalósításához elengedhetetlenül szükséges.\n          "
              ),
              _c("br")
            ]),
            _vm._v(" "),
            _c(
              "h2",
              {
                staticClass: "text-uppercase",
                staticStyle: { "margin-bottom": "30px", "margin-top": "30px" }
              },
              [_vm._v("\n          9. AZ ÉRINTETTEK JOGAI"), _c("br")]
            ),
            _vm._v(" "),
            _c(
              "h3",
              {
                staticClass: "text-uppercase",
                staticStyle: {
                  "margin-top": "40px",
                  "margin-bottom": "40px",
                  "margin-left": "25px"
                }
              },
              [_vm._v("\n          1. A hozzáférés joga"), _c("br")]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "text-justify",
                staticStyle: { "margin-left": "30px" }
              },
              [
                _vm._v(
                  "\n          Ön jogosult arra, hogy az adatkezelőtől visszajelzést kapjon arra\n          vonatkozóan, hogy személyes adatainak kezelése folyamatban van-e, és\n          ha ilyen adatkezelés folyamatban van, jogosult arra, hogy a\n          személyes adatokhoz és a rendeletben felsorolt információkhoz\n          hozzáférést kapjon."
                ),
                _c("br")
              ]
            ),
            _vm._v(" "),
            _c(
              "h3",
              {
                staticClass: "text-uppercase",
                staticStyle: {
                  "margin-top": "40px",
                  "margin-bottom": "40px",
                  "margin-left": "25px"
                }
              },
              [_vm._v("\n          2. A helyesbítéshez való jog "), _c("br")]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "text-justify",
                staticStyle: { "margin-left": "30px" }
              },
              [
                _vm._v(
                  "\n          Ön jogosult arra, hogy kérésére az adatkezelő indokolatlan késedelem\n          nélkül helyesbítse az Önre vonatkozó pontatlan személyes adatokat.\n          Figyelembe véve az adatkezelés célját, Ön jogosult arra, hogy kérje\n          a hiányos személyes adatok – egyebek mellett kiegészítő nyilatkozat\n          útján történő – kiegészítését. "
                ),
                _c("br")
              ]
            ),
            _vm._v(" "),
            _c(
              "h3",
              {
                staticClass: "text-uppercase",
                staticStyle: {
                  "margin-top": "40px",
                  "margin-bottom": "40px",
                  "margin-left": "25px"
                }
              },
              [_vm._v("\n          3. A törléshez való jog "), _c("br")]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "text-justify",
                staticStyle: { "margin-left": "30px" }
              },
              [
                _vm._v(
                  "\n          Ön jogosult arra, hogy kérésére az adatkezelő indokolatlan késedelem\n          nélkül törölje az Önre vonatkozó személyes adatokat, az adatkezelő\n          pedig köteles arra, hogy Önre vonatkozó személyes adatokat\n          indokolatlan késedelem nélkül törölje meghatározott feltételek\n          esetén. "
                ),
                _c("br")
              ]
            ),
            _vm._v(" "),
            _c(
              "h3",
              {
                staticClass: "text-uppercase",
                staticStyle: {
                  "margin-top": "40px",
                  "margin-bottom": "40px",
                  "margin-left": "25px"
                }
              },
              [_vm._v("\n          4. Az elfeledtetéshez való jog "), _c("br")]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "text-justify",
                staticStyle: { "margin-left": "30px" }
              },
              [
                _vm._v(
                  "\n          Ha az adatkezelő nyilvánosságra hozta a személyes adatot, és azt\n          törölni köteles, az elérhető technológia és a megvalósítás\n          költségeinek figyelembevételével megteszi az ésszerűen elvárható\n          lépéseket – ideértve technikai intézkedéseket – annak érdekében,\n          hogy tájékoztassa az adatokat kezelő adatkezelőket, hogy Ön\n          kérelmezte a szóban forgó személyes adatokra mutató linkek vagy e\n          személyes adatok másolatának, illetve másodpéldányának törlését.\n          "
                ),
                _c("br")
              ]
            ),
            _vm._v(" "),
            _c(
              "h3",
              {
                staticClass: "text-uppercase",
                staticStyle: {
                  "margin-top": "40px",
                  "margin-bottom": "40px",
                  "margin-left": "25px"
                }
              },
              [
                _vm._v(
                  "\n          5. Az adatkezelés korlátozásához való jog "
                ),
                _c("br")
              ]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "text-justify",
                staticStyle: { "margin-left": "30px" }
              },
              [
                _vm._v(
                  "\n          Ön jogosult arra, hogy kérésére az adatkezelő korlátozza az\n          adatkezelést, ha az alábbi feltételek valamelyike teljesül:\n          "
                ),
                _c("br")
              ]
            ),
            _vm._v(" "),
            _c("ul", { staticStyle: { "margin-left": "30px" } }, [
              _c("li", { staticClass: "text-justify" }, [
                _vm._v(
                  "\n            Ön vitatja a személyes adatok pontosságát, ez esetben a korlátozás\n            arra az időtartamra vonatkozik, amely lehetővé teszi, hogy az\n            adatkezelő ellenőrizze a személyes adatok pontosságát; "
                ),
                _c("br")
              ]),
              _vm._v(" "),
              _c("li", [
                _vm._v(
                  "\n            az adatkezelés jogellenes, és Ön ellenzi az adatok törlését, és\n            ehelyett kéri azok felhasználásának korlátozását; "
                ),
                _c("br")
              ]),
              _vm._v(" "),
              _c("li", [
                _vm._v(
                  "\n            az adatkezelőnek már nincs szüksége a személyes adatokra\n            adatkezelés céljából, de Ön igényli azokat jogi igények\n            előterjesztéséhez, érvényesítéséhez vagy védelméhez;"
                ),
                _c("br")
              ]),
              _vm._v(" "),
              _c("li", [
                _vm._v(
                  "\n            Ön tiltakozott az adatkezelés ellen; ez esetben a korlátozás arra\n            az időtartamra vonatkozik, amíg megállapításra nem kerül, hogy az\n            adatkezelő jogos indokai elsőbbséget élveznek-e Ön jogos\n            indokaival szemben. "
                ),
                _c("br")
              ])
            ]),
            _vm._v(" "),
            _c(
              "h3",
              {
                staticClass: "text-uppercase",
                staticStyle: {
                  "margin-top": "40px",
                  "margin-bottom": "40px",
                  "margin-left": "25px"
                }
              },
              [
                _vm._v("\n          6. Az adathordozhatósághoz való jog "),
                _c("br")
              ]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "text-justify",
                staticStyle: { "margin-left": "30px" }
              },
              [
                _vm._v(
                  "\n          Ön jogosult arra, hogy az Önre vonatkozó, általa egy adatkezelő\n          rendelkezésére bocsátott személyes adatokat tagolt, széles körben\n          használt, géppel olvasható formátumban megkapja, továbbá jogosult\n          arra, hogy ezeket az adatokat egy másik adatkezelőnek továbbítsa\n          anélkül, hogy ezt akadályozná az az adatkezelő, amelynek a személyes\n          adatokat a rendelkezésére bocsátotta (...) "
                ),
                _c("br")
              ]
            ),
            _vm._v(" "),
            _c(
              "h3",
              {
                staticClass: "text-uppercase",
                staticStyle: {
                  "margin-top": "40px",
                  "margin-bottom": "40px",
                  "margin-left": "25px"
                }
              },
              [_vm._v("\n          7. A tiltakozáshoz való jog "), _c("br")]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "text-justify",
                staticStyle: { "margin-left": "30px" }
              },
              [
                _vm._v(
                  "\n          A jogos érdeken, illetve a közhatalmi jogosítványon, mint\n          jogalapokon alapuló adatkezelések esetében Ön jogosult arra, hogy a\n          saját helyzetével kapcsolatos okokból bármikor tiltakozzon személyes\n          adatainak a (...) kezelése ellen, ideértve az említett\n          rendelkezéseken alapuló profilalkotást is. "
                ),
                _c("br")
              ]
            ),
            _vm._v(" "),
            _c(
              "h3",
              {
                staticClass: "text-uppercase",
                staticStyle: {
                  "margin-top": "40px",
                  "margin-bottom": "40px",
                  "margin-left": "25px"
                }
              },
              [
                _vm._v(
                  "\n          8. Tiltakozás közvetlen üzletszerzés estén "
                ),
                _c("br")
              ]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "text-justify",
                staticStyle: { "margin-left": "30px" }
              },
              [
                _vm._v(
                  "\n          Ha a személyes adatok kezelése közvetlen üzletszerzés érdekében\n          történik, Ön jogosult arra, hogy bármikor tiltakozzon az Önre\n          vonatkozó személyes adatok e célból történő kezelése ellen, ideértve\n          a profilalkotást is, amennyiben az a közvetlen üzletszerzéshez\n          kapcsolódik. Ha Ön tiltakozik a személyes adatok közvetlen\n          üzletszerzés érdekében történő kezelése ellen, akkor a személyes\n          adatok a továbbiakban e célból nem kezelhetők. "
                ),
                _c("br")
              ]
            ),
            _vm._v(" "),
            _c(
              "h3",
              {
                staticClass: "text-uppercase",
                staticStyle: {
                  "margin-top": "40px",
                  "margin-bottom": "40px",
                  "margin-left": "25px"
                }
              },
              [
                _vm._v(
                  "\n          9. Automatizált döntéshozatal egyedi ügyekben, beleértve a\n          profilalkotást "
                ),
                _c("br")
              ]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "text-justify",
                staticStyle: { "margin-left": "30px" }
              },
              [
                _vm._v(
                  "\n          Ön jogosult arra, hogy ne terjedjen ki Önre az olyan, kizárólag\n          automatizált adatkezelésen – ideértve a profilalkotást is – alapuló\n          döntés hatálya, amely rá nézve joghatással járna vagy őt\n          hasonlóképpen jelentős mértékben érintené. Az előző bekezdés nem\n          alkalmazandó abban az esetben, ha a döntés: "
                ),
                _c("br")
              ]
            ),
            _vm._v(" "),
            _c("ul", { staticStyle: { "margin-left": "30px" } }, [
              _c("li", { staticClass: "text-justify" }, [
                _vm._v(
                  "\n            Ön és az adatkezelő közötti szerződés megkötése vagy teljesítése\n            érdekében szükséges; "
                ),
                _c("br")
              ]),
              _vm._v(" "),
              _c("li", [
                _vm._v(
                  "\n            meghozatalát az adatkezelőre alkalmazandó olyan uniós vagy\n            tagállami jog teszi lehetővé, amely Ön jogainak és szabadságainak,\n            valamint jogos érdekeinek védelmét szolgáló megfelelő\n            intézkedéseket is megállapít; vagy"
                ),
                _c("br")
              ]),
              _vm._v(" "),
              _c("li", [
                _vm._v("Ön kifejezett hozzájárulásán alapul. "),
                _c("br")
              ])
            ]),
            _vm._v(" "),
            _c(
              "h3",
              {
                staticClass: "text-uppercase",
                staticStyle: {
                  "margin-top": "40px",
                  "margin-bottom": "40px",
                  "margin-left": "25px"
                }
              },
              [_vm._v("\n          10. INTÉZKEDÉSI HATÁRIDŐ"), _c("br")]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "text-justify",
                staticStyle: { "margin-left": "30px" }
              },
              [
                _vm._v(
                  "\n          Az adatkezelő indokolatlan késedelem nélkül, de mindenféleképpen a\n          kérelem beérkezésétől számított\n          "
                ),
                _c("strong", [_vm._v("1 hónapon belül")]),
                _vm._v(
                  " tájékoztatja Önt a fenti kérelmek\n          nyomán hozott intézkedésekről. "
                ),
                _c("br")
              ]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "text-justify",
                staticStyle: { "margin-left": "30px" }
              },
              [
                _vm._v("\n          Szükség esetén ez "),
                _c("strong", [_vm._v("2 hónappal meghosszabbítható")]),
                _vm._v(
                  ". A\n          határidő meghosszabbításáról az adatkezelő a késedelem okainak\n          megjelölésével a kérelem kézhezvételétől számított\n          "
                ),
                _c("strong", [_vm._v("1 hónapon belül")]),
                _vm._v(" tájékoztatja Önt. "),
                _c("br")
              ]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "text-justify",
                staticStyle: { "margin-left": "30px" }
              },
              [
                _vm._v(
                  "\n          Ha az adatkezelő nem tesz intézkedéseket Ön kérelme nyomán,\n          "
                ),
                _c("strong", [
                  _vm._v(
                    "késedelem nélkül, de legkésőbb a kérelem beérkezésétől számított\n            egy hónapon belül tájékoztatja Önt az intézkedés elmaradásának\n            okairól"
                  )
                ]),
                _vm._v(
                  ", valamint arról, hogy Ön panaszt nyújthat be valamely felügyeleti\n          hatóságnál, és élhet bírósági jogorvoslati jogával."
                ),
                _c("br")
              ]
            ),
            _vm._v(" "),
            _c(
              "h3",
              {
                staticClass: "text-uppercase",
                staticStyle: {
                  "margin-top": "40px",
                  "margin-bottom": "40px",
                  "margin-left": "25px"
                }
              },
              [_vm._v("\n          11. AZ ADATKEZELÉS BIZTONSÁGA"), _c("br")]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "text-justify",
                staticStyle: { "margin-left": "30px" }
              },
              [
                _vm._v(
                  "\n          Az adatkezelő és az adatfeldolgozó a tudomány és technológia állása\n          és a megvalósítás költségei, továbbá az adatkezelés jellege,\n          hatóköre, körülményei és céljai, valamint a természetes személyek\n          jogaira és szabadságaira jelentett, változó valószínűségű és\n          súlyosságú kockázat figyelembevételével megfelelő technikai és\n          szervezési intézkedéseket hajt végre annak érdekében, hogy a\n          kockázat mértékének megfelelő szintű adatbiztonságot garantálja,\n          ideértve, többek között, adott esetben:"
                ),
                _c("br")
              ]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "text-justify",
                staticStyle: { "margin-left": "50px" }
              },
              [
                _c("strong", [_vm._v("a)")]),
                _vm._v(
                  " a személyes adatok álnevesítését és\n          titkosítását; "
                ),
                _c("br")
              ]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "text-justify",
                staticStyle: { "margin-left": "50px" }
              },
              [
                _c("strong", [_vm._v("b)")]),
                _vm._v(
                  " a személyes adatok kezelésére használt\n          rendszerek és szolgáltatások folyamatos bizalmas jellegének\n          biztosítását, integritását, rendelkezésre állását és ellenálló\n          képességét; "
                ),
                _c("br")
              ]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "text-justify",
                staticStyle: { "margin-left": "50px" }
              },
              [
                _c("strong", [_vm._v("c)")]),
                _vm._v(
                  " fizikai vagy műszaki incidens esetén az arra\n          való képességet, hogy a személyes adatokhoz való hozzáférést és az\n          adatok rendelkezésre állását kellő időben vissza lehet állítani;\n          "
                ),
                _c("br")
              ]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "text-justify",
                staticStyle: { "margin-left": "50px" }
              },
              [
                _c("strong", [_vm._v("d) ")]),
                _vm._v(
                  "az adatkezelés biztonságának garantálására\n          hozott technikai és szervezési intézkedések hatékonyságának\n          rendszeres tesztelésére, felmérésére és értékelésére szolgáló\n          eljárást. "
                ),
                _c("br")
              ]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "text-justify",
                staticStyle: { "margin-left": "50px" }
              },
              [
                _c("strong", [_vm._v("e)")]),
                _vm._v(
                  " A kezelt adatokat úgy kell tárolni, hogy azokhoz\n          illetéktelenek ne férhessenek hozzá. Papír alapú adathordozók\n          esetében a fizikai tárolás, irattárazás rendjének kialakításával,\n          elektronikus formában kezelt adatok esetén központi\n          jogosultságkezelő rendszer alkalmazásával. "
                ),
                _c("br")
              ]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "text-justify",
                staticStyle: { "margin-left": "50px" }
              },
              [
                _c("strong", [_vm._v("f)")]),
                _vm._v(
                  " Az adatok informatikai módszerrel történő\n          tárolási módját úgy kell megválasztani, hogy azok törlése – az\n          esetleg eltérő törlési határidőre is tekintettel – az adattörlési\n          határidő lejártakor, illetve ha az egyéb okból szükséges,\n          elvégezhető legyen. A törlésnek visszaállíthatatlannak kell lennie.\n          "
                ),
                _c("br")
              ]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "text-justify",
                staticStyle: { "margin-left": "50px" }
              },
              [
                _c("strong", [_vm._v("g)")]),
                _vm._v(
                  " A papír alapú adathordozókat iratmegsemmisítő\n          segítségével, vagy külső, iratmegsemmisítésre szakosodott szervezet\n          igénybevételével kell a személyes adatoktól megfosztani.\n          Elektronikus adathordozók esetében az elektronikus adathordozók\n          selejtezésére vonatkozó szabályok szerint kell gondoskodni a fizikai\n          megsemmisítésről, illetve szükség szerint előzetesen az adatoknak a\n          biztonságos és visszaállíthatatlan törléséről."
                ),
                _c("br")
              ]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "text-justify",
                staticStyle: { "margin-left": "50px" }
              },
              [
                _c("strong", [_vm._v("h)")]),
                _vm._v(
                  " Az adatkezelő az alábbi konkrét adatbiztonsági\n          intézkedéseket teszi:"
                ),
                _c("br")
              ]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "text-justify",
                staticStyle: { "margin-left": "70px" }
              },
              [
                _c("strong", [_vm._v("a.")]),
                _vm._v(
                  " A papír alapon kezelt személyes adatok\n          biztonsága érdekében a Szolgáltató az alábbi intézkedéseket\n          alkalmazza (fizikai védelem): "
                ),
                _c("br")
              ]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "text-justify",
                staticStyle: { "margin-left": "90px" }
              },
              [
                _c("strong", [_vm._v("I.")]),
                _vm._v(
                  " A dokumentumokat biztonságos, jól zárható száraz\n          helyiségben helyezni el."
                ),
                _c("br"),
                _c("strong", [_vm._v("II.")]),
                _vm._v(
                  " A Szolgáltató\n          épülete és helyiségei tűzvédelmi és vagyonvédelmi berendezéssel\n          vannak ellátva."
                ),
                _c("br"),
                _c("strong", [_vm._v("III.")]),
                _vm._v(
                  " A személyes adatokat csak\n          az arra jogosult személyek ismerhetik meg, azokhoz harmadik\n          személyek nem férhetnek hozzá."
                ),
                _c("br"),
                _c("strong", [_vm._v("IV.")]),
                _vm._v(
                  " A\n          Szolgáltató adatkezelést végző munkatársa a munkavégzése során csak\n          úgy hagyhatja el azt a helyiséget, ahol adatkezelés folyik, hogy a\n          rá bízott adathordozókat elzárja, vagy az adott helyiséget\n          bezárja."
                ),
                _c("br"),
                _c("strong", [_vm._v("V.")]),
                _vm._v(
                  " Amennyiben a papíralapon kezelt\n          személyes adatok digitalizálására kerül sor, akkor a digitálisan\n          tárolt dokumentumokra irányadó szabályokat kell alkalmazni. "
                ),
                _c("br")
              ]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "text-justify",
                staticStyle: { "margin-left": "70px" }
              },
              [
                _c("strong", [_vm._v("b.")]),
                _vm._v(" Informatikai védelem "),
                _c("br")
              ]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "text-justify",
                staticStyle: { "margin-left": "90px" }
              },
              [
                _c("strong", [_vm._v("I.")]),
                _vm._v(
                  " Az adatkezelés során használt számítógépek és\n          mobil eszközök (egyéb adathordozók) a Szolgáltató birtokát\n          képezik."
                ),
                _c("br"),
                _c("strong", [_vm._v("II.")]),
                _vm._v(
                  " A számítógépeken található\n          adatokhoz felhasználónévvel és jelszóval lehet csak hozzáférni."
                ),
                _c("br"),
                _c("strong", [_vm._v("III.")]),
                _vm._v(
                  "\n          A központi szerver géphez csak megfelelő jogosultsággal és csakis az\n          arra kijelölt személyek férhetnek hozzá."
                ),
                _c("br"),
                _c("strong", [_vm._v("IV.")]),
                _vm._v(
                  " A\n          digitálisan tárolt adatok biztonsága érdekében a Szolgáltató\n          adatmentéseket és archiválásokat alkalmaz."
                ),
                _c("br"),
                _c("strong", [_vm._v("V.")]),
                _vm._v(
                  "\n          A Szolgáltató által hasznát személyes adatokat tartalmazó\n          számítógépes rendszer vírusvédelemmel van ellátva. "
                ),
                _c("br"),
                _c("br")
              ]
            ),
            _vm._v(" "),
            _c(
              "h3",
              {
                staticClass: "text-uppercase",
                staticStyle: {
                  "margin-top": "40px",
                  "margin-bottom": "40px",
                  "margin-left": "25px"
                }
              },
              [
                _vm._v(
                  "\n          12. AZ ÉRINTETT TÁJÉKOZTATÁSA AZ ADATVÉDELMI INCIDENSRŐL"
                ),
                _c("br")
              ]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "text-justify",
                staticStyle: { "margin-left": "30px" }
              },
              [
                _vm._v(
                  "\n          Ha az adatvédelmi incidens valószínűsíthetően magas kockázattal jár\n          a természetes személyek jogaira és szabadságaira nézve, az\n          adatkezelő indokolatlan késedelem nélkül tájékoztatja az érintettet\n          az adatvédelmi incidensről. "
                ),
                _c("br")
              ]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "text-justify",
                staticStyle: { "margin-left": "30px" }
              },
              [
                _vm._v(
                  "\n          Az érintett részére adott tájékoztatásban\n          "
                ),
                _c("strong", [_vm._v("világosan és közérthetően")]),
                _vm._v(
                  " ismertetni kell az\n          adatvédelmi incidens jellegét, és közölni kell az adatvédelmi\n          tisztviselő vagy a további tájékoztatást nyújtó egyéb kapcsolattartó\n          nevét és elérhetőségeit; ismertetni kell az adatvédelmi incidensből\n          eredő, valószínűsíthető következményeket; ismertetni kell az\n          adatkezelő által az adatvédelmi incidens orvoslására tett vagy\n          tervezett intézkedéseket, beleértve adott esetben az adatvédelmi\n          incidensből eredő esetleges hátrányos következmények enyhítését\n          célzó intézkedéseket. "
                ),
                _c("br")
              ]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "text-justify",
                staticStyle: { "margin-left": "30px" }
              },
              [
                _vm._v(
                  "\n          Az érintettet nem kell tájékoztatni, ha a következő feltételek\n          bármelyike teljesül:"
                ),
                _c("br")
              ]
            ),
            _vm._v(" "),
            _c("ul", { staticStyle: { "margin-left": "30px" } }, [
              _c("li", { staticClass: "text-justify" }, [
                _vm._v("\n            az adatkezelő\n            "),
                _c("strong", [
                  _vm._v(
                    "megfelelő technikai és szervezési védelmi intézkedéseket\n              hajtott végre"
                  )
                ]),
                _vm._v(
                  ", és ezeket az intézkedéseket az adatvédelmi incidens által\n            érintett adatok tekintetében alkalmazták, különösen azokat az\n            intézkedéseket – mint például a titkosítás alkalmazása –, amelyek\n            a személyes adatokhoz való hozzáférésre fel nem jogosított\n            személyek számára\n            "
                ),
                _c("strong", [
                  _vm._v("értelmezhetetlenné teszik az adatokat;")
                ]),
                _c("br")
              ]),
              _vm._v(" "),
              _c("li", [
                _vm._v(
                  "\n            az adatkezelő az adatvédelmi incidenst követően olyan további\n            intézkedéseket tett, amelyek\n            "
                ),
                _c("strong", [
                  _vm._v(
                    "biztosítják, hogy az érintett jogaira és szabadságaira\n              jelentett, magas kockázat a továbbiakban valószínűsíthetően nem\n              valósul meg;"
                  )
                ]),
                _c("br")
              ]),
              _vm._v(" "),
              _c("li", [
                _vm._v("\n            a tájékoztatás\n            "),
                _c("strong", [
                  _vm._v("aránytalan erőfeszítést tenne szükségessé")
                ]),
                _vm._v(
                  ". Ilyen\n            esetekben az érintetteket nyilvánosan közzétett információk útján\n            kell tájékoztatni, vagy olyan hasonló intézkedést kell hozni,\n            amely biztosítja az érintettek hasonlóan hatékony tájékoztatását.\n            "
                ),
                _c("br")
              ])
            ]),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "text-justify",
                staticStyle: { "margin-left": "30px" }
              },
              [
                _vm._v(
                  "\n          Ha az adatkezelő még nem értesítette az érintettet az adatvédelmi\n          incidensről, a felügyeleti hatóság, miután mérlegelte, hogy az\n          adatvédelmi incidens valószínűsíthetően magas kockázattal jár-e,\n          elrendelheti az érintett tájékoztatását. "
                ),
                _c("br")
              ]
            ),
            _vm._v(" "),
            _c(
              "h3",
              {
                staticClass: "text-uppercase",
                staticStyle: {
                  "margin-top": "40px",
                  "margin-bottom": "40px",
                  "margin-left": "25px"
                }
              },
              [
                _vm._v(
                  "\n          13. ADATVÉDELMI INCIDENS BEJELENTÉSE A HATÓSÁGNAK"
                ),
                _c("br")
              ]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "text-justify",
                staticStyle: { "margin-left": "30px" }
              },
              [
                _vm._v(
                  "\n          Az adatvédelmi incidenst az adatkezelő indokolatlan késedelem\n          nélkül, és ha lehetséges, legkésőbb 72 órával azután, hogy az\n          adatvédelmi incidens a tudomására jutott, bejelenti az 55. cikk\n          alapján illetékes felügyeleti hatóságnak, kivéve, ha az adatvédelmi\n          incidens valószínűsíthetően nem jár kockázattal a természetes\n          személyek jogaira és szabadságaira nézve. Ha a bejelentés nem\n          történik meg 72 órán belül, mellékelni kell hozzá a késedelem\n          igazolására szolgáló indokokat is. "
                ),
                _c("br")
              ]
            ),
            _vm._v(" "),
            _c(
              "h3",
              {
                staticClass: "text-uppercase",
                staticStyle: {
                  "margin-top": "40px",
                  "margin-bottom": "40px",
                  "margin-left": "25px"
                }
              },
              [
                _vm._v(
                  "\n          14. FELÜLVIZSGÁLAT KÖTELEZŐ ADATKEZELÉS ESETÉN"
                ),
                _c("br")
              ]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "text-justify",
                staticStyle: { "margin-left": "30px" }
              },
              [
                _vm._v(
                  "\n          Ha a kötelező adatkezelés időtartamát, vagy szükségessége időszakos\n          felülvizsgálatát törvény, helyi önkormányzat rendelete, vagy az\n          Európai Unió kötelező jogi aktusa nem határozza meg,\n          "
                ),
                _c("strong", [
                  _vm._v(
                    "az adatkezelő az adatkezelés megkezdésétől legalább háromévente\n            felülvizsgálja"
                  )
                ]),
                _vm._v(
                  ", hogy az általa, illetve a megbízásából vagy rendelkezése alapján\n          eljáró adatfeldolgozó által kezelt személyes adat kezelése az\n          adatkezelés céljának megvalósulásához "
                ),
                _c("strong", [_vm._v("szükséges-e.")]),
                _vm._v(" "),
                _c("br")
              ]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "text-justify",
                staticStyle: { "margin-left": "30px" }
              },
              [
                _vm._v(
                  "\n          Ezen felülvizsgálat körülményeit és eredményét az adatkezelő\n          "
                ),
                _c("strong", [
                  _vm._v(
                    "dokumentálja, e dokumentációt a felülvizsgálat elvégzését követő\n            tíz évig megőrzi"
                  )
                ]),
                _vm._v(
                  "\n          és azt a Nemzeti Adatvédelmi és Információszabadság Hatóság (a\n          továbbiakban: Hatóság) kérésére a Hatóság rendelkezésére bocsátja.\n          "
                ),
                _c("br")
              ]
            ),
            _vm._v(" "),
            _c(
              "h3",
              {
                staticClass: "text-uppercase",
                staticStyle: {
                  "margin-top": "40px",
                  "margin-bottom": "40px",
                  "margin-left": "25px"
                }
              },
              [_vm._v("\n          15. PANASZTÉTELI LEHETŐSÉG"), _c("br")]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "text-justify",
                staticStyle: { "margin-left": "30px" }
              },
              [
                _vm._v(
                  "\n          Az adatkezelő esetleges jogsértése ellen panasszal a Nemzeti\n          Adatvédelmi és Információszabadság Hatóságnál lehet élni: "
                ),
                _c("br")
              ]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "text-justify",
                staticStyle: { "margin-left": "30px" }
              },
              [
                _c("strong", [
                  _vm._v("Nemzeti Adatvédelmi és Információszabadság Hatóság")
                ]),
                _c("br"),
                _vm._v("1125 Budapest, Szilágyi Erzsébet fasor 22/C."),
                _c("br"),
                _vm._v(
                  "Levelezési\n          cím: 1530 Budapest, Postafiók: 5."
                ),
                _c("br"),
                _vm._v("Telefon:\n          "),
                _c(
                  "a",
                  {
                    staticStyle: { color: "#c1132c", "font-weight": "500" },
                    attrs: { href: "tel:+36 -1-391-1400" }
                  },
                  [_vm._v("+36 1 391 1400")]
                ),
                _c("br"),
                _vm._v("Fax: +36-1-391-1410"),
                _c("br"),
                _vm._v("E-mail:\n          "),
                _c(
                  "a",
                  {
                    staticStyle: { color: "#c1132c", "font-weight": "500" },
                    attrs: { href: "mailto:ugyfelszolgalat@naih.hu" }
                  },
                  [_vm._v("ugyfelszolgalat@naih.hu")]
                ),
                _c("br")
              ]
            ),
            _vm._v(" "),
            _c(
              "h3",
              {
                staticClass: "text-uppercase",
                staticStyle: {
                  "margin-top": "40px",
                  "margin-bottom": "40px",
                  "margin-left": "25px"
                }
              },
              [_vm._v("\n          16. ZÁRSZÓ"), _c("br")]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "text-justify",
                staticStyle: { "margin-left": "30px" }
              },
              [
                _vm._v(
                  "\n          A tájékoztató elkészítése során figyelemmel voltunk az alábbi\n          jogszabályokra: "
                ),
                _c("br")
              ]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "text-justify",
                staticStyle: { "margin-left": "30px" }
              },
              [
                _c("br"),
                _vm._v(
                  "- A természetes személyeknek a személyes adatok kezelése\n          tekintetében történő védelméről és az ilyen adatok szabad\n          áramlásáról, valamint a 95/46/EK rendelet hatályon kívül\n          helyezéséről (általános adatvédelmi rendelet)\n          "
                ),
                _c("strong", [
                  _vm._v(
                    "AZ EURÓPAI PARLAMENT ÉS A TANÁCS (EU) 2016/679 RENDELETE\n            (GDPR)(2016. április 27.)"
                  )
                ]),
                _c("br"),
                _vm._v("- "),
                _c("strong", [_vm._v("2011. évi CXII. törvény")]),
                _vm._v(
                  " – az információs\n          önrendelkezési jogról és az információszabadságról (a továbbiakban:\n          Infotv.)"
                ),
                _c("br"),
                _vm._v("- "),
                _c("strong", [_vm._v("2001. évi CVIII. törvény")]),
                _vm._v(
                  " – az\n          elektronikus kereskedelmi szolgáltatások, valamint\n          az információs társadalommal összefüggő szolgáltatások egyes\n          kérdéseiről ("
                ),
                _c("strong", [_vm._v("főképp a 13/A. §-a")]),
                _vm._v(")"),
                _c("br"),
                _vm._v("-"),
                _c("strong", [
                  _vm._v("\n            2008. évi XLVII. törvény")
                ]),
                _vm._v(
                  "\n          – a fogyasztókkal szembeni tisztességtelen kereskedelmi gyakorlat\n          tilalmáról;"
                ),
                _c("br"),
                _vm._v("- "),
                _c("strong", [_vm._v("2008. évi XLVIII. törvény")]),
                _vm._v(
                  " – a\n          gazdasági reklámtevékenység alapvető feltételeiről és egyes\n          korlátairól (különösen a 6.§-a)"
                ),
                _c("br"),
                _vm._v("-\n          "),
                _c("strong", [_vm._v("2005. évi XC. törvény")]),
                _vm._v(" az elektronikus\n          információszabadságról"),
                _c("br"),
                _vm._v("-\n          "),
                _c("strong", [_vm._v("2003. évi C. törvény")]),
                _vm._v(" az elektronikus hírközlésről\n          ("),
                _c("strong", [_vm._v("kifejezetten a 155.§-a")]),
                _vm._v(")"),
                _c("br"),
                _vm._v("-\n          "),
                _c("strong", [_vm._v("16/2011. sz. vélemény")]),
                _vm._v(
                  " a viselkedésalapú online\n          reklám bevált gyakorlatára vonatkozó EASA/IAB-ajánlásról"
                ),
                _c("br"),
                _vm._v(
                  "- A\n          Nemzeti Adatvédelmi és Információszabadság Hatóság ajánlása az\n          előzetes tájékoztatás adatvédelmi követelményeiről "
                ),
                _c("br"),
                _c("br")
              ]
            )
          ])
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="pay">
    <top-nav-bar />
    <div v-if="!loading" class="container" style="margin-top: 100px;margin-bottom: 50px;">
      <div class="row">
        <div class="col-12 text-center">
          <p>Egyenlítse ki számláját azonnal, bankkártyája segítségével.</p>
          <div v-html="payment_form"></div>
        </div>
      </div>
    </div>
    <div v-else style="margin-top: 100px;margin-bottom: 50px;">Betöltés...</div>
  </div>
</template>

<script>
  import TopNavBar from '../general/top-nav-bar';

  export default {
    name: "pay",
    components: {
      TopNavBar
    },

    data()
    {
      return {
        loading: true,
        payment_form: null
      }
    },

    mounted()
    {
      this.loading = true;
      this.$api
              .pay(this.$route.params.uniqueId)
              .then((res1) =>
              {
                if (!res1.success)
                {
                  let error = '';
                  switch (res1.code)
                  {
                    case 2001:
                      error = 'Hiba: Foglalás nem létezik.';
                      break;
                  }

                  this.$bvModal.msgBoxOk(error, {okVariant: 'danger'});
                }
                else
                {
                  if (res1.payment_url)
                  {
                    window.location.href = res1.payment_url;
                    return;
                  }

                  this.$set(this, 'payment_form', res1.payment_form);
                  this.loading = false;
                }
              });
    }
  };
</script>

<style scoped>
</style>

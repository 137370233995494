<template>
    <div class="reservation">
        <div class="reservation-header">
            <top-nav-bar />
            <header>
                <div>
                    <h1>Időpontfoglalás</h1>
                    <h2>Válassz csomagot!</h2>
                    <p>Válaszd ki a neked tetsző helyszínt<br />a megfelelő időponttal!</p>
                </div>
            </header>
        </div>
        <main>
            <div class="container-md text-left" style="margin-top: 100px;">
                <h1
                        class="text-uppercase"
                        style="font-family: Montserrat, sans-serif;font-weight: 700;margin-bottom: 0px;font-size: 3rem;"
                >
                    Foglalj helyet
                </h1>
                <h2
                        class="text-uppercase"
                        style="font-family: Montserrat, sans-serif;font-weight: 500;margin-bottom: 35px;font-size: 2rem;"
                >
                    A teslapilóta-ülésben!
                </h2>
                <h3
                        class="text-uppercase"
                        style="font-family: Montserrat, sans-serif;font-weight: 400;margin-bottom: 5px;font-size: 1.4rem;"
                >
                    Kérlek, töltsd ki az űrlapot
                </h3>
                <p style="font-family: Montserrat, sans-serif;font-size: 1.1rem;">
                    FONTOS: A megrendelés a "Távollévők között kötött szerződésnek"<br />minősül
                    és részedről fizetési kötelezettséggel jár. További információkért<br />olvasd
                    el az&nbsp;<router-link
                        to="/aszf"
                        style="color: #c1132c;font-size: 1.1rem;"
                        target="_blank"
                ><strong>ászf</strong></router-link
                >-et!
                </p>
                <div v-if="!show_summary" class="row">
                    <div
                            class="col-xl-12 text-sm-center text-md-center text-lg-left text-xl-left"
                            style="margin-top: 30px;"
                    >
                        <h3
                                class="text-uppercase"
                                style="font-family: Montserrat, sans-serif;font-weight: 400;font-size: 1.4rem;margin-bottom: 0px;"
                        >
                            Ha már van ajándékkártyád, akkor kattints ide!<a
                                class="btn text-uppercase"
                                role="button"
                                href="#"
                                style="background-color: rgba(255,255,255,0);color: rgb(0,0,0);font-size: 1.5rem;font-family: Montserrat, sans-serif;margin-right: 0px;padding-right: 15px;padding-top: 0px;margin-top: 0px;"
                                @click.prevent="has_gift_card = !has_gift_card"
                        ><img
                                src="~assets/img/btn-reservation-red2.png"
                                style="padding-left: 0px;width: 45px;margin-left: 10px;"
                        /></a>
                        </h3>
                        <div v-if="has_gift_card" class="form-group" style="margin-top: 30px; margin-bottom: 70px;">
                            <input
                                    class="border rounded col-12"
                                    type="text"
                                    id="giftcard"
                                    style="height: 60px;margin-right: 5px;padding-left: 1.5rem;margin-left: 0px;width: 50%;"
                                    placeholder="Ajándékkártya száma"
                                    name="name"
                                    v-model="$v.gift_code.$model"
                            />
                            <button class="btn active shadow-none" @click="sendGiftCode" type="submit" :disabled="gift_code.length === 0||send">KÜLDÉS</button>
                            <div class="error" v-if="!$v.gift_code.required">Töltsd ki a ajándékkártya mezőt!</div>
                        </div>
                        <div v-if="!has_gift_card" style="margin-bottom: 70px;"></div>
                    </div>
                </div>
            </div>
        </main>
        <section ref="top">
            <div v-if="!show_summary" class="container-md" style="margin-top: 99px;margin-bottom: 100px;font-family: Montserrat, sans-serif;">
                <div class="row justify-content-center">
                    <template v-if="!has_gift_card">
                        <div
                                class="col-12 col-xl-6 pr-xl-5 feature-box"
                                style="margin-bottom: 55px;padding-right: 30px;"
                        >
                            <h4
                                    class="text-uppercase"
                                    style="font-size: 2rem;line-height: 35px;letter-spacing: 0px;font-weight: 400;margin-bottom: 50px;"
                            >
                                Melyik csomag?
                            </h4>
                            <select id="package" class="package" style="margin-left: 0px;" v-model="$v.data.package.$model">
                                <option value="none" selected>Válassz csomagot</option>
                                <option v-for="(p, index) in packages" :value="index">{{p.name}} - {{p.short_name}}</option>
                            </select>
                            <div class="error" v-if="!$v.data.package.minValue">Válassz csomagot!</div>
                            <h4
                                    class="text-uppercase"
                                    style="font-weight: 700;padding-top: 0px;margin-top: 40px;font-size: 1.5rem;"
                            >
                                Videóval<!-- Csomag extrák  -->
                            </h4>
                            <template v-for="(e, index) in extras">
                                <p style="font-weight: 500;font-size: 1.1rem;">
                                    {{e.name}} (+{{e.price}} Ft)
                                </p>
                                <section>
                                    <div class="container">
                                        <div class="row">
                                            <div class="col-md-12 col-xl-4" style="padding-left: 0px;">
                                                <div>
                                                    <p>
                                                        <input :value="true" type="radio" :id="'extra1_' + index" name="extra" v-model="extraClicks[index]" @change="addExtra(index)" />
                                                        <label :for="'extra1_' + index">Kérem</label>
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="col" style="padding-left: 0px;">
                                                <div>
                                                    <p>
                                                        <input :value="false" type="radio" :id="'extra2_' + index" name="extra" v-model="extraClicks[index]" @change="removeExtra(index)" />
                                                        <label :for="'extra2_' + index">Nem kérem</label>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </template>
                        </div>
                        <div
                                class="text-center text-sm-center text-md-center text-lg-center text-xl-center col-12 col-xl-6 pr-xl-5 feature-box"
                                style="margin-top: 75px;margin-bottom: 55px;padding-top: 0px;padding-right: 0px;padding-bottom: 0px;padding-left: 0px;"
                        >
                            <section v-if="data.package !== 'none'" class="pricing-table">
                                <div class="container">
                                    <div class="pricing-area text-center">
                                        <div class="row">
                                            <div class="col-sm-6 col-md-3 plan price red wow fadeInDown">
                                                <ul class="list-group">
                                                    <li class="list-group-item shadow heading" :class="{'best-seller': getPackage.best_seller}" style="width: 400px;margin-left: 30px;background-color: rgb(255,255,255);font-family: Montserrat, sans-serif;">
                                                        <h1 class="package-title" style="color: rgb(0,0,0);font-family: Montserrat, sans-serif;font-size: 2rem;font-weight: 500;"><strong>{{ getPackage.name }}</strong></h1>
                                                        <p class="package-text" style="font-size: 1.1rem;margin-bottom: 0px;color: rgb(0,0,0);">{{ getPackage.short_name }}</p><span class="price" style="color: rgb(0,0,0);font-size: 3rem;margin-bottom: 0px;">{{ formatPrice(getPackage.actual_price) }} Ft</span>
                                                        <p class="package-text" style="color: rgb(0,0,0);margin-top: 10px;" v-html="getPackage.description"></p>
                                                        <p v-if="getPackage.discount > 0" class="package-text" style="color: rgb(255,0,0);margin-top: 10px;">{{ getPackage.discount }}% kedvezménnyel!</p>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </template>
                    <div
                            class="col-12 col-xl-6 pr-xl-5 feature-box"
                            style="padding-right: 0px;"
                    >
                        <template v-if="!has_gift_card && !distributing">
                            <h4
                                    class="text-uppercase"
                                    style="font-size: 2rem;font-weight: 400;"
                            >
                                Kinek?<br />
                            </h4>
                            <section>
                                <div
                                        class="container"
                                        style="padding-right: 0px;padding-left: 0px;"
                                >
                                    <div class="row">
                                        <div class="col-md-12 col-xl-5">
                                            <div>
                                                <p>
                                                    <input
                                                            type="radio"
                                                            id="gift1"
                                                            name="gift"
                                                            :value="false"
                                                            v-model="data.is_gift"
                                                    />
                                                    <label for="gift1">Saját részre</label>
                                                </p>
                                            </div>
                                        </div>
                                        <div class="col">
                                            <div>
                                                <p>
                                                    <input
                                                            type="radio"
                                                            id="gift2"
                                                            name="gift"
                                                            :value="true"
                                                            v-model="data.is_gift"
                                                    />
                                                    <label for="gift2">Ajándékba</label>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group" style="margin-bottom: 15px;">
                                        <input
                                                v-if="data.is_gift"
                                                class="border rounded col-6"
                                                type="text"
                                                style="height: 60px;margin-right: 5px;padding-left: 1.5rem;margin-left: 0px;width: 47%;"
                                                placeholder="Vezetéknév"
                                                name="lastname"
                                                v-model="$v.data.user_last_name.$model"
                                        />
                                        <input
                                            v-if="data.is_gift"
                                            class="border rounded col-6"
                                            type="text"
                                            style="height: 60px;padding-left: 1.5rem;width: 47%;margin-bottom: 20px;"
                                            placeholder="Utónév"
                                            name="firstname"
                                            v-model="$v.data.user_first_name.$model"
                                        />
                                        <div class="error" v-if="data.is_gift && !$v.data.user_last_name.required">Töltsd ki a vezetéknév mezőt!</div>
                                        <div class="error" v-if="data.is_gift && !$v.data.user_first_name.required">Töltsd ki a utónév mezőt!</div>
                                        <div v-if="!data.is_gift">
                                            <p>
                                                <input
                                                        type="checkbox"
                                                        id="lateAppointmentAndLocation"
                                                        name="lateAppointmentAndLocation"
                                                        :value="true"
                                                        v-model="data.is_late_appointment_and_location"
                                                />
                                                <label for="lateAppointmentAndLocation"
                                                >Későbbi időpont és helyszín egyeztetéssel kérem!</label
                                                >
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </template>
                        <template v-if="!has_gift_card || (has_gift_card && data.package !== 'none')">
                            <template v-if="!lateOrGift">
                                <h4
                                        class="text-uppercase"
                                        style="font-size: 2rem;font-weight: 400;margin-bottom: 25px;margin-top: 50px;"
                                >
                                    Melyik nap?
                                </h4>
                                <div class="row">
                                    <div class="col" style="margin-left: 0px">
                                        <select id="date" class="date" style="margin-left: 0px;" v-model="$v.data.reservation_date.$model">
                                            <option value="none" selected>Válassz dátumot</option>
                                            <option v-for="(date, index) in dates" :value="date">{{date}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="error" v-if="!$v.data.reservation_date.minValue">Válassz dátumot!</div>
                                <h4
                                        class="text-uppercase"
                                        style="font-size: 2rem;font-weight: 400;margin-bottom: 25px;margin-top: 50px;"
                                >
                                    Hova?
                                </h4>
                                <div class="row">
                                    <div class="col" style="margin-left: 0px">
                                        <select
                                                id="chargerLocation"
                                                class="chargerLocation"
                                                style="margin-left: 0px;"
                                                v-model="$v.data.charger_location.$model"
                                        >
                                            <option value="none" selected>Válassz helyszínt</option>
                                            <option v-for="(cl, index) in charger_locations" :value="index">{{cl.location.name}}, {{cl.address}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="error" v-if="!$v.data.charger_location.minValue">Válassz helyszínt!</div>
                                <h4
                                        class="text-uppercase"
                                        style="font-size: 2rem;font-weight: 400;margin-bottom: 25px;margin-top: 50px;"
                                >
                                    Mikor?
                                </h4>
                                <div class="row">
                                    <div class="col" style="margin-left: 0px">
                                        <select id="time" class="time" style="margin-left: 0px" v-model="$v.data.reservation_duration.$model" :disabled="loading_slots">
                                            <option value="none" selected>--:--</option>
                                            <option v-for="(time, index) in slots" :value="time">{{time}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="error" v-if="!$v.data.reservation_duration.minValue">Válassz időpontot!</div>
                            </template>
                            <template v-if="!has_gift_card">
                                <h4
                                        class="text-uppercase"
                                        style="font-size: 2rem;font-weight: 400;margin-bottom: 25px;margin-top: 50px;"
                                >
                                    ki?
                                </h4>
                                <div
                                        class="form-group"
                                        style="margin-bottom: 0px;margin-top: 30px;"
                                >
                                    <input
                                            class="border rounded col-6"
                                            type="text"
                                            style="height: 60px;margin-right: 5px;padding-left: 1.5rem;margin-left: 0px;width: 47%;"
                                            placeholder="Vezetéknév"
                                            name="lastname"
                                            v-model="$v.data.payer_last_name.$model"
                                    /><input
                                        class="border rounded col-6"
                                        type="text"
                                        style="height: 60px;padding-left: 1.5rem;width: 47%;margin-bottom: 0px;"
                                        placeholder="Utónév"
                                        name="firstname"
                                        v-model="$v.data.payer_first_name.$model"
                                    />
                                </div>
                                <div class="error" v-if="!$v.data.payer_last_name.required">Töltsd ki a vezetéknév mezőt!</div>
                                <div class="error" v-if="!$v.data.payer_first_name.required">Töltsd ki az utónév mezőt!</div>
                                <div
                                        class="form-group text-nowrap"
                                        style="margin-bottom: 0px;margin-top: 15px;"
                                >
                                    <div class="row">
                                        <div
                                                class="col-xl-3 col-5 col-xs-6 col-sm-3 col-md-3 col-lg-2"
                                                style="padding-right: 0px;width: 20%;"
                                        >
                                            <select
                                                    id="package-1"
                                                    class="package"
                                                    style="margin-left: 0px;" v-model="phone_start_num">
                                                <option :value="0" selected>+36</option>
                                            </select>
                                        </div>
                                        <div
                                                class="col-xl-9 col-7 col-xs-6 col-sm-9 col-md-9 col-lg-10"
                                                style="padding-left: 5px;width: 100%;"
                                        >
                                            <input
                                                    class="border rounded"
                                                    type="text"
                                                    style="height: 60px;padding-left: 1.5rem;width: 80%;"
                                                    placeholder="Telefon szám"
                                                    name="phone"
                                                    v-model="$v.data.payer_phone.$model"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="error" v-if="!$v.data.payer_phone.required">Töltsd ki a telefon szám mezőt!</div>
                                <div
                                        class="form-group"
                                        style="margin-bottom: 0px;margin-top: 15px;"
                                >
                                    <input
                                            class="border rounded col-12"
                                            type="text"
                                            style="height: 60px;margin-right: 0px;padding-left: 1.5rem;margin-left: 0px;width: 85%;min-width: 85%;"
                                            placeholder="E-mail cím"
                                            name="mail"
                                            v-model="$v.data.payer_email.$model"
                                    />
                                </div>
                                <div class="error" v-if="!$v.data.payer_email.required">Töltsd ki az e–mail cím mezőt!</div>
                                <div class="error" v-if="!$v.data.payer_email.email">Hibás e–mail cím!</div>
                            </template>
                        </template>
                    </div>

                    <div
                            class="col-12 col-xl-6 pl-xl-5 feature-box"
                            style="padding-left: 15px;margin-left: 0px;margin-right: 0px;padding-right: 0px;"
                    >
                        <template v-if="!has_gift_card">
                            <h4
                                    class="text-uppercase"
                                    style="font-size: 2rem;font-weight: 400;margin-bottom: 15px;margin-top: 0px;"
                            >
                                számlázás és fizetés?<br />
                            </h4>
                            <p style="font-size: 1.1rem;">
                                Ha cégként kérsz számlát, akkor autóbérlésről küldünk számlát,
                                amit a vállalkozásod el tud számolni.
                            </p>
                            <div class="row">
                                <div class="col-md-12 col-xl-5">
                                    <div>
                                        <p>
                                            <input :value="false" type="radio" id="szamla1" name="group-szamla" v-model="data.bill_is_firm" />
                                            <label for="szamla1">Magánszemély</label>
                                        </p>
                                    </div>
                                </div>
                                <div class="col">
                                    <div>
                                        <p>
                                            <input :value="true" type="radio" id="szamla2" name="group-szamla" v-model="data.bill_is_firm" />
                                            <label for="szamla2">Cég</label>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group" style="margin-bottom: 15px;">
                                <input
                                        class="border rounded col-12"
                                        type="text"
                                        style="height: 60px;margin-right: 5px;padding-left: 1.5rem;margin-left: 0px;width: 100%;"
                                        placeholder="Név/Cégnév"
                                        name="name"
                                        v-model="$v.data.bill_name.$model"
                                />
                            </div>
                            <div class="error" v-if="!$v.data.bill_name.required" style="margin-bottom: 15px;">Töltsd ki a név mezőt!</div>
                            <div v-if="data.bill_is_firm" class="form-group text-nowrap" style="margin-bottom: 15px;">
                                <input
                                        class="border rounded col-12"
                                        type="text"
                                        style="height: 60px;margin-right: 1%;padding-left: 1.5rem;margin-left: 0px;width: 78%;"
                                        placeholder="Adószám"
                                        name="adoszam"
                                        v-model="$v.vat1.$model"
                                /><input
                                    class="border rounded col-12"
                                    type="text"
                                    style="height: 60px;margin-right: 1%;padding-left: 1.5rem;margin-left: 0px;width: 10%;"
                                    v-model="$v.vat2.$model"
                            />
                                <input
                                        class="border rounded col-12"
                                        type="text"
                                        style="height: 60px;margin-right: 0px;margin-left: 0px;width: 10%;"
                                        v-model="$v.vat3.$model"
                                />
                            </div>
                            <div class="error" v-if="data.bill_is_firm && (!$v.vat1.required || !$v.vat2.required || !$v.vat3.required)" style="margin-bottom: 15px;">Töltsd ki az adószám mezőt!</div>
                            <div class="form-group text-nowrap" style="margin-bottom: 15px;">
                                <input
                                        class="border rounded col-12"
                                        type="text"
                                        style="height: 60px;margin-right: 1%;padding-left: 1.5rem;margin-left: 0px;width: 44%;"
                                        placeholder="Irányítószám"
                                        name="zip"
                                        v-model="$v.data.bill_post_code.$model"
                                /><input
                                    class="border rounded col-12"
                                    type="text"
                                    style="height: 60px;margin-right: 0px;padding-left: 1.5rem;margin-left: 0px;width: 55%;"
                                    placeholder="Település"
                                    name="city"
                                    v-model="$v.data.bill_city.$model"
                            />
                            </div>
                            <div class="error" v-if="!$v.data.bill_post_code.required">Töltsd ki az irányítószám mezőt!</div>
                            <div class="error" v-if="!$v.data.bill_city.required">Töltsd ki a település mezőt!</div>
                            <div v-if="!$v.data.bill_post_code.required || !$v.data.bill_city.required" style="margin-bottom: 15px;"></div>

                            <div class="form-group" style="margin-bottom: 15px;">
                                <input
                                        class="border rounded col-12"
                                        type="text"
                                        style="height: 60px;margin-right: 5px;padding-left: 1.5rem;margin-left: 0px;width: 100%;"
                                        placeholder="Utca, házszám"
                                        name="street"
                                        v-model="$v.data.bill_address.$model"
                                />
                            </div>
                            <div class="error" v-if="!$v.data.bill_address.required">Töltsd ki az utca, házszám mezőt!</div>
                            <div class="row" style="margin-top: 60px;">
                                <div class="col-md-12 col-xl-12" style="padding-left: 15px;">
                                    <div>
                                        <p>
                                            <input :value="true" type="radio" id="fizetes1" name="group-fizetes" v-model="data.payer_is_pay_online" />
                                            <label for="fizetes1">Bankkártyás fizetés</label>
                                        </p>
                                    </div>
                                </div>
                                <div class="col-xl-12" style="padding-left: 15px;">
                                    <div>
                                        <p>
                                            <input
                                                    :value="false"
                                                    type="radio"
                                                    id="fizetes2"
                                                    name="group-fizetes"
                                                    v-model="data.payer_is_pay_online"
                                            />
                                            <label for="fizetes2">Banki átutalás</label>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </template>
                        <div v-if="!has_gift_card || (has_gift_card && data.package !== 'none')" class="row" style="margin-top: 60px;">
                            <div
                                    class="col-md-12 col-xl-12"
                                    style="padding-left: 15px;font-size: 1rem;"
                            >
                                <div>
                                    <p>
                                        <input
                                                type="checkbox"
                                                id="adatvedelem"
                                                name="group-elfogad"
                                                v-model="$v.adatvedelem.$model"
                                        />
                                        <label for="adatvedelem"
                                        >Elolvastam és elfogadom az
                                            <router-link
                                                    to="/adatvedelem"
                                                    style="font-size: 1rem; color:#c1132c"
                                                    target="_blank"
                                            >
                                                Adatvédelmi irányelveket.</router-link
                                            ></label
                                        >
                                    </p>
                                </div>
                            </div>
                            <div
                                    class="col-xl-12"
                                    style="padding-left: 15px;font-size: 1rem;"
                            >
                                <div>
                                    <p>
                                        <input type="checkbox" id="aszf" name="group-elfogad" v-model="$v.aszf.$model" />
                                        <label for="aszf"
                                        >Elolvastam és elfogadom az
                                            <router-link
                                                    to="/aszf"
                                                    style="font-size: 1rem; color: #c1132c"
                                                    target="_blank"
                                            >
                                                Általános szerződési feltételeket.</router-link
                                            ></label
                                        >
                                    </p>
                                </div>
                                <div class="error" v-if="!$v.adatvedelem.sameAs">Adatvédelmi irányelvek elfogadása!</div>
                                <div class="error" v-if="!$v.aszf.sameAs">Általános szerződési feltételek elfogadása!</div>
                            </div>
							<div class="col" style="margin-top: 50px;">
								<button v-if="!has_gift_card" class="btn active shadow-none" type="submit" @click="summary" :disabled="$v.$invalid||send">FIZETEK</button>
                                <button v-else class="btn active shadow-none" type="submit" @click="completeGift" :disabled="$v.$invalid||send">LEFOGLALOM</button>
							</div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="container-md" style="margin-top: 99px;margin-bottom: 100px;font-family: Montserrat, sans-serif;">
                <div class="row">
                    <div class="col">
                        <div class="col-12"><h4><strong>Választott csomag</strong></h4></div>
                        <div class="row">
                            <div class="col-12" id="confirm-package">
                                <p id="confirm-package--name" class="font-weight-bold">{{ getPackage.name }}</p>
                                <p id="confirm-package--shortTitle" class="font-size-14">{{ getPackage.short_name }}</p>
                                <p id="confirm-package--features" class="font-size-14" v-html="getPackage.description"></p>
                                <p id="confirm-package--addons" class="font-size-14"><template v-for="extra in data.extras">{{ extra.name }} (+{{ formatPrice(extra.price) }} Ft), </template></p>
                            </div>
                        </div>
                        <hr/>
                        <div class="row">
                            <div class="col-12"><strong>Ajándékba:</strong></div>
                            <div class="col-12" id="confirm-isGift">{{ data.is_gift ? 'Igen' : 'Nem' }}</div>
                        </div>
                        <div class="row" id="confirm-discussLater-row">
                            <div class="col-12"><strong>Későbbi időpont és helyszín egyezetéssel!</strong></div>
                            <div class="col-12" id="confirm-discussLater">{{ data.is_late_appointment_and_location ? 'Igen' : 'Nem' }}</div>
                        </div>
                        <div class="row" id="confirm-location-row">
                            <div class="col-12"><strong>Helyszín:</strong></div>
                            <div class="col-12" id="confirm-location">
                                <template v-if="data.charger_location !== 'none'">{{ charger_locations[data.charger_location].location.name }}, {{ charger_locations[data.charger_location].address }}</template>
                                <template v-else>Nincs megnevezve</template>
                            </div>
                        </div>
                        <div class="row" id="confirm-dateReservation-row">
                            <div class="col-12"><strong>Időpont:</strong></div>
                            <div class="col-12" id="confirm-dateReservation">
                                <template v-if="data.reservation_date !== 'none' && data.reservation_duration !== 'none'">{{ data.reservation_date }} {{ data.reservation_duration }}</template>
                                <template v-else>Nincs megnevezve</template>
                            </div>
                        </div>
                        <hr/>
                        <div class="row">
                            <div class="col-12"><h4><strong>Megrendelő adatai</strong></h4></div>
                        </div>
                        <div class="row">
                            <div class="col-12"><strong>Név:</strong></div>
                            <div class="col-12" id="confirm-name">{{ data.payer_last_name }} {{ data.payer_first_name }}</div>
                        </div>
                        <div class="row">
                            <div class="col-12"><strong>Telefonszám:</strong></div>
                            <div class="col-12" id="confirm-phone">+36 {{ data.payer_phone }}</div>
                        </div>
                        <div class="row">
                            <div class="col-12"><strong>E-mail cím:</strong></div>
                            <div class="col-12" id="confirm-email">{{ data.payer_email }}</div>
                        </div>
                    </div>
                    <div class="col" style="margin-top: 73px;">
                        <div class="container">
                            <div class="row">
                                <div class="col-12"><h4><strong>Számlázás és fizetés</strong></h4></div>
                            </div>
                            <div class="row">
                                <div class="col-12"><strong>Név, cégnév:</strong></div>
                                <div class="col-12" id="confirm-bill_name">{{ data.bill_name }}</div>
                            </div>
                            <div v-if="data.bill_vat_number.length > 0" class="row">
                                <div class="col-12"><strong>Adószám:</strong></div>
                                <div class="col-12" id="confirm-bill_vat_number">{{ data.bill_vat_number }}</div>
                            </div>
                            <div class="row">
                                <div class="col-12"><strong>Cím:</strong></div>
                                <div class="col-12" id="confirm-address">{{ data.bill_post_code }} {{ data.bill_city }} {{ data.bill_address }}</div>
                            </div>
                            <hr/>
                            <div class="row">
                                <div class="col-12"><h4><strong>Fizetendő összeg:</strong></h4></div>
                            </div>
                            <div class="row">
                                <div class="col-12"><span id="confirm-total"></span> (<span id="confirm-paymentMethod">{{ formatPrice(calPrice) }}</span> Ft)</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" style="margin-top: 30px;">
                    <div class="col-6">
                        <button class="btn active shadow-none" id="cancel-order" @click="show_summary = false">Mégsem</button>
                    </div>
                    <div class="col-6 text-right">
                        <button class="btn active shadow-none" id="accept-order" @click="submit" type="submit" :disabled="send">megrendelés beküldése</button>
                    </div>
                </div>
            </div>
        </section>
        <div style="padding-top: 90px;">
            <div class="container">
                <div class="row">
                    <div class="col-md-6" style="font-family: Montserrat, sans-serif;">
                        <h3
                                class="text-uppercase"
                                style="padding-bottom: 20px;font-family: Montserrat, sans-serif;font-size: 1.5rem;"
                        >
                            <strong>A fizetést követően</strong>
                        </h3>
                        <ul style="font-size: 15px;font-weight: 500;">
                            <li>számlát küldünk a megadott email címre</li>
                            <li>
                                telefonon, vagy emailben egyeztetjük az időpontot és helyszínt
                            </li>
                            <li>testben és lélekben felkészülsz a TeslaPilóta élményre</li>
                        </ul>
                    </div>
                    <div
                            class="col-md-6"
                            style="border-left: 1px solid black;margin-bottom: 60px;padding-right: 0px;"
                    >
                        <h3
                                class="text-uppercase"
                                style="padding-bottom: 20px;font-family: Montserrat, sans-serif;font-size: 1.5rem;"
                        >
                            <strong>Adataid biztonsága</strong>
                        </h3>
                        <p
                                style="font-family: Montserrat, sans-serif;font-size: 15px;font-weight: 500;"
                        >
                            A megadott adatokat a hatályos GDPR törvénynek megfelelően,
                            biztonságos szerveren, titkosítva tároljuk, azt harmadik félnek
                            nem adjuk ki. A szolgáltatás igénybevételét követően kérheted
                            adataid törlését is emailben. Adatvédelmi irányelveinket&nbsp;
                            <router-link to="/adatvedelem" style="color: #c1132c;" target="_blank"
                            ><strong>itt</strong></router-link
                            >
                            és a láblécben találod.
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <h3
                                class="text-uppercase"
                                style="font-family: Montserrat, sans-serif;font-size: 1.5rem;padding-bottom: 20px;"
                        >
                            <strong>Pénzvisszafizetési garancia</strong>
                        </h3>
                        <p
                                style="font-family: Montserrat, sans-serif;font-size: 15px;font-weight: 500;"
                        >
                            Amennyiben úgy érzed, szolgáltatásunk vagy az élmény nem hozta az
                            elvárható színvonalat, küldj egy emailt nekünk és a
                            pénzvisszafizetési garanciánk alapján a teljes összeget
                            visszautaljuk 5 munkanapon belül.
                        </p>
                    </div>
                    <div
                            class="col-md-6"
                            style="border-left: 1px solid black;margin-bottom: 60px;padding-right: 0px;"
                    >
                        <h3
                                class="text-uppercase"
                                style="font-family: Montserrat, sans-serif;font-size: 1.5rem;padding-bottom: 20px;"
                        >
                            <strong>A bankkártyás fizetés</strong><br /><strong
                        >a legnagyobb biztonságban</strong
                        >
                        </h3>
                        <p
                                style="font-family: Montserrat, sans-serif;font-size: 0.9rem;font-weight: 500;"
                        >
                            A bankkártyás fizetéshez az OTP Simplepay rendszerét használjuk,
                            ez<br />neked is garanciát jelent a biztonságos fizetéshez. Itt
                            olvashatsz a<br />rendszerükről:&nbsp;<a
                                href="https://simplepay.hu/vasarloknak"
                                style="color: #c1132c;font-size: 0.9rem;"
                                target="_blank"
                        ><strong>https://simplepay.hu/vasarloknak</strong></a
                        >&nbsp;és az ügyfélszol-<br />gálatukat itt éred el
                            <a href="tel:0613666611" style="color: #c1132c;font-size: 0.9rem;"
                            ><strong>Tel: 06 1 3666 611,</strong></a
                            >&nbsp;
                            <a
                                    href="mailto:ugyfelszolgalat@simple.hu"
                                    style="color: #c1132c;font-size: 0.9rem;"
                            ><strong>ugyfelszolgalat@simple.hu</strong></a
                            >
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6" style="border-right: 1px solid black">
                        <h3
                                class="text-uppercase"
                                style="font-family: Montserrat, sans-serif;font-size: 1.5rem;padding-bottom: 20px;"
                        >
                            <strong>eltérő helyszín</strong>
                        </h3>
                        <p style="font-family: Montserrat, sans-serif;font-weight: 500;">
                            Ez csak rendezvény (pl. csapatépítő) esetén jöhet szóba, de akkor
                            is töltő közelébe kell szervezni az ilyen alkalmakat.
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div
                class="row justify-content-sm-center justify-content-md-center col-12"
                style="padding-left: 0px;padding-right: 15px;margin-top: 80px;margin-right: 0px;margin-left: 0px;"
        >
            <div
                    class="col-md-6 col-xl-7 text-sm-center text-md-center align-self-center"
                    style="margin-left: 0px;padding-right: 0px;padding-left: 0px;"
            >
                <img
                        class="d-inline-flex"
                        src="~assets/img/layer21.png"
                        style="margin-left: 0px;width: 100%;"
                />
            </div>
            <div class="col-xl-1" style="padding-right: 0px;padding-left: 0px;"></div>
            <div
                    class="col-xl-4 text-sm-center text-md-center text-lg-center text-xl-left align-self-center"
                    style="padding-left: 0px;padding-right: 0px;margin-left: 0px;"
            >
                <h1
                        class="text-uppercase text-sm-center text-md-center text-lg-center text-xl-left justify-content-xl-center align-items-xl-end"
                        id="team_h1"
                        style="font-size: 3.3rem;font-family: Montserrat, sans-serif;font-weight: 800;margin-bottom: 0px;margin-right: 0px;"
                >
                    Csapatépítő<br />
                </h1>
                <h3
                        class="text-uppercase text-sm-center text-md-center text-lg-center text-xl-left"
                        id="team_h3"
                        style="font-size: 1.75rem;font-family: Montserrat, sans-serif;font-weight: 700;margin-bottom: 80px;"
                >
                    programot szervezel?
                </h3>
                <h5
                        class="text-sm-center text-md-center text-lg-center text-xl-left"
                        id="team_h5"
                        style="font-size: 1.2rem;font-family: Montserrat, sans-serif;font-weight: 700;margin-bottom: 30px;"
                >
                    A TeslaPilóta programja garantáltan<br />szórakoztató élmény lesz a
                    csapat tagjainak.
                </h5>
                <p>
                </p>
                <a
                        class="btn text-uppercase"
                        role="button"
                        :href="goToH('company')"
                        style="background-color: rgba(255,255,255,0);color: rgb(0,0,0);font-size: 1.5rem;font-family: Montserrat, sans-serif;margin-right: 0px;padding-right: 15px;padding-top: 35px;margin-top: 60px;"
                ><strong>tovább</strong
                ><img src="~assets/img/btn-more.png" style="padding-left: 35px;"
                /></a>
            </div>
        </div>
        <div style="padding-top: 90px;">
            <div class="container" style="margin-top: 60px;">
                <div class="row">
                    <div class="col">
                        <h1></h1>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <h1 class="text-uppercase" style="margin-bottom: 0px;">
                            <strong>Üzenetküldés</strong>
                        </h1>
                        <h3 class="text-uppercase">A Teslapilótának</h3>
                        <p>
                            Ha kérésed vagy kérdésed lenne, akkor küldj üzenetet a Teslapilóta
                            irányítóközpontba! Add meg adataidat, hogy felvehessük veled a
                            kapcsolatot.
                        </p>
                    </div>
                    <div class="col-md-6">
                        <contact-form />
                    </div>
                </div>
            </div>
        </div>
        <div class="text-right" style="margin-bottom: 90px;">
            <img
                    class="center"
                    src="~assets/img/img-header-logo-small-gray.png"
                    style="padding-top: 8rem;"
            />
        </div>
        <div class="d-none d-xl-block">
            <div class="text-uppercase">
                <div class="container">
                    <div class="row">
                        <div class="col-md-4 col-xl-10 offset-xl-2">
                            <h1 class="text-uppercase text-left"><strong>KÖVESS</strong></h1>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div class="container">
                    <div class="row">
                        <div
                                class="col-md-4 col-xl-2 offset-xl-5 text-right align-self-center"
                        >
                            <a href="http://www.facebook.com/teslapilota"
                            ><img
                                    style="padding-right: 1.875rem"
                                    src="~assets/img/btn-facebook.png"/></a
                            ><a href="http://www.instagram.com/teslapilota"
                        ><img src="~assets/img/btn-instagram.png"
                        /></a>
                        </div>
                        <div class="col-md-4 col-xl-4 offset-xl-0">
                            <h1 class="text-uppercase text-right"><strong>MINKET</strong></h1>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="mobile d-block d-xl-none">
            Kövess minket
            <div>
                <a href="https://www.facebook.com/TeslaPilota/" target="_blank">
                    <img src="~assets/img/btn-facebook.png" />
                </a>
                <a href="https://www.instagram.com/teslapilota/" target="_blank">
                    <img src="~assets/img/btn-instagram.png" />
                </a>
            </div>
        </div>
        <!-- Inenn indul a form -->
    </div>
</template>
<script>
    import TopNavBar from '../general/top-nav-bar';
    import ContactForm from '../general/contact-form';
    import { required, email, sameAs } from 'vuelidate/lib/validators';
    import router from "assets/js/plugins/router";

    const defaultData =
    {
        id: null,
        package: 'none',
        charger_location: 'none',
        extras: [],
        reservation_date: 'none',
        reservation_duration: 'none',
        user_first_name: '',
        user_last_name: '',
        is_gift: false,
        is_late_appointment_and_location: false,
        payer_first_name: '',
        payer_last_name: '',
        payer_phone: '',
        payer_email: '',
        payer_is_pay_online: true,
        bill_is_firm: false,
        bill_name: '',
        bill_vat_number: '',
        bill_post_code: '',
        bill_city: '',
        bill_address: '',
        distributorUniqueId: null
    };

    export default
    {
        name: "reservation",

        components:
        {
            TopNavBar,
            ContactForm
        },

        data()
        {
            return {
                data: {...defaultData},
                packages: [],
                extras: [],
                charger_locations: [],
                dates: [],
                slots: [],
                aszf: false,
                adatvedelem: false,
                has_gift_card: false,
                phone_start_num: 0,
                vat1: '',
                vat2: '',
                vat3: '',
                show_summary: false,
                loading_charger_locations: false,
                loading_slots: false,
                send: false,
                gift_code: '',
                discount: 0,
                distributing: false,
                extraClicks: []
            }
        },

        mounted()
        {
            Promise.all([
                this.$api.get_extras(),
                this.$api.get_packages(typeof this.$route.query.d !== 'undefined' ? this.$route.query.d : null),
                this.$api.get_dates()
            ]).then(([res1, res2, res3]) =>
            {
                if (res1.extras.length > 0) this.$set(this, 'extras', res1.extras);
                if (res2.packages.length > 0) this.$set(this, 'packages', res2.packages);
                if (res3.dates.length > 0) this.$set(this, 'dates', res3.dates);
                this.$set(this.data, 'package', typeof this.$route.query.p !== 'undefined' ? this.$route.query.p : 'none');

                if (typeof this.$route.query.d !== 'undefined')
                {
                    const found = this.dates.find((d) => {
                        return d.split(" ")[0] === this.$route.query.d;
                    });

                    this.$set(this.data, 'reservation_date', found);
                }
            });

            if (typeof this.$route.query.g !== 'undefined' && this.$route.query.g)
            {
                this.$set(this, 'has_gift_card', true);
				this.$set(this.data, 'package', 'none');
            }
            if (typeof this.$route.query.d !== 'undefined')
            {
                this.$set(this.data, 'distributorUniqueId', this.$route.query.d);
                this.$set(this, 'distributing', true);
            }
        },

        watch:
        {
            'data.package': function(val)
            {
                this.resetDateTime(val);
            },
            'data.reservation_date': function(val)
            {
                this.resetChargerLocation();

                if (val === 'none')
                {
                    this.$set(this, 'charger_locations', []);
                }
                else
                {
                    this.loadChargerLocations();
                }

                this.resetDateTime(val);
            },
            'data.charger_location': function(val)
            {
                this.resetDateTime(val);
            },
			'has_gift_card': function(val) {
				this.$set(this.data, 'package', 'none');
			}
        },

        computed:
        {
            lateOrGift: function()
            {
                return this.data.is_late_appointment_and_location || this.data.is_gift;
            },

            getPackage: function()
            {
                if (this.data.package === 'none') return null;
                return this.packages[this.data.package];
            },

            calPrice: function()
            {
                if (this.data.package === 'none') return null;
                let price = parseInt(this.getPackage.actual_price);

                if (this.data.extras.length > 0)
                {
                    this.data.extras.forEach(e =>
                    {
                        price += parseInt(e.price);
                    });
                }

                return price;
            }
        },

        methods:
        {
            resetChargerLocation: function()
            {
                this.$set(this.data, 'charger_location', 'none');
            },
            resetDateTime: function(val)
            {
                this.$set(this.data, 'reservation_duration', 'none');

                if (val === 'none')
                {
                    this.$set(this, 'slots', []);
                }
                else
                {
                    this.loadSlots();
                }
            },
            loadChargerLocations: function()
            {
                if (this.data.reservation_date === 'none') return;
                this.loading_charger_locations = true;
                this.$api
                    .get_charger_locations(this.data.reservation_date, this.distributing)
                    .then((res1) =>
                    {
                        this.$set(this, 'charger_locations', res1.charger_locations);
                        if (typeof this.$route.query.cl !== 'undefined')
                        {
                            const index = this.charger_locations.findIndex((cl) => {
                                return cl.id === parseInt(this.$route.query.cl);
                            });

                            this.$set(this.data, 'charger_location', index);
                        }
                        this.loading_charger_locations = false;
                    }).catch(() =>
                    {
                        this.loading_charger_locations = false;
                    });
            },
            loadSlots: function()
            {
                if (this.data.package === 'none' || this.data.charger_location === 'none' || this.data.reservation_date === 'none') return;
                this.loading_slots = true;
                this.$api
                    .get_slots(this.packages[this.data.package].id, this.charger_locations[this.data.charger_location].id, this.data.reservation_date)
                    .then((res1) =>
                    {
                        this.$set(this, 'slots', res1.slots);
                        this.loading_slots = false;
                    }).catch(() =>
                    {
                        this.loading_slots = false;
                    });
            },

            summary: function()
            {
                this.show_summary = true;
                this.scrollMeTo('top');
            },

            submit: function()
            {
                let newData = Object.assign({}, this.data);
                newData.package = this.packages[this.data.package];
                newData.charger_location = this.data.charger_location !== 'none' ? this.charger_locations[this.data.charger_location] : null;
                newData.reservation_date = this.data.reservation_date !== 'none' ? this.data.reservation_date.split(' ')[0] : null;
                if (this.data.bill_is_firm) newData.bill_vat_number = this.vat1 + '-' + this.vat2 + '-' + this.vat3;

                this.send = true;
                this.$api
                    .process_reservation(newData)
                    .then((res1) =>
                    {
                        if (!res1.success)
                        {
                            let error = '';
                            switch (res1.code)
                            {
                                case 1001:
                                    error = 'Hiba történt, próbálkozzon újra később!';
                                    break;
                                case 2001:
                                    error = 'A választott időpontot lefoglalták, kérem válasszon másikat!';
                                    break;
                            }

                            this.$bvModal.msgBoxOk(error, {okVariant: 'danger'});
                        }
                        else
                        {
                            if (res1.transaction_id) gtag('event', 'conversion', { 'send_to': 'AW-710456008/XGD6COT7g60BEMjl4tIC', 'value': this.calPrice, 'currency': 'HUF', 'transaction_id': res1.transaction_id }); 

                            let successText = 'Foglalását sikeresen rögzítettük! ';
                            if (res1.payment_url)
                            {
                                successText += 'Ne zárja be a böngészőt, pillanatokon belül átirányítjuk az online fizetési felületre!';
                                setTimeout(() => window.location.href = res1.payment_url, 5000);
                            }
                            else
                            {
                                successText += 'A fizetéssel kapcsolatos információkat elküldtük az ön e-mail címére!';
                                setTimeout(() => window.location.reload(), 5000);
                            }

                            this.$bvModal
                                .msgBoxOk(successText)
                                .then(() =>
                                {
                                    if (res1.payment_url)
                                    {
                                        window.location.href = res1.payment_url;
                                    }
                                    else
                                    {
                                        window.location.reload();
                                    }
                                });
                        }
                        this.send = false;
                    }).catch(() =>
                    {
                        this.send = false;
                    });
            },

            sendGiftCode: function()
            {
                this.send = true;
                this.$api
                    .send_gift_code(this.gift_code)
                    .then((res1) =>
                    {
                        if (!res1.success)
                        {
                            this.$bvModal.msgBoxOk('Hibás ajándékkártyá azonosító!', {okVariant: 'danger'});
                        }
                        else
                        {
                            this.$set(this.data, 'id', res1.reservation);
                            let index = this.packages.findIndex((e) => e.id === res1.package);
                            if (index !== -1) this.$set(this.data, 'package', index);
                        }
                        this.send = false;
                    }).catch(() =>
                    {
                        this.send = false;
                    });
            },

            completeGift: function()
            {
                let newData = Object.assign({}, this.data);
                newData.package = this.packages[this.data.package];
                newData.charger_location = this.charger_locations[this.data.charger_location];
                newData.reservation_date = this.data.reservation_date.split(' ')[0];
                this.send = true;
                this.$api
                    .complete_gift(newData)
                    .then((res1) =>
                    {
                        if (!res1.success)
                        {
                            let error = '';
                            switch (res1.code)
                            {
                                case 1001:
                                    error = 'Hiba történt, próbálkozzon újra később!';
                                    break;
                                case 2001:
                                    error = 'A választott időpontot lefoglalták, kérem válasszon másikat!';
                                    break;
                                case 2002:
                                    error = 'Nem található a foglalás, kérem ellenőrizze az foglalási azonosítót!';
                                    break;
                                case 2003:
                                    error = 'Az ajándékkártyához már lett időpont foglalva!';
                                    break;
                            }

                            this.$bvModal.msgBoxOk(error, {okVariant: 'danger'});
                        }
                        else
                        {
                            let successText = 'Sikeresen rögzítettük a választott időpontot és helyszínt!';
                            setTimeout(() => window.location.reload(), 5000);

                            this.$bvModal
                                .msgBoxOk(successText)
                                .then(() =>
                                {
                                    window.location.reload();
                                });
                        }
                        this.send = false;
                    }).catch(() =>
                    {
                        this.send = false;
                    });
            },

            addExtra(index)
            {
                const idx = this.data.extras.findIndex(e => e.id === this.extras[index].id);
                if (idx !== -1) return;
                this.data.extras.push(this.extras[index]);
            },

            removeExtra(index)
            {
                if (this.data.extras.length === 0) return;
                const idx = this.data.extras.findIndex(e => e.id === this.extras[index].id);
                if (idx === -1) return;
                this.data.extras.splice(idx, 1);
            },

            scrollMeTo(refName)
            {
                let element = this.$refs[refName];
                let top = element.offsetTop;
                window.scrollTo(0, top - 250);
            },

            formatPrice(value)
            {
                return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
            },

            goToH(name, query = {})
            {
                return router.resolve({name: name, query: query}).href;
            },

            goToC(name, query = {})
            {
                document.location = router.resolve({name: name, query: query}).href;
            }
        },

        validations()
        {
            let global = {};
            let data = {};

            if (this.data.is_gift)
            {
                data.user_first_name = { required };
                data.user_last_name = { required };
            }

            if (!this.lateOrGift)
            {
                data.charger_location = { minValue: function() { return this.data.charger_location !== 'none'; }};
                data.reservation_date = { minValue: function() { return this.data.reservation_date !== 'none'; }};
                data.reservation_duration = { minValue: function() { return this.data.reservation_duration !== 'none'; }};
                global.location = { minValue: function() { return this.location !== 'none'; }};
            }

            if (this.data.bill_is_firm)
            {
                global.vat1 = { required };
                global.vat2 = { required };
                global.vat3 = { required };
            }

            if (this.has_gift_card)
            {
                global.gift_code = { required };
            }
            else
            {
                data.payer_first_name = { required };
                data.payer_last_name = { required };
                data.payer_phone = { required };
                data.payer_email = { required, email };
                data.bill_name = { required };
                data.bill_post_code = { required };
                data.bill_city = { required };
                data.bill_address = { required };
            }

            return Object.assign(global, {
                data: Object.assign(data, {
                    package: { minValue: function() { return this.data.package !== 'none'; }},
                }),
                aszf: { sameAs: sameAs(() => true) },
                adatvedelem: { sameAs: sameAs(() => true) }
            });
        }
    };
</script>

<style scoped>
    .error {
        color: red;
    }

    .btn-lg {
        width: 19.8rem;
        height: 5.5rem;
        font-size: 1.4rem;
        border: 0;
        padding: 0.1rem;
        cursor: pointer;
        color: #fff;
        font-weight: 700;
        text-transform: uppercase;
        text-align: center;
        background: url("~assets/img/btn-reservation-red.png") no-repeat;
        background-size: contain;
    }


    .mb-0 {
        margin-right: 1.5rem;
        margin-left: 3rem;
        margin-top: 4rem;
        margin-bottom: 0;
        font-weight: 700;
    }

    a {
        color: #8e888a;
        text-decoration: none;
        outline: none;
    }

    a:hover, a:focus {
        color: #a7a0a2;
    }

    @media (max-width:767px) {
        a {
            font-size: 25px;
        }
    }

    .x-drop-btn {
        width: 100%;
        border-radius: 30px;
        background-color: white;
        box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.05);
        padding: 9px 1.25rem;
        color: #666;
        font-size: 1.25rem;
        font-weight: 500;
    }

    .x-drop-btn:hover {
        cursor: pointer;
    }

    .x-drop-btn i {
        position: absolute;
        font-size: 32px;
        right: 16px;
    }

    .x-dropdown .dropdown-menu {
        right: 0px;
        border: none;
        padding: 8px 0px;
    }

    [type="checkbox"]:checked + label:before, [type="checkbox"]:not(:checked) + label:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 33px;
        height: 33px;
        border: 2px solid #ddd;
        border-radius: 15%;
        background: #FFFFFF;
        border-color: #000000;
    }

    [type="checkbox"]:checked + label, [type="checkbox"]:not(:checked) + label {
        position: relative;
        padding-left: 45px;
        padding-top: 5px;
        cursor: pointer;
        line-height: inherit;
        display: inline-block;
        color: #000000;
    }

    [type="checkbox"]:checked, [type="checkbox"]:not(:checked) {
        position: absolute;
        left: -9999px;
    }

    [type="checkbox"]:checked + label:after, [type="checkbox"]:not(:checked) + label:after {
        content: '';
        width: 21px;
        height: 21px;
        background: #000000;
        position: absolute;
        top: 6px;
        left: 6px;
        border-radius: 15%;
        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease;
    }

    [type="checkbox"]:not(:checked) + label:after {
        opacity: 0;
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    [type="radio"]:checked, [type="radio"]:not(:checked) {
        position: absolute;
        left: -9999px;
    }

    [type="radio"]:checked + label, [type="radio"]:not(:checked) + label {
        position: relative;
        padding-left: 45px;
        padding-top: 5px;
        cursor: pointer;
        line-height: inherit;
        display: inline-block;
        color: #000000;
    }

    [type="radio"]:checked + label:before, [type="radio"]:not(:checked) + label:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 33px;
        height: 33px;
        border: 2px solid #ddd;
        border-radius: 100%;
        background: #FFFFFF;
        border-color: #000000;
    }

    [type="radio"]:checked + label:after, [type="radio"]:not(:checked) + label:after {
        content: '';
        width: 21px;
        height: 21px;
        background: #000000;
        position: absolute;
        top: 6px;
        left: 6px;
        border-radius: 100%;
        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease;
    }

    [type="radio"]:not(:checked) + label:after {
        opacity: 0;
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    img.center {
        display: block;
        margin: 0 auto;
    }

    .text-uppercase.text-right {
        -webkit-tap-highlight-color: transparent;
        --breakpoint-xs: 0;
        --breakpoint-sm: 36rem;
        --breakpoint-md: 48rem;
        --breakpoint-lg: 62rem;
        --breakpoint-xl: 75rem;
        --font-family-sans-serif: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
        --font-family-monospace: SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
        text-align: right;
        text-transform: uppercase;
        font-family: Raleway;
        font-weight: 500;
        color: #b6b6b6;
        box-sizing: border-box;
        margin-bottom: 1rem;
        font-size: 7.0625rem;
        line-height: 7.0625rem;
        margin-top: -1.875rem;
    }

    .text-uppercase.text-left {
        -webkit-tap-highlight-color: transparent;
        --breakpoint-xs: 0;
        --breakpoint-sm: 36rem;
        --breakpoint-md: 48rem;
        --breakpoint-lg: 62rem;
        --breakpoint-xl: 75rem;
        text-align: left;
        text-transform: uppercase;
        font-family: Raleway;
        font-weight: 500;
        color: #b6b6b6;
        box-sizing: border-box;
        margin-top: 0;
        font-size: 11.875rem;
        line-height: 11.875rem;
        margin-bottom: 0;
    }

    .d-xl-none {
        -webkit-tap-highlight-color: transparent;
        line-height: 1.5;
        text-align: center;
        color: #2b2b2b;
        text-transform: uppercase;
        font-family: Raleway;
        font-weight: 600;
        box-sizing: border-box;
        font-size: 41px;
        padding-bottom: 2rem;
    }

    select.time {
        margin: 10px;
        width: 100%;
        padding: 10px 45px 10px 15px;
        font-size: 16px;
        border: 1px solid #DEE2E6;
        height: 60px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background: url('~assets/img/dropd-clock.png') no-repeat #f8f8f8;
        background-position: right;
        background-size: auto 100%;
        color: #3d3d3d !important;
        border-radius: 5px;
        cursor: pointer;
    }

    select.date {
        margin: 10px;
        width: 100%;
        padding: 10px 45px 10px 15px;
        font-size: 16px;
        border: 1px solid #DEE2E6;
        height: 60px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background: url('~assets/img/dropd-calendar.png') no-repeat #f8f8f8;
        background-position: right;
        background-size: auto 100%;
        color: #3d3d3d !important;
        border-radius: 5px;
        cursor: pointer;
    }

    select.chargerLocation {
        margin: 10px;
        width: 100%;
        padding: 10px 45px 10px 15px;
        font-size: 16px;
        border: 1px solid #DEE2E6;
        height: 60px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background: url('~assets/img/dropd-arrow.png') no-repeat #f8f8f8;
        background-position: right;
        background-size: auto 100%;
        color: #3d3d3d !important;
        border-radius: 5px;
        cursor: pointer;
    }

    select.package {
        margin: 0px;
        width: 100%;
        padding: 10px 45px 10px 15px;
        font-size: 16px;
        border: 1px solid #DEE2E6;
        height: 60px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background: url('~assets/img/dropd-arrow.png') no-repeat #f8f8f8;
        background-position: right;
        background-size: auto 100%;
        color: #3d3d3d !important;
        border-radius: 5px;
        cursor: pointer;
    }

    .btn.active.shadow-none {
        background-image: url("~assets/img/btn-giftcard.png");
        width: 298px;
        height: 74px;
        background-color: rgba(0,0,0,0);
        font-weight: 700;
        font-size: 1.1rem;
        background-repeat: no-repeat;
        background-size: cover;
        color: rgb(255,255,255);
    }

    #accept-order {
        background-image: url("~assets/img/btn-reservation-red.png");
        width: 298px;
        height: 74px;
        background-color: rgba(0,0,0,0);
        font-weight: 700;
        font-size: 1.1rem;
        text-transform: uppercase;
        background-repeat: no-repeat;
        background-size: cover;
        color: rgb(255,255,255);
    }

    #cancel-order {
        background-image: url("~assets/img/btn-reservation-grey.png");
        width: 298px;
        height: 74px;
        background-color: rgba(0,0,0,0);
        font-weight: 700;
        font-size: 1.1rem;
        text-transform: uppercase;
        background-repeat: no-repeat;
        background-size: cover;
        color: rgb(255,255,255);
    }

    .pricing-table {
        padding: 70px 0;
    }

    .pricing-table .package-title {
        color: #4e4e4e;
        font-family: "Montserrat, sans-serif";
        font-weight: 600;
    }

    .pricing-table .package-text {
        margin: 0 0 10px;
    }

    .pricing-table .plan {
        margin-bottom: 70px;
        position: relative;
    }

    .pricing-area ul {
        background: #fafafa;
        margin: 0;
        padding: 0;
    }

    .pricing-area li {
        background: #fafafa;
    }

    .pricing-area .heading {
        margin-left: -10px;
        border-radius: 2px 2px 0 0;
        padding: 17px 0;
    }

    .pricing-area ul li {
        font-size: 16px;
        font-weight: 400;
        list-style: outside none none;
        padding: 15px 0;
        color: #666666;
    }

    .pricing-area h1, .pricing-area .price {
        font-weight: 300;
        margin-bottom: 15px;
        margin-top: 0;
        font-size: 36px;
        color: #ffffff;
    }

    .pricing-area .price {
        font-size: 18px;
        font-weight: 700;
    }

    .pricing-area .best-seller {
        background-image: url("~assets/img/bg-package-ribbon.png");
        background-repeat: no-repeat;
        background-position: top right;
    }

    #app > div > section > div > div > div:nth-child(3) > section > div > div.form-group {
      margin-top: 30px;
    }
</style>

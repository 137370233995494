var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "tours" }, [
    _c(
      "div",
      { staticClass: "tours-header" },
      [_c("top-nav-bar"), _vm._v(" "), _c("header")],
      1
    ),
    _vm._v(" "),
    _vm._m(0),
    _vm._v(" "),
    _vm._m(1),
    _vm._v(" "),
    _vm._m(2),
    _vm._v(" "),
    _vm._m(3),
    _vm._v(" "),
    _vm._m(4),
    _vm._v(" "),
    _c("div", { staticClass: "container sucha-reservation-form" }, [
      _c(
        "a",
        {
          staticClass: "btn active shadow-none",
          attrs: {
            role: "button",
            id: "btn-sucha-res",
            href: _vm.goToH("reservation")
          }
        },
        [_vm._v("IDŐPONTFOGLALÁS")]
      )
    ]),
    _vm._v(" "),
    _vm._m(5),
    _vm._v(" "),
    _vm._m(6),
    _vm._v(" "),
    _c("div", { staticStyle: { "padding-top": "90px" } }, [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "row" }, [
          _vm._m(7),
          _vm._v(" "),
          _c("div", { staticClass: "col-md-6" }, [_c("contact-form")], 1)
        ])
      ])
    ]),
    _vm._v(" "),
    _vm._m(8),
    _vm._v(" "),
    _vm._m(9)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "container sucha-heading" }, [
      _c("h1", [_vm._v("Supercharger túrák")]),
      _vm._v(" "),
      _c("h2", [
        _vm._v("Kapcsold össze a kellemest"),
        _c("br"),
        _vm._v("a hasznossal!")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row sucha-wrapper1" }, [
      _c("div", { staticClass: "col-xl-8 sucha-wrapper1-l" }),
      _vm._v(" "),
      _c("div", { staticClass: "col-xl-4 sucha-wrapper1-r" }, [
        _c("img", { attrs: { src: require("assets/img/line.png") } }),
        _vm._v(" "),
        _c("h1", [
          _vm._v("Lenne kedvetek egy kicsit"),
          _c("br"),
          _vm._v("kiruccanni")
        ]),
        _vm._v(" "),
        _c("h2", [_vm._v("Győrbe, Zólyomra, Pozsonyba vagy Bécsbe (stb.)?")]),
        _vm._v(" "),
        _c("h3", [
          _vm._v("Közben kicsit megtapasztalni a"),
          _c("br"),
          _vm._v("P100D"),
          _c("br"),
          _vm._v("lopakodó fenevadat?")
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "A TeslaPilótánál minderre van lehetőség. Mivel a Teslákkal zökkenőmentesen lehet utazni olyan helyeken, ahol már kiépült a Supercharger hálózat, ezért ilyen helyekre tervezünk túrákat. Ez lehet egyirányú is: csak oda vagy csak vissza is. De\n          lehet oda-vissza út is. Ilyenkor a célállomáson a töltés mellett belefér egy rövidebb séta, max 1-2 órás időtöltés."
          )
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "container sucha-important" }, [
      _c("h1", [_vm._v("Fontos!")]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "Pozsonyi vagy Bécsi (Schwehati) túrák esetén Győrben megállunk rátölteni oda-"
        ),
        _c("br"),
        _vm._v(
          "és visszafelé is. Ez az Arrabona pihenőnél van, az ÖMV töltőállomásoknál. Egész"
        ),
        _c("br"),
        _vm._v(
          "korrekt étkezési lehetőség is van, ha valaki megéhezik a túra közben."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row sucha-wrapper2" }, [
      _c("div", { staticClass: "col-xl-4 sucha-wrapper2-l" }, [
        _c("h1", [_vm._v("Teslapilótával a")]),
        _vm._v(" "),
        _c("h2", [_vm._v("Schwehat"), _c("br"), _vm._v("Reptérre")]),
        _vm._v(" "),
        _c("p", [
          _c("strong", [_vm._v("Ha a Schwehati reptérre mennénk,")]),
          _c("br"),
          _c("strong", [
            _vm._v("és utazol - azaz indul a géped vagy érkezik,")
          ]),
          _c("br"),
          _c("strong", [_vm._v("velünk egy élményvezetéssel kombinálhatod")]),
          _c("br"),
          _c("strong", [_vm._v("az oda- vagy visszajutásod!")])
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "Gondolj csak bele, mennyivel szórakoztatóbb így az utazás! Persze nem muszáj élményvezetned, ez csak egy lehetőség. Szívesen leszünk mi a TeslaPilótád. A Győri SC töltéssel mindenképpen számolni kell (kb. 30-40 perc). Oda is és vissza is be\n          kell iktatni."
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-xl-8 sucha-wrapper2-r" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "container sucha-reservation-head" }, [
      _c("h1", [_vm._v("Foglalj helyet")]),
      _vm._v(" "),
      _c("h2", [_vm._v("A teslapilótaülésben!")]),
      _vm._v(" "),
      _c("p", [
        _vm._v('Válassz a TeslaPilóta "Szupercharger túrák" csomagok közül!'),
        _c("br"),
        _vm._v("Vigyázat! Erősen addiktív élmény. :)")
      ]),
      _c("img", { attrs: { src: require("assets/img/line.png") } })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "container text-center sucha-center-line" },
      [_c("img", { attrs: { src: require("assets/img/line.png") } })]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "container sucha-tel" }, [
      _c("h1", [
        _vm._v("Javasoljuk, hogy SuperCharger túrákról"),
        _c("br"),
        _vm._v("érdeklődj nálunk telefonon:")
      ]),
      _vm._v(" "),
      _c("h2", [_vm._v("+36 30 222 3005")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-6 contact-text" }, [
      _c(
        "h1",
        {
          staticClass: "text-uppercase",
          staticStyle: { "margin-bottom": "0px" }
        },
        [_c("strong", [_vm._v("Üzenetküldés")])]
      ),
      _vm._v(" "),
      _c("h3", { staticClass: "text-uppercase" }, [_vm._v("A Teslapilótának")]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "Ha kérésed vagy kérdésed lenne, akkor küldj üzenetet a Teslapilóta irányítóközpontba! Add meg adataidat, hogy felvehessük veled a kapcsolatot."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("img", {
        staticClass: "center",
        staticStyle: { "padding-top": "8rem" },
        attrs: { src: require("assets/img/img-header-logo-small-gray.png") }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "container", attrs: { id: "followus" } }, [
      _c("div", { staticClass: "d-none d-xl-block" }, [
        _c("div", { staticClass: "text-uppercase" }, [
          _c("div", { staticClass: "container" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-md-4 col-xl-10 offset-xl-2" }, [
                _c("h1", { staticClass: "text-uppercase text-left" }, [
                  _c("strong", [_vm._v("KÖVESS")])
                ])
              ])
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", [
          _c("div", { staticClass: "container" }, [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                {
                  staticClass:
                    "col-md-4 col-xl-2 offset-xl-5 text-right align-self-center"
                },
                [
                  _c(
                    "a",
                    { attrs: { href: "http://www.facebook.com/teslapilota" } },
                    [
                      _c("img", {
                        staticStyle: { "padding-right": "1.875rem" },
                        attrs: { src: require("assets/img/btn-facebook.png") }
                      })
                    ]
                  ),
                  _c(
                    "a",
                    { attrs: { href: "http://www.instagram.com/teslapilota" } },
                    [
                      _c("img", {
                        attrs: { src: require("assets/img/btn-instagram.png") }
                      })
                    ]
                  )
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "col-md-4 col-xl-4 offset-xl-0" }, [
                _c("h1", { staticClass: "text-uppercase text-right" }, [
                  _c("strong", [_vm._v("MINKET")])
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "clearfix" })
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "mobile d-block d-xl-none" }, [
        _vm._v("\n        Kövess minket\n        "),
        _c("div", [
          _c(
            "a",
            {
              attrs: {
                href: "https://www.facebook.com/TeslaPilota/",
                target: "_blank"
              }
            },
            [
              _c("img", {
                attrs: { src: require("assets/img/btn-facebook.png") }
              })
            ]
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              attrs: {
                href: "https://www.instagram.com/teslapilota/",
                target: "_blank"
              }
            },
            [
              _c("img", {
                attrs: { src: require("assets/img/btn-instagram.png") }
              })
            ]
          )
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }